import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { constant } from 'src/app/core/constants';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-lost-reasons',
  templateUrl: './lost-reasons.component.html',
  styleUrls: ['./lost-reasons.component.scss']
})
export class LostReasonsComponent implements OnInit {
  activeId = 1;
  lostResHeading = ['Activity', 'Lead', 'Flow'];
  lostRes_table_cols = [
    {
      name: 'Type',
      is_visible: true
    },
    {
      name: '',
      is_visible: true
    },
    {
      name: 'Flow Name',
      is_visible: false
    },
    {
      name: 'Actions',
      is_visible: true
    }];
  allOutcomeForm!: FormGroup;
  modalRef!: NgbModalRef;
  isSubmitted = false;
  allPipelineLists = [];
  apisEndpoint = constant.activityOutcome;
  allOutcomeLists: Array<any> = [];
  pageIndex = 0;
  totalPages = 0;
  currentPage = 1;
  selectedPipeline: any;
  loggedInUser: any;
  tableColumns: any[] = [];
  tableSkeletonLoader = true;
  constructor(private modalService: NgbModal, private fb: FormBuilder, private settingsService: SettingsService, private confirmationMsz: ConfirmationMszService, private crmService: CrmService) {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  }

  ngOnInit() {
    this.tableColumns =  this.lostRes_table_cols.filter((elem:any) => elem.is_visible);
    this.getAllOutcome(this.apisEndpoint, this.currentPage);
    this.getAllPipelineData();
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
    this.activeId === 3 ? this.lostRes_table_cols[2].is_visible = true : this.lostRes_table_cols[2].is_visible = false;
    this.currentPage = 1;
    this.pageIndex = 0;
    this.getCurrentTabData();
    this.isSubmitted = false;
  }

  onSelectPipeline(event: any) {
    this.allOutcomeForm.patchValue({ pipeline: event.id });
  }

  getAllOutcome(endpoint: string, pages?: any) {
    this.tableSkeletonLoader = true;
    this.settingsService.getAllActivityOutcome(endpoint, pages).subscribe((response: any) => {
      this.tableSkeletonLoader = false;
      this.allOutcomeLists = response['data'];
      if (this.activeId === 3) {
        if (this.allPipelineLists.length > 0) {
          const allOutcome = response['data'].map((data: any) => {
            const pipeline = this.allPipelineLists.find((ele: any) => ele.id === data.pipeline);
            pipeline ? data['pipeline_name'] = pipeline['name'] : data['pipeline_name'] = 'NA';
            return data;
          });
          this.allOutcomeLists = allOutcome;
        }
      }
      this.totalPages = response.count || response.total;
    });
  }

  updateOutcome(endpoint: string) {
    if (this.loggedInUser.data.role === 'ADMIN') {
      const data = this.getPayloadData();
      let id = '';
      if (this.lostResHeading[this.activeId - 1] !== 'Activity') {
        id = data['id'];
      }
      this.settingsService.updateActivityOutcome(data, endpoint, id).subscribe(() => {
        this.getAllOutcome(endpoint, this.currentPage);
        this.modalRef.dismiss();
      });
    }
  }

  addOutcome(endpoint: string) {
    if (this.loggedInUser.data.role === 'ADMIN') {
      const data = this.getPayloadData();
      this.settingsService.postActivityOutcome(data, endpoint).subscribe(() => {
        this.getAllOutcome(endpoint, this.currentPage);
        this.modalRef.dismiss();
      });
    }
  }

  onDelete(outcome: any) {
    if (this.loggedInUser.data.role === 'ADMIN') {
      this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: `All the existing ${outcome.outcome_text || outcome.reason_text} will get deleted permanently!`, button: 'Yes Delete!' })
        .then((data) => {
          if (data.value)
            this.settingsService.deleteActivityOutcome(outcome.id, this.apisEndpoint).subscribe(() => {
              this.getAllOutcome(this.apisEndpoint, this.currentPage);
            });
        });
    }
  }

  getAllPipelineData() {
    this.tableSkeletonLoader = true;
    this.crmService.getPipelines().subscribe((response: any) => {
      this.tableSkeletonLoader = false;
      this.allPipelineLists = response['data'];
    });
  }

  getCurrentTabData() {
    this.allOutcomeLists = [];
    this.apisEndpoint = (this.lostResHeading[this.activeId - 1] === 'Activity') ? constant.activityOutcome : (this.lostResHeading[this.activeId - 1] === 'Lead') ? constant.crmEnquiryLostReason : constant.crmPipelineLostReason;
    this.getAllOutcome(this.apisEndpoint, this.currentPage);
  }

  getPayloadData() {
    let data: any = {
      outcome_text: this.allOutcomeForm.value.outcome_text
    };
    this.allOutcomeForm.value.id && (data['id'] = this.allOutcomeForm.value.id);

    //updating data field as payload variable key name changed from outcome_text to reason_text

    if (this.lostResHeading[this.activeId - 1] !== 'Activity') {
      // const source = this.lostResHeading[this.activeId - 1] === 'Enquiry' ? 'lead' : 'pipeline';
      data = {
        reason_text: this.allOutcomeForm.value.outcome_text
        // source: source
      };
      this.allOutcomeForm.value.id && (data['id'] = this.allOutcomeForm.value.id);
      if (this.lostResHeading[this.activeId - 1] === 'Flow') {
        data['pipeline'] = this.allOutcomeForm.value.pipeline;
      }
    }
    return data;
  }

  onAdd(content: any, data?: any) {
    if (this.loggedInUser.data.role === 'ADMIN') {
      this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
      this.initForm(data);
      this.lostResHeading[this.activeId - 1] === 'Flow' ? this.updateFromField(data) : this.removeFormField();
    }
  }

  get f() {
    return this.allOutcomeForm.controls;
  }

  initForm(data?: any) {
    this.allOutcomeForm = this.fb.group({
      outcome_text: [data ? data.outcome_text || data.reason_text : '', [Validators.required]],// Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      id: [data ? data.id : '']
    });
  }

  updateFromField(data: any) {
    this.selectedPipeline = null;
    this.allOutcomeForm.addControl('pipeline', new FormControl('', [Validators.required]));
    if (data && data.pipeline) {
      const selectedPipeline: any = this.allPipelineLists.find((elem: any) => elem.id === data.pipeline);
      this.allOutcomeForm.patchValue({ pipeline: data.pipeline });
      this.selectedPipeline = selectedPipeline.id;
      // this.selectedPipeline = JSON.parse(JSON.stringify(selectedPipeline));
    }
  }

  removeFormField() {
    this.allOutcomeForm.contains('pipeline') && this.allOutcomeForm.removeControl('pipeline');
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.allOutcomeForm.valid) {
      this.isSubmitted = false;
      if (this.allOutcomeForm.value.id) {
        this.updateOutcome(this.apisEndpoint);
      } else {
        this.addOutcome(this.apisEndpoint);
      }
    }
  }

  openEditModal(target: any, data: any) {
    this.onAdd(target, data);
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
      this.pageIndex = e.pageIndex++;
    } else if (e.pageIndex === 0) {
      this.currentPage = 1;
      this.pageIndex = 0;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
      this.pageIndex = e.pageIndex--;
    }
    this.getAllOutcome(this.apisEndpoint, this.currentPage);
  }

}
