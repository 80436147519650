import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray, FormGroupDirective } from '@angular/forms';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ACTIVITYJSON } from '../../../../core/data/crm_modal_json';
import { CommonService } from 'src/app/core/service/common.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ClientsService } from 'src/app/core/service/clients.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { PageEvent } from '@angular/material/paginator';
import { ActivityMarkedModelComponent } from 'src/app/pages/shared/activity-marked-model/activity-marked-model.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  @Input() isModal!: string;
  @Output() closeModalEvent = new EventEmitter<string>();
  activityJSON = ACTIVITYJSON[0];
  errorjson = ERRORJSON[0];
  selected_id!: string;
  modalRef: any;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  // selected_type!: string;
  activity_show = false;
  activity_type = '';
  associated_dropdown = 'All';
  currentActiveActivity: any;
  currentActivityIndex = -1;
  allActivityOutcomeList = [];
  submitedActivityForm = false;
  activity_form!: FormGroup;
  activity_filter = 'all';
  activityList_filter = 'all';
  activitiesArray: any = [];
  staffMembers: any = [];
  time_slotes = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM'
  ];
  source_id_details: any;
  info: any = {};
  loggedInUser: any;
  guestsMeetingList: any = [];
  minDate = new Date();
  activities_total_count!: number;
  pageEvent!: PageEvent;
  currentPage = 0;
  allActivityLists: any;
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };
  activityId = '';

  constructor(private modalService: NgbModal, private location: Location, private route: ActivatedRoute, private service: CommonService, private client_service: ClientsService, private settingsService: SettingsService, private confirmationMsz: ConfirmationMszService, private renderer: Renderer2, private toastAlert: ToastrService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      // this.selected_type = params.showtype;
      if (this.selected_id) {
        this.initlizeActivityForm();
        this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
        this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
        this.organisationMembersList();
        const activityId = new URLSearchParams(window.location.search).get('activityId');
        if (params?.activityId && activityId) {
          this.activityId = params.activityId;
          this.getSelectedActivitesById(this.activityId);
        } else {
          this.getAllActivites(this.selected_id, 1);
        }
        this.getAllActivity();
        this.client_service.getProjectDetailsById(this.selected_id).subscribe({
          next: (resp: any) => {
            this.source_id_details = resp.data;
            this.activity_form.patchValue({ source_id: this.source_id_details.id });
          }
        });
      }
    });
    if (this.isModal) {
      console.log('called to check');
      this.activity_show = true;
      // this.activity_type = this.allActivityLists[0].name.split(" ").join("").toLowerCase(); My line ingore this one
    }
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  getAllActivites(id: string, page: number) {
    this.service.getActivitysById(id, page).subscribe({
      next: (resp: any) => {
        // console.log("get activities - ", resp);
        this.activities_total_count = resp.count;
        this.activitiesArray = resp.data;
      }
    });
  }

  getSelectedActivitesById(id: string) {
    this.service.getSelectedActivitesById(id).subscribe({
      next: (resp: any) => {
        // console.log("get activities - ", resp);
        this.activities_total_count = resp.count;
        this.activitiesArray = [resp.data];
      },
      error: (err: any) => {
        if (err && err?.data.length === 0) {
          this.clearFilter();
        }
      }
    });
  }

  clearFilter() {
    const currentUrl = this.location.path();
    const updatedUrl = currentUrl.replace(/[?&]activityId=[^&]+/, '').replace(/[?&]activeId=[^&]+/, '');
    this.location.replaceState(updatedUrl);
    this.activityId = '';
    this.getAllActivites(this.selected_id, 1);
  }

  getAllActivity() {
    this.settingsService.getAllActivities().subscribe((response: any) => {
      this.allActivityLists = response.data;
      console.log(this.allActivityLists);
    });
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }

  createActivity(body: any, source_type: string, formDirective: any) {
    let department: any;
    for (const item of this.staffMembers) {
      if (item.id === body.owner) {
        this.info['owner_info'] = {
          fname: item.fname,
          lname: item.lname
        };
        department = item.department_info.id;
      }
    }
    this.service.createActivity({ ...body, source_type: source_type, info: this.info, department: department }).subscribe({
      next: () => {
        this.activity_show = false;
        this.closeModalEvent.emit('close');
        this.guestsMeetingList = [];
        // console.log(this.guestsMeetingList,"meetingslist");

        this.activityId ? this.clearFilter() : this.getAllActivites(this.selected_id, 1);
        formDirective.resetForm();
        this.activity_form.reset();
        // console.log(this.activity_form.value);

        this.submitedActivityForm = false;
        this.initlizeActivityForm();
        this.activity_form.patchValue({ type: this.currentActiveActivity.id });
        this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
        this.activity_form.patchValue({ source_id: this.source_id_details.id });

      }
    });
  }

  currentActivity(content: any, formDirective: FormGroupDirective, index: number) {
    this.submitedActivityForm = false;
    if (this.currentActiveActivity?.id === content?.id) {
      this.activity_show = !this.activity_show;
      this.activity_show ? (this.currentActivityIndex = index) : (this.currentActivityIndex = -1);
    } else {
      this.activity_show = true;
      this.currentActivityIndex = index;
    }
    this.currentActiveActivity = content;
    formDirective.resetForm();
    this.activity_form.reset();
    this.initlizeActivityForm();
    this.activity_type = content.name.split(" ").join("").toLowerCase();
    console.log(this.currentActiveActivity);
    this.activity_form.patchValue({ type: this.currentActiveActivity.id });
    this.activity_form.patchValue({ owner: this.loggedInUser.data.id });
    this.activity_form.patchValue({ source_id: this.source_id_details.id });
    if (content.name === 'Meeting') {
      this.activity_form.get('location')?.addValidators([Validators.required]);
      this.activity_form.get('location')?.updateValueAndValidity();
      this.activity_form.get('meeting_link')?.addValidators([Validators.required]);
      this.activity_form.get('meeting_link')?.updateValueAndValidity();
    } else {
      this.activity_form.get('location')?.clearValidators();
      this.activity_form.get('location')?.markAsUntouched();
      this.activity_form.get('location')?.updateValueAndValidity();
      this.activity_form.get('meeting_link')?.clearValidators();
      this.activity_form.get('meeting_link')?.markAsUntouched();
      this.activity_form.get('meeting_link')?.updateValueAndValidity();
    }

    // console.log(this.activity_form.value,"value",this.guestsMeetingList);

  }

  initlizeActivityForm() {
    this.activity_form = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      due_date: new FormControl(''),
      source_id: new FormControl(this.selected_id, Validators.required),
      // source_name: new FormControl({ value: '', disabled: true }),
      owner: new FormControl('', Validators.required),
      source_type: new FormControl({ value: 'project', disabled: true }, Validators.required),
      type: new FormControl('', Validators.required),
      location: new FormControl(''),
      meeting_link: new FormControl(''),
      guest: new FormArray([]),
      description: new FormControl('')
    });
  }

  get guestList() {
    return this.activity_form.get('guest') as FormArray;
  }

  addMailsToTheList(mail: any) {
    this.guestList.push(new FormControl(mail));
  }

  addnew = (option: any) => ({ mail: option });

  onSelectEmail(e: any) {
    // console.log("event", e);
    this.guestsMeetingList = e;
  }

  get f() {
    return this.activity_form.controls;
  }

  submitActivityForm(formDirective: FormGroupDirective) {
    // console.log(this.activity_form);

    this.submitedActivityForm = true;
    if (this.activity_form.valid) {
      if (this.guestsMeetingList) {
        this.guestsMeetingList.map((data: any) => {
          this.addMailsToTheList(data.mail);
        });
      }
      this.createActivity(this.activity_form.value, this.activity_form.value.source_type ? this.activity_form.value.source_type : this.activity_form.controls["source_type"].value, formDirective);
    }
  }

  updateActivity(id: any, status: boolean) {
    this.modalRef = this.modalService.open(ActivityMarkedModelComponent, { size: 'md', centered: true });
    this.modalRef.componentInstance.data = { id, status };
    this.modalRef.closed.subscribe((data: any) => {
      data && this.activityId ? this.getSelectedActivitesById(this.activityId) : this.getAllActivites(this.selected_id, 1);
    });
    // this.service.updateActivity(id, { mark_as_done: status }).subscribe(() => {
    //   this.getAllActivites(this.selected_id, 1);
    // });
  }

  deleteActivity(id: string) {
    this.service.deleteActivty(id).subscribe(() => {
      this.activityId ? this.clearFilter() : this.getAllActivites(this.selected_id, 1);
    });
  }

  confirmDelete(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteActivity(fileId);
      }
    });
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }
  onOwnerchange() {
    if (this.activity_form.controls['location'].value === 'google') {
      this.getConnectivityTest('google');
    } 
  }
  
  onSelectMode(event: any) {
    const meetingLink = document.querySelector('.meeting-link');
    if (event.value !== 'custom') {
      if (event.value === "google") {
        this.getConnectivityTest('google');
      } else if (event.value === 'zoom') {
        this.getConnectivityTest('zoom');
      } else {
        this.activity_form.get('meeting_link')?.clearValidators();
        this.activity_form.get('meeting_link')?.markAsUntouched();
        this.activity_form.get('meeting_link')?.updateValueAndValidity();
        if (meetingLink) {
          this.renderer.setStyle(meetingLink, 'opacity', '0');
          setTimeout(() => {
            this.renderer.setStyle(meetingLink, 'display', 'none');
          }, 400);
        }
      }
    } else {
      this.renderer.setStyle(meetingLink, 'display', 'block');
      this.renderer.setStyle(meetingLink, 'opacity', '1');
      this.activity_form.get('meeting_link')?.addValidators([Validators.required]);
      this.activity_form.get('meeting_link')?.updateValueAndValidity();
    }
  }
  getConnectivityTest(type: string) {
    let connectivityTest;
    let message = '';
    if (type === 'google') {
      connectivityTest = this.settingsService.staffConnected(this.activity_form.value.owner);
      message = 'Staff not connected to Gmail';
    } else {
      connectivityTest = this.settingsService.zoomCoonectivity(this.loggedInUser.data.organisation);
      message = 'The organization is not connected to Zoom.';
    }
    connectivityTest.subscribe({
      next: (resp: any) => {
        if (resp.data && resp.data?.isConnected) {
          const meetingLink = document.querySelector('.meeting-link');
          this.activity_form.get('meeting_link')?.clearValidators();
          this.activity_form.get('meeting_link')?.markAsUntouched();
          this.activity_form.get('meeting_link')?.updateValueAndValidity();
          if (meetingLink) {
            this.renderer.setStyle(meetingLink, 'opacity', '0');
            setTimeout(() => {
              this.renderer.setStyle(meetingLink, 'display', 'none');
            }, 400);
          }
        } else {
          this.toastAlert.error(message);
          this.activity_form.patchValue({ location: 'custom' });
          this.onSelectMode({ value: 'custom' });
        }
      },
      error: (err: any) => {
        console.log(err);
        this.activity_form.patchValue({ location: 'custom' });
        this.onSelectMode({ value: 'custom' });
      }
    });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getAllActivites(this.selected_id, this.currentPage);
  }

}
