import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AwsUtilsService {

  constructor(private http: HttpClient) { }

  getUploadFileLink(fileType: any,fileExtention: any) {
    return this.http.get<any>(`${environment.urlPrefix}${constant.S3presignedUrl}?content_type=${fileType}&extention=${'.'+fileExtention}`);
  }

  uploadFile(file: File, url: string) {
    return this.http.put<any>(`${url}`, file);
  }

  chatbotFile(body: any){
    return this.http.post<any>(`${environment.messageUrl}${constant.chatbot_fileupload}`,body);
  }
}
