<div class="notification-badge bg-color-noti pointer" (click)="onOpenViewInModal()"
    *ngIf="showNotificationToolbar === 'visible' && (pushNotificationLists.length > 0 || pushAudioVideoNotifications.length > 0)"
    [@slideOutAnimationNotifications]="showNotificationToolbar">
    <button class="bg-color-noti" mat-raised-button color="primary"
        [matBadge]="pushNotificationLists.length + pushAudioVideoNotifications.length" matBadgePosition="before"
        matBadgeColor="accent">
    </button>
</div>

<div class="msg-notifications">
    <div *ngFor="let notification of pushNotificationLists;let i = index">
        <div class="new-message-notifications animation  border-radius-16  bg-color-noti pointer"
            [id]="'notification'+i" (mouseenter)="showCancel[i] = true" (mouseleave)="showCancel[i] = false"
            (click)="openAllNotificationsModal(pushNotificationLists.length)">
            <div class="bg-color-noti" [ngClass]="{'cancel-btn':showClearAll}"
                (click)="removeNotifications(pushNotificationLists.length)"
                (mouseenter)="(pushNotificationLists.length > 1 ? showClearAll = true : showClearAll = false)"
                (mouseleave)="(pushNotificationLists.length > 1 ? showClearAll = false : showClearAll = false)"
                *ngIf="showClearAll || showCancel[i]">
                <mat-icon class="close-btn font-size-16 bg-color-noti pointer"
                    *ngIf="showCancel[i] && !showClearAll">close</mat-icon>
                <p class="font-size-7 m-0 pointer border-radius-50 "
                    *ngIf="pushNotificationLists.length > 1 && showClearAll">Clear all</p>
            </div>
            <div class="message-content">
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <div class="d-flex align-items-center">
                        <mat-icon> chat</mat-icon>
                        <p class="m-0 font-size-16 ps-1" *ngIf="notification?.message?.payload?.mentions?.length === 0 && !notification?.customText_heading">A new
                            message received</p>
                        <p class="m-0 font-size-16 ps-1" *ngIf="notification?.customText_heading">{{notification?.customText_heading}}</p>
                        <p class="m-0 font-size-16 ps-1" *ngIf="notification?.message?.payload?.mentions?.length > 0 && !notification?.customText_heading">Someone
                            mentioned you</p>
                    </div>
                    <p class="m-0 font-size-12">{{notification?.formatedTime}}</p>
                </div>
                <div>
                    <p class="m-0 font-size-14 mb-2 text-capitalize">{{notification?.userName}} <span *ngIf="notification.requestType === 'exitChat'" class="ms-1 text-lowercase ">left the chat.</span></p>
                    
                    <p class="m-0 font-size-14"
                        [ngClass]="(notification.requestType === 'addUser' || notification.type === 'transferInitiated') ? 'd-flex':'limit-line-length-1'">
                        {{notification?.message?.payload?.text ||
                        notification?.customText}} <span
                            *ngIf="notification.requestType === 'addUser' || notification.type === 'transferInitiated'"><mat-icon
                                class="snooz-icon ms-2">notification_important</mat-icon></span></p>
                </div>
            </div>
        </div>
    </div>
</div>

<audio loop muted #audioElement>
    <source [src]="'assets/files/voice_call.mp3'" type="audio/mpeg">
    Your browser does not support the audio element.
</audio>

<div class="call-notifications">
    <ng-container *ngFor="let notification of pushAudioVideoNotifications;let i = index">
        <div class="new-call-notifications border-radius-16 animation bg-color-noti">
            <div class="message-content">
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <div class="d-flex align-items-center justify-content-between">
                        <mat-icon *ngIf="notification.callType === 'audio'"> phone_callback</mat-icon>
                        <mat-icon *ngIf="notification.callType === 'video'">video_call</mat-icon>
                        <p class="m-0 font-size-16 ps-1 text-capitalize">Incoming {{ notification?.callType}} Call </p>
                    </div>
                    <mat-icon class="pointer snooz-icon" (click)="muteCall()">snooze</mat-icon>
                </div>
                <p class="m-0 font-size-14 pb-3">{{notification?.userName}}</p>
                <div class="d-flex align-items-center justify-content-between">
                    <button
                        class="w-45 border-radius-8 text-green py-2 d-flex align-items-center justify-content-center call-action pointer "
                        (click)="onAcceptCall(i)">
                        <mat-icon class="me-2"> call</mat-icon> Accept</button>
                    <button
                        class="w-45 border-radius-8 text-danger py-2 d-flex align-items-center justify-content-center call-action pointer"
                        (click)="onRejectCall(i)">
                        <mat-icon class="me-2"> call_end</mat-icon> Reject</button>
                </div>
            </div>
        </div>
    </ng-container>
</div>