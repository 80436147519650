export const APPOINTMENTJSON = [
  {
    schedule: 'Schedule',
    reschedule: 'Reschedule',
    heading_label: 'a slot',
    email_label: 'Email',
    email_placeholder: 'Enter Contact Email',
    name_label: 'Contact Name',
    name_placeholder: 'Contact Name',
    mobile_label: 'Phone Number',
    mobile_placeholder: 'Phone Number',
    category_label: 'Category',
    category_placeholder: 'Select Category',
    service_label: 'Schedulers',
    service_placeholder: 'Select Scheduler',
    assigned_staff_label: 'Assigned Staff',
    assigned_staff_placeholder: 'Select Staff',
    staff: 'Staff',
    appointment_type_label: 'Scheduler Type',
    appointment_type_placeholder: 'Select Scheduler Type',
    appointment_details_label: 'Scheduler Details',
    details_placeholder: 'Select Scheduler Details',
    date_label: 'Date',
    date_placeholder: 'Select Date',
    time_label: 'Time',
    time_placeholder: 'Select Time',
    payment_label: 'Payment Type',
    payment_placeholder: 'Select Payment Type',
    close_btn: 'Close',
    save_btn: 'Save',
    update_btn: 'Update'
  }
];