<div class="modal-header">
    <h5 class="modal-title">Call </h5><!--with (number)-->
    <button type="button" class="btn-close" aria-label="Close" (click)="modalService.dismissAll()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="feedForm">
        <div class="row px-2">
            <div class="col-12">
                <h6 class="fw-600">Call Details</h6>
            </div>
            <div class="col-12 mb-2">
                <label class="mb-2 fw-600">Contact </label><!--<small class="text-danger">*</small>-->
                <ng-select class="form-control" [items]="contactsList" bindLabel="first_name" bindValue="id"
                    [multiple]="false" placeholder='Select' formControlName="contact" (click)="$event.stopPropagation()"
                    (search)="searchSubject.next($event.term)">
                    <ng-template ng-option-tmp let-item="item">
                        {{item.first_name}} {{item.last_name}} <br />
                        <small>{{item.ccode}} {{item.phone_number}}</small>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-12">
                <label class="mb-2 fw-600">Call Purpose</label>
                <mat-form-field appearance="outline" class="mat-hint-none w-100 mb-3">
                    <mat-select placeholder="select purpose" formControlName="purpose">
                        <mat-option value='other call'>Other Call</mat-option>
                        <mat-option value='opportunity call'>Opportunity Call</mat-option>
                        <mat-option value='prospecting call'>Prospecting Call</mat-option>
                        <mat-option value='support call'>Support Call</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <label class="mb-2 fw-600">Call Disposition</label>
                <mat-form-field appearance="outline" class="mat-hint-none w-100 mb-3">
                    <mat-select placeholder="select disposition" formControlName="disposition">
                        <mat-option [value]=item *ngFor="let item of disposition">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <label class="mb-2 fw-600">Notes</label>
                <textarea class="form-control" rows="3" formControlName="note" placeholder="Enter notes"></textarea>
            </div>
            <div class="col-12 mt-3">
                <h6 class="fw-600">Feed Back</h6>
            </div>
            <div class="feed-back">
                <p class="">How was your call experience? (optional)</p>
                <div class="d-flex gap-3">
                    <div class="card p-3 text-center cursor" (click)="callExpience('Great')" [ngClass]="{'selectedemoji': feedForm.controls['experience'].value === 'Great'}">
                        <!-- <mat-icon> insert_emoticon</mat-icon> -->
                        <mat-icon class="m-auto">tag_faces</mat-icon>
                        Great
                    </div>
                    <div class="card p-3 text-center cursor" (click)="callExpience('Average')" [ngClass]="{'selectedemoji': feedForm.controls['experience'].value === 'Average'}">
                        <mat-icon class="m-auto">sentiment_satisfied</mat-icon>
                        Average
                    </div>
                    <div class="card p-3 text-center cursor" (click)="callExpience('Poor')" [ngClass]="{'selectedemoji': feedForm.controls['experience'].value === 'Poor'}">
                        <!-- <mat-icon>mood_bad</mat-icon> -->
                        <mat-icon class="m-auto">sentiment_very_dissatisfied</mat-icon>
                        Poor
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>
<div class="modal-footer border-0 d-flex justify-content-end pt-0">
    <button type="button" class="d-flex align-items-center btn btn-primary small" (click)="submitForm()">
        Save
    </button>
</div>