import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { CampaignComponent } from './campaign.component';
import { ContactGroupsComponent } from './contact-groups/contact-groups.component';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { map } from 'rxjs';
import { AuthGuardService } from 'src/app/core/service/auth-guard.service';

const routes: Routes = [
  {
    path: 'campaign',
    component: CampaignComponent,
    canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('campaign').pipe(map(is_assessable =>  is_assessable  || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'group',
    component: ContactGroupsComponent,
    canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('campaign_group').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'contact-list',
    component: ContactsListComponent,
    canMatch: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('contacts_list').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignRoutingModule { }
