import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { maximumNumberAllowed, onOnlyNumbers } from 'src/app/core/common/common-functions';
import { REGISTERJSON } from 'src/app/core/data/authorization_json';
import { mobile_json } from 'src/app/core/data/mobile_json';

@Component({
  selector: 'app-mobile-input',
  templateUrl: './mobile-input.component.html',
  styleUrls: ['./mobile-input.component.scss']
})
export class MobileInputComponent implements OnInit {
  public bankFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  mobilejson = mobile_json;
  registorjson= REGISTERJSON[0];
  @Input() control!: any;

  ngOnInit() {
    console.log(this.control,"control");
    if (this.control.value !== '') {
      mobile_json.forEach(ele => {
        if (this.control.value && this.control.value.indexOf(ele.country_code) !== -1) {
          // this.ccode = ele.country_code;
          // this.number = this.control.value.split(ele.country_code)[1];
          return;
        }
      });
      // if(!this.number) this.number = this.control.value;
    }
    this.filteredBanks.next(this.mobilejson.slice());
    console.log(this.filteredBanks,"control");
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
  }

  protected filterBanks() {
    if (!this.mobilejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.mobilejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.mobilejson.filter(bank => (bank.country_code.toLowerCase().indexOf(search) > -1) ||
        bank.country.toLowerCase().indexOf(search) > -1)
    );
  }

  keyDownEvent(event: any) {
    const maxNum = maximumNumberAllowed(event, 12);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange() {
    this.control.patchValue('');
  }
}
