<div class="container-fluid">
    <div *ngIf="loggedInUser.data.role === 'ADMIN'">


        <div class="d-flex align-items-top justify-content-between mt-3">
            <div class="heading font-size-16 fw-700 ">Activity Type</div>
            <div class="d-flex align-items-center justify-content-around">
                <button class="btn btn-primary d-flex align-items-center me-3"
                    [disabled]="loggedInUser.data.role !== 'ADMIN'" (click)="openAddActivityModal(activity_modal)">
                    <mat-icon>add</mat-icon>Activity Type
                </button>
                <mat-form-field appearance="outline" class="tagsfilter ms-3 mat-hint-none d-none">
                    <mat-select placeholder="All">
                        <mat-option value="All">All</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mt-3 d-flex flex-column justify-content-between user-settings-div-height">
            <div class="table-responsive table-height">
                <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="activity_table_cols.length"
                    [fromLocation]="'ACTIVITY'"></app-table-skeleton>
                <table *ngIf="!tableSkeletonLoader" class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
                    <thead>
                        <tr class="position-sticky">
                            <th scope="col" class="text-primary pb-3" *ngFor="let col of activity_table_cols">{{col}}
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="activityData && activityData.length > 0">
                        <tr class="" *ngFor="let activity of activityData;let i = index">
                            <td class="text-capitalize">{{activity.name}}</td>
                            <td>
                                <label class="switch ms-3">
                                    <input type="checkbox" [checked]="activity.status"
                                        (change)="onToggleChange($event,activity)">
                                    <span class="slider round"></span>
                                </label>
                            </td>
                            <td>
                                <div class="d-flex align-items-center" *ngIf="activity.name !== 'Meeting'">
                                    <mat-icon class="me-2 font-size-18 cursor"
                                        (click)="openEditActivityModal(activity_modal,activity!)">edit</mat-icon>
                                    <mat-icon class="font-size-18 cursor"
                                        (click)="onDeleteActivity(activity)">delete</mat-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <mat-paginator [length]="totalPages" [pageSize]="20" (page)="page($event)" aria-label="Select page"
                    hidePageSize="true" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </div>
    <ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>
<ng-template #activity_modal let-modal>
    <div class="modal-header pb-0 border-0">
        <h6 class="modal-title text-primary fw-600" id="label">{{activityForm.value?.id ? 'Update' : 'Add'}} Activity Type
        </h6>
        <mat-icon class="cursor" (click)="modalRef.close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <form [formGroup]="activityForm" (ngSubmit)="onSubmit()">
            <label class="mb-2 fw-500 text-primary">Activity type name <small class="text-danger">*</small></label>
            <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput placeholder="Enter activity type name" formControlName="name" />
                <mat-error class="error d-flex align-items-center" *ngIf="isSubmitted && f['name'].errors">
                    <mat-icon class="font-size-12 me-2">info</mat-icon>
                    <span *ngIf="f['name'].errors['required']">Activity type name is required</span>
                    <span *ngIf="f['name'].errors['pattern']">Invalid activity type name</span>
                </mat-error>
            </mat-form-field>
            <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </form>
    </div>
</ng-template>