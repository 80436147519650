<div class="container-fluid">
    <div class="row px-2" *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="col-md-12">
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="i+1" class="me-2" *ngFor="let plan of subscriptionHeading;let i = index">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">{{plan.name}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="container-fluid overflow-auto">
    <div class="row px-2 profile-div-height pb-3" *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="col-md-12">
            <!-- <h6 class="fw-600">Select subscription billing cycle</h6>
            <h6 class="font-size-14">Save 60% with an annual billing cycle</h6> -->
            <div class="row mt-3" *ngIf="currentViewPlan !== 'life_time'">
                <div class="col-md-4 pb-4" *ngFor="let item of manage_plans">
                    <div class="card h-100vh h-100">
                        <div class="p-3">
                            <h5 class="fw-600">{{item.name}}</h5>
                            <p class="text-secondary">{{item.short_description}}</p>
                            <div class="d-flex mb-4">
                                <label class="font-size-24">{{item.currency_symbol}} {{item.price}} <span
                                        class="font-size-14 text-secondary">{{currentViewPlan !== 'free' ? '/ Monthly'
                                        :'/ Lifetime'}} </span></label>
                            </div>
                            <button class="btn w-100 dotted-border-1" type="button"
                                *ngIf="currentPlan.includes(item.id)">
                                Current Plan
                            </button>
                            <button class="btn w-100 btn-primary" type="button" *ngIf="!currentPlan.includes(item.id)"
                                (click)="openPlanDetailsModal(planDetailsModal, item)">
                                Subscribe
                            </button>
                        </div>
                        <hr>
                        <div class="p-3 pt-0">
                            <label class="fw-600 mb-2">Features</label>
                            <div class="d-flex align-items-center mt-2" *ngFor="let feature of item.plan_features">
                                <mat-icon
                                    class="me-2 font-size-16 d-flex align-items-center justify-content-center">check</mat-icon>
                                <label>{{feature.feature_text}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="currentViewPlan === 'life_time'">
                <div *ngFor="let item_type of manage_plans" class="row mt-3 border-bottom">
                    <h4 class="fw-600 mb-3">{{item_type.type}}</h4>
                    <div class="col-md-4 pb-4" *ngFor="let item of item_type.values">
                        <div class="card h-100vh h-100">
                            <div class="p-3">
                                <h5 class="fw-600" *ngIf="item.name !== 'doNot_display'">{{item.name}}</h5>
                                <p class="text-secondary">{{item.short_description}}</p>
                                <div class="d-flex mb-4">
                                    <label class="font-size-24">{{item.currency_symbol}} {{item.price}} <span
                                            class="font-size-14 text-secondary">/ Lifetime</span></label>
                                </div>
                                <button class="btn w-100 dotted-border-1" type="button"
                                    *ngIf="currentPlan.includes(item.id)">
                                    Current Plan
                                </button>
                                <button class="btn w-100 btn-primary" type="button"
                                    *ngIf="!currentPlan.includes(item.id)"
                                    (click)="openPlanDetailsModal(planDetailsModal, item.id)">
                                    Subscribe
                                </button>
                            </div>
                            <hr>
                            <div class="p-3 pt-0">
                                <label class="fw-600 mb-2">Features</label>
                                <div class="d-flex align-items-center mt-2" *ngFor="let feature of item.plan_features">
                                    <mat-icon
                                        class="me-2 font-size-16 d-flex align-items-center justify-content-center">check</mat-icon>
                                    <label>{{feature.feature_text}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template let-modal let-c="close" #planDetailsModal let-planDetails="planDetails">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Subscription Plan Details</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="c('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <h6 class="">
                    Since the billing cycle starts on the 1st of every month, we're only charging
                    <b>{{selectedPlanDetails?.currency_symbol}}{{selectedPlanDetails?.prorated_plan_price}}</b>
                    for the next <b>{{selectedPlanDetails?.remaining_days}}</b> days.
                </h6>
                <div class="d-flex justify-content-end mt-3">
                    <button class="btn btn-primary" type="button" (click)="subscriptionSubmit(selectedPlanData.id,selectedPlanData.plan_type)"><!--(click)="subscriptionSubmit(item.id,item.plan_type)"-->
                        Subscribe
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>