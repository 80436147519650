import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/service/auth.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrmService } from 'src/app/core/service/crm.service';
import { EmailModalComponent } from '../../shared/email-modal/email-modal.component';

@Component({
  selector: 'app-client-managment',
  templateUrl: './client-managment.component.html',
  styleUrls: ['./client-managment.component.scss']
})
export class ClientManagmentComponent implements OnInit,AfterViewInit {
  activeId: any = 1;
  featuresData: any;
  date!: Date;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  source_type: any;
  source_id: any;
  contact_id: any;
  source_email = '';
  email_converstion: any[] = [];
  modalRef: any;
  headerHeight = 0;

  constructor(private route: ActivatedRoute, private authService: AuthService, private crmService: CrmService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getFeatues();
    this.route.queryParams.subscribe((params: any) => {
      if (params?.activeId) {
        this.activeId = +params?.activeId;
      }
    });

    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    this.date = new Date();
    this.route.queryParams.subscribe((params: any) => {
      this.source_id = params.id;
      this.source_type = params.showtype;
      this.contact_id = params.contact_id;
    });
    this.getMailTemplate();
    this.crmService.registeredEmailId.subscribe((data: any) => {
      this.source_email = data?.email;
    });
  }
  
  tabChange(event: any) {
    this.activeId = event.nextId;
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
      if(this.featuresData?.project_timeline) {
        this.activeId = 1;
      } else if (this.featuresData?.project_task && this.featuresData['create_task']) {
        this.activeId = 2;
      } else if(this.featuresData?.project_notes) {
        this.activeId = 3;
      } else if (this.featuresData?.project_activities && this.featuresData['home_activity']) {
        this.activeId = 4;
      } else if(this.featuresData?.project_files) {
        this.activeId = 5;
      }
    });
  }
  
  getMailTemplate() {
    const body = {
      source_id: this.source_id,
      source_type: this.source_type
    };
    this.crmService.getCommunicationsMails(body).subscribe((resp: any) => {
      this.email_converstion = resp.data;
      const emailsLists: any[] = [];

      this.email_converstion.forEach((elem: any) => {
        const duplicateSender = emailsLists.find((ele: any) => ele.from === elem.message.from);
        if (!duplicateSender) {
          const element = {
            from: elem.message.from,
            colour: this.generateRandomColor()
          };
          emailsLists.push(element);
          elem['colour'] = element.colour;
        } else {
          elem['colour'] = duplicateSender.colour;
        }
      });
    });
  }

  generateRandomColor() {
    return "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0");
  }

  onToogle(id:number){
    const messageBody=document.getElementById('item'+id);
    const toMailBody = document.getElementById('toId'+id);
    const arrow = document.getElementById('arrow'+id);
    messageBody?.classList.toggle('limit-line-length-1');
    messageBody?.classList.toggle('content-height');
    toMailBody?.classList.toggle('message-to');
    arrow?.classList.toggle('upArrow');
  }

  openSendMailTemplate(mailType: string) {
    let subject = '';
    if (this.email_converstion.length > 0){
      subject = this.email_converstion[this.email_converstion.length - 1]?.payload?.subject;
    }
    const emailData = {
      source_id: this.source_id,
      source_type: this.source_type,
      contact_id: this.contact_id,
      mailType: false,
      subject: subject,
      source_email:this.source_email,
      type:'sent'
    };
    this.modalRef = this.modalService.open(EmailModalComponent, { size: 'lg', centered: true });
    this.modalRef.componentInstance.emailData = emailData;
    if(this.modalRef){
      this.modalRef?.result?.then((receivedEntry: any) => {
        if (receivedEntry === 'success') this.getMailTemplate();
      });
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      const headerHieght = document.querySelector('.z-index-header');
      headerHieght && (this.headerHeight = headerHieght.clientHeight - 64);
    });
  }

}
