import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { constant } from 'src/app/core/constants';
import { CommonService } from 'src/app/core/service/common.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-activity-marked-model',
  templateUrl: './activity-marked-model.component.html',
  styleUrls: ['./activity-marked-model.component.scss']
})
export class ActivityMarkedModelComponent implements OnInit {
  modalRef!: NgbModalRef;
  apisEndpoint = constant.activityOutcome;
  allActivityOutcomeList = [];
  markedOutcomeForm!: FormGroup;
  isSubmitted = false;
  @Input() data!: any;
  @Input() type!: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private settingsService: SettingsService, public ngbActiveModal: NgbActiveModal, private fb: FormBuilder, private service: CommonService) { }

  ngOnInit() {
    console.log(this.type);
    this.initForm();
    if (this.type) {
      this.type === 'Pipeline' ? (this.apisEndpoint = constant.crmPipelineLostReason) : (this.apisEndpoint = constant.crmEnquiryLostReason);
      // this.apisEndpoint = constant.crmLostReason;
    }
    this.getAllActivityOutcome();
  }

  initForm() {
    this.markedOutcomeForm = this.fb.group({
      outcome: ['', [Validators.required]]
    });
  }

  get f() {
    return this.markedOutcomeForm.controls;
  }

  getAllActivityOutcome() {
    this.settingsService.getAllActivityOutcome(this.apisEndpoint).subscribe((response: any) => {
      // if (this.type) {
      //   const activity = response.data.filter((item: any) => item.source === this.type);
      //   this.allActivityOutcomeList = activity;
      // } else {
      this.allActivityOutcomeList = response['data'];
      // }
    });
  }

  onSelectOutcome(event: any) {
    this.markedOutcomeForm.patchValue({ outcome: event.id });
  }

  onSubmit() {
    console.log('called');
    this.isSubmitted = true;
    if (this.markedOutcomeForm.valid) {
      this.isSubmitted = false;
      if (this.type) {
        const lost_text: any = this.allActivityOutcomeList.filter((ele: any) => ele.id === this.markedOutcomeForm.value.outcome);
        this.passEntry.emit({ lost_reason: this.markedOutcomeForm.value.outcome, lost_text: lost_text[0].reason_text });
        this.ngbActiveModal.close("true");
      } else {
        const payload = {
          mark_as_done: "true",
          outcome: this.markedOutcomeForm.value.outcome
        };
        this.service.updateActivity(this.data.id, payload).subscribe(() => {
          this.ngbActiveModal.close("true");
        });
      }
    }
  }


}
