<mat-stepper linear #stepper>
    <mat-step label="Settings">
        <app-general-settings (onfinish)="onStepperCheck($event)"></app-general-settings>

    </mat-step>
    <mat-step label="Working Hours">
        <div *ngIf="loggedInUser.data.role === 'ADMIN'">
            <app-work-hours (onfinish)="onStepperCheck($event)"></app-work-hours>
        </div>
        <ng-container class="mt-3" *ngIf="loggedInUser.data.role !== 'ADMIN'">
            <div class="d-flex align-items-center justify-content-center">
                <h6 class="text-secondary">You are not authorized to view this.</h6>
            </div>
        </ng-container>
    </mat-step>
    <mat-step label="Set Up">
        <div class="container-fluid">
            <div class="h-100 overflow-auto p-2">
                <h5 class="fw-500 font-size-22 text-primary my-2">{{setupJson.primary_heading_1}}</h5>
                <p class="fw-400 text-light my-2">{{setupJson.secondary_heading_1}}</p>
                <div class="recommended-cards d-flex flex-wrap align-items-center w-100 mt-4 mb-4">
                    <div class="" *ngFor="let item of setupData">
                        <div class="card setup-cards col-md-4" *ngIf="featuresData[item?.launch_darkly_route]">
                            <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-inline-flex align-items-center bg-circle border-radius-50 p-2">
                                        <img [src]="urlPrefix + item?.image" alt="app" width="25">
                                    </div>
                                    <label class="switch ms-3" *ngIf="loggedInUser.data.role === 'ADMIN'">
                                        <input type="checkbox" [id]="item.id" [checked]="item.status"
                                            (change)="onToggleChange($event,item)">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <h6 class="fw-500 font-size-16 text-primary mt-2 d-flex align-items-center">
                                    {{item.name}} &nbsp;<span class="font-size-12"
                                        *ngIf="item?.route_link==='None'">(Coming Soon)</span></h6>
                                <!-- <p class="fw-400 font-size-14 text-light mb-2">{{setupJson.appointy_heading_secondary}}</p> -->
                                <p class="fw-400 font-size-14 text-light my-2 content">{{item.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center justify-content-end">
                    <button class="btn btn-primary mt-3" routerLink='/home'>
                        Finish
                    </button>
                </div>
            </div>
        </div>
    </mat-step>
</mat-stepper>