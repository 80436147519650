<div class="d-flex">
    <mat-form-field appearance="outline" class="me-2">
        <mat-select placeholder={{registorjson.ccode_placeholder}}>
            <mat-option>
                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredBanks | async"
                value={{country.country_code}}>{{country.country_code}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
        <input style="outline: none" (keydown)="keyDownEvent($event)" matInput placeholder={{registorjson.input_phonenumber}} type="text" />
        <!-- <mat-error class="error text-danger d-flex" *ngIf="submited && f['phone_number'].errors">
        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
        <span *ngIf="f['phone_number'].errors['required']">{{registorjson.input_phonenumber}}
          {{errorjson.required}}</span>
        <span *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorjson.invalid}} {{registorjson.input_phonenumber}}</span>
      </mat-error> -->
    </mat-form-field>
</div>
<!-- <app-mobile-input  [control]="phone.controls[i]"></app-mobile-input> -->