import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { onOnlyNumbers, onlyNumberDecimal } from 'src/app/core/common/common-functions';
import { InvoiceService } from 'src/app/core/service/invoice.service';

@Component({
  selector: 'app-tax-modal',
  templateUrl: './tax-modal.component.html',
  styleUrls: ['./tax-modal.component.scss']
})
export class TaxModalComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  table_cols = ['Tax Rate Name', 'Rate %', 'Status', 'Actions'];
  page = 1;
  taxes_list: any;
  // display_no_data = false;
  addTaxForm!: FormGroup;
  submitted = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(private service: InvoiceService, public ngbActiveModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.getTaxes();
    this.initilizeTaxForm();
  }

  getTaxes() {
    this.service.getTaxes(this.page).subscribe((resp: any) => {
      this.taxes_list = resp.data;
      // if (this.taxes_list.length === 0) {
      //   this.display_no_data = true;
      // }
    });
  }

  initilizeTaxForm(data?: any) {
    this.addTaxForm = new FormGroup({
      name: new FormControl(data ? data.name : '', Validators.required),
      percentage: new FormControl(data ? data.percentage : '', Validators.required),
      type: new FormControl(1),
      id: new FormControl(data ? data.id : '')
    });
  }

  editTax(data?: any) {
    this.submitted = false;
    this.initilizeTaxForm(data);
  }

  onTaxSubmit(formDirective: any) {
    this.submitted = true;
    if (this.addTaxForm.valid) {
      if (this.addTaxForm.value.id) {
        this.service.putTaxes(this.addTaxForm.value).subscribe(() => {
          this.passEntry.emit('true');
          formDirective.resetForm();
          this.initilizeTaxForm();
          this.submitted = false;
          this.getTaxes();
        });
      } else {
        this.service.postTaxes(this.addTaxForm.value).subscribe(() => {
          this.passEntry.emit('true');
          formDirective.resetForm();
          this.initilizeTaxForm();
          this.submitted = false;
          this.getTaxes();
        });
      }
    }
  }

  onToggleChange(ev: any, item: any) {
    this.service.putTaxes({ id: item.id, status: ev.target.checked }).subscribe(() => {
      this.getTaxes();
      this.passEntry.emit('true');
    });
  }

  get f() {
    return this.addTaxForm.controls;
  }

  keyDownEvent(event: any) {
    const decimalPoints = 1;
    const type = 'PERCENTAGE';
    if (event.target.value.length === 0) {
      return onOnlyNumbers(event);
    } else {
      return onlyNumberDecimal(event, decimalPoints, type);
    }
  }

  resetTaxForm(formDirective: any) {
    formDirective.resetForm();
    this.submitted = false;
    this.initilizeTaxForm();
  }
}
