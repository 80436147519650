import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  headerNotificationsSubscription$ = new Subject();
  filterAddedSubscriptions$ = new Subject();
  
  constructor(private http: HttpClient) { }

  getTasks(page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.task}?page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=20`
    );
  }

  getTasksBySearch(name: any, page: number) {
    return this.http.get(
      `${environment.urlPrefix}${constant.task}?name=${name}&page=${page}&limit=20`
    );
  }
  getTaskCustomFilteredData(body: any, page: number) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_task_filter}?page=${page}&offset=20`, body);
  }

  getTaskById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.task}${id}`);
  }

  editTask(data: any) {
    return this.http.put(
      `${environment.urlPrefix}${constant.task}${data?.id}/?source_id=${data.source_id}&source_type=${data.source_type}`,
      data
    );
  }
  editActivity(data: any) {
    return this.http.put(
      `${environment.urlPrefix}${constant.activity}${data?.id}/`,
      data
    );
  }
  getTaskStages(data: any) {
    return this.http.get(
      `${environment.urlPrefix}${constant.stage}?source_id=${data.source_id}&source_type=${data.source_type}`
    );
  }
  saveNotes(data: any) {
    return this.http.post(`${environment.urlPrefix}${constant.notes}`, data);
  }
  editNotes(data: any) {
    return this.http.put(
      `${environment.urlPrefix}${constant.notes}${data.id}/`,
      data
    );
  }
  deleteNotes(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.notes}${id}/`);
  }
  deleteFiles(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.files}${id}/`);
  }
  getFiles(id: string, page: number) {
    return this.http.get(
      `${environment.urlPrefix}${constant.files}?page=${page}&limit=20&source_id=${id}`
    );
  }
  fileUpload(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.files}`, body);
  }
  Updatefile(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.files}${body.id}/`, body);
  }
  getActivityData(type: string, page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=${type}&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=20`
    );
  }

  getActivituyCustomFilteredData(body: any, type: string, page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.post(`${environment.urlPrefix}${constant.custom_activity_filter}?type=${type}&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=20`, body);
  }

  getActivityDataBySearch(type: string, name: any, page: number) {
    return this.http.get(
      `${environment.urlPrefix}${constant.activity}?type=${type}&search=${name}&page=${page}&limit=20`
    );
  }
  // getCalls(page: any, filter_param?: string, filter?: any) {
  //   filter_param = filter_param ? `&${filter_param}=` : '';
  //   return this.http.get(
  //     `${environment.urlPrefix}${constant.activity}?type=call&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=10`
  //   );
  // }

  // getCallsBySearch(name: any) {
  //   return this.http.get(
  //     `${environment.urlPrefix}${constant.activity}?type=call&search=${name}`
  //   );
  // }

  // getEmails(page: any, filter_param?: string, filter?: any) {
  //   filter_param = filter_param ? `&${filter_param}=` : '';
  //   return this.http.get(
  //     `${environment.urlPrefix}${constant.activity}?type=email&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=10`
  //   );
  // }

  // getEmailsBySearch(name: any) {
  //   return this.http.get(
  //     `${environment.urlPrefix}${constant.activity}?type=email&search=${name}`
  //   );
  // }

  // getMeetings(page: any, filter_param?: string, filter?: any) {
  //   filter_param = filter_param ? `&${filter_param}=` : '';
  //   return this.http.get(
  //     `${environment.urlPrefix}${constant.activity}?type=meeting&page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=10`
  //   );
  // }
  // getMeetingsBySearch(name: any) {
  //   return this.http.get(
  //     `${environment.urlPrefix}${constant.activity}?type=meeting&search=${name}`
  //   );
  // }

  getActivitiesTaskById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.activity}${id}`);
  }

  getNotifications(pageSize: number) {
    return this.http.get(`${environment.urlPrefix}${constant.notification}?page=${pageSize}`);
  }

  notificationsRead() {
    return this.http.get(`${environment.urlPrefix}${constant.notification}?read_all=True`);
  }

  putNotification(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.notification}${body.id}/`, body);
  }

  getNotificationsCount() {
    return this.http.get(`${environment.urlPrefix}${constant.notificationsCount}`);
  }

  //Appointment
  getAppointments(page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.appointments}?page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=20`
    );
  }
  putAppointmentStatus(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.appointments}${id}/`, body);
  }

  getAppointmentById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.appointments}${id}/`);
  }
  getAppointmentCustomFilteredData(body: any, page: number) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_appointment_filter}?page=${page}&offset=20`, body);
  }

  //DashBoard Counts
  getTasksCount() {
    return this.http.get(`${environment.urlPrefix}${constant.task}?task_count`);
  }

  getEnquiresCount() {
    return this.http.get(`${environment.urlPrefix}${constant.leads_crm}?due_today`);
  }

  getActivityCount() {
    return this.http.get(`${environment.urlPrefix}${constant.activity}?due_date`);
  }

  getAppointmentCount() {
    return this.http.get(`${environment.urlPrefix}${constant.appointment_count}`);
  }

  getPersonalCalendar(page: any, filter_param?: string, filter?: any) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.bookedorgMeetings}?page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=20`
    );
  }

  getPersonalCalendarById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.bookedorgMeeting}info/${id}/`);
  }

  updatePersonalCalendar(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.bookedorgMeeting}update/${id}/`, body);
  }

  reschedulePersonalCalendar(id: string,body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.bookedorgMeeting}reschedule/${id}/`, body);
  }

  getPersonalCalendarBySearch(name: any, page: number) {
    return this.http.get(
      `${environment.urlPrefix}${constant.bookedorgMeetings}?search=${name}&page=${page}&limit=20`
    );
  }
  getCalenderCustomFilteredData(body: any, page: number) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_calender_filter}?page=${page}&limit=20`, body);
  }
}
