import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CONTACTSJSON } from 'src/app/core/data/contacts_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';

@Component({
  selector: 'app-contact-groups',
  templateUrl: './contact-groups.component.html',
  styleUrls: ['./contact-groups.component.scss']
})
export class ContactGroupsComponent implements OnInit {
  contactsJson = CONTACTSJSON[0];
  groupList: any[] = [];
  selectedItem: any;
  groupName: any;
  duplicateName = '';
  originalList:any[] = [];
  @Input() isInnerScreen!: boolean;
  @Input() searchSubject!:Subject<any>;

  constructor(private service: CrmService, private modalService: NgbModal, private confirmationMsz: ConfirmationMszService) {
    
  }

  ngOnInit() {
    this.getList();
    this.searchSubject && this.searchSubject.subscribe((text: any) => {
      if (text.length >= 2) {
        this.groupList =  this.originalList.filter((elem: any) => elem.name.toLowerCase().includes(text.toLowerCase()));
      } else {
        this.groupList = JSON.parse(JSON.stringify(this.originalList));
      }
    });
  }

  getList() {
    this.service.getGroupList().subscribe((resp: any) => {
      this.groupList = resp.data;
      this.originalList = JSON.parse(JSON.stringify(resp.data));
    });
  }

  onDuplicateList(container: any, item: any) {
    this.selectedItem = item;
    this.modalService.open(container, { size: 'md', centered: true });
  }

  duplicatingList() {
    const payload = {
      new_group_name: this.duplicateName,
      group_id: this.selectedItem.id
    };
    this.service.duplicateContactList(payload).subscribe({
      next: () => {
        this.modalService.dismissAll();
        this.getList();
        this.duplicateName = '';
        this.selectedItem = null;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  onGroupEdit(addtolistModel: any, item: any) {
    this.selectedItem = item;
    this.modalService.open(addtolistModel, { size: 'md' });
  }

  updateToList() {
    this.service.updateGroup(this.selectedItem).subscribe((result: any) => {
      console.log(result, "result");
      this.getList();
      this.modalService.dismissAll();
    });
  }

  confirmDelete(group_id: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteGroup(group_id);
      }
    });
  }

  deleteGroup(id: string) {
    this.service.deleteGroup(id).subscribe(() => {
      this.getList();
    });
  }
}
