<div class="export-item-selection">
  <div class="d-flex algin-items-center px-3 py-3 modal-header justify-content-between">
    <p class="m-0 export-item-header">{{export ? 'Select Exported Fields' : 'Export Completed' }}</p>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
  </div>
  <div class="modal-body" [ngClass]="export ? 'full-height' : 'view-height'">
    <mat-chip-listbox *ngIf="export" [multiple]="true" class="mat-mdc-chip-set-stacked">
      <mat-chip-option [selected]="item?.status" (click)="onSelectionChange(item,i)"
        [disabled]="disabledItems.includes(item?.field_name) || ignoreLists.includes(item?.field_name)"
        *ngFor="let item of tableColumns;let i = index">{{(item?.field_name!.split("_").join(" ") | titlecase) ||
        ''}}</mat-chip-option>
    </mat-chip-listbox>
    <div *ngIf="!export">
      <p class="m-0">Click <span class="cursor" style="color: blue;" (click)="onNavigateToAllDownloads()"> Download List </span> to view your exported files </p>
    </div>
  </div>
  <div class="modal-footer py-1 justify-content-between" *ngIf="export">
    <span class="font-size-14" [innerHTML]="message"></span>
    <button *ngIf="!isSubmitted" type="submit" class="btn btn-primary" (click)="onExportingData()">Export</button>
    <button *ngIf="isSubmitted" style="min-width: 76px;" class="btn btn-primary">
      <div id="loading"></div>
    </button>
  </div>
  <div class="modal-footer py-1" *ngIf="!export">
    <button type="submit" class="btn btn-primary" (click)="onNavigateToAllDownloads()">Download List</button>
    <!-- <button class="btn btn-primary" [disabled]="CSVlinkLastElement === ''"><a href="{{CSVlinkLastElement}}" target="_blank" style="color: white;" class="ms-2 fw-medium"
        download>
        {{'Download Now'}}
      </a>
    </button> -->
  </div>
</div>