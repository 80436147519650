import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  constructor(private authService: AuthService) { }

  transform(value: any, ...args: unknown[]): unknown {
    const price = this.authService.getPriceData()?.no_of_decimals;
    const originalValue = value?.split('.')[0];
    const decimalValue = (value?.split('.')[1])?.slice(0, price) || 0;
    if (/^0+$/.test(decimalValue)) {
      return `${originalValue}`;
    } else {
      return `${originalValue}.${decimalValue}`;
    }
  }
}
