<mat-sidenav-container class="sidenav-container h-100">
    <mat-sidenav #drawer class="bg-primary side-nav-auto" fixedInViewport="true" style="width: 66px;"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false">

        <mat-nav-list class="sidenav-list">
            <app-sidebar></app-sidebar>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="overflow-x: hidden;">
        <mat-toolbar class="d-block" style="position: fixed !important;">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button *ngIf="isHandset$ | async"
                (click)="drawer.toggle()" class="border-0 btn-outline-light"> <mat-icon
                    aria-label="Side nav toggle icon">menu</mat-icon></button>
            <app-header [selectedMenu]="selectedMenu"></app-header>
        </mat-toolbar>
        <div class="mt-5 position-relative " style="z-index: -1;padding-top: 5px;">
            <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        </div>
        <mat-toolbar
            class="float" *ngIf="callStatus === 'open' || callStatus === 'connecting' || callStatus === 'ringing' || callStatus === 'reconnecting' || incomingCallStatus"><!---->
            <div class="">
                <h6>{{caller_details?.userName}}</h6>
            </div>
            <div class="d-flex justify-content-end w-100 gap-3" *ngIf="callStatus !== 'pending' && callStatus !== 'closed'">
                <p *ngIf="callStatus === 'open'" class="fw-600 text-green mb-0 d-flex align-items-center">
                    <label class="d-flex align-items-center"><mat-icon class="me-2"> phone_in_talk</mat-icon>
                        Answered</label>
                    <span class="fw-600 ms-3 text-dark">{{call_duration}}</span>
                </p>
                <p *ngIf="callStatus === 'ringing'" class="fw-600 mb-0 d-flex align-items-center"
                    style="color: #018786;">
                    <label class="d-flex align-items-center"><mat-icon class="me-2">phone_in_talk</mat-icon>
                        Ringing...</label>
                </p>
                <p *ngIf="callStatus === 'connecting'" class="mb-0 d-flex align-items-center fw-600"
                    style="color: #e17300;">
                    <mat-icon class="me-2"> call</mat-icon>
                    Connecting...
                </p>
                <button mat-raised-button matTooltip="DialPad"
                    aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                    class="btn header-item border-0 p-0" [matMenuTriggerFor]="menuadd" id="page-header-create-dropdown">
                    <span class="rounded d-flex align-items-center justify-content-center">
                        <mat-icon class="font-size-26">dialpad</mat-icon>

                    </span>
                </button>
                <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                    <app-dial-pad></app-dial-pad>
                </mat-menu>
                <button class="btn btn-light" (click)="muteCall()">
                    <mat-icon *ngIf="!muteStatus" style="position: relative; top: 4px;">mic</mat-icon>
                    <mat-icon *ngIf="muteStatus" style="position: relative; top: 4px;">mic_off</mat-icon>
                </button>
                <button class="btn hang-up" (click)="hangCall()">
                    <mat-icon class="font-size-20 me-1 position-relative" style="top: 2px;">call_end</mat-icon>
                    <span>HangUp</span>
                </button>
            </div>
            <div class="d-flex justify-content-end w-100 gap-3" *ngIf="incomingCallStatus && callStatus === 'pending'">
                <button type="button" class="btn hang-up" (click)="incomingCall('Decline')">
                    <mat-icon class="font-size-20 me-1 position-relative" style="top: 2px;">call_end</mat-icon>
                    Decline</button>
                <button type="button" class="btn hang-up" style="background-color: #34a634;" (click)="incomingCall('Accept')">
                    <mat-icon class="me-2"> call</mat-icon>Accept</button>
            </div>

        </mat-toolbar>
    </mat-sidenav-content>
</mat-sidenav-container>