<div class="ai-page-section my-4">
  <div class="ai px-2">
    <form [formGroup]="prompt_box_form">
      <div class="ai-prompt-sec mx-4 position-relative d-flex align-item-center justify-content-between">
        <div class="col-9 d-flex align-items-start justify-content-between">
          <mat-form-field appearance="outline" class="me-3 prompt-box" style="width: 60%;">
            <mat-icon class="search-icon">search icon</mat-icon>
            <input type="text" matInput formControlName="prompt" class="input-prompt-box h-100"
              placeholder="Prompt:Write an email....">
            <mat-error class=" mt-1 error d-flex align-item-center" *ngIf="f['prompt'].errors && isSubmitted">
              <mat-icon class="font-size-12 me-1 w-auto h-auto my-auto">info</mat-icon>
              <span *ngIf="f['prompt'].errors['required']">Prompt is required</span>
              <span *ngIf="prompt_box_form.get('prompt')?.hasError('contains')">Prompt should relevant to email content only
              </span>
            </mat-error>
          </mat-form-field>
          <div class="drop-down-box">
            <mat-form-field appearance="outline" class="me-2 w-100">
              <mat-select style="width: fit-content;height: 38px;" formControlName="suggestionBox" class="w-100" placeholder="Suggestions"
                (selectionChange)="onChangeSelections($event)">
                <mat-option *ngFor="let option of dropDownLists" [value]="option.id">{{option.value}}</mat-option>
              </mat-select>
              <mat-error class=" mt-1 error d-flex align-item-center" *ngIf="f['suggestionBox'].errors && isSubmitted">
                <mat-icon class="font-size-12 me-1 w-auto h-auto my-auto">info</mat-icon>
                <span *ngIf="f['suggestionBox'].errors['required']">Prompt is required</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="d-flex mt-2">
            <input type="checkbox" (change)="onCheckboxChange($event)">
            <span class="ms-2 font-size-12">Do you want custom prompt?</span>
          </div>

        </div>
        <button class="btn btn-primary font-size-12 px-3 col-3" style="height: 38px; width: fit-content;"
          (click)="onGenerateResponse()">
          <img src="assets/images/magic-pen.svg" width="24px" height="24px" class="me-1" alt="">Generate Now</button>
      </div>
    </form>
    <div class="ai-response mx-4 mb-4 mt-4 py-4" *ngIf="responseObject.length > 0">
      <div class="ai-response-items mx-4 mb-3 p-3" *ngFor="let response of responseObject">
        <div class="ai-response-subject d-flex align-items-start justify-content-between">
          <p class="fw-600 subject-head m-0">Subject: {{response.subject}}</p>
          <div class="px-2 py-1 d-flex align-items-center justify-content-between cursor"
            (click)="onSelectingItems(response)">
            <img src="assets/images/ai-copy.svg" width="16px" height="16px" alt="">
            <p class="fw-600 ms-2">Use Text</p>
          </div>
        </div>
        <div class="ai-response-body mx-1 mt-3">
          <p [innerHTML]="response.body">
          </p>
        </div>
      </div>
    </div>
  </div>
</div>