import { mobile_json } from "src/app/core/data/mobile_json";

function returnServiceName(options: any, serviceData: any) {
  return serviceData.find((ele: any) => ele.id === options)?.name;
}

function returnItemServiceName(option: any) {
  return JSON.parse(option)?.name;
}

const TEXT_FIELD = (label: string, id?: string, type = "text", key?: string, is_mandatory = false,placeholder?: string) => {
  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    <input type="${type}" style="padding:7px; font-size:16px; " name="${label}" id="${id}" data-key="${key}" data-name="${label}" placeholder="${placeholder}" ${is_mandatory ? 'required' : ''} />
</div>`;
};

const PHONE_NUMBER = (label: string, id?: string, type = "text", ccodeid?: string, key?: string, ccodeLabel?: string, is_mandatory = false,placeholder?: string) => {

  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    <div style="display: table-row;">
      <select id="${ccodeid}" name="${ccodeLabel}" data-key="NUMBER" data-name="${ccodeLabel}" placeholder="${placeholder}" class="worke-ccode-select" style="padding:7px;  font-size: 16px; max-width:25%">
      </select>
      <input type="text" style="padding:7px; font-size:16px; " name="${label}" id="${id}" data-key="${key}" data-name="${label}" ${is_mandatory ? 'required' : ''} />
    </div>
  </div>`;
};

const DATETIME_FIELD = (label: string, id?: string, key?: string, is_mandatory = false,placeholder?: string) => {
  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    <input type="datetime-local" style="padding:7px; font-size:16px; " name="${label}" id="${id}" placeholder="${placeholder}" data-key="${key}" data-name="${label}" ${is_mandatory ? 'required' : ''} />
</div>`;
};

const DATE_FIELD = (label: string, id?: string, key?: string, is_mandatory = false,placeholder?: string) => {
  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    <input type="date" style="padding:7px; font-size:16px; " name="${label}" id="${id}" placeholder="${placeholder}" data-key="${key}" data-name="${label}" ${is_mandatory ? 'required' : ''} />
</div>`;
};

const DATERANGE_FIELD = (label: string, id?: string, key?: string, is_mandatory = false,placeholder?: string) => {
  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    <input type="text" style="padding:7px; font-size:16px;" class="worke-date-picker" name="${label}" placeholder="${placeholder}" id="${id}" data-key="${key}" data-name="${label}" ${is_mandatory ? 'required' : ''} />
</div>`;
};

const TEXTAREA_FIELD = (label: string, id?: string, key?: string, is_mandatory = false,placeholder?: string) => {
  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    <textarea type="text" style="padding:7px; font-size:16px; " name="${label}" placeholder="${placeholder}" id="${id}" data-key="${key}" data-name="${label}" ${is_mandatory ? 'required' : ''}></textarea>
</div>`;
};

const RADIO_FIELD = (label: string, options: string[], id?: string, key?: string, is_mandatory = false) => {
  let options_array = '';
  options.forEach(ele => {
    const temp = `  \n<div style="display: flex; align-items: center; margin-bottom: 7px;">
        <input type="radio" style="height:17px; width: 17px; margin: 0px 7px" value="${ele}" name="${label}" id="${id}" data-key="${key}" data-name="${label}"/>
        <label style="font-size: 16px; font-family: Trebuchet MS;">${ele}</label>
    </div>`;
    options_array += temp;
  });
  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    ${options_array}
</div>`;
};

const CHECKBOX_FIELD = (label: string, options: string[], id?: string, key?: string, is_mandatory = false) => {
  let options_array = '';
  options.forEach(ele => {
    const temp = `\n<div style="display: flex; align-items: center; margin-bottom: 7px;">
        <input onchange="onCheckBoxChange(event, '${label}')" type="checkbox" style="height:17px; width: 17px; margin: 0px 7px" value="${ele}" name="${label}" id="${id}" data-key="${key}" data-name="${label}" />
        <label style="font-size: 16px; font-family: Trebuchet MS;">${ele}</label>
    </div>`;
    options_array += temp;
  });
  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    ${options_array}
</div>`;
};

const SELECT_FIELD = (label: string, options: string[], id?: string, original_label?: string, key?: string, is_mandatory = false,placeholder?: string) => {

  let options_array = '';
  options.forEach(ele => {
    let temp = "";
    try {
      const jsonObject = JSON.parse(ele);
      temp = `<option value='${ele}' style="font-size: 12px;">${jsonObject.name}</option>`;
    } catch (error) {
      temp = `<option value='${ele}' style="font-size: 12px;">${ele}</option>`;
    }
    options_array += temp;
  });

  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    <select type="text" style="padding:7px; font-size: 16px; " name="${label}" placeholder="${placeholder}" id="${id}" data-key="${key}" data-name="${label}" class="worke-${original_label}-select" ${is_mandatory ? 'required' : ''} ${original_label === "multiple" ? "multiple" : ""}>
    <option value="" style="font-size: 12px;">${placeholder}</option>
        ${options_array}
    </select>
</div>`;
};

const SERVICE_SELECT = (label: string, options: any[], id?: string, serviceData?: any, key?: string, is_mandatory = false,placeholder?: string) => {


  let options_array = '';
  options.forEach(ele => {
    // const temp = `<option value="${ele}" style="font-size: 12px;">${returnServiceName(ele, serviceData)}</option>`; [FOR Futute]: if only id has to be sent
    const temp = `
      <option value="${returnItemServiceName(ele)}" style="font-size: 12px;">${returnItemServiceName(ele)}</option>`;
    options_array += temp;
  });

  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    <select type="text" style="padding:7px; font-size: 16px; " name="${label}" placeholder="${placeholder}" id="${id}" data-key="${key}" data-name="${label}" ${is_mandatory ? 'required' : ''}>
    <option value="" style="font-size: 12px;">${placeholder}</option>
        ${options_array}
    </select>
</div>`;
};

const FILE_FIELD = (label: string, id?: string, key?: string, formId?: string, chatbot_env?: string,is_mandatory = false) => {
  return `\n<div style="display: grid; margin: 20px 15px">
    <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;" for="${id}">${is_mandatory ? '<span style="color: #ff0000">*</span>' : ''} ${label}</label>
    <input type="file" onchange="onFileUpload(event, '${id}', '${formId}','${chatbot_env}')" style="padding:7px; font-size:16px; " name="${label}" id="${id}" data-key="${key}" data-name="${label}" ${is_mandatory ? 'required' : ''} />
</div>`;
};

const BUTTON_FIELD = () => {
  return `\n<div style="display: flex; justify-content: end;">
    <button type='reset' style="padding: 10px 15px; font-size:16px; font-weight: 500; background-color: #bcbcbc; border: 0px; border-radius: 5px; margin-right: 20px">Reset</button>
    <button type='submit' style="padding: 10px 15px; font-size:16px; font-weight: 500; background-color: black; color:white; border: 0px; border-radius: 5px; margin-right: 20px">Submit</button>
</div>
`;
};

export { SELECT_FIELD, CHECKBOX_FIELD, TEXT_FIELD, TEXTAREA_FIELD, RADIO_FIELD, FILE_FIELD, BUTTON_FIELD, SERVICE_SELECT, DATE_FIELD, PHONE_NUMBER, DATETIME_FIELD, DATERANGE_FIELD };


// \n<div style="display: grid; margin: 20px 15px">
//   <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;">Text</label>
//   <input type="text" style="padding:7px; font-size:16px; ">
// </div>

// \n<div style="display: grid; margin: 20px 15px">
//   <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;">TextBox</label>
//   <textarea type="text" style="padding:7px; font-size:16px; "></textarea>
// </div>

// \n<div style="display: grid; margin: 20px 15px">
//   <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;">Radio</label>
//   \n<div style="display: flex; align-items: center; margin-bottom: 7px;">
//   	<input type="radio" style="height:17px; width: 17px; margin: 0px 7px" name="radio"/>
//   	<label style="font-size: 16px; font-family: Trebuchet MS;">Option 1</label>
//   </div>
//     \n<div style="display: flex; align-items: center; margin-bottom: 7px;">
//   	<input type="radio" style="height:17px; width: 17px; margin: 0px 7px" name="radio"/>
//   	<label style="font-size: 16px; font-family: Trebuchet MS;">Option 1</label>
//   </div>
//     \n<div style="display: flex; align-items: center; margin-bottom: 7px;">
//   	<input type="radio" style="height:17px; width: 17px; margin: 0px 7px" name="radio"/>
//   	<label style="font-size: 16px; font-family: Trebuchet MS;">Option 1</label>
//   </div>
// </div>

// \n<div style="display: grid; margin: 20px 15px">
//   <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;">Check Box</label>
//   \n<div style="display: flex; align-items: center; margin-bottom: 7px;">
//   	<input type="checkbox" style="height:17px; width: 17px; margin: 0px 7px" name="checkbox"/>
//   	<label style="font-size: 16px; font-family: Trebuchet MS;">Option 1</label>
//   </div>
//     \n<div style="display: flex; align-items: center; margin-bottom: 7px;">
//   	<input type="checkbox" style="height:17px; width: 17px; margin: 0px 7px" name="checkbox"/>
//   	<label style="font-size: 16px; font-family: Trebuchet MS;">Option 1</label>
//   </div>
//     \n<div style="display: flex; align-items: center; margin-bottom: 7px;">
//   	<input type="checkbox" style="height:17px; width: 17px; margin: 0px 7px" name="checkbox"/>
//   	<label style="font-size: 16px; font-family: Trebuchet MS;">Option 1</label>
//   </div>
// </div>

// \n<div style="display: grid; margin: 20px 15px">
//   <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;">Text</label>
//   <select type="text" style="padding:7px; font-size: 16px; ">
//   	<option value=1 style="font-size: 12px;">Option 1</option>
//     <option value=2 style="font-size: 12px;">Option 2</option>
//     <option value=3 style="font-size: 12px;">Option 3</option>
//   </select>
// </div>

// \n<div style="display: grid; margin: 20px 15px">
//   <label style="font-size: 16px; font-family: Trebuchet MS;padding-bottom:5px;">Text</label>
//   <input type="file" style="padding:7px; font-size:16px; " />
// </div>