import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { COMPANIESJSON } from 'src/app/core/data/companies_json';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { CrmService } from 'src/app/core/service/crm.service';
import { CompanyModalComponent } from '../../shared/company-modal/company-modal.component';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { PageEvent } from '@angular/material/paginator';
import { TaskBoardComponent } from '../../clients/client-managment/task-board/task-board.component';
import { SettingsService } from 'src/app/core/service/settings.service';
import { countryData } from 'src/app/core/data/countryData';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { CustomFieldsService } from 'src/app/core/service/custom-fields.service';
import { CustomFiltersComponent } from '../../shared/custom-filters/custom-filters.component';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { filterConstant } from 'src/app/core/data/filter-storing-data';
import { AuthService } from 'src/app/core/service/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { ExportItemSelectionComponent } from '../../shared/export-item-selection/export-item-selection.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  modalRef!: NgbModalRef;
  previewModal!: NgbModalRef;
  companiesJson = COMPANIESJSON[0];
  errorjson = ERRORJSON[0];
  pageEvent!: PageEvent;
  page_size = 20;
  currentPage = 1;
  tableColumns: any[] = [];
  featuresData: any;
  filtersLables = [
    {
      position: 1,
      labelName: 'Name',
      checked: true
    },
    {
      position: 19,
      labelName: 'Email',
      checked: true
    },
    {
      position: 17,
      labelName: 'Phone Number',
      checked: true
    },
    {
      position: 2,
      labelName: 'Website',
      checked: true
    },
    // {
    //   position: 3,
    //   labelName: 'Company Owner',
    //   checked: true
    // },
    // {
    //   position: 4,
    //   labelName: 'Tags',
    //   checked: true
    // },
    // {
    //   position: 5,
    //   labelName: 'Total Contacts',
    //   checked: true
    // },
    {
      position: 6,
      labelName: 'Country',
      checked: true
    },
    {
      position: 7,
      labelName: 'Owner',
      checked: true
    },
    {
      position: 8,
      labelName: 'Channel',
      checked: true
    },
    // {
    //   position: 9,
    //   labelName: 'Last Updated',
    //   checked: true
    // },
    {
      position: 10,
      labelName: 'Followers',
      checked: false
    },
    {
      position: 12,
      labelName: 'City',
      checked: false
    },
    {
      position: 13,
      labelName: 'Number Of Employees',
      checked: false
    },
    {
      position: 14,
      labelName: 'Annual Revenue',
      checked: false
    },
    {
      position: 16,
      labelName: 'Skype',
      checked: false
    },

    {
      position: 18,
      labelName: 'Other Phone',
      checked: false
    },

    {
      position: 20,
      labelName: 'Other Email',
      checked: false
    },
    {
      position: 21,
      labelName: 'Linkedin',
      checked: false
    },
    {
      position: 22,
      labelName: 'Twitter',
      checked: false
    },
    {
      position: 23,
      labelName: 'Instagram',
      checked: false
    },
    {
      position: 24,
      labelName: 'Facebook',
      checked: false
    },
    {
      position: 25,
      labelName: 'State',
      checked: false
    },
    // {
    //   position: 26,
    //   labelName: 'Zipcode',
    //   checked: true
    // },
    {
      position: 27,
      labelName: 'Address Line 1',
      checked: false
    },
    {
      position: 28,
      labelName: 'Address Line 2',
      checked: false
    },
    {
      position: 29,
      labelName: 'Shipping City',
      checked: false
    },
    {
      position: 30,
      labelName: 'Shipping State',
      checked: false
    },
    {
      position: 31,
      labelName: 'Shipping Country',
      checked: false
    },
    {
      position: 32,
      labelName: 'Shipping Zipcode',
      checked: false
    },
    {
      position: 33,
      labelName: 'Shipping Address Line 1',
      checked: false
    },
    {
      position: 34,
      labelName: 'Shipping Address Line 2',
      checked: false
    },
    {
      position: 35,
      labelName: 'Total Activities',
      checked: false
    },
    {
      position: 36,
      labelName: 'Done Activities',
      checked: false
    },
    {
      position: 37,
      labelName: 'Activities To Do',
      checked: false
    },
    {
      position: 38,
      labelName: 'Next Activities Date',
      checked: false
    },
    {
      position: 39,
      labelName: 'Last Activities Date',
      checked: false
    },
    {
      position: 40,
      labelName: 'Lost Deals',
      checked: false
    },
    {
      position: 41,
      labelName: 'Open Deals',
      checked: false
    },
    {
      position: 42,
      labelName: 'Closed Deals',
      checked: false
    },
    {
      position: 43,
      labelName: 'Industry',
      checked: false
    },
    {
      position: 44,
      labelName: 'Created On',
      checked: true
    },
    {
      position: 45,
      labelName: 'Actions',
      checked: true
    }
  ];
  companiesdropDown = [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Todays',
      value: 'todays'
    },
    {
      label: 'Yesterday',
      value: 'yesterday'
    },
    {
      label: 'Last 7 days',
      value: 'this_week'
    },
    {
      label: 'Iframe',
      value: 'iframe'
    },
    {
      label: 'Chatbot',
      value: 'chatbot'
    },
    {
      label: 'Facebook',
      value: 'facebook'
    },
    {
      label: 'Manual',
      value: 'manual'
    },
    // {
    //   label: 'Forms',
    //   value: 'web_forms'
    // },
    {
      label: 'Web Forms',
      value: 'web_forms'
    },
    {
      label: 'Call',
      value: 'call'
    },
    {
      label: 'Google',
      value: 'google'
    },
    {
      label: 'Instagram',
      value: 'instagram'
    },
    {
      label: 'Whatsapp',
      value: 'whatsapp'
    },
    {
      label: 'Bulk Upload',
      value: 'bulk_upload'
    },
    {
      label: 'Employee Reference',
      value: 'employee_reference'
    },
    {
      label: 'Just Dial',
      value: 'just_dial'
    },
    {
      label: 'Sulekha',
      value: 'sulekha'
    },
    {
      label: 'Classified Listing',
      value: 'classified_listing'
    },
    {
      label: 'Word of Mouth',
      value: 'word_of_mouth'
    },
    {
      label: 'Walk in Customer',
      value: 'walk_in_customer'
    },
    {
      label: 'Preview',
      value: 'preview'
    }
    // {
    //   label: 'Meetings',
    //   value: 'meetings'
    // }
    // 'Recently Created Companies',
    // 'Recently Modified Companies',
    // 'Not Yet Viewed'
  ];
  filter: any = this.companiesdropDown[0].value;
  selected_filter!: string;
  selected_filter_value: any;
  public selection = new SelectionModel<any>(true, []);
  companiesObj: any = {};
  companiesListArray: any = [];
  companyForm!: FormGroup;
  submited = false;
  search_global!: string;
  selectedFile: any;
  enableImportBtn = false;
  loggedInUser: any;
  display_no_data = false;
  staffMembers: any = [];
  countryjson = countryData;
  columnFilters!: FormGroup;
  showExtraFilters = false;
  departmentsList: any = [];
  listOfTags: any = [];
  products_array: any = [];
  product_categories_array: any = [];
  contactListArray: any = [];
  customFiltersArray: any = [];
  filters_modal!: NgbModalRef;
  column_filters: any[] = [];
  selectedSortOptions: any;
  pageIndex = 0;
  sortByDropDown = [
    {
      label: 'Company Name',
      key: 'name'

    },
    {
      label: 'Website',
      key: 'website'

    },
    {
      label: 'Country',
      key: 'country'

    },
    {
      label: 'Number of Employee',
      key: 'number_of_employees'

    },
    {
      label: 'Created On',
      key: 'created_at'

    }
  ];
  isSelected: any = {
    flag: false,
    pageIndex: [],
    multiple: false
  };
  tableColumnResponse: any;
  columnFilterSearch = '';
  columnFieldSearch: any[] = [];
  originalTableColumnLists: any[] = [];
  tableColumnNoResFound = false;
  checkBoxStorageKeeper: any = {};
  tableSkeletonLoader = true;
  tagsListArray: any[] = [];
  searchSubject: Subject<string> = new Subject<string>();
  stepper = 1;
  imported_id: any;
  operation = 'CREATE';

  constructor(private modalService: NgbModal, private authService: AuthService, private service: CrmService, private confirmationMsz: ConfirmationMszService,
    private settingsService: SettingsService, private custom_service: CustomFieldsService, private router: Router) {
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      if (searchTerm.length >= 3) {
        this.filter = this.companiesdropDown[0].value;
        this.selected_filter_value = '';
        this.selected_filter = '';
        if (this.selectedSortOptions || this.column_filters.length > 0) {
          this.selectedSortOptions = null;
          this.column_filters = [];
          // this.filterFormSubmit(payload, 1, 'ACTIVE'); Optional Call
        }
        this.currentPage = 1;
        this.pageIndex = 0;
        this.getCompaniesBySearch(searchTerm);
        this.updateFilterState();
      } else if (searchTerm.length < 1) {
        this.page_size = 20;
        this.getCompaniesList('clear', 1);
        this.updateFilterState();
      }
    });
  }

  ngOnInit(): void {
    // this.tableColumns = this.filtersLables.filter((elem: any) => elem.checked);
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getFeatues();
    if (filterConstant.filterInfoData && filterConstant.filterInfoData.page_name === 'company') {
      this.updateCurrentPageFiltrData();
    } else {
      this.getCompaniesList('clear', 1);
      this.updateFilterState();
    }
    this.initilizeFiltersForm();
    this.filtersJsonForm();
    this.getAllCompanyFields();
    this.getColoumsList();
    this.getTags();
  }

  getTags() {
    this.settingsService.getTags('company').subscribe((resp: any) => {
      this.tagsListArray = resp.data;
    });
  }

  updateFilterState() {
    filterConstant.filterInfoData = {
      mainFilter: this.column_filters,
      sortValue: this.selectedSortOptions,
      dropdown_filter: this.selected_filter,
      dropdown_filter_value: this.selected_filter_value,
      searchKey: this.search_global,
      page_name: 'company',
      currentPageIndex: this.currentPage
    };
  }


  updateCurrentPageFiltrData() {
    this.column_filters = filterConstant.filterInfoData.mainFilter;
    this.selectedSortOptions = filterConstant.filterInfoData.sortValue;
    this.selected_filter = filterConstant.filterInfoData.dropdown_filter;
    this.selected_filter_value = filterConstant.filterInfoData.dropdown_filter_value;
    this.search_global = filterConstant.filterInfoData.searchKey;
    this.currentPage = filterConstant.filterInfoData.currentPageIndex;
    this.pageIndex = filterConstant.filterInfoData.currentPageIndex - 1;
    this.filter = this.selected_filter || 'all';
    this.getListBasedOnFilter();
  }

  onFilterChange(e: any) {
    this.search_global = '';
    if (this.selectedSortOptions || this.column_filters.length > 0) {
      this.selectedSortOptions = null;
      this.column_filters = [];
      // this.filterFormSubmit(payload, 1, 'ACTIVE'); Optional call
    }
    this.filter = e;
    if (this.filter === 'this_week') {
      this.selected_filter = 'weeks';
      this.selected_filter_value = true;
    } else if (this.filter === 'todays') {
      this.selected_filter = 'todays';
      this.selected_filter_value = true;
    } else if (this.filter === 'yesterday') {
      this.selected_filter = 'yesterday';
      this.selected_filter_value = true;
    } else if (this.filter === 'iframe') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'iframe';
    } else if (this.filter === 'chatbot') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'bot';
    } else if (this.filter === 'manual') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'manual';
    } else if (this.filter === 'facebook') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'facebook';
    } else if (this.filter === 'web_forms') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'web_forms';
    } else if (this.filter === 'call') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'call';
    } else if (this.filter === 'google') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'google';
    } else if (this.filter === 'instagram') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'instagram';
    } else if (this.filter === 'whatsapp') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'whatsapp';
    } else if (this.filter === 'bulk_upload') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'bulk_upload';
    } else if (this.filter === 'employee_reference') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'employee_reference';
    } else if (this.filter === 'just_dial') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'just_dial';
    } else if (this.filter === 'sulekha') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'sulekha';
    } else if (this.filter === 'classified_listing') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'classified_listing';
    } else if (this.filter === 'word_of_mouth') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'word_of_mouth';
    } else if (this.filter === 'walk_in_customer') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'walk_in_customer';
    } else if (this.filter === 'preview') {
      this.selected_filter = 'channel';
      this.selected_filter_value = 'preview';
    }
    // else if (this.filter === 'meetings') {
    //   this.selected_filter = 'channel';
    //   this.selected_filter_value = 'meeting';
    // }
    else {
      this.selected_filter = '';
      this.selected_filter_value = '';
    }
    // console.log(this.selected_filter, this.selected_filter_value);
    this.updateFilterState();

    this.getCompaniesList('clear', 1, this.selected_filter, this.selected_filter_value);
  }

  getCompaniesList(pageIndexClear: string, page: any, filter_param?: string, filter?: any) {
    this.tableSkeletonLoader = true;
    this.service.getAllCompanies(page, filter_param, filter).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        if (pageIndexClear === 'clear') {
          this.pageIndex = 0;
          this.currentPage = 1;
          // this.clearSelection();
        }
        this.companiesObj = resp;
        this.companiesListArray = resp.data;
        // this.selection.clear();
        // if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
        // }
        if (this.companiesListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
        this.organisationMembersList();
      },
      error: () => {
        this.selectedSortOptions = null;
        this.column_filters = [];
        this.search_global = '';
        this.selected_filter_value = '';
        this.selected_filter = '';
        this.tableSkeletonLoader = false;
      }
    });
  }

  getListBasedOnFilter() {
    if (this.search_global?.length >= 3) {
      this.getCompaniesBySearch(this.search_global);
    } else if (Object.keys(this.column_filters).length > 0 || this.selectedSortOptions?.sort_order) {
      const payload = {
        filter: this.column_filters,
        sort_by: this.selectedSortOptions?.sort_by,
        sort_order: this.selectedSortOptions?.sort_order
      };
      this.filterFormSubmit(payload, this.currentPage);
    } else if (this.selected_filter && this.selected_filter_value) {
      this.getCompaniesList('pgn', this.currentPage, this.selected_filter, this.selected_filter_value);
    } else {
      this.getCompaniesList('pgn', this.currentPage);
    }
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
      this.pageIndex = e.pageIndex++;
    } else if (this.pageEvent.pageIndex === 0) {
      this.currentPage = 1;
      this.pageIndex = 0;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
      this.pageIndex = e.pageIndex--;
    }
    this.updateFilterState();
    this.getListBasedOnFilter();
  }

  removeCompany(companyId: string) {
    this.service.deleteCompany(companyId).subscribe({
      next: () => {
        this.getListBasedOnFilter();
        // this.getCompaniesList('pgn', this.currentPage, this.selected_filter, this.selected_filter_value);
      }
    });
  }

  confirmDelete(companyId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.removeCompany(companyId);
      }
    });
  }
  //filter dropdown fun
  // selectFilterLable(event: any) {
  //   event.stopPropagation();
  // event.preventDefault();
  // if (event.target) {
  //   event.target.classList.toggle('selected');
  // }
  // }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.companiesListArray.length;
    return numSelected === numRows;
  }

  masterToggle(currentPage?: number | string,event?:any) {
    const masterIndex = this.isSelected.pageIndex.findIndex((elem: any) => elem.page === currentPage);
    if ((event && !event.checked)) {
      // if (this.isSelected.pageIndex.length > 1 && currentPage) {
      //   if (index > -1) {
      this.companiesListArray.forEach((row: any) => {
        delete this.checkBoxStorageKeeper[row.id];
      });
      this.selection.clear();
      (masterIndex > -1) && this.isSelected.pageIndex.splice(masterIndex, 1);
      //   }
      // }
      // else {
      //   this.clearSelection();
      //   this.checkBoxStorageKeeper = {};
      // }
    } else if (typeof currentPage === 'number' && event && event.checked) {
      // this.selection.clear();
      this.companiesListArray.forEach((row: any) => {
        const exsistObj = {
          [row.id]: row
        };
        Object.assign(this.checkBoxStorageKeeper, exsistObj);
        this.selection.select(row);
      });
      const index = this.isSelected.pageIndex.findIndex((elem: any) => elem.page === currentPage);
      if (index > -1) {
        this.isSelected.pageIndex[index].qnty = this.companiesListArray?.length;
      } else {
        this.isSelected.pageIndex.push({ page: currentPage, qnty: this.companiesListArray?.length });
        this.isSelected.flag = true;
      }
      // this.isSelected.pageIndex.push({ page: currentPage, qnty: this.companiesListArray?.length });
      // this.isSelected.flag = true;

    } else {
      // this.selection.clear();
      this.companiesListArray.forEach((row: any) => {
        if (Object.prototype.hasOwnProperty.call(this.checkBoxStorageKeeper, row.id)) {
          this.selection.select(row);
        }
      });
    }
    this.updateIsSelectedMultipleKey();
  }
  individualRecordToogle(item: any) {
    const exsistObj = {
      [item.id]: item
    };
    if (Object.prototype.hasOwnProperty.call(this.checkBoxStorageKeeper, item.id)) {
      delete this.checkBoxStorageKeeper[item.id];
      const index = this.getPageIndexValues();
      if (index > -1) {
        this.isSelected.pageIndex[index].qnty -= 1;
        if (this.isSelected.pageIndex[index].qnty <= 0) {
          this.isSelected.pageIndex.splice(index, 1);
        }
      }
      if (this.isSelected.pageIndex.length === 0) {
        this.isSelected.flag = false;
      }
    } else {
      Object.assign(this.checkBoxStorageKeeper, exsistObj);
      const index = this.getPageIndexValues();
      if (index > -1) {
        this.isSelected.pageIndex[index].qnty += 1;
        if (this.isSelected.pageIndex[index].qnty <= 0) {
          this.isSelected.pageIndex.splice(index, 1);
        }
        if (this.isSelected.pageIndex.length === 0) {
          this.isSelected.flag = false;
        }
      } else {
        this.isSelected.flag = true;
        this.isSelected.pageIndex.push({ page: this.currentPage, qnty: 1 });
      }
    }
    this.updateIsSelectedMultipleKey();
  }

  updateIsSelectedMultipleKey() {
    (Object.keys(this.checkBoxStorageKeeper).length > 1) ? (this.isSelected.multiple = true) : (this.isSelected.multiple = false);
  }

  getPageIndexValues() {
    return this.isSelected.pageIndex.findIndex((elem: any) => elem.page === this.currentPage);
  }

  clearSelection() {
    this.selection.clear();
    this.isSelected.flag = false;
    this.isSelected.pageIndex = [];
    this.isSelected.multiple = false;
    this.checkBoxStorageKeeper={};
    
  }

  openAddCompanyModal(company?: any) {
    this.modalRef = this.modalService.open(CompanyModalComponent, { size: 'lg', scrollable: true });
    this.modalRef.componentInstance.company = company;
    this.modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.clearSelection();
        if (company) {
          this.getListBasedOnFilter();
        } else {
          this.getCompaniesList('clear', 1);
        }
      }
    });
  }
  getCompaniesBySearch(name: string) {
    this.tableSkeletonLoader = true;
    this.service.getCompaniesBySearch(name, this.currentPage).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        this.companiesObj = resp;
        // this.page_size = this.companiesObj.total;
        // this.selection.clear();
        this.companiesListArray = resp.data;
        // if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
        // }
        if (this.companiesListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      },
      error: () => {
        this.selectedSortOptions = null;
        this.column_filters = [];
        this.search_global = '';
        this.selected_filter_value = '';
        this.selected_filter = '';
        this.getCompaniesList('clear', 1);
      }
    });
  }
  // search(name: any) {
  //   this.searchSubject.next(name);
  // }

  importModalDialog(importExportModel: any, type: any) {
    this.modalService.open(importExportModel, { size: 'md' });
  }

  onFileChange(event: any) {
    this.enableImportBtn = true;
    this.selectedFile = event.target.files[0];
  }

  importCompanies() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);

    this.service.postCSVFile(formData).subscribe((resp: any) => {
      this.stepper = 2;
      this.imported_id = resp.data.id;
      // this.modalService.dismissAll('cross click');
      // this.getCompaniesList('clear', 1);
    });
  }

  downloadCompanies() {
    let count = this.companiesObj.total;
    const selectedCheckbox = Object.keys(this.checkBoxStorageKeeper).length;
    if (this.checkBoxStorageKeeper && selectedCheckbox > 0) {
      count = selectedCheckbox;
    }
    const modalRef = this.modalService.open(ExportItemSelectionComponent, { size: 'md', centered: true });
    modalRef.componentInstance.payload = {
      type: 'company',
      data: this.tableColumns,
      disabledItems: ['company_id', 'name', 'tags'],
      checkBoxStorageKeeper: this.checkBoxStorageKeeper,
      message: `<b class='blue-class'>${count}</b> ${count < 2 ? 'Company' : 'Companies'} will be exported`
    };
    const outputContacts: any = [];
    // if (this.isSelected.flag === false) {
    //   this.service.getAllCompaniesForExport().subscribe((resp: any) => {
    //     resp.data.forEach((company: any) => {
    //       outputContacts.push({
    //         "Name": company.name,
    //         "Email": company.email,
    //         "Phone": company.phonenumber,
    //         "Website": company.website,
    //         "Address": company.address,
    //         "City": company.city,
    //         "Country": company.country,
    //         "Zipcode": company.zipcode
    //       });
    //     });
    //     this.service.jsonToCsv(outputContacts, "Companies");
    //   });
    // } else {
    //   Object.values(this.checkBoxStorageKeeper).forEach((company: any) => {
    //     outputContacts.push({
    //       "Name": company.name,
    //       "Email": company.email,
    //       "Phone": company.phonenumber,
    //       "Website": company.website,
    //       "Address": company.address,
    //       "City": company.city,
    //       "Country": company.country,
    //       "Zipcode": company.zipcode
    //     });
    //   });
    //   this.service.jsonToCsv(outputContacts, "Companies");
    // }
  }

  // downloadFile() {
  //   const url = 'assets/myfile.txt';
  //   this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = 'myfile.txt';
  //     link.click();
  //   });
  // }

  downloadSampleFile() {
    this.service.downloadSample('Companies');
  }

  openTaskBoardPreview() {
    this.previewModal = this.modalService.open(TaskBoardComponent, { size: 'lg', scrollable: true });
    this.previewModal.componentInstance.crm_type = 'company';
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }

  onUpdatePopOver(event: any, data: any) {
    const input: any = { id: data.id, custom_contact_fields: [] };

    if (event.type === 'dropdown') {
      if (event.labelName === 'Owner') {
        input.info = {
          "owner_info": {
            "id": event.value.id,
            "fname": event.value.fname,
            "lname": event.value.lname
          }
        };
        input.owner = event.value.id;
        // this.staffMembers.forEach((staff: any) => {
        //   if (staff.id === event.value.id) input.owner_department = staff.department_info.id;
        // });
        input.owner_department = event.value.department_info?.id ? event.value.department_info?.id : '';
      }
      else if (event.labelName === "Country") {
        input.country = event.value.Entity;
      } else if (event.labelName === 'Tag') {
        input[event.formName] = event.value.id;
      }
    } else
      input[event.formName] = event.value;
    if (event?.cCode) {
      input['ccode'] = event.cCode;
    }

    this.service.updateCompany(input).subscribe(() => {
      this.getListBasedOnFilter();
    });
  }

  getFilters() {
    this.showExtraFilters = true;
    this.getAllCompanyFields();
    this.initilizeFiltersForm();
    this.filtersJsonForm();
  }

  resetFilters() {
    this.columnFilters.reset();
    this.initilizeFiltersForm();
    this.filtersJsonForm();
    this.getCompaniesList('clear', 1);
  }

  clearCustomFilter() {
    this.column_filters = [];
    this.selectedSortOptions = null;
    this.updateFilterState();
    this.getCompaniesList('clear', 1);
  }

  getAllCompanyFields() {
    this.customFiltersArray = [];
    this.custom_service.getCompanyModelFields().subscribe({
      next: (resp: any) => {
        for (const [key, value] of Object.entries(resp.data)) {
          let formatted_obj: any = {};
          let replaced_text: any;
          if (key === 'custom_company_templates') {
            const custom_fields_list: any = value;
            custom_fields_list.forEach((element: any) => {
              formatted_obj = {};
              formatted_obj['id'] = element.id;
              formatted_obj['display_label'] = element.label;
              formatted_obj['custom_input'] = true;
              formatted_obj['data_type'] = element.data_type;
              formatted_obj['is_mandatory'] = element.is_mandatory;
              formatted_obj['options'] = element.options;
              if (element?.on_form) {
                this.customFiltersArray.push(formatted_obj);
              }
            });
          } else if (key === 'id' || key === 'organisation' || key === 'company_fields_order') {
            console.log("");

          } else {
            const system_fields_value: any = value;
            if (key.includes('_')) {
              replaced_text = key.replace('_', " ");
            } else {
              replaced_text = key;
            }
            formatted_obj['id'] = key;
            formatted_obj['display_label'] = replaced_text;
            formatted_obj['custom_input'] = false;
            formatted_obj['data_type'] = system_fields_value.data_type;
            formatted_obj['is_mandatory'] = system_fields_value.is_mandatory;
            if (system_fields_value?.on_form) {
              this.customFiltersArray.push(formatted_obj);
            }
          }

        }
        const created_at = {
          id: 'created_at',
          is_mandatory: true,
          display_label: 'Created at',
          custom_input: false,
          data_type: 'DATE_TIME'
        };
        const updated_at = {
          id: 'updated_at',
          is_mandatory: true,
          display_label: 'Updated at',
          custom_input: false,
          data_type: 'DATE_TIME'
        };
        this.customFiltersArray.push(created_at);
        this.customFiltersArray.push(updated_at);
      }
    });
  }

  forCustomFieldStatus(field: any, index: number) {
    console.log(field, index);
    this.filter_of_column.controls[index].patchValue({
      custom_field: field.custom_input
    });
    if (field.id === 'owner' || field.id === 'assignee') {
      this.organisationMembersList();
    } else if (field.id === 'tags') {
      this.getTagsOfCompany();
    } else if (field.id === 'assignee_department') {
      this.getDepartmentsList();
    }
  }


  initilizeFiltersForm() {
    this.columnFilters = new FormGroup({
      filter: new FormArray([]),
      sort_by: new FormControl(''),
      sort_order: new FormControl('desend')
    });
  }

  get filter_of_column() {
    return this.columnFilters.controls["filter"] as FormArray;
  }

  filtersJsonForm() {
    const filter_form: any = new FormGroup({
      column: new FormControl(''),
      data: new FormControl(''),
      operation: new FormControl(''),
      custom_field: new FormControl('')
    });

    this.filter_of_column.push(filter_form);
  }

  deleteFilter(index: number) {
    this.filter_of_column.removeAt(index);
  }

  fieldsMapping() {
    this.service.updateCSVFile({ id: this.imported_id, operation: this.operation, type: 'company', is_active: true }).subscribe(() => {
      this.modalService.dismissAll('cross click');
      this.router.navigate(['/crm/import'], { queryParams: { module: 'company', id: this.imported_id } });
    });
  }

  filterFormSubmit(filters_data: any, page: number) {
    // this.column_filters = filters_data;
    this.tableSkeletonLoader = true;
    this.service.getCompanyCustomFilteredData(filters_data, page ? page : 1).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        this.companiesObj = resp;
        // this.selection.clear();
        this.companiesListArray = resp.data;
        // if (this.isSelected.flag === true && this.getPageIndexValues() > -1) {
        this.masterToggle();
        // }
        if (this.companiesListArray.length === 0) {
          this.display_no_data = true;
        } else {
          this.display_no_data = false;
        }
      },
      error: () => {
        this.selectedSortOptions = null;
        this.column_filters = [];
        this.search_global = '';
        this.selected_filter_value = '';
        this.selected_filter = '';
        this.getCompaniesList('clear', 1);
      }
    });
  }

  searchTriggerConatct(change: any) {
    if (change.target.value.length >= 3) {
      this.service.getContactsBySearch(change.target.value).subscribe({
        next: (resp: any) => {
          this.contactListArray = resp.data;
        }
      });
    }
  }

  getTagsOfCompany() {
    this.settingsService.getTags('company').subscribe({
      next: (resp: any) => {
        this.listOfTags = resp.data;
      }
    });
  }

  getDepartmentsList() {
    this.settingsService.getTeams(false).subscribe((resp: any) => {
      this.departmentsList = resp.data;
    });

  }

  openFiltersModal(type: string) {

    if (type === 'sort') {
      this.filters_modal = this.modalService.open(CustomFiltersComponent, { size: 'md', centered: true });
    } else {
      this.filters_modal = this.modalService.open(CustomFiltersComponent, { size: 'lg', scrollable: true, centered: true });
    }
    this.filters_modal.componentInstance.filters_custm = this.column_filters;
    this.filters_modal.componentInstance.filterType = type;
    if (this.selectedSortOptions) {
      this.filters_modal.componentInstance.selectedSortOptions = this.selectedSortOptions;
    }
    this.filters_modal.componentInstance.customFiltersArray = this.customFiltersArray;
    this.filters_modal.componentInstance.is_filter_for = 'company';
    this.filters_modal.componentInstance.sortByOptions = this.sortByDropDown;
    this.filters_modal.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      if (receivedEntry) {
        this.search_global = '';
        if (receivedEntry.from === 'sort') {
          if (receivedEntry.type === 'save') {
            this.selectedSortOptions = receivedEntry.data.sorting;
          } else {
            this.selectedSortOptions = null;
          }
        } else {
          if (receivedEntry.type === 'save') {
            this.column_filters = receivedEntry.data.filter;
          } else {
            this.column_filters = [];
          }
        }
        this.updateFilterState();
        const payload = {
          filter: receivedEntry.data.filter,
          sort_by: receivedEntry.data?.sorting?.sort_by,
          sort_order: receivedEntry.data?.sorting?.sort_order
        };
        this.currentPage = 1;
        this.pageIndex = 0;
        // this.clearSelection();
        this.filterFormSubmit(payload, 1);
      }
    });
  }
  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }

  getColoumsList() {
    this.service.getTableColoums('company').subscribe((resp: any) => {
      this.tableColumnResponse = resp.data;
      this.tableColumns = resp.data.customized_fields;
      this.originalTableColumnLists = JSON.parse(JSON.stringify(this.tableColumns));
    });
  }
  onUpdateTableColumns() {
    const payload = JSON.parse(JSON.stringify(this.tableColumnResponse));
    Object.assign(payload.customized_fields, this.originalTableColumnLists);
    this.service.updateTableColumn(payload).subscribe((data: any) => {
      console.log(data);
    });
  }

  selectFilterLable(event: any, item: any, index: number) {
    if (!['company_id', 'name', 'tags'].includes(item?.field_name)) {
      const obj = JSON.parse(JSON.stringify(item));
      obj.status = event.checked;
      this.tableColumns[index] = obj;
      this.originalTableColumnLists[index] = obj;
    }
    // Object.assign(this.originalTableColumnLists, { [item.key]: event.checked });
    // Object.assign(this.tableColumns, { [item.key]: event.checked });
  }

  onColumnFilterSearch(event: any) {
    if (this.columnFilterSearch.length > 1) {
      const searchResult: any[] = [];
      this.tableColumns = this.originalTableColumnLists.filter((item: any) => (item.field_name.split('_').join(" ").toLocaleLowerCase()).includes(this.columnFilterSearch.toLocaleLowerCase()));
      if (this.tableColumns && this.tableColumns.length > 0) {
        this.tableColumnNoResFound = false;
        this.columnFieldSearch = [];

        searchResult.forEach((elem: any) => {
          Object.assign(this.columnFieldSearch, elem);
        });
      } else {
        this.tableColumnNoResFound = true;
        this.columnFieldSearch = [];
        this.tableColumns = [];
      }
      // Object.assign(this.tableColumns,this.columnFieldSearch);
      // this.tableColumns = JSON.parse(JSON.stringify(this.columnFieldSearch));
    } else {
      this.tableColumns = JSON.parse(JSON.stringify(this.originalTableColumnLists));
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex > 2) {
      moveItemInArray(this.tableColumns, event.previousIndex, event.currentIndex);
      moveItemInArray(this.originalTableColumnLists, event.previousIndex, event.currentIndex);

    }
  }
}
