
export const colorCodes = [
  {
    color: "#DE350B"
  },
  {
    color: "#0052CC"
  },
  {
    color: "#00875A"
  },
  {
    color: "#5243AA"
  }
];

export const source_color_code = {
  'pipeline': {
    color: '#752522',
    bgColor: '#F9E3E2'
  },
  'lead': {
    color: '#302E7C',
    bgColor: '#E1E7FD'
  },
  'project': {
    color: '#302E7C',
    bgColor: '#E1E7FD'
  },
  'task': {
    color: 'rgba(18, 18, 18)',
    bgColor: 'rgba(18, 18, 18, 0.10)'
  },
  'appointment': {
    color: '#254D4A',
    bgColor: '#D5FAF1'
  },
  'contact': {
    color: '#73321B',
    bgColor: '#FCEED8'
  },
  'meeting': {
    color: '#752522',
    bgColor: '#F9E3E2'
  },
  'company': {
    color: '#73321B',
    bgColor: '#FCEED8'
  },
  'user': {
    color: '#00000',
    bgColor: 'rgba(18, 18, 18, 0.10)'
  },
  'order':{
    color: '#752522',
    bgColor: '#F9E3E2'
  },
  'board': {
    color: '#73321B',
    bgColor: '#FCEED8'
  }
};

export const RANDOM_CHANNEL_COLORS = [
  {
    color: "#ffe6e6"
  },
  {
    color: "#e6faff"
  },
  {
    color: "#ffe6ff"
  },
  {
    color: "#ffffe6"
  },
  {
    color: "#e6ffe6"
  },
  {
    color: "#fff2e6"
  },
  {
    color: " #e6ffff"
  },
  {
    color: "#ffebe6"
  },
  {
    color: "#ecf9f2"
  },
  {
    color: "#ffe6f9"
  }
];