<div class="container-fluid user-control-div-height-payment">
    <ng-container *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="d-flex justify-content-between border-bottom border-2 align-items-center">
            <h4 class="mb-0 pb-4">
                Payments Settings
            </h4>
        </div>
        <div class="d-flex border box_shadow p-4 my-3">
            <div class="me-3">
                <img src="assets/images/stripe.svg" width="120px">
            </div>
            <div class="w-100">
                <div class="d-flex justify-content-between">
                    <h4 class="mt-3 fw-bolder d-flex align-items-center">
                        Stripe <span class="ms-1 mt-1 p-1 border-radius-4 font-size-10"
                            style="background-color: rgb(153, 255, 153, 0.4);"
                            *ngIf="stripeKeys?.data?.gateway_id">Connected</span>
                    </h4>
                </div>
                <p class="fs-6 w-75">Accept customer payments online using Stripe. Connect your account with Stripe
                    payment
                    gateway and get paid for appointments at the moment of booking.</p>

            </div>
            <div>
                <div class="d-flex justify-content-end mt-5">
                    <button class="btn  me-3 btn-primary"
                        (click)="openModal(stripeKeysModal, 'stripe')">{{stripeKeys?.data?.gateway_id ? "Update" :
                        "Connect"}}</button>
                    <button class="btn  me-3 btn-primary"
                        (click)="disconnectPayment('stripe',stripeKeys?.data?.gateway_id)"
                        *ngIf="stripeKeys?.data?.gateway_id">Disconnect</button>
                </div>
            </div>
        </div>
        <div class="d-flex border box_shadow p-4 my-3">
            <div class="me-3 d-inline-flex align-items-center">
                <img src="assets/images/razorpay-icon.svg" width="120px">
            </div>
            <div class="w-100">
                <div class="d-flex justify-content-between">
                    <h4 class="mt-3 fw-bolder d-flex align-items-center">
                        Razorpay <span class="ms-1 mt-1 p-1 border-radius-4 font-size-10"
                            style="background-color: rgb(153, 255, 153, 0.4);"
                            *ngIf="razorPayKeys?.data?.gateway_id">Connected</span>
                    </h4>
                </div>
                <p class="fs-6 w-75">Accept customer payments online using Razorpay. Connect your account with Razorpay
                    payment
                    gateway and get paid for appointments at the moment of booking.</p>

            </div>
            <div>
                <div class="d-flex justify-content-end mt-5">
                    <button class="btn  me-3 btn-primary"
                        (click)="openModal(keysModal, 'razorpay')">{{razorPayKeys?.data?.gateway_id ? "Update" :
                        "Connect"}}</button>
                    <button class="btn  me-3 btn-primary"
                        (click)="disconnectPayment('razorpay',razorPayKeys?.data?.gateway_id)"
                        *ngIf="razorPayKeys?.data?.gateway_id">Disconnect</button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>

<ng-template #keysModal let-modal>
    <div class="modal-header">
        <h4>
            {{this.payment_type | titlecase}} Keys
        </h4>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>

    </div>
    <div class="modal-body">

        <form [formGroup]="keysForm">
            <div class="form-group">
                <label class="form-label">
                    Public Key <span class="text-danger">*</span>
                </label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="Public Key" formControlName="api_key_id" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['api_key_id'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['api_key_id'].errors['required']">Public Key
                            {{errorjson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group mt-3">
                <label class="form-label">
                    Secret Key <span class="text-danger">*</span>
                </label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="{{razorSecretKey || 'Secret Key'}}" formControlName="secret_key" />
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['secret_key'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['secret_key'].errors['required']">Secret Key
                            {{errorjson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <div class="d-flex justify-content-end">
            <button class="btn btn-light mx-3" (click)="modal.dismiss('Cross click')">
                Cancel
            </button>
            <button class="btn btn-primary" (click)="onKeySave()">
                {{razorPayKeys?.data?.gateway_id ? 'Update' : 'Save'}}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #stripeKeysModal let-modal>
    <div class="modal-header">
        <h4>
            {{this.payment_type | titlecase}} Keys
        </h4>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-hidden="true"></button>

    </div>
    <div class="modal-body">

        <form [formGroup]="StripeKeysForm">
            <div class="form-group">
                <label class="form-label">
                    Public Key <span class="text-danger">*</span>
                </label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="Public Key" formControlName="pk_key" />
                    <mat-error class="error text-danger d-flex" *ngIf="s_submitted && s_f['pk_key'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="s_f['pk_key'].errors['required']">Public Key
                            {{errorjson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group mt-3">
                <label class="form-label">
                    Secret Key <span class="text-danger">*</span>
                </label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="{{stripeSecretKey || 'Secret Key'}}" formControlName="sk_key" />
                    <mat-error class="error text-danger d-flex" *ngIf="s_submitted && s_f['sk_key'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="s_f['sk_key'].errors['required']">Secret Key
                            {{errorjson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <div class="d-flex justify-content-end">
            <button class="btn btn-light mx-3" (click)="modal.dismiss('Cross click')">
                Cancel
            </button>
            <button class="btn btn-primary" (click)="onKeySave()">
                {{stripeKeys?.data?.gateway_id ? 'Update' : 'Save'}}
            </button>
        </div>
    </div>
</ng-template>