import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';

@Pipe({
  name: 'dateConversion'
})
export class DateConversionPipe implements PipeTransform {
  constructor(private authService: AuthService) { }

  transform(value: any, ...args: unknown[]): unknown {
    const date = this.authService.getCompanyData();
    const datePipe = new DatePipe("en-US");
    const utcDate = new Date(value);
    // utcDate.setMinutes(utcDate.getMinutes() - utcDate.getTimezoneOffset());
    if (args.length > 0 && args[0] === 'dateTime') {
      value = datePipe.transform(utcDate, (date?.dateformat + ' ' +'hh:mm:ss') || "yyyy-MM-dd hh:mm:ss"); //date&time format';
    } else {
      value = datePipe.transform(utcDate, date?.dateformat || "yyyy-MM-dd");
    }
    return value;
  }

}
