import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit{
  showType:any = 'Templates';
  public Editor: any = ClassicEditor;
  selectedTemplateType: any = 'all';
  templates_lists: any = [
    {
      id: 1,
      name: 'Summer sale',
      dsp: "Summer Refreshing offer on your first order! Check out the new products and apply coupons to avail of amazing discounts! Get up to {{20%}}‍ off on your next order.",
      type: 'Promotional',
      status: 'Approved'
    },
    {
      id: 2,
      name: 'OTP Verification',
      dsp: "Dear customer payment of Rs.{{{ 6356.36 }}for your jio fiber connection with jio services through UPI payment have been received. Thank Now setup Jio Autopay and enjoy convenience of automatic bill payments",
      type: 'OTP',
      status: 'Approved'
    },
    {
      id: 3,
      name: 'Payment received',
      dsp: "Dear customer payment of Rs.{{{ 6356.36 }}for your jio fiber connection with jio services through UPI payment have been received. Thank Now setup Jio Autopay and enjoy convenience of automatic bill payments",
      type: 'Transactional',
      status: 'Awaiting approval'
    },
    {
      id: 4,
      name: 'Summer sale',
      dsp: "Summer Refreshing offer on your first order! Check out the new products and apply coupons to avail of amazing discounts! Get up to {{20%}}‍ off on your next order.",
      type: 'Promotional',
      status: 'Approved'
    },
    {
      id: 5,
      name: 'Summer sale',
      dsp: "Summer Refreshing offer on your first order! Check out the new products and apply coupons to avail of amazing discounts! Get up to {{20%}}‍ off on your next order.",
      type: 'Promotional',
      status: 'Approved'
    }
  ];
  selectedTemplate: any = [];
  templateForm!: FormGroup;
  newTemplate = false;
  ckconfigQue: any;
  files: any;
  fileToUpload: any;
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.selectedTemplate = this.templates_lists[0];
    this.ckconfigQue = {
      height: 95,
      toolbar: ['Bold', 'Italic', 'underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }


  onTemplateChange(ev: any) {
    this.selectedTemplate = '';
    this.templates_lists.filter((ele: any) => {
      if (ele.id === ev.value) {
        this.selectedTemplate = ele;
      }
    });
  }

  back(type: any) {
    if (type === 'template') {
      this.newTemplate = false;
    } else {
      this.router.navigate(['/marketing']);
    }
  }

  intilizeForm(data?: any) {
    this.templateForm = new FormGroup({
      id: new FormControl(data ? data.id : ''),
      name: new FormControl(data ? data.name : ''),
      category: new FormControl(data ? data.category : ''),
      description: new FormControl(data ? data.description : ''),
      image: new FormControl(data ? data.image : ''),
      redirection_url: new FormControl('')
    });
  }

  openModel() {
    this.newTemplate = true;
    this.intilizeForm();
  }

  onSelect(event:any) {
    this.files.push(...event.addedFiles);
    if (this.files && this.files.length >= 1) {
      this.onRemove(this.files[0]);
    }
    this.files.push(...event.addedFiles);
    this.fileToUpload = this.files[0];
    // this.awsUtilService.getUploadFileLink(this.files[0].type).subscribe((resp) => {
    //   this.awsUtilService.uploadFile(this.files[0], resp.url).subscribe((res) => {
    //   });
    // });
  }

  onRemove(event:any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  openTemplate() {
    this.showType = 'list';
  }
}
