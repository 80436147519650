<div class="container-fluid overflow-auto">
    <table class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
        <thead>
            <tr>
                <th scope="col" *ngFor="let col of table_cols" class="text-primary pb-3">
                    {{col}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let details of details_fetch | keyvalue">
                <td class="text-capitalize">{{replaceUnderscore(details.key)}}</td>
                <td>{{ details.value }}</td>
                <td>{{ plan_details?.month_start_date }}</td>
                <td>{{ plan_details?.month_end_date }} </td>
            </tr>
        </tbody>
    </table>
</div>