<div class="mt-3 p-3">
    <label class="fw-600 font-size-14">Working Hours</label>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-3">
        <div class="card border-0 mb-2">
            <div class="card-body">
                <div class="row" formGroupName="MONDAY">
                    <div class="col-3 d-flex align-items-center">
                        <label class="switch">
                            <input type="checkbox" value="false" formControlName="status">
                            <span class="slider round"></span>
                        </label>
                        <label class="ms-3">Monday</label>
                    </div>
                    <div class="col-8">
                        <div formArrayName="slots" *ngIf="mondayArray.value.status">
                            <div *ngFor="let item of mondaySlots.controls; let i = index">
                                <div class="row" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select Start Time"
                                                formControlName="0">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[1] ? time > item.value[1] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select End Time"
                                                formControlName="1">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[0] ? time < item.value[0] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-2 d-flex align-items-center justify-content-around">
                                        <img class="cursor" (click)="removeMonField(i)"
                                            src="../../../assets/images/delete-board-icon.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-danger mb-0" *ngIf="!mondayArray.value.status">Unavailable</p>
                    </div>
                    <div class="col-1 d-flex align-items-center" *ngIf="mondayArray.value.status">
                        <mat-icon class="cursor" (click)="addMonField()">add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mb-2">
            <div class="card-body">
                <div class="row" formGroupName="TUESDAY">
                    <div class="col-3 d-flex align-items-center">
                        <label class="switch">
                            <input type="checkbox" value="false" formControlName="status">
                            <span class="slider round"></span>
                        </label>
                        <label class="ms-3">Tuesday</label>
                    </div>
                    <div class="col-8">
                        <div formArrayName="slots" *ngIf="tuesdayArray.value.status">
                            <div *ngFor="let item of tuesdaySlots.controls; let i = index">
                                <div class="row" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select Start Time"
                                                formControlName="0">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[1] ? time > item.value[1] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select End Time"
                                                formControlName="1">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[0] ? time < item.value[0] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-2 d-flex align-items-center justify-content-around">
                                        <img class="cursor" (click)="removeTueField(i)"
                                            src="../../../assets/images/delete-board-icon.svg">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-danger mb-0" *ngIf="!tuesdayArray.value.status">Unavailable</p>
                    </div>
                    <div class="col-1 d-flex align-items-center" *ngIf="tuesdayArray.value.status">
                        <mat-icon class="cursor" (click)="addTueField()">add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mb-2">
            <div class="card-body">
                <div class="row" formGroupName="WEDNESDAY">
                    <div class="col-3 d-flex align-items-center">
                        <label class="switch">
                            <input type="checkbox" value="false" formControlName="status">
                            <span class="slider round"></span>
                        </label>
                        <label class="ms-3">wednesday</label>
                    </div>
                    <div class="col-8">
                        <div formArrayName="slots" *ngIf="wednesdayArray.value.status">
                            <div *ngFor="let item of wednesdaySlots.controls; let i = index">
                                <div class="row" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select Start Time"
                                                formControlName="0">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[1] ? time > item.value[1] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select End Time"
                                                formControlName="1">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[0] ? time < item.value[0] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-2 d-flex align-items-center justify-content-around">
                                        <img class="cursor" (click)="removeWedField(i)"
                                            src="../../../assets/images/delete-board-icon.svg">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-danger mb-0" *ngIf="!wednesdayArray.value.status">Unavailable</p>
                    </div>
                    <div class="col-1 d-flex align-items-center">
                        <mat-icon class="cursor" (click)="addWedField()"
                            *ngIf="wednesdayArray.value.status">add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mb-2">
            <div class="card-body">
                <div class="row" formGroupName="THURSDAY">
                    <div class="col-3 d-flex align-items-center">
                        <label class="switch">
                            <input type="checkbox" value="false" formControlName="status">
                            <span class="slider round"></span>
                        </label>
                        <label class="ms-3">Thursday</label>
                    </div>
                    <div class="col-8">
                        <div formArrayName="slots" *ngIf="thursdayArray.value.status">
                            <div *ngFor="let item of thursdaySlots.controls; let i = index">
                                <div class="row" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select Start Time"
                                                formControlName="0">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[1] ? time > item.value[1] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select End Time"
                                                formControlName="1">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[0] ? time < item.value[0] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-2 d-flex align-items-center justify-content-around">
                                        <img class="cursor" (click)="removeThuField(i)"
                                            src="../../../assets/images/delete-board-icon.svg">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-danger mb-0" *ngIf="!thursdayArray.value.status">Unavailable</p>
                    </div>
                    <div class="col-1 d-flex align-items-center" *ngIf="thursdayArray.value.status">
                        <mat-icon class="cursor" (click)="addThuField()">add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mb-2">
            <div class="card-body">
                <div class="row" formGroupName="FRIDAY">
                    <div class="col-3 d-flex align-items-center">
                        <label class="switch">
                            <input type="checkbox" value="false" formControlName="status">
                            <span class="slider round"></span>
                        </label>
                        <label class="ms-3">Friday</label>
                    </div>
                    <div class="col-8">
                        <div formArrayName="slots" *ngIf="fridayArray.value.status">
                            <div *ngFor="let item of fridaySlots.controls; let i = index">
                                <div class="row" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select Start Time"
                                                formControlName="0">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[1] ? time > item.value[1] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select End Time"
                                                formControlName="1">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[0] ? time < item.value[0] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-2 d-flex align-items-center justify-content-around">
                                        <img class="cursor" (click)="removeFriField(i)"
                                            src="../../../assets/images/delete-board-icon.svg">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-danger mb-0" *ngIf="!fridayArray.value.status">Unavailable</p>
                    </div>
                    <div class="col-1 d-flex align-items-center" *ngIf="fridayArray.value.status">
                        <mat-icon class="cursor" (click)="addFriField()">add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mb-2">
            <div class="card-body">
                <div class="row" formGroupName="SATURDAY">
                    <div class="col-3 d-flex align-items-center">
                        <label class="switch">
                            <input type="checkbox" value="false" formControlName="status">
                            <span class="slider round"></span>
                        </label>
                        <label class="ms-3">Saturday</label>
                    </div>
                    <div class="col-8">
                        <div formArrayName="slots" *ngIf="saturdayArray.value.status">
                            <div *ngFor="let item of saturdaySlots.controls; let i = index">
                                <div class="row" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select Start Time"
                                                formControlName="0">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[1] ? time > item.value[1] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select End Time"
                                                formControlName="1">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[0] ? time < item.value[0] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-2 d-flex align-items-center justify-content-around">
                                        <img class="cursor" (click)="removeSatField(i)"
                                            src="../../../assets/images/delete-board-icon.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-danger mb-0" *ngIf="!saturdayArray.value.status">Unavailable</p>
                    </div>
                    <div class="col-1 d-flex align-items-center" *ngIf="saturdayArray.value.status">
                        <mat-icon class="cursor" (click)="addSatField()">add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border-0 mb-2">
            <div class="card-body">
                <div class="row" formGroupName="SUNDAY">
                    <div class="col-3 d-flex align-items-center">
                        <label class="switch">
                            <input type="checkbox" value="false" formControlName="status">
                            <span class="slider round"></span>
                        </label>
                        <label class="ms-3">Sunday</label>
                    </div>
                    <div class="col-8">
                        <div formArrayName="slots" *ngIf="sundayArray.value.status">
                            <div *ngFor="let item of sundaySlots.controls; let i = index">
                                <div class="row" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select Start Time"
                                                formControlName="0">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[1] ? time > item.value[1] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                            <mat-select style="outline: none" placeholder="Select End Time"
                                                formControlName="1">
                                                <mat-option *ngFor="let time of time_slotes"
                                                    [disabled]="item.value[0] ? time < item.value[0] : null"
                                                    [value]="time">{{time}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-2 d-flex align-items-center justify-content-around">
                                        <img class="cursor" (click)="removeSunField(i)"
                                            src="../../../assets/images/delete-board-icon.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-danger mb-0" *ngIf="!sundayArray.value.status">Unavailable</p>
                    </div>
                    <div class="col-1  d-flex align-items-center" *ngIf="sundayArray.value.status">
                        <mat-icon class="cursor" (click)="addSunField()">add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
            <button class="btn btn-light mt-3 me-3" type="button" (click)="onStepperChange()"
                *ngIf="activatedRouter === '/onboarding'">
                Skip
            </button>
            <button class="btn btn-primary d-flex align-items-center mt-3" type="submit">
                {{activatedRouter === '/onboarding' ? "Save & Proceed" : 'Update' }}
            </button>
        </div>
    </form>
</div>


<!-- <ng-container class="mt-3" *ngIf="loggedInUser.data.role !== 'ADMIN'">
    <div class="d-flex align-items-center justify-content-center">
        <h6 class="text-secondary">You are not authorized to view this.</h6>
    </div>
</ng-container> -->