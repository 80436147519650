import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private http: HttpClient
  ) { }

  getPaymentKeys(gateway_type: string) {
    return this.http.get<any>(`${environment.urlPrefix}${constant.payments}?gateway_name=${gateway_type}`);

  }
  postPaymentKeys(payload: any) {
    return this.http.post<any>(`${environment.urlPrefix}${constant.payments}`, payload);
  }

  updatePaymentKeys(id: string, payload: any) {
    return this.http.put<any>(`${environment.urlPrefix}${constant.payments}${id}/?gateway_name=${payload.gateway_name}`, payload);
  }

  deletePaymentKeys(id: string, gateway_type: string) {
    console.log(gateway_type);

    return this.http.delete<any>(`${environment.urlPrefix}${constant.payments}${id}/?gateway_name=${gateway_type}`);
  }
}
