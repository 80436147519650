import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent implements OnInit {
  loggedInUser: any;
  currentPlan: any[] = [];
  manage_plans: any[] = [];
  activeId = 1;
  currentViewPlan = 'free';

  subscriptionHeading = [
    {
      name: 'Free',
      plan: 'free'
    },
    {
      name: 'Life Time',
      plan: 'life_time'
    },
    {
      name: 'Regular',
      plan: 'regular'
    }, {
      name: 'AddOns',
      plan: 'addon'
    }];
  selectedPlanDetails: any;
  selectedPlanData: any;

  constructor(private settingService: SettingsService, private modalService: NgbModal) { }
  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.getCurrentPlan();
    this.getListOfPlans();
  }

  tabChange(event: any) {
    console.log(event);
    this.currentViewPlan = this.subscriptionHeading[event.nextId - 1]?.plan;
    this.getListOfPlans();
  }

  getListOfPlans() {
    this.manage_plans = [];
    this.settingService.getListOfPlans(this.currentViewPlan).subscribe((resp: any) => {
      this.manage_plans = resp.data;
      const test = JSON.parse(JSON.stringify(this.manage_plans));
      if (this.currentViewPlan === 'life_time') {
        const manage_plans = test.reduce((acc: any, value: any) => {
          const key = value?.sub_title || 'doNot_display';
          if (!acc[key]) {
            acc[key] = { type: value?.sub_title, values: [value] };
          } else {
            acc[key].values.push(value);
          }
          return acc;
        }, []);
        this.manage_plans = Object.values(manage_plans);
      }
    });


    // console.log(manage_plan_keys);
  }

  getCurrentPlan() {
    this.settingService.getCurrentPlanOfOrg().subscribe((resp: any) => {
      resp.data.forEach((element: any) => {
        this.currentPlan.push(element.subscription_plan);
      });
      // this.currentPlan = resp.data.subscription_plan;
    });
  }

  subscriptionSubmit(selectedPlanId: string, type: string) {
    this.settingService.checkOutPayment(selectedPlanId, type).subscribe((resp: any) => {
      if (type === 'free') {
        this.getListOfPlans();
        this.getCurrentPlan();
        this.modalService.dismissAll();
      } else {
        window.open(resp.data.url, "_self");
      }
    });
  }

  getPlanDetails(planId: string) {
    this.settingService.getPlanDetails(planId).subscribe((resp: any) => {
      this.selectedPlanDetails = resp.data;
    });
  }

  openPlanDetailsModal(modal: any,plan: any) {
    this.modalService.open(modal, { size: 'md' });
    this.getPlanDetails(plan.id);
    this.selectedPlanData = plan;
  }
}
