import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';

@Pipe({
  name: 'currencyConversion'
})
export class CurrencyConversionPipe implements PipeTransform {
  constructor(private authService: AuthService) { }

  transform(value: unknown, symbol?: string, ...args: unknown[]): unknown {
    if(!symbol) symbol = this.authService.getPriceData()?.currency?.split(',')[1];
    if(this.authService.getPriceData()?.price_symbol_position === 'before'){
      return `${symbol || '₹'}` + value;
    } else {
      return value + `${symbol || '₹'}`;
    }
  }

}
