import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  getStaffInfoList = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    this.user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  }

  getGeneralSettings() {
    return this.http.get(`${environment.urlPrefix}${constant.general_settings}`);
  }

  postGeneralSettings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.general_settings}`, body);
  }

  getCompanySettings() {
    return this.http.get(`${environment.urlPrefix}${constant.company_settings}`);
  }

  postCompanySettings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.company_settings}`, body);
  }

  getLocationSettings() {
    return this.http.get(`${environment.urlPrefix}${constant.locations}`);
  }

  getActiveLocationSettings() {
    return this.http.get(`${environment.urlPrefix}${constant.locations}?active=true`);
  }

  getStaffLocationsByStaffId(staff_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.staff_locations}?staff_id=${staff_id}`);
  }

  getLocationSettingsById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.locations}${id}/`);
  }

  postLocationSettings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.locations}`, body);
  }

  putLocationSettings(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.locations}${body.id}/`, body);
  }

  deleteLocationSettings(id: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.locations}${id}`);
  }

  getGoogleLocations(searchText: string) {
    return this.http.get(`${environment.urlPrefix}${constant.google_locations}?search=${searchText}`);
  }

  getPriceSettings() {
    return this.http.get(`${environment.urlPrefix}${constant.price_settings}`);
  }

  postPriceSettings(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.price_settings}`, body);
  }

  getWorkingHours() {
    return this.http.get(`${environment.urlPrefix}${constant.working_hours}`);
  }

  postWorkingHours(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.working_hours}`, body);
  }

  // getStaff_info() {
  //   return this.http.get(`${environment.urlPrefix}${constant.staff_info}?active=true`);
  // }
  getStaff_info(refreshType?: string) {
    // return this.http.get(`${environment.urlPrefix}${constant.staff_info}?active=true`);
    if (this.getStaffInfoList.getValue() !== null && refreshType !== "force") {
      return this.getStaffInfoList.asObservable();
    } else {
      this.http.get(`${environment.urlPrefix}${constant.staff_info}?active=true`).subscribe((data) => {
        this.getStaffInfoList.next(data);
      }, err => {
        this.getStaffInfoList.next(err);
      });
      return this.getStaffInfoList.asObservable();
    }
  }

  getStaff_bookings() {
    return this.http.get(`${environment.urlPrefix}${constant.staff_info}?booking=true`);//?booking_status=true
  }

  getStaff_infoByPagination(page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.staff_info}?page=${page}&offset=20`);
  }

  getStaff_infoById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.staff_info}${id}/`);
  }

  postStaffInfo(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.staff_info}`, body);
  }

  putStaffInfo(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.staff_info}${id}/`, body);
  }

  getBranchStaff(location_id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.branch_staff}?location=${location_id}`);
  }

  getStaffFilters(search: any, type: string, page: number) {
    let params;
    if (type === 'department') {
      params = `department=${search}`;
    } else {
      params = `search=${search}`;
    }
    return this.http.get(`${environment.urlPrefix}${constant.staff_info}?${params}&page=${page}`);//page=${page}&offset=10&
  }

  getDeveloperInfo() {
    return this.http.get(`${environment.urlPrefix}${constant.developer_info}`);
  }

  postDeveloperInfo(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.developer_info}`, body);
  }

  putDeveloperInfo(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.developer_info}${body.id}/`, body);
  }

  deleteDeveloperInfo(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.developer_info}${id}/`);
  }

  pswRestForDeveloper(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.developer_password_reset}${body.id}/`,body);
  }

  getRolesPermissions(active: boolean) {
    if (!active) {
      return this.http.get(`${environment.urlPrefix}${constant.roles_permissions}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.roles_permissions}?active=true`);
    }
  }

  postRolesPermissions(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.roles_permissions}`, body);
  }

  getTeams(active: boolean) {
    if (!active) {
      return this.http.get(`${environment.urlPrefix}${constant.teams}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.teams}?active=true`);
    }
  }

  postTeams(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.teams}`, body);
  }

  putTeams(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.teams}${body.id}/`, body);
  }

  deleteTeams(id: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.teams}${id}/`);
  }

  //integration Apis
  getZoomKeys() {
    return this.http.get(`${environment.urlPrefix}${constant.zoom}`);
  }

  postZoomKeys(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.zoom}`, body);
  }

  updateZoomKeys(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.zoom}${body.id}/`, body);
  }

  deleteZoomKeys(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.zoom}${id}/`);
  }

  getGoogle(type: string) {
    return this.http.get(`${environment.urlPrefix}${constant.google}${constant.get_google_connect}?user=${this.user_details.data.id}&type=${type}`);
  }

  staffConnected(id:string){
    return this.http.get(`${environment.messageUrl}${constant.calender_disconnect}${id}${constant.get_outlook}`);
  }

  zoomCoonectivity(orgId:string){
    return this.http.get(`${environment.messageUrl}${constant.zoomConnectivity}?orgId=${orgId}`);
  }

  googleRegister() {
    const body = { name: this.user_details?.data.email.split('@')[0] };
    return this.http.post(`${environment.urlPrefix}${constant.google}${constant.google_register}`, body);
  }

  connectTogmail() {
    return this.http.get(`${environment.urlPrefix}${constant.google}${constant.google_authorize}`);
  }

  disconnectTogmail() {
    return this.http.delete(`${environment.urlPrefix}${constant.google}${constant.google_disconnect}`);
  }

  getGoogleCalendar() {
    return this.http.get(`${environment.urlPrefix}${constant.google}${constant.get_google_calendar}`);
  }

  get_gmail_connect() {
    return this.http.get(`${environment.urlPrefix}${constant.google}${constant.google_connect}`);
  }

  get_calendar_connect() {
    return this.http.get(`${environment.urlPrefix}${constant.google}${constant.google_calendar_connect}`);
  }

  disconnectToGoogler(type: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.google}${constant.google_disconnect}?type=${type}`);
  }

  checkCalenderConnection() {
    return this.http.get(`${environment.messageUrl}${constant.calender_connect_check}`);
  }

  calenderDisConnect() {
    return this.http.delete(`${environment.messageUrl}${constant.calender_disconnect}`);
  }

  get_Mail_connect() {
    return this.http.get(`${environment.messageUrl}${constant.google__connect}${constant.calendar_connect}`);
  }

  get_outlook_connect() {
    return this.http.get(`${environment.messageUrl}${constant.outlook_connect}${constant.calendar_connect}`);
  }

  getStaffConnections(staff_id: string) {
    return this.http.get(`${environment.messageUrl}${constant.calender_disconnect}${staff_id}${constant.calender_isconnect}`);
  }

  getOutlook() {
    return this.http.get(`${environment.messageUrl}${constant.chatbot_outlook}`);
  }

  connectToOutlook(type: string) {
    if (type === 'connect') {
      return this.http.get(`${environment.urlPrefix}${constant.outlook}${this.user_details?.data.id}${constant.connect_outlook}`);
    } else {
      return this.http.delete(`${environment.urlPrefix}${constant.outlook}${this.user_details?.data.id}${constant.disconnect_outlook}`);
    }
  }
  //Mail gun integartion
  getDomains() {
    return this.http.get(`${environment.urlPrefix}${constant.domain_gun}`);
  }

  postDomains(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.domain_gun}`, body);
  }

  verifyDomains(id: any) {
    return this.http.put(`${environment.urlPrefix}${constant.domain_gun}${id}/`, {});
  }

  deleteDomain(id: any) {
    return this.http.delete(`${environment.urlPrefix}${constant.domain_gun}${id}/`, {});
  }

  postmails(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.mail_gun}`, body);
  }

  getmails(domainId: any) {
    return this.http.get(`${environment.urlPrefix}${constant.mail_gun}?domain_id=${domainId}`);
  }

  getSearchedMails(domainId: string, searchTerm: string) {
    return this.http.get(`${environment.urlPrefix}${constant.mail_gun}?domain_id=${domainId}&search=${searchTerm}`);
  }

  getUserMailList() {
    return this.http.get(`${environment.urlPrefix}${constant.mail_gun_staff_emails}`);
  }

  addStaffSignature(id: string, body: any) {
    return this.http.patch(`${environment.urlPrefix}${constant.staff_email_signature}${id}/`, body);
  }

  //Custom Tags CRM
  getTagswithpagination(page?: number, source?: string,pipeline_label?: string) {
    return this.http.get(`${environment.urlPrefix}${constant.custom_tags}?source=${source}&flow_source=${pipeline_label || ""}&page=${page}&offset=20`);
  }

  getTags(source_type: string) {
    return this.http.get(`${environment.urlPrefix}${constant.custom_tags}?source=${source_type}`);
  }

  getTagById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.custom_tags}?id=${id}`);
  }

  postTags(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_tags}`, body);
  }

  putTags(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.custom_tags}?id=${body.id}`, body);
  }

  deletetTags(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.custom_tags}?id=${id}`);
  }

  //custom tags CLIENT

  getClientTagswithpagination(page?: number, source?: string) {
    return this.http.get(`${environment.urlPrefix}${constant.custom_client_tags}?source=${source}&page=${page}&offset=20`);
  }

  getClientTags(source_type: string) {
    return this.http.get(`${environment.urlPrefix}${constant.custom_client_tags}?source=${source_type}`);
  }

  getClientTagById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.custom_client_tags}?id=${id}`);
  }

  postClientTags(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_client_tags}`, body);
  }

  putClientTags(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.custom_client_tags}?id=${body.id}`, body);
  }

  deletetClientTags(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.custom_client_tags}?id=${id}`);
  }

  //Activities Apis

  getAllActivities(page?: any) {
    if (page) {
      return this.http.get(`${environment.urlPrefix}${constant.activityType}?page=${page}&limit=20`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.activityType}`);
    }
  }

  postActivities(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.activityType}`, body);
  }

  updateActivities(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.activityType}`, body);
  }

  deleteActivities(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.activityType}?id=${id}`);
  }

  //Lost Reasons 

  //Activity Outcome

  getAllActivityOutcome(endpoint: any, page?: any) {
    if (page) {
      return this.http.get(`${environment.urlPrefix}${endpoint}?page=${page}&limit=20`);
    } else {
      return this.http.get(`${environment.urlPrefix}${endpoint}`);
    }
  }

  postActivityOutcome(body: any, endpoint: any) {
    return this.http.post(`${environment.urlPrefix}${endpoint}`, body);
  }

  updateActivityOutcome(body: any, endpoint: any, id?: string) {
    if (id) {
      return this.http.put(`${environment.urlPrefix}${endpoint}?id=${id}`, body);

    }
    return this.http.put(`${environment.urlPrefix}${endpoint}`, body);
  }

  deleteActivityOutcome(id: string, endpoint: any) {
    return this.http.delete(`${environment.urlPrefix}${endpoint}?id=${id}`);
  }

  //badges
  getBadges(type: string,category_id?: string) {
    if (type === 'DYNAMICAPP') {
      return this.http.get(`${environment.urlPrefix}${constant.dynamic_app_badge}?category_id=${category_id}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.badges}?type=${type}`);
    }
  }

  getBadgeById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.badges}${id}`);
  }

  postBadge(body: any) {
    if (body.type === 'DYNAMICAPP') {
      return this.http.post(`${environment.urlPrefix}${constant.dynamic_app_badge}`, body);
    } else {
      return this.http.post(`${environment.urlPrefix}${constant.badges}`, body);
    }
  }

  updateBadge(body: any) {
    if (body.type === 'DYNAMICAPP') {
      return this.http.put(`${environment.urlPrefix}${constant.dynamic_app_badge}${body.id}/`, body);
    } else {
      return this.http.put(`${environment.urlPrefix}${constant.badges}${body.id}/`, body);
    }
  }

  deleteBadge(id: string,type?: string) {
    if (type === 'DYNAMICAPP') {
      return this.http.delete(`${environment.urlPrefix}${constant.dynamic_app_badge}${id}/`);
    } else {
      return this.http.delete(`${environment.urlPrefix}${constant.badges}${id}/`);
    }
  }

  //DayOff
  getDayoff() {
    return this.http.get(`${environment.urlPrefix}${constant.dayoff}`);
  }
  getDayOffById(staffId: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.dayoff}?staff_id=${staffId}&page=${page}&limit=20`);
  }

  getDayoffFilters(startDate: any, endDate: any, time: any) {
    let type;
    if (!endDate && !time) {
      type = `?start_date=${startDate.split('T')[0]}`;
    } else if (endDate && !time) {
      type = `?start_date=${startDate}&end_date=${endDate}`;
    } else if (time && endDate) {
      type = `?start_date=${startDate}&end_date=${endDate}&time=${time}`;
    }
    return this.http.get(`${environment.urlPrefix}${constant.dayoff}${type ? type : ''}`);
  }

  getDayoffFilterBasedStaff(staffname: string) {
    return this.http.get(`${environment.urlPrefix}${constant.dayoff}?staff_name=${staffname}`);
  }

  postDayoff(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.dayoff}`, body);
  }

  putDayoff(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.dayoff}${body.id}/`, body);
  }

  //Assigned services for Appointments
  getAssignedServices(staffid: string) {
    return this.http.get(`${environment.urlPrefix}${constant.appointmentStaffService}?staff=${staffid}`);
  }

  putAssignedServices(staffid: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.appointmentStaffService}${staffid}/`, body);
  }

  //Email Templates

  getEmailTemplate(type: string) {
    return this.http.get(`${environment.urlPrefix}${constant.email_template}?type=${type}`);
  }
  putEmailTemplate(id: string, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.email_template}${id}/`, body);
  }
  postTestEmail(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.test_email}`, body);
  }

  //Invoice Count
  getInvoiceCount(){
    return this.http.get(`${environment.urlPrefix}${constant.invoice_count}`);
  }

  //Subscription plans
  getListOfPlans(plan_type:string){
    return this.http.get(`${environment.urlPrefix}${constant.billing}${constant.subscription}/${plan_type}${constant.plans}`);
  }

  checkOutPayment(plan_id: string,type: string){
    return this.http.get(`${environment.urlPrefix}${constant.billing}${constant.plan_payment}${plan_id}/?gateway=${type === 'free' ? 'free' : 'stripe'}`);
  }

  getCurrentPlanOfOrg(){
    return this.http.get(`${environment.urlPrefix}${constant.billing}${constant.current_plan}`);
  }

  getcurrenPlan_usage() {
    return this.http.get(`${environment.urlPrefix}${constant.billing}${constant.current_plan_usage}`);
  }

  getPlanDetails(planId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.billing}${constant.plan_pricing_details }${planId}/`);
  }

  getTwilioNumbers(){
    return this.http.get(`${environment.messageUrl}${constant.twilio_from_numbers}`);
  }
}
