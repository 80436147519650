import { Component, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-link-open-warning-modal',
  templateUrl: './link-open-warning-modal.component.html',
  styleUrls: ['./link-open-warning-modal.component.scss']
})
export class LinkOpenWarningModalComponent {

  @ViewChild('tp') _matTooltip!: MatTooltip;
  linkUrl!: string;
  constructor(public ngbActiveModal: NgbActiveModal) { }

  onClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltip.show();
      this._matTooltip.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }
}