import { Component, OnInit } from '@angular/core';
import { InvoiceService } from 'src/app/core/service/invoice.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormGroup, FormControl, FormControlName, Validators } from '@angular/forms';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  terms_and_conditions: any;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  terms_and_conditions_form!: FormGroup;
  submitted = false;
  terms_and_conditions_resp: any;

  constructor(private invoice_service: InvoiceService) { }

  ngOnInit(): void {
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    this.getOrganisationTermsAndConditions();
    this.initilizeForm();
  }

  initilizeForm() {
    this.terms_and_conditions_form = new FormGroup({
      id: new FormControl(this.terms_and_conditions_resp?.id),
      terms_conditions: new FormControl(this.terms_and_conditions_resp?.terms_conditions ? this.terms_and_conditions_resp?.terms_conditions : '', Validators.required),
      footer_contact_us: new FormControl(this.terms_and_conditions_resp?.footer_contact_us ? this.terms_and_conditions_resp?.footer_contact_us : '', Validators.required)
    });
  }

  get f() {
    return this.terms_and_conditions_form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.terms_and_conditions_form.valid) {
      this.invoice_service.updateTermsAndCondition(this.terms_and_conditions_form.value).subscribe({
        next: (resp: any) => {
          console.log(resp);

        }
      });
    }
  }

  getOrganisationTermsAndConditions() {
    this.invoice_service.getTermsAndConditions().subscribe({
      next: (resp: any) => {
        console.log(resp);
        this.terms_and_conditions_resp = resp.data[0];
        this.terms_and_conditions_form.patchValue({ ...this.terms_and_conditions_resp });
      }
    });
  }
}
