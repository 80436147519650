<div class="dial-pad" (click)="$event.stopPropagation();$event.preventDefault();">
  <div class="display">
    <input type="text" [value]="dialedNumber" readonly />
  </div>

  <div class="buttons">
    <div *ngFor="let row of numberPad">
      <button *ngFor="let num of row" (click)="press(num)" [ngClass]="{'starClass' : num=== '*' }">{{ num }}</button>
    </div>
    <div class="d-flex align-items-center justify-content-end">
      <!-- <button (click)="call()">
        <mat-icon class="font-size-20 d-flex align-item-center">call</mat-icon>
      </button> -->
      <button (click)="clear()">
        <mat-icon class="font-size-20 d-flex align-items-center justify-content-center">clear</mat-icon>
      </button>
    </div>
  </div>
</div>