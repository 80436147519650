import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Chart from "chart.js/auto";
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CommonService } from 'src/app/core/service/common.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { TaskBoardService } from 'src/app/core/service/task-board.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('canvasTask') canvasTask!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasPipeline') canvasPip!: ElementRef<HTMLCanvasElement>;
  @ViewChild('compareCanvas') canvasComp!: ElementRef<HTMLCanvasElement>;
  reportLists = [{
    type: 'Enquiry',
    customFieldSeclection: false,
    totalReportsCreated: 0,
    'method': this.getEnquiryReports,
    maxDate: new Date(2000, 1, 1),
    startMaxDate: new Date(),
    selectedfilterType: 'Today',
    sourceType: 'lead',
    disabled: false
  },
  {
    type: 'Contact',
    customFieldSeclection: false,
    totalReportsCreated: 0,
    'method': this.getContantReports,
    maxDate: new Date(2000, 1, 1),
    startMaxDate: new Date(),
    selectedfilterType: 'Today',
    sourceType: 'contact',
    disabled: false
  },
  {
    type: 'Company',
    customFieldSeclection: false,
    totalReportsCreated: 0,
    'method': this.getCompanyReports,
    maxDate: new Date(2000, 1, 1),
    startMaxDate: new Date(),
    selectedfilterType: 'Today',
    sourceType: 'company',
    disabled: false
  },
  {
    type: 'Clients',
    customFieldSeclection: false,
    totalReportsCreated: 0,
    'method': this.getClientReports,
    maxDate: new Date(2000, 1, 1),
    startMaxDate: new Date(),
    selectedfilterType: 'Today',
    sourceType: '',
    disabled: false
  },
  {
    type: 'Client Projects',
    customFieldSeclection: false,
    totalReportsCreated: 0,
    'method': this.getClientProjectsReports,
    maxDate: new Date(2000, 1, 1),
    startMaxDate: new Date(),
    selectedfilterType: 'Today',
    sourceType: 'project',
    disabled: false
  },
  {
    type: 'Task',
    customFieldSeclection: false,
    totalReportsCreated: 0,
    'method': this.getAllTaskReports,
    maxDate: new Date(2000, 1, 1),
    startMaxDate: new Date(),
    selectedfilterType: 'Today',
    sourceType: '',
    disabled: false
  },
  {
    type: 'Activity',
    customFieldSeclection: false,
    totalReportsCreated: 0,
    'method': this.getAllActivitiesReports,
    maxDate: new Date(2000, 1, 1),
    startMaxDate: new Date(),
    selectedfilterType: 'Today',
    sourceType: '',
    disabled: true
  },
  {
    type: 'Appointments',
    customFieldSeclection: false,
    totalReportsCreated: 0,
    'method': this.getAllAppointmentsReports,
    maxDate: new Date(2000, 1, 1),
    startMaxDate: new Date(),
    selectedfilterType: 'Today',
    sourceType: '',
    disabled: false
  },
  {
    type: 'Orders',
    customFieldSeclection: false,
    totalReportsCreated: 0,
    'method': this.getAllOrdersReports,
    maxDate: new Date(2000, 1, 1),
    startMaxDate: new Date(),
    selectedfilterType: 'Today',
    sourceType: '',
    disabled: false
  }
  ];

  comapreDataValidations: any = [
    {
      compare_with: {
        maxDate: new Date(2000, 1, 1),
        minDate: new Date(2000, 1, 1),
        startMaxDate: new Date(),
        endMaxDate: new Date(),
        totalReportsCreated: 0,
        customFieldSeclection: false,
        selectedfilterType: 'This Week'
      },
      compare_to: {
        maxDate: new Date(2000, 1, 1),
        minDate: new Date(2000, 1, 1),
        startMaxDate: new Date(),
        endMaxDate: new Date(),
        totalReportsCreated: 0,
        customFieldSeclection: false,
        selectedfilterType: 'Today'
      }
    }
  ];

  filteredBy = ['Today', 'This Week', 'Last Week', 'Last Month', 'Custom Date'];
  customDateFrom!: FormGroup;
  compareCustomDateForm!: FormGroup;
  customFieldSeclection = false;
  pipelineReports: any;
  allPipelineLists: any[] = [];
  allTasksCreated: any;
  pipChart!: Chart;
  taskChart!: Chart;
  compareChart!: Chart;
  minDate!: Date;
  maxDate!: Date;
  today = new Date();
  formsArray: FormGroup[] = [];
  allStages: any[] = [];
  sourceType = '';
  taskBoardReports: any;
  clientProjectLists: any;
  selectedFieldForCompare = 0;
  compareFromReportsCount = 0;
  compareToReportsCount = 0;
  compareReportPercentage = 0;
  reloadingLists: any[] = [];
  constructor(private commonService: CommonService, private crmService: CrmService, private fb: FormBuilder, private toastr: ToastrService, private taskboardServices: TaskBoardService, private renderer: Renderer2, private clientService: ClientsService) {
  }

  ngOnInit(): void {
    const todayDate = new Date().toISOString();
    this.sourceType = this.reportLists[0].sourceType;
    this.getAllReports(todayDate);
    this.getAllStages('', this.reportLists[0].sourceType);
    if (this.comapreDataValidations[0].compare_to.selectedfilterType !== 'Custom Date' && this.comapreDataValidations[0].compare_with.selectedfilterType !== 'Custom Date') {
      this.onSwitchFilterBy(this.comapreDataValidations[0].compare_with.selectedfilterType, 'compare_with');
      this.onSwitchFilterBy(this.comapreDataValidations[0].compare_to.selectedfilterType, 'compare_to');
    }
    this.initForm();
  }

  getAllReports(start_date: string, end_date?: string, type?: string) {
    this.getAllPipelines();
    this.getCompanyReports(start_date, end_date, type);
    this.getContantReports(start_date, end_date, type);
    this.getClientReports(start_date, end_date, type);
    this.getEnquiryReports(start_date, end_date, type);
    this.getClientProjectsReports(start_date, end_date, type);
    this.getAllTaskReports(start_date, end_date, type);
    this.getAllOrdersReports(start_date, end_date, type);
    this.getAllAppointmentsReports(start_date, end_date, type);
    this.getAllActivitiesReports(start_date, end_date, type);
    this.getAllPipelineReports();
    this.getClientProjectsLists();
  }

  initForm() {
    this.reportLists.forEach((elem: any) => {
      const customDateFrom = this.fb.group({
        start_date: ['', [Validators.required]],
        end_date: ['', [Validators.required]]
      });
      this.formsArray.push(customDateFrom);
    });

    this.compareCustomDateForm = this.fb.group({
      compare_with: this.fb.group({
        start_date: ['', [Validators.required]],
        end_date: ['', [Validators.required]]
      }),
      compare_to: this.fb.group({
        start_date: ['', [Validators.required]],
        end_date: ['', [Validators.required]]
      })
    });
  }

  ngAfterViewInit() {
    if (this.canvasTask) {
      this.canvasTask.nativeElement.width = 0;
      this.canvasTask.nativeElement.height = 0;
    } else if (this.canvasPip) {
      this.canvasPip.nativeElement.width = 0;
      this.canvasPip.nativeElement.height = 0;
    } else if (this.canvasComp) {
      this.canvasComp.nativeElement.width = 0;
      this.canvasComp.nativeElement.height = 0;
    }
    const loader: any = document.querySelectorAll('.reloader');
    if (loader.length > 0) {
      this.reloadingLists = loader;
    }
  }

  onDateChange(ev: any, index: number) {
    if (ev.targetElement.name === 'end') {
      const lessThanOneDay = new Date(ev.value);
      lessThanOneDay.setDate(ev.value.getDate() - 1);
      this.reportLists[index]['startMaxDate'] = new Date(lessThanOneDay);
    } else if (ev.targetElement.name === 'start') {
      const lessThanOneDay = new Date(ev.value);
      lessThanOneDay.setDate(ev.value.getDate() + 1);
      this.reportLists[index]['maxDate'] = new Date(lessThanOneDay);
    }
    if (this.formsArray[index].valid && (this.formsArray[index].value.end_date > this.formsArray[index].value.start_date)) {
      this.addLoadingSpinner(index);
      this.onSubmit(index);
    }
  }
  onCompareDateChange(event: any, type: string) {
    const lessThanOneDay = new Date(event.value);
    const oneThanOneDays = new Date(event.value);
    lessThanOneDay.setDate(event.value.getDate() - 1);
    oneThanOneDays.setDate(event.value.getDate() + 1);
    if (this.compareCustomDateForm.controls['compare_to'].value.start_date) {
      const date = this.compareCustomDateForm.controls['compare_to'].value.start_date;
      const lessThanDay = new Date(date);
      lessThanDay.setDate(date.getDate() - 1);
      this.comapreDataValidations[0].compare_with.endMaxDate = new Date(lessThanDay);
    }
    if (type === 'compare_with') {
      if (event.targetElement.name === 'end') {
        this.comapreDataValidations[0].compare_with.startMaxDate = new Date(lessThanOneDay);
        this.comapreDataValidations[0].compare_to.minDate = new Date(oneThanOneDays);
      } else if (event.targetElement.name === 'start') {
        this.comapreDataValidations[0].compare_with.maxDate = new Date(oneThanOneDays);
      }
      // }
    } else {
      if (event.targetElement.name === 'end') {
        this.comapreDataValidations[0].compare_to.startMaxDate = new Date(lessThanOneDay);
      } else if (event.targetElement.name === 'start') {
        this.comapreDataValidations[0].compare_to.maxDate = new Date(oneThanOneDays);
      }
    }
    if (this.compareCustomDateForm.controls['compare_with'].valid && type === 'compare_with') {
      this.addCompareLoadingSpinner();
      this.onSubmit('', 'compare_with');
      if (this.comapreDataValidations[0].compare_to.customFieldSeclection === false) {
        this.onSwitchFilterBy(this.comapreDataValidations[0].compare_to.selectedfilterType, 'compare_toß');
      }
    } else if (this.compareCustomDateForm.controls['compare_to'].valid && type === 'compare_to') {
      this.addCompareLoadingSpinner();
      this.onSubmit("", 'compare_to');
      if (this.comapreDataValidations[0].compare_to.customFieldSeclection === false) {
        this.onSwitchFilterBy(this.comapreDataValidations[0].compare_with.selectedfilterType, 'compare_with');
      }
    }
  }
  onSelectCompareFilter(event: any) {
    this.selectedFieldForCompare = this.reportLists.findIndex((elem: any) => elem.type === event);
    if (this.comapreDataValidations[0].compare_to.selectedfilterType === 'Custom Date' || this.comapreDataValidations[0].compare_with.selectedfilterType === 'Custom Date') {
      if (this.compareCustomDateForm.controls['compare_with'].valid) {
        this.addCompareLoadingSpinner();
        this.onSubmit('', 'compare_with');
        this.onSwitchFilterBy(this.comapreDataValidations[0].compare_to.selectedfilterType, 'compare_to');
      } else if (this.compareCustomDateForm.controls['compare_to'].valid) {
        this.addCompareLoadingSpinner();
        this.onSwitchFilterBy(this.comapreDataValidations[0].compare_with.selectedfilterType, 'compare_with');
        this.onSubmit("", 'compare_to');
      }
    } else {
      if (this.comapreDataValidations[0].compare_to.selectedfilterType !== 'Custom Date' && this.comapreDataValidations[0].compare_with.selectedfilterType !== 'Custom Date') {
        this.addCompareLoadingSpinner();
        this.onSwitchFilterBy(this.comapreDataValidations[0].compare_with.selectedfilterType, 'compare_with');
        this.onSwitchFilterBy(this.comapreDataValidations[0].compare_to.selectedfilterType, 'compare_to');
      }
    }
  }
  onReload() {
    if (this.compareCustomDateForm.controls['compare_with'].valid && this.compareCustomDateForm.controls['compare_to'].valid) {
      this.addCompareLoadingSpinner();
      this.onSubmit('', 'compare_with');
      this.onSubmit("", 'compare_to');
    } else if (this.comapreDataValidations[0].compare_to.selectedfilterType !== 'Custom Date' && this.comapreDataValidations[0].compare_with.selectedfilterType !== 'Custom Date') {
      this.addCompareLoadingSpinner();
      this.onSwitchFilterBy(this.comapreDataValidations[0].compare_with.selectedfilterType, 'compare_with');
      this.onSwitchFilterBy(this.comapreDataValidations[0].compare_to.selectedfilterType, 'compare_to');
    } else if (this.comapreDataValidations[0].compare_to.selectedfilterType !== 'Custom Date' || this.comapreDataValidations[0].compare_with.selectedfilterType !== 'Custom Date') {
      if (this.compareCustomDateForm.controls['compare_with'].valid) {
        this.addCompareLoadingSpinner();
        this.onSubmit('', 'compare_with');
        if (this.comapreDataValidations[0].compare_to.customFieldSeclection === false) {
          this.onSwitchFilterBy(this.comapreDataValidations[0].compare_to.selectedfilterType, 'compare_toß');
        }
      } else if (this.compareCustomDateForm.controls['compare_to'].valid) {
        this.addCompareLoadingSpinner();
        this.onSubmit("", 'compare_to');
        if (this.comapreDataValidations[0].compare_to.customFieldSeclection === false) {
          this.onSwitchFilterBy(this.comapreDataValidations[0].compare_with.selectedfilterType, 'compare_with');
        }
      }
    }
  }

  reloadReport(selectedType: string, index: number) {
    this.addLoadingSpinner(index);
    this.onSelectFilteredBy(selectedType, index);
  }

  onSelectFilteredBy(event: any, index: number) {
    this.reportLists[index].selectedfilterType = event;
    this.reportLists[index].totalReportsCreated = 0;
    switch (event) {
      case 'Custom Date':
        this.reportLists[index].customFieldSeclection = true;
        if (this.formsArray[index].valid && (this.formsArray[index].value.end_date > this.formsArray[index].value.start_date)) {
          this.onSubmit(index);
        }
        break;

      case 'Today': {
        this.reportLists[index].customFieldSeclection = false;
        const todayDate = new Date();
        this.addLoadingSpinner(index);
        const todayDate_iso = todayDate.toISOString();
        this.callingApis(index, todayDate_iso);
        this.resetForm(index);
      }
        break;

      case 'Last Month': {
        this.reportLists[index].customFieldSeclection = false;
        this.addLoadingSpinner(index);
        const lastMonth: any = this.getLastMonthDate();
        const UTCDate = this.UTCConversion(lastMonth.startOfLastMonth, lastMonth.endOfLastMonth);
        this.callingApis(index, UTCDate.start_date_iso, UTCDate.end_date_iso);
        this.resetForm(index);
      }
        break;

      case 'Last Week': {
        this.reportLists[index].customFieldSeclection = false;
        this.addLoadingSpinner(index);
        const lastWeekDate: any = this.getLastWeekDate();
        const UTCDate = this.UTCConversion(lastWeekDate.lastMonday, lastWeekDate.lastSunday);
        this.callingApis(index, UTCDate.start_date_iso, UTCDate.end_date_iso);
        this.resetForm(index);
      }
        break;

      case 'This Week': {
        this.reportLists[index].customFieldSeclection = false;
        this.addLoadingSpinner(index);
        const thisWeekDate = this.getThisWeekDate();
        const start_date = new Date(new Date(thisWeekDate).setUTCHours(0, 0, 0, 1));
        const start_date_iso = start_date.toISOString();
        const end_date_iso = new Date().toISOString();
        this.callingApis(index, start_date_iso, end_date_iso);
        this.resetForm(index);
      }
        break;

      default:
    }
  }

  onSwitchFilterBy(filteredType: string, type: string) {
    switch (filteredType) {
      case 'Today': {
        const todayDate = new Date();
        const todayDate_iso = todayDate.toISOString();
        this.callingApis(this.selectedFieldForCompare, todayDate_iso, '', type);
      }
        break;
      case 'Last Month': {
        const lastMonth: any = this.getLastMonthDate();
        const UTCDate = this.UTCConversion(lastMonth.startOfLastMonth, lastMonth.endOfLastMonth);
        this.callingApis(this.selectedFieldForCompare, UTCDate.start_date_iso, UTCDate.end_date_iso, type);
      }
        break;

      case 'Last Week': {
        const lastWeekDate: any = this.getLastWeekDate();
        const UTCDate = this.UTCConversion(lastWeekDate.lastMonday, lastWeekDate.lastSunday);
        this.callingApis(this.selectedFieldForCompare, UTCDate.start_date_iso, UTCDate.end_date_iso, type);
      }
        break;

      case 'This Week': {
        const thisWeekDate = this.getThisWeekDate();
        const start_date = new Date(new Date(thisWeekDate).setUTCHours(0, 0, 0, 1));
        const start_date_iso = start_date.toISOString();
        const end_date_iso = new Date().toISOString();
        this.callingApis(this.selectedFieldForCompare, start_date_iso, end_date_iso, type);
      }
        break;

      default:
    }
  }

  UTCConversion(start: string, end: string) {
    const start_date = new Date(new Date(start).setUTCHours(0, 0, 0, 1));
    const start_date_iso = start_date.toISOString();
    const end_date = new Date(new Date(end).setUTCHours(23, 59, 59, 999));
    const end_date_iso = end_date.toISOString();
    return { start_date_iso, end_date_iso };
  }

  getLastMonthDate() {
    const startOfLastMonth = new Date(Date.UTC(this.today.getUTCFullYear(), this.today.getUTCMonth() - 1, 1));
    const endOfLastMonth = new Date(Date.UTC(this.today.getUTCFullYear(), this.today.getUTCMonth(), 0));
    return { startOfLastMonth, endOfLastMonth };

  }
  getLastWeekDate() {
    const currentDayOfWeek = this.today.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
    const daysToAdd = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    const lastWeekMonday = new Date(this.today);
    lastWeekMonday.setDate(this.today.getDate() - daysToAdd - 7);
    const lastMonday = lastWeekMonday;
    const lastSunday = new Date(lastMonday);
    lastSunday.setDate(lastMonday.getDate() + 6);
    console.log(lastMonday, lastSunday);
    return { lastMonday, lastSunday };
  }
  getThisWeekDate() {

    const currentDayOfWeek = this.today.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
    const daysToMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    const mostRecentMonday = new Date(this.today);
    return mostRecentMonday.setDate(this.today.getDate() - daysToMonday);
  }

  resetForm(index: any) {
    this.formsArray[index].reset();
  }

  onSelectCompareFilteredBy(ev: any, type: string) {
    this.comapreDataValidations[0][type]['selectedfilterType'] = ev;
    if (ev === 'Custom Date') {
      this.comapreDataValidations[0][type]['customFieldSeclection'] = true;
    } else {
      this.comapreDataValidations[0][type]['customFieldSeclection'] = false;
    }
    if (this.compareCustomDateForm.controls['compare_with'].valid) {
      this.addCompareLoadingSpinner();
      this.onSubmit('', 'compare_with');
      if (this.comapreDataValidations[0].compare_to.customFieldSeclection === false) {
        this.onSwitchFilterBy(this.comapreDataValidations[0].compare_to.selectedfilterType, 'compare_to');
      }
    } else if (this.compareCustomDateForm.controls['compare_to'].valid) {
      this.addCompareLoadingSpinner();
      this.onSubmit("", 'compare_to');
      if (this.comapreDataValidations[0].compare_to.customFieldSeclection === false) {
        this.onSwitchFilterBy(this.comapreDataValidations[0].compare_with.selectedfilterType, 'compare_with');
      }
    } else if (this.comapreDataValidations[0].compare_to.selectedfilterType !== 'Custom Date' && this.comapreDataValidations[0].compare_with.selectedfilterType !== 'Custom Date') {
      this.addCompareLoadingSpinner();
      this.onSwitchFilterBy(this.comapreDataValidations[0].compare_with.selectedfilterType, 'compare_with');
      this.onSwitchFilterBy(this.comapreDataValidations[0].compare_to.selectedfilterType, 'compare_to');
    }
    // this.onSwitchFilterBy(ev, type);
  }

  callingApis(index: number, start_date: string, end_date?: string, type?: string) {
    const methodToCall = this.reportLists[index]['method'];
    if (methodToCall && typeof methodToCall === 'function') {
      const getApisCall = methodToCall.bind(this);
      getApisCall(start_date, end_date, type);
    }
  }

  UTCCustomDateConversion(start: string, end: string) {
    const start_date = new Date(start);
    const utcStartDate = new Date(start_date.getTime() - start_date.getTimezoneOffset() * 60000);
    utcStartDate.setUTCHours(0, 0, 0, 1);
    const start_date_iso = utcStartDate.toISOString();
    const end_date = new Date(end);
    const utcEndDate = new Date(end_date.getTime() - end_date.getTimezoneOffset() * 60000);
    utcEndDate.setUTCHours(23, 59, 59, 999);
    const end_date_iso = utcEndDate.toISOString();
    return { start_date_iso, end_date_iso };
  }

  onSubmit(index: any, type?: string) {
    if ((index === 0 || index) && this.formsArray[index].valid) {
      const UTCDate = this.UTCCustomDateConversion(this.formsArray[index].value.start_date, this.formsArray[index].value.end_date);
      this.callingApis(index, UTCDate.start_date_iso, UTCDate.end_date_iso);
    } else if (type) {
      const UTCDate = this.UTCCustomDateConversion(this.compareCustomDateForm.controls[type].value.start_date, this.compareCustomDateForm.controls[type].value.end_date);
      this.callingApis(this.selectedFieldForCompare, UTCDate.start_date_iso, UTCDate.end_date_iso, type);
    }
  }

  getAllPipelines() {
    this.crmService.getPipelines().subscribe({
      next: (response: any) => {
        const data = response['data'];
        const filteredList = data.filter((elem: any) => elem && elem.stage_order.length > 0);
        this.allPipelineLists = filteredList;
        if (this.pipelineReports) {
          this.initilizeBarChat(this.pipelineReports[this.allPipelineLists[0]?.name], 'pipline');
        }
      },
      error: (err) => {
        console.log(err);
        this.toastr.error('Failed to load');
      }
    }
    );
  }
  getEnquiryReports(startDate: string, endDate = '', type?: string) {
    this.commonService.getEnquiryReports(startDate, endDate).subscribe({
      next: (response: any) => {
        this.updateReportsData(0, response['data'].leads_created, type);
        this.removeLoadingSpinner(0);
      },
      error: (err) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
        this.removeLoadingSpinner(0);
      }
    });
  }
  getContantReports(startDate: string, endDate = '', type?: string) {
    this.commonService.getContantReports(startDate, endDate).subscribe({
      next: (response: any) => {
        this.updateReportsData(1, response['data'].contacts_created, type);
        this.removeLoadingSpinner(1);
      },
      error: (err) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
        this.removeLoadingSpinner(1);
      }
    });
  }
  getCompanyReports(startDate: string, endDate = '', type?: string) {
    this.commonService.getCompanyReports(startDate, endDate).subscribe({
      next: (response: any) => {
        this.updateReportsData(2, response['data'].companies_created, type);
        this.removeLoadingSpinner(2);
      },
      error: (err) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
        this.removeLoadingSpinner(2);
      }
    });
  }
  getClientReports(startDate: string, endDate = '', type?: string) {
    this.commonService.getClientReports(startDate, endDate).subscribe({
      next: (response: any) => {
        this.updateReportsData(3, response['data'].clients_created, type);
        this.removeLoadingSpinner(3);
      },
      error: (err) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
        this.removeLoadingSpinner(3);
      }
    });
  }

  getClientProjectsReports(startDate: string, endDate = '', type?: string) {
    this.commonService.getProjectReports(startDate, endDate).subscribe({
      next: (response: any) => {
        this.updateReportsData(4, response['data'].project_created, type);
        this.removeLoadingSpinner(4);
      },
      error: (err) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
        this.removeLoadingSpinner(4);
      }
    });
  }
  getAllTaskReports(startDate: string, endDate = '', type?: string) {
    this.commonService.getTaskReports(startDate, endDate).subscribe({
      next: (response: any) => {
        this.updateReportsData(5, response['data'].tasks_created, type);
        this.removeLoadingSpinner(5);
      },
      error: (err) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
        this.removeLoadingSpinner(5);
      }
    });
  }
  getAllActivitiesReports(startDate: string, endDate = '', type?: string) {
    this.commonService.getActivityReports(startDate, endDate).subscribe({
      next: (response: any) => {
        this.updateReportsData(6, response['data'].activities, type);
        this.removeLoadingSpinner(6);
      },
      error: (err) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
        this.removeLoadingSpinner(6);
      }
    });
  }
  getAllPipelineReports() {
    this.commonService.getPipelineReports().subscribe((response: any) => {
      this.pipelineReports = response['data'];
      if (this.allPipelineLists && this.allPipelineLists.length > 0) {
        this.initilizeBarChat(this.pipelineReports[this.allPipelineLists[0].name], 'pipline');
      }
    });
  }
  getAllAppointmentsReports(startDate: string, endDate = '', type?: string) {
    this.commonService.getAppointmentsReports(startDate, endDate).subscribe({
      next: (response: any) => {
        const appointmentListRespons:any = response['data'];
        if (type === 'charts') {
          if (this.taskChart) {
            this.taskChart.destroy();
          }
          const data: any[] = [
            {
              'stage_name': 'Total Orders',
              'number_of_cards': appointmentListRespons?.total_appointment_count
            },
            {
              stage_name: 'Pending',
              number_of_cards: appointmentListRespons?.waiting_count
            },
            {
              stage_name: 'Accepted',
              number_of_cards: appointmentListRespons?.accepted_count
            },
            {
              stage_name: 'Rejected',
              number_of_cards: appointmentListRespons?.rejected_count
            },
            {
              stage_name: 'No show',
              number_of_cards: appointmentListRespons?.no_show_count
            },
            {
              stage_name: 'Completed',
              number_of_cards: appointmentListRespons?.completed
            }
 
          ];
          this.initilizeBarChat(data, 'appointments');
        } else {
          this.updateReportsData(7, response['data'].total_appointment_count, type);
        }
        this.removeLoadingSpinner(7);
      },
      error: (err) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
        this.removeLoadingSpinner(7);
      }
    });
  }
  getAllOrdersReports(startDate: any, endDate = '', type?: string) {
    this.commonService.getOrdersReports(startDate, endDate).subscribe({
      next: (response: any) => {
        const ordersListResponse:any = response['data'];
        if (type === 'charts') {
          if (this.taskChart) {
            this.taskChart.destroy();
          }
          const data: any[] = [
            {
              'stage_name': 'Total Orders',
              'number_of_cards': ordersListResponse?.total_order_count
            },
            {
              stage_name: 'Accepted Orders',
              number_of_cards: ordersListResponse?.accepted_count
            },
            {
              stage_name: 'Pending Orders',
              number_of_cards: ordersListResponse?.pending_count
            },
            {
              stage_name: 'Rejected Orders',
              number_of_cards: ordersListResponse?.rejected_count
            }
          ];
          this.initilizeBarChat(data, 'orders');
        } else {
          this.updateReportsData(8, response['data'].total_order_count, type);
        }
        this.removeLoadingSpinner(8);
      },
      error: (err) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
        this.removeLoadingSpinner(8);
      }
    });
  }

  addLoadingSpinner(index: number) {
    if (this.reloadingLists.length > 0) {
      this.renderer.addClass(this.reloadingLists[index], 'rotate-loader');
    }
  }

  addCompareLoadingSpinner() {
    const reloader = document.querySelector('.compare-reloader');
    reloader && this.renderer.addClass(reloader, 'rotate-loader');
  }

  removeLoadingSpinner(index: number) {
    const reloader = document.querySelector('.compare-reloader');
    reloader && this.renderer.removeClass(reloader, 'rotate-loader');
    if (this.reloadingLists[index].classList.contains('rotate-loader')) {
      this.renderer.removeClass(this.reloadingLists[index], 'rotate-loader');
    }
  }
  // getAllPipelineReports() {
  //   this.commonService.getPipelineReports().subscribe((response: any) => {
  //     this.pipelineReports = response['data'];
  //     if (this.allPipelineLists && this.allPipelineLists.length > 0) {
  //       this.initilizeBarChat(this.pipelineReports[this.allPipelineLists[0].name], 'pipline');
  //     }
  //   });
  // }

  updateReportsData(index: number, data: any, type?: string) {
    if (!type) {
      this.reportLists[index].totalReportsCreated = data;
    } else {
      if (type === 'compare_with') {
        this.compareFromReportsCount = data;
      } else if (type === 'compare_to') {
        this.compareToReportsCount = data;
      }
      this.compareTwoDatas();
    }
  }
  compareTwoDatas() {
    const data = [
      {
        stage_name: this.comapreDataValidations[0].compare_with.selectedfilterType,
        number_of_cards: this.compareFromReportsCount
      },
      {
        stage_name: this.comapreDataValidations[0].compare_to.selectedfilterType,
        number_of_cards: this.compareToReportsCount
      }];
    if (this.compareChart) {
      this.compareChart.destroy();
    }
    this.initilizeBarChat(data, 'compare');
    const textNode = document.querySelector('.compare-percentage');
    textNode && this.renderer.setStyle(textNode, 'color', 'black');
    this.compareReportPercentage = 0;
    if (this.compareToReportsCount - this.compareFromReportsCount > 0) {
      this.compareReportPercentage = ((this.compareToReportsCount - this.compareFromReportsCount) / this.compareToReportsCount) * 100;
      this.compareReportPercentage = this.compareReportPercentage ? Math.round(this.compareReportPercentage * 1e2) / 1e2 : this.compareReportPercentage;
      textNode && this.renderer.setStyle(textNode, 'color', 'green');
    } else if (this.compareToReportsCount - this.compareFromReportsCount < 0) {
      this.compareReportPercentage = ((this.compareFromReportsCount - this.compareToReportsCount) / this.compareFromReportsCount) * 100;
      this.compareReportPercentage = this.compareReportPercentage ? Math.round(this.compareReportPercentage * 1e2) / 1e2 : this.compareReportPercentage;
      textNode && this.renderer.setStyle(textNode, 'color', 'red');
    }
  }
  onSelectionPipeline(event: any) {
    if (this.pipChart) {
      this.pipChart.destroy();
    }
    this.initilizeBarChat((this.pipelineReports[event]), 'pipline');
  }

  //currently not using but can be added in future for Pie chat 
  // onSelectionTask(event: any) {
  //   console.log(event);
  // }

  getClientProjectsLists() {
    this.clientService.getAllProjects().subscribe((response: any) => {
      this.clientProjectLists = response['data'];
    });
  }

  onSelectTaskBoard(ev: any) {
    const reportList = this.reportLists.find((elem: any) => elem.type === ev);
    reportList && (this.sourceType = reportList?.sourceType);
    switch (ev) {
      case 'Pipeline':
        this.allStages = JSON.parse(JSON.stringify(this.allPipelineLists));
        this.onShowHidesourceIdDropdown('Pipeline');
        break;

      case 'Client Projects':
        this.getClientProjectsLists();
        this.allStages = JSON.parse(JSON.stringify(this.clientProjectLists));
        this.onShowHidesourceIdDropdown('Client Projects');
        break;
      case 'Orders':
        this.getAllOrdersReports('', '', 'charts');
        break;
      case 'Appointments':
        this.getAllAppointmentsReports('', '', 'charts');
        break;
      default:
        this.onShowHidesourceIdDropdown('');
        this.getAllStages('', this.sourceType);
    }
  }

  async onShowHidesourceIdDropdown(type: string) {
    const sourceId = document.querySelector('.source_id');
    if (type === 'Pipeline' || type === 'Client Projects') {
      sourceId && this.renderer.setStyle(sourceId, 'display', 'flex');
      this.allStages && this.getAllStages(this.allStages[0].id, this.sourceType);
    } else {
      sourceId && this.renderer.setStyle(sourceId, 'display', 'none');
    }
  }

  onSelectionsourceId(ev: any) {
    this.getAllStages(ev, this.sourceType);
  }

  getAllStages(id = '', type: string) {
    this.taskboardServices.getStages(id, type).subscribe({
      next: (response: any) => {
        const stagesList = response['data'];
        this.getTaskBoardReports(stagesList);
      }, error: (err: any) => {
        console.log(err);
        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.toastr.error('Failed to load');
      }
    });
  }

  getTaskBoardReports(stagesList: any) {
    this.taskBoardReports = [];
    const apiCalls: any = [];
    stagesList.forEach((elem: any) => {
      apiCalls.push(this.commonService.getTaskByStageId(elem.id).pipe(
        catchError((error) => {
          console.error('API call failed:', error);
          return of(null); // Return an observable with a default value (null) to continue with forkJoin
        })
      ));
    });

    forkJoin(apiCalls).subscribe({
      next: (responses: any) => {
        let data: any;
        responses.forEach((response: any, index: number) => {
          if (response && response['data']) {
            data = {
              stage_name: stagesList[index].name,
              number_of_cards: response.count
            };
            this.taskBoardReports.push(data);
          }
        });

        if (this.taskChart) {
          this.taskChart.destroy();
        }
        this.taskBoardReports && this.initilizeBarChat(this.taskBoardReports, 'taskBoard');
      }, error: (err: any) => {
        console.log(err);
        this.toastr.error('Failed to load');
      }
    });
  }

  initilizeBarChat(data: any, type?: string) {
    const dynamicColors = ['#FF5733', '#f1b44c', 'green'];
    const stage_name: Array<string> = [];
    const number_of_cards: Array<number> = [];
    data.forEach((element: any) => {
      stage_name.push(element.stage_name);
      number_of_cards.push(element.number_of_cards);
    });
    let stageName = '';
    if (type === 'compare') {
      stageName = 'Date';
    } else if (type === 'pipline') {
      stageName = 'Pipeline Stage';
    } else if (type === 'taskBoard') {
      stageName = 'Task Stage';
    } else if (type === 'orders') {
      stageName = 'Order Status';
    } else if (type === 'appointments') {
      stageName = 'Appointment Status';
    }
    const chatData: any = {
      labels: stage_name,
      datasets: [
        {
          data: number_of_cards,
          borderWidth: 2,
          backgroundColor: dynamicColors,
          barPercentage: 0.8,
          categoryPercentage: 0.75
        }
      ]
    };
    const options: any = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        }
      },
      indexAxis: 'y',
      scales: {
        y: {
          title: {
            display: true,
            text: stageName
          }
        }
      },
      animation: {
        duration: 500
        // easing: 'linear'
      }
    };
    if (type === 'pipline') {
      if (this.canvasPip) {
        this.canvasPip.nativeElement.width = 400;
        this.canvasPip.nativeElement.height = 400;
      }
      this.pipChart = new Chart('canvasPip', {
        type: 'bar',
        data: chatData,
        options: options
      });
    } else if (type === 'compare') {
      if (this.canvasComp) {
        this.canvasComp.nativeElement.width = 180;
        this.canvasComp.nativeElement.height = 180;
      }
      this.compareChart = new Chart('compareCanvas', {
        type: 'bar',
        data: chatData,
        options: options
      });
    } else if (type === 'taskBoard') {
      if (this.canvasTask) {
        if (data.length > 3) {
          this.canvasTask.nativeElement.width = 300;
          this.canvasTask.nativeElement.height = 300;
        } else {
          this.canvasTask.nativeElement.width = 200;
          this.canvasTask.nativeElement.height = 200;
        }
      }
      this.taskChart = new Chart('canvasTask', {
        type: 'bar',
        data: chatData,
        options: options
      });
    } else if (type === 'orders') {
      if (this.canvasTask) {
        chatData['datasets'][0].backgroundColor = ['#f1b44c', 'green', 'blue', '#FF5733'];
        if (data.length > 3) {
          this.canvasTask.nativeElement.width = 300;
          this.canvasTask.nativeElement.height = 300;
        } else {
          this.canvasTask.nativeElement.width = 200;
          this.canvasTask.nativeElement.height = 200;
        }
      }
      this.taskChart = new Chart('canvasTask', {
        type: 'bar',
        data: chatData,
        options: options
      });
    } else if (type === 'appointments') {
      if (this.canvasTask) {
        chatData['datasets'][0].backgroundColor = ['#f1b44c', 'blue', 'green', '#FF5733'];
        if (data.length > 3) {
          this.canvasTask.nativeElement.width = 300;
          this.canvasTask.nativeElement.height = 300;
        } else {
          this.canvasTask.nativeElement.width = 200;
          this.canvasTask.nativeElement.height = 200;
        }
      }
      this.taskChart = new Chart('canvasTask', {
        type: 'bar',
        data: chatData,
        options: options
      });
    }

  }
  ngOnDestroy() {
    // destroying the chart to avoid memory leaks
    this.pipChart?.destroy();
    this.taskChart?.destroy();
    this.compareChart?.destroy();
  }
}
