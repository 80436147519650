<div class="modal-header pb-0 border-0">
    <h6 class="modal-title text-primary fw-600 text-capitalize" id="label">{{ type ? (type === 'Pipeline' ? 'Flow' : type) : 'Activity'}}
    </h6>
    <mat-icon class="cursor" (click)="ngbActiveModal.dismiss()">close</mat-icon>
</div>
<div class="modal-body">
    <form [formGroup]="markedOutcomeForm" (ngSubmit)="onSubmit()">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            <label class="mb-2 fw-600 text-primary font-size-14 text-capitalize ">{{ type ? (type === 'Pipeline' ? 'Flow' : type) : 'Activity'}} {{type === 'lead' ? 'Lost Reason' : 'Outcome'}}</label>
            <ng-select class="form-control" [items]="allActivityOutcomeList" bindLabel="outcome_text" bindValue="id"
                (change)="onSelectOutcome($event)" placeholder="Outcome" *ngIf="!type">
            </ng-select>
            <ng-select class="form-control" [items]="allActivityOutcomeList" bindLabel="reason_text" bindValue="id"
                (change)="onSelectOutcome($event)" placeholder="{{type ? (type === 'Pipeline' ? 'Flow' : type) : ''}}" *ngIf="type">
            </ng-select>
            <mat-error class="error d-flex align-items-center mt-1" *ngIf="isSubmitted && f['outcome'].errors">
                <mat-icon class="font-size-12 w-auto h-auto me-2">info</mat-icon>
                <span *ngIf="f['outcome'].errors['required']">{{ type ? (type === 'Pipeline' ? 'Flow' : type) : 'Activity'}} {{type === 'lead' ? 'lost Reason' : 'outcome'}}
                    required</span>
            </mat-error>
        </div>
        <div class="d-flex justify-content-end align-items-center">
            <button type="submit" class="btn btn-primary">Save</button>
        </div>
    </form>
</div>