<iframe allow="camera; microphone;screenobtainer; obtainscreenfromgetdisplaymedia;clipboard-read; clipboard-write" #iframeRef [ngClass]="{'d-none': !iframeLoaded}" [src]=src id="campaign-widget-value" width="100%" height="100%" style="min-height: 90vh;"></iframe>
<!-- <app-coming-soon></app-coming-soon> -->
<!-- <div class="container-fluid d-none">
    <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0 mb-2"
                (navChange)="tabChange($event)">
                <li [ngbNavItem]="1">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Campaigns</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-campaigns-list></app-campaigns-list>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Marketing list</span>
                    </a>
                    <ng-template ngbNavContent>
                       <app-marketing></app-marketing>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Templates</span>
                    </a>
                    <ng-template ngbNavContent>
                       <app-template></app-template>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Settings</span>
                    </a>
                    <ng-template ngbNavContent>
                        
                    </ng-template>
                </li>
                <li [ngbNavItem]="5">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">Reports</span>
                    </a>
                    <ng-template ngbNavContent>
                       
                    </ng-template>
                </li>
                <li [ngbNavItem]="6">
                    <a ngbNavLink class="d-flex">
                        <span class="d-sm-block font-size-12">more</span>
                    </a>
                    <ng-template ngbNavContent>
                        
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
</div> -->