import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HomeService } from 'src/app/core/service/home.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { ClientsService } from 'src/app/core/service/clients.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { TaskBoardService } from 'src/app/core/service/task-board.service';
import { ContactsModalComponent } from '../../shared/contacts-modal/contacts-modal.component';
import { LeadModalComponent } from '../../shared/lead-modal/lead-modal.component';
import { CompanyModalComponent } from '../../shared/company-modal/company-modal.component';
import { PipelineModalComponent } from '../../shared/pipeline-modal/pipeline-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientProjectModalComponent } from '../../shared/client-project-modal/client-project-modal.component';

@Component({
  selector: 'app-home-management',
  templateUrl: './home-management.component.html',
  styleUrls: ['./home-management.component.scss']
})
export class HomeManagementComponent implements OnInit {
  show_type = 'task';
  id: any;
  taskDetails: any;
  stageList: any;
  relatedSourceDetails: any;
  cardFields: any;
  storeId = '';
  boardDetails: any;
  assigneeMembers: any = [];
  loggedInUser: any;
  validateUserAccess = false;
  contactId = '';

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private homeService: HomeService,
    private crm_service: CrmService,
    private client_service: ClientsService,
    private setupService: SetUpService,
    private settingsService: SettingsService,
    private taskBoardService: TaskBoardService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.route.queryParams.subscribe((params: any) => {
      console.log(params);

      if (params.show_type) this.show_type = params.show_type;
      if (params.id) this.id = params.id;
    });
    if (this.show_type === 'task') {
      this.getTaskById();
    } else if (this.show_type === 'order' || this.show_type === 'calendar' || this.show_type === 'appointment') {
      console.log(this.id);
    } else {
      this.show_type = this.show_type.split('_').join(" ").toUpperCase();
      this.getActivitiesTaskById();
    }
  }
  getActivitiesTaskById() {
    this.homeService
      .getActivitiesTaskById(this.id)
      .subscribe((response: any) => {
        this.taskDetails = response.data;
        this.getContactById(response.data.source_id);
        this.show_type = response.data.activity_type_name;
        if (response.data.length !== 0) {
          if (response.data.source_type === 'lead') {
            this.getLeadById(response.data.source_id);
          } else if (response.data.source_type === 'contact') {
            // this.getContactById(response.data.source_id);
          } else if (response.data.source_type === 'company') {
            this.getCompanyById(response.data.source_id);
          } else if (response.data.source_type === 'project') {
            this.getProjectById(response.data.source_id);
          } else if (response.data.source_type === 'deal') {
            this.getFieldsBasedonCardId(response.data.source_id);
          }
        }
      });
  }

  getTaskById() {
    this.homeService.getTaskById(this.id).subscribe((response: any) => {
      this.taskDetails = response.data;
      this.getContactById(response.data.source_id);
      if (response.data.length !== 0) {
        if (response.data.source_type === 'lead') {
          this.getLeadById(response.data.source_id);
        } else if (response.data.source_type === 'contact') {
          // this.getContactById(response.data.source_id);
        } else if (response.data.source_type === 'company') {
          this.getCompanyById(response.data.source_id);
        } else if (response.data.source_type === 'project') {
          this.getProjectById(response.data.source_id);
        } else if (response.data.source_type === 'deal') {
          this.getFieldsBasedonCardId(response.data.source_id);
        } else if (response.data.source_type === 'board') {
          this.getBoardById(response.data.source_id);
        }
      }
    });
  }

  getLeadById(id: string) {
    this.crm_service.getLeadById(id).subscribe({
      next: (resp: any) => {
        console.log(resp, "lead");
        this.relatedSourceDetails = resp.data[0];
        if (this.relatedSourceDetails?.assignee.length > 0) this.getSourceStaffList(this.relatedSourceDetails.assignee);
        if (this.relatedSourceDetails?.tags) this.getTagData(this.relatedSourceDetails?.tags, 'lead');
      }
    });
  }

  getContactById(id: string) {
    this.crm_service.getContactById(id).subscribe({
      next: (resp: any) => {
        // console.log(resp,"contact");
        this.relatedSourceDetails = resp.data[0];
        if (this.relatedSourceDetails?.assignee.length > 0) this.getSourceStaffList(this.relatedSourceDetails.assignee);
        if (this.relatedSourceDetails?.tags) this.getTagData(this.relatedSourceDetails.tags, 'contact');
        this.usersAccess();
      }
    });
  }

  getCompanyById(id: string) {
    this.crm_service.getCompanyById(id).subscribe({
      next: (resp: any) => {
        // console.log(resp, "company");
        this.relatedSourceDetails = resp.data[0];
        if (this.relatedSourceDetails?.assignee.length > 0) this.getSourceStaffList(this.relatedSourceDetails.assignee);
        if (this.relatedSourceDetails.tags) this.getTagData(this.relatedSourceDetails.tags, 'company');
      }
    });
  }

  onNavigateToInnerScreen(sourceType: string, sourceId: string, pipelineId?: string, contactId?: string) {
    this.contactId = sourceId;
    let queryParams: any = {
      id: sourceId,
      showtype: sourceType
    };
    if (sourceType === 'deal') {
      queryParams['pipeline_id'] = pipelineId;
      queryParams['contact_id'] = contactId;
    }
    if (sourceType === 'project') {
      this.router.navigate(['/clients/client-managment'], { queryParams: queryParams });
    } else if (sourceType === 'board') {
      queryParams = {
        id: sourceId,
        board: this.boardDetails?.name
      };
      this.router.navigate(['/taskboard/manage-global-board'], { queryParams: queryParams });
    }
    else {
      this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });
    }
  }

  usersAccess() {
    if (this.taskDetails && this.relatedSourceDetails) {
      if (this.relatedSourceDetails.assignee.includes(this.loggedInUser.data.id) || this.relatedSourceDetails?.owner_info?.id === this.loggedInUser.data.id || this.loggedInUser.data.role === 'ADMIN') {
        this.validateUserAccess = true;
      } else {
        this.validateUserAccess = false;
      }

      //For manager control
      //   // const departmentsList = [...this.taskDetails.assignee_department, this.taskDetails.owner_department];
      //   // this.settingsService.getStaff_infoById(this.loggedInUser.data.id).subscribe({
      //   //   next: (resp: any) => {
      //   //     this.validateUserAccess = (resp.data?.department && departmentsList.includes(resp.data?.department) && resp.data?.role_info?.name === "MANAGER") ? true : false;
      //   //   }
      // }
    } else {
      this.validateUserAccess = false;
    }
  }

  getProjectById(id: string) {
    this.client_service.getProjectDetailsById(id).subscribe({
      next: (resp: any) => {
        // console.log(resp, "project");
        this.relatedSourceDetails = resp.data;
        if (this.relatedSourceDetails?.assignee.length > 0) this.getSourceStaffList(this.relatedSourceDetails.assignee);
        if (this.relatedSourceDetails.tags) this.getClientTagById(this.relatedSourceDetails.tags);
      }
    });
  }

  getBoardById(id: string) {
    this.taskBoardService.getBoardsbyId(id).subscribe((resp: any) => {
      this.boardDetails = resp.data[0];
    });
  }

  getTagData(id: string, type: string) {
    this.settingsService.getTagById(id).subscribe({
      next: (resp: any) => {
        this.relatedSourceDetails.tags = resp.data[0].name;
        this.relatedSourceDetails.colour = resp.data[0].colour;
      }
    });
  }

  getClientTagById(id: string) {
    this.settingsService.getClientTagById(id).subscribe({
      next: (resp: any) => {
        this.relatedSourceDetails.tags = resp.data[0].name;
        this.relatedSourceDetails.colour = resp.data[0].colour;
      }
    });
  }

  onbackChange() {
    this._location.back();
  }

  //pipelineDetails
  getFieldsBasedonCardId(cardId: string) {
    this.crm_service.getCardsById(cardId).subscribe((resp: any) => {
      this.cardFields = resp.data[0];
      console.log(this.cardFields, "cardfields");
      if (this.cardFields?.assignee?.length > 0) this.getSourceStaffList(this.cardFields.assignee);
    });
  }

  /**********************************Fields API Starts*************************************/
  getAPTCategoriesList() {
    this.setupService.getAllCategories().subscribe({
      next: (resp: any) => {
        this.cardFields.filter((ele: any) => {
          if (ele.url === '/consultations/category/') {
            const selectedItem = resp.data.filter((item: any) => item.id === ele.data);
            if (selectedItem.length !== 0) ele.data_name = selectedItem[0].name;
          }
        });
      }
    });
  }

  getAppointments(ele: any) {
    this.setupService.getConsultationByID(ele.data).subscribe({
      next: (resp: any) => {
        ele.data_name = resp.data.name;
      }
    });
  }

  getContacts(ele: any) {
    this.crm_service.getContactById(ele.data).subscribe((resp: any) => {
      ele.data_name = resp.data.name;
    });
  }

  getCompanybyId(ele: any) {
    this.crm_service.getCompanyById(ele.data).subscribe((resp: any) => {
      ele.data_name = resp.data[0].name;
    });
  }
  /**********************************Fields API Ends**************************************/

  getSourceStaffList(body: any) {
    this.taskBoardService.getStaffForSource({ id: body }).subscribe({
      next: (resp: any) => {
        // console.log(resp, "ONLY SOURCE STAFF");
        this.assigneeMembers = resp.data;
      }
    });
  }

  openModalDialog(ev: any, dialogName: string) {
    ev.stopPropagation();
    let modalRef: any;
    if (dialogName === 'contact') {
      modalRef = this.modalService.open(ContactsModalComponent, { size: 'lg', scrollable: true });
      modalRef.componentInstance.contact = this.relatedSourceDetails;
    } else if (dialogName === 'lead') {
      modalRef = this.modalService.open(LeadModalComponent, { size: 'lg', scrollable: true });
      modalRef.componentInstance.lead = this.relatedSourceDetails;
    } else if (dialogName === 'company') {
      modalRef = this.modalService.open(CompanyModalComponent, { size: 'lg', scrollable: true });
      modalRef.componentInstance.company = this.relatedSourceDetails;
    } else if (dialogName === 'deal') {
      modalRef = this.modalService.open(PipelineModalComponent, { size: 'lg', scrollable: true });
      modalRef.componentInstance.pipelineData = { pipelineId: this.cardFields.pipeline, selectedstageId: this.cardFields.stage, cardId: this.cardFields.id, cardName: this.cardFields.title };
    } else if (dialogName === 'project') {
      modalRef = this.modalService.open(ClientProjectModalComponent, { size: 'lg', scrollable: true });
      modalRef.componentInstance.clientProjectData = this.relatedSourceDetails;
    }
  }
}
