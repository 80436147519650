import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InboxService } from 'src/app/core/service/inbox.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  table_cols = ['S.No', 'Image', 'Product Name', 'Quantity', 'Discount', 'Price', 'Final Price', 'Description'];
  orderDetails: any = {};
  order_id!: string;
  preSendUrl = environment.preSendUrl;
  billing_address: any;
  shipping_address: any;
  totalQuantityInNum = 0;
  orders_tax_list: any = [];
  loggedInUser:any;
  validateUserAccess = false;
  tableSkeletonLoader = true;
  constructor(private route: ActivatedRoute, private router: Router , private order_service: InboxService) { }

  ngOnInit(): void {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.route.queryParams.subscribe((params: any) => {
      console.log("route params - ", params);
      this.order_id = params.id;
      if (this.order_id) {
        this.getSelectedOrderDetails();
      }
    });
  }

  onStatusChange() {
    this.order_service.updateOrderStatus(this.orderDetails).subscribe({
      next: (resp: any) => {
        this.getSelectedOrderDetails();
      }
    });
  }

  onNaviagtetoContactInnerScreen(userId: string) {
    const queryParams: any = {
      id: userId,
      showtype: 'contact'
    };
    this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });
  }

  getNavigationAccess(){
    if (this.loggedInUser.data.role === 'ADMIN'){
      this.validateUserAccess = true;
    }else{
      this.validateUserAccess = false;
    }
  }

  getSelectedOrderDetails() {
    this.order_service.getOrderById(this.order_id).subscribe({
      next: (resp: any) => {
        this.tableSkeletonLoader = false;
        this.orderDetails = resp.data;
        let total_quantity = 0;
        this.getNavigationAccess();
        if (this.orderDetails?.items.length > 0) {
          this.orderDetails.items.forEach((ele: any) => {
            total_quantity += ele.quantity;
          });
        }
        if (Object.keys(this.orderDetails?.taxes_dict).length > 0) {
          this.orders_tax_list = Object.values(this.orderDetails?.taxes_dict);
        }
        this.totalQuantityInNum = total_quantity;
      }
    });
  }

}
