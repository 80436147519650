<div class="modal-container h-100">
    <div class="modal-header">
        <h5 class="modal-title text-primary">Support</h5>
        <button type="button" class="btn-close" (click)="onClosingModal()"
            aria-hidden="true"></button>
    </div>
    <div class="modal-body h-90 overflow-auto">
        <!-- <div class="d-flex align-items-center justify-content-between m-auto" style="position: relative;top: 70px;min-height: 300px;" *ngIf="!iframeLoaded">
            <div class="m-auto" style="width:40px;height:40px">
                <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                    <path fill="black"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                            from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
        </div> -->
        <div id="widgetContainer"></div>
        <!-- <iframe allow="camera; microphone;screenobtainer; obtainscreenfromgetdisplaymedia;clipboard-read; clipboard-write"
        #iframeRef src='https://brexa.brexa.ai/?appId=6686653616a68aab24b68d61' id="widget-value" width="100%" height="100%" style="min-height: 90vh;"
        (load)="myLoadEvent()"></iframe> -->
    </div>
</div>