import { Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-campaigns-list',
  templateUrl: './campaigns-list.component.html',
  styleUrls: ['./campaigns-list.component.scss']
})
export class CampaignsListComponent {
  campaignsList = [
    {
      title: 'Happy Christmas',
      type: 'Promotional',
      segment: 'Segment 1',
      date: 'Tue, August 2nd 12:18 am',
      status: 'Sent',
      channel: 'WhatsApp'
    },
    {
      title: 'End of season sale',
      type: 'Promotional',
      segment: 'Segment 1',
      date: 'Tue, August 2nd 12:18 am',
      status: 'Draft',
      channel: 'WhatsApp'
    },
    {
      title: 'Flat 50% off sale',
      type: 'Promotional',
      segment: 'Segment 1',
      date: 'Tue, August 2nd 12:18 am',
      status: 'Failed',
      channel: 'Messenger'
    },
    {
      title: 'New year sale',
      type: 'Promotional',
      segment: 'Segment 1',
      date: 'Tue, August 2nd 12:18 am',
      status: 'Sent',
      channel: 'WhatsApp'
    }
  ];
  modelRef! : NgbModalRef;
  channels_list: any[] = [
    {
      icon: 'assets/images/sms.png',
      name: 'SMS Broadcast'
    },
    {
      icon: 'assets/images/whatsapp.svg',
      name: 'Whatsapp Broadcast'
    },
    {
      icon: 'assets/images/facebook.svg',
      name: 'Facebook Broadcast'
    },
    {
      icon: 'assets/images/instagram.svg',
      name: 'Instagram Broadcast'
    },
    {
      icon: 'assets/images/sms.png',
      name: 'Email Marketing'
    },
    {
      icon: 'assets/images/google-msging.svg',
      name: 'Google Messages'
    }
  ];

  showType:any = 'campaigns-list';

  constructor(private modelservice: NgbModal) {}

  openModel(model: any, type: any) {
    this.modelRef = this.modelservice.open(model, { size: 'lg' });
  }

  createCampaign() {
    this.showType = 'campaign-channels';
  }

}
