import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { SettingsService } from 'src/app/core/service/settings.service';
@Component({
  selector: 'app-email-notifications',
  templateUrl: './email-notifications.component.html',
  styleUrls: ['./email-notifications.component.scss']
})
export class EmailNotificationsComponent implements OnInit {
  public Editor: any = ClassicEditor;
  @ViewChild('ckeditor', { static: true }) ckeditor!: CKEditorComponent;
  ckconfigQue: any;
  showCc = false;
  showBcc = false;
  email_templateForm!: FormGroup;
  ccEmailLists: string[] = [];
  bccEmailLists: string[] = [];
  selectedCcEmails: string[] = [];
  inputEmailString = '';
  editorInstance: any;
  selectedCcEmail = '';
  allowToAddItem = false;
  currentActiveTabs = 0;
  cursorPosition = 0;
  appointmentLists: any[] = [];
  selectedAppointmentIndex = 0;
  checkboxRefAppoinment = true;
  modalRef: any;
  loggedInUser: any;
  errorjson = ERRORJSON[0];
  customisedMsgSelection = [
    {
      type: 'Appointment',
      subtype: [
        { type: 'Duration', value: 'appointment_duration' },
        { type: 'Mode', value: 'appointment_mode' },
        { type: 'Staff Date', value: 'staff_appointment_date' },
        { type: 'Staff Time', value: 'staff_appointment_time' },
        { type: 'Customer Date', value: 'customer_appointment_date' },
        { type: 'Customer Time', value: 'customer_appointment_time' },
        { type: 'Price', value: 'appointment_price' },
        { type: 'Staff timezone', value: 'staff_timezone' },
        { type: 'Customer timezone', value: 'customer_timezone' },
        { type: 'Meeting Details', value:'meeting_details'}
      ]
    },
    {
      type: 'Customer',
      subtype: [
        { type: 'Name', value: 'customer_name' },
        { type: 'Email', value: 'customer_email' },
        { type: 'Phone number', value: 'customer_phone_number' }
      ]
    },
    {
      type: 'Staff',
      subtype: [
        { type: 'Name', value: 'staff_name' },
        { type: 'Email', value: 'staff_email' },
        { type: 'Phone number', value: 'staff_phone_number' }
      ]
    },
    {
      type: 'Company',
      subtype: [
        { type: 'Name', value: 'company_name' },
        { type: 'Email', value: 'company_email' },
        { type: 'Phone number', value: 'company_phone_number' },
        { type: 'Website', value: 'company_website' }
      ]
    },
    {
      type: 'Service',
      subtype: [
        { type: 'Name', value: 'consultation_name' },
        { type: 'Category name', value: 'consultation_category_name' },
        { type: 'Duration', value: 'consultation_duration' }
      ]
    }];

  constructor(private fb: FormBuilder, private settingServices: SettingsService, private modalService: NgbModal) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    this.getEmailTemplates();
    this.initForm();

  }

  tabChanged(ev: any) {
    this.currentActiveTabs = ev.index;
    this.getEmailTemplates();
    this.selectedAppointmentIndex = 0;
    this.onSelectAppoinments(this.selectedAppointmentIndex);

  }

  getEmailTemplates() {
    const type = this.currentActiveTabs === 0 ? 'TO_CUSTOMER' : 'TO_STAFF';
    this.settingServices.getEmailTemplate(type).subscribe((response: any) => {
      this.appointmentLists = response['data'];
      this.checkboxRefAppoinment = this.appointmentLists[0].status;
      this.onSelectAppoinments(0);
    });
  }
  onSelectAppoinments(index: number) {
    this.showCc = false;
    this.showBcc = false;
    this.selectedAppointmentIndex = index;
    const appointmentData = this.appointmentLists[index];
    this.checkboxRefAppoinment = appointmentData.status;
    const updatedData = this.updatingData(appointmentData);
    this.email_templateForm.patchValue(updatedData);
    this.showCc = this.email_templateForm.value.cc_recipients.length > 0 ? true : false;
    this.showBcc = this.email_templateForm.value.bcc_recipients.length > 0 ? true : false;
    this.onAddRemoveCcBcc();
  }

  onStatusChange(ev: any) {
    const appointment = this.appointmentLists[this.selectedAppointmentIndex];
    const updatedData = this.updatingData({ ...appointment, status: ev.currentTarget.checked });
    this.updateEmailNotifications(appointment.id, updatedData);
  }

  onEmailInputChange(event: any) {
    this.inputEmailString = event.target.value;
    const regex = /^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/; //eslint-disable-line 
    if (!regex.test(this.inputEmailString)) {
      this.allowToAddItem = false;
    } else {
      this.allowToAddItem = true;
    }
  }

  onAddRemoveCcBcc(type?: string) {
    type && (type === 'cc' ? this.showCc = !this.showCc : this.showBcc = !this.showBcc);
    if (this.showBcc) {
      this.email_templateForm.get('bcc_recipients')?.addValidators([Validators.required]);
      this.email_templateForm.get('bcc_recipients')?.updateValueAndValidity();
    } else {
      this.email_templateForm.get('bcc_recipients')?.clearValidators();
      this.email_templateForm.get('bcc_recipients')?.markAsUntouched();
      this.email_templateForm.get('bcc_recipients')?.updateValueAndValidity();
    }
    if (this.showCc) {
      this.email_templateForm.get('cc_recipients')?.addValidators([Validators.required]);
      this.email_templateForm.get('cc_recipients')?.updateValueAndValidity();
    } else {
      this.email_templateForm.get('cc_recipients')?.clearValidators();
      this.email_templateForm.get('cc_recipients')?.markAsUntouched();
      this.email_templateForm.get('cc_recipients')?.updateValueAndValidity();
    }
  }

  initForm() {
    this.email_templateForm = this.fb.group({
      subject: ['Appointment Approved', [Validators.required]],
      cc_recipients: [''],
      bcc_recipients: [''],
      email_body: ['', [Validators.required]],
      to_mail: [null],
      status: [true]
    });
    this.email_templateForm.controls['to_mail'].disable();
  }
  get f() {
    return this.email_templateForm.controls;
  }

  updatingData(data: any) {
    return {
      subject: data.subject,
      cc_recipients: data.cc_recipients,
      bcc_recipients: data.bcc_recipients,
      email_body: data.email_body,
      status: data.status
    };
  }

  updateEmailNotifications(id: string, updatedData: any) {
    this.settingServices.putEmailTemplate(id, updatedData).subscribe(() => {
      this.getEmailTemplates();
    });
  }

  onEmailNotificationSubmit() {
    if (this.email_templateForm.valid) {
      const updatedData = this.email_templateForm.value;
      this.updateEmailNotifications(this.appointmentLists[this.selectedAppointmentIndex].id, updatedData);
    }
  }
  onOptionSelected(value: string, position: string) {
    this.insertTextAtCursor(value, position);
  }

  onClickInputField(event: any, position: string) {
    this.getCurrentCursorPosition(event, position);
  }

  // Calculate the cursor position based on content before the cursor

  getCurrentCursorPosition(event: any, position: string) {
    const inputElement = event.target;
    const cursorPosition = inputElement.selectionStart;
    const textInput = this.email_templateForm.get(position)?.value;
    const contentBeforeCursor = textInput.substring(0, cursorPosition);
    const contentBeforeCursorLength = contentBeforeCursor.length;
    this.cursorPosition = contentBeforeCursorLength;
  }

  onEditorReady(editorInstance: any) {
    this.editorInstance = editorInstance;
    editorInstance.editing.view.document.on('click', () => {
      this.getCursorPosition(editorInstance);
    });

    editorInstance.editing.view.document.on('keyup', () => {
      this.getCursorPosition(editorInstance);
    });
  }

  getCursorPosition(editorInstance: any) {
    const selection = editorInstance.editing.view.document.selection;
    const cursorPosition = selection.anchor.offset;
    this.cursorPosition = cursorPosition;
  }

  insertTextAtCursor(newText: string, position: string) {
    const control = this.email_templateForm.get(position);
    let existingText = '';
    if (position === 'subject') {
      existingText = control?.value;
      const newTextInserted = existingText.slice(0, this.cursorPosition) + `{{${newText}}}` + existingText.slice(this.cursorPosition);
      control?.setValue(newTextInserted);
    } else {
      if (this.editorInstance) {
        const currentPosition = this.editorInstance.model.document.selection.getFirstPosition();
        this.editorInstance.model.change((writer: any) => {
          writer.insertText(`{{${newText}}}`, currentPosition);
        });
      }
    }
  }

  onOpenTestMail(content: any) {
    this.email_templateForm.controls['to_mail'].enable();
    const emailPattern = Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/); //eslint-disable-line
    this.email_templateForm.get('to_mail')?.setValidators([Validators.required, emailPattern]);
    this.email_templateForm.get('to_mail')?.updateValueAndValidity();
    this.email_templateForm.get('to_mail')?.markAsUntouched();
    setTimeout(() => {
      this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
      this.modalRef.result.then(() => {
        this.email_templateForm.controls['to_mail'].disable();
      }).catch((err: any) => {
        console.log(err);
        this.email_templateForm.controls['to_mail'].disable();
      });
    }, 10);
  }

  onTestMailSubmit() {
    if (this.email_templateForm.valid) {
      const payload = {
        ...this.email_templateForm.value, body: this.email_templateForm.value.email_body
      };
      this.settingServices.postTestEmail(payload).subscribe(() => {
        this.modalRef.close();
      });
    }
  }
}

