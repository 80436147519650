import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-prompt-notifications',
  templateUrl: './prompt-notifications-modal.component.html',
  styleUrls: ['./prompt-notifications-modal.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class PromptNotificationsModalComponent implements OnInit, AfterViewInit {

  @Input() allNotificationLists!: any;
  constructor(public ngbActiveModal: NgbActiveModal, private renderer: Renderer2) { }
  pushNotificationLists: any[] = [];
  pushCallNotificationLists: any[] = [];
  showCancel: any[] = [];
  audioElement: any;
  commonService: any;


  ngOnInit() {
    console.log(this.allNotificationLists);
  }
  ngAfterViewInit() {

    this.pushNotificationLists = this.allNotificationLists.textNotification;
    this.pushCallNotificationLists = this.allNotificationLists.callNotifications;
    this.audioElement = this.allNotificationLists.audioElement;
    this.commonService = this.allNotificationLists.commonService;
    this.pushNotificationLists.map((data) => {
      data['state'] = 'enter';
    });
    this.pushCallNotificationLists.map((data) => {
      data['state'] = 'enter';
    });

  }

  clearAllChats() {
    this.pushNotificationLists = [];
    !(this.pushCallNotificationLists.length > 0) && this.ngbActiveModal.close({ index: 0, type: 'clear' });

  }

  onAcceptCall(index: number) {
    this.ngbActiveModal.close({ index: index, type: 'answer_call' });
  }

  onRejectCall(index: number) {
    const callId = this.pushCallNotificationLists[index].chatId;
    if (this.pushCallNotificationLists.length < 2) {
      this.ngbActiveModal.close({ index: index, type: 'reject_call' });
    } else {
      // this.commonService.onRejectCallapis(callId).subscribe(() => {
      //   this.audioElement.nativeElement.pause();
      //   const divisionsSec = this.el.nativeElement.querySelectorAll(`.new-call-notifications`);
      //   this.renderer.removeClass(divisionsSec[index], 'animations');
      //   this.renderer.addClass(divisionsSec[index], 'remove-animation');
      //   setTimeout(() => {
      //     this.pushCallNotificationLists.splice(index, 1);
      //   }, 400);
      // });
    }
  }

  muteCall() {
    this.audioElement.nativeElement.muted = true;
    const snoozeButton = document.querySelector('.snooz-icon');
    snoozeButton && this.renderer.removeClass(snoozeButton, 'snooz-icon');
  }

  onNavigateToChat(index: number) {
    this.ngbActiveModal.close({ index: index, type: 'chat' });
  }

  removeNotifications(index: any) {
    event?.stopPropagation();
    this.pushNotificationLists[index].state = 'leave';
    setTimeout(() => {
      this.pushNotificationLists.splice(index, 1);
      if(this.pushNotificationLists.length === 0 ){
        !(this.pushCallNotificationLists.length > 0) && this.ngbActiveModal.close({ index: 0, type: 'clear' });
      }
    }, 200);
  }
}

