<div class="container-fluid mt-3">
    <div class="d-flex align-items-center bg-white p-3 border-radius-8">
        <button class="btn border-light-1 border-radius-4 font-size-14 me-4 px-4 d-flex align-items-center"
            [ngClass]="display === 'subscription' ? 'bg-gray-light': 'bg-white'"
            (click)="switchTo('subscription')"><mat-icon class="me-2"
                style="color: rgb(128, 128, 128);">local_atm</mat-icon>Subscription</button>
        <button class="btn border-light-1 border-radius-4 font-size-14 px-5 d-flex align-items-center"
            [ngClass]="display === 'wallet' ? 'bg-gray-light': 'bg-white'" (click)="switchTo('wallet')"><mat-icon
                class="me-2" style="color: rgb(128, 128, 128);">account_balance_wallet</mat-icon>Wallet</button>
    </div>
    <ng-container *ngIf="display === 'subscription'">
        <div class="mt-3 bg-white p-4 border-radius-8">
            <div class="d-flex align-items-center justify-content-between">
                <h6 class="fw-600">You are currently on <span class="fw-700 text-primary">Free Trial of Business
                        Plan</span>
                </h6>
                <button class="btn on-hover text-primary" type="button">Have a coupon code?</button>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <h6 class="fw-600 font-size-14">$0 <s class="mx-1">$25</s> <span
                            class="text-light">/month/user</span></h6>
                    <h6 class="fw-600 font-size-14 text-light">It's a free trial, unlimited users</h6>
                    <button class="btn btn-primary mt-3 mb-5">Subscribe</button>
                    <h5 class="fw-700 font-size-16">Active until Mar 16, 2023</h5>
                    <h6 class="text-light font-size-14">We will send you a notification upon free trial expiration</h6>
                </div>
                <div class="col-6">
                    <div class="pb-5">
                        <h6 class="d-flex align-items-center justify-content-between fw-600">
                            <span>Users</span><span>1 of unlimited
                            </span>
                        </h6>
                        <mat-progress-bar mode="determinate" value="1"></mat-progress-bar>
                        <p class="mb-0 text-light font-size-14">You can add unlimited users in your free trial</p>
                    </div>
                    <div class="py-5">
                        <h6 class="d-flex align-items-center justify-content-between fw-600">
                            <span>Usage</span><span>20 of unlimited
                            </span>
                        </h6>
                        <mat-progress-bar mode="determinate" value="20"></mat-progress-bar>
                        <p class="mb-0 text-light font-size-14">You can add unlimited records in your current plan</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3 bg-white p-3 border-radius-8">
            <div class="card border-0">
                <div class="card-header border-radius-0 bg-white">
                    <h5 class="border-bottom-1">Plan Details</h5>
                </div>
                <div class="card-body mt-3">
                    <p class="text-light font-size-14 mb-0">You can't downgrade your plan; please delete the records.
                        Check more at billing.</p>
                    <table class="table mt-3">
                        <thead class="table-light">
                            <tr>
                                <th scope="col">Modules</th>
                                <th scope="col">Consumed</th>
                                <th scope="col">Available</th>
                                <th scope="col">Unit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Contacts</td>
                                <td class="d-flex align-items-center">20
                                    <span
                                        class="text-primary font-size-12 border-radius-6 bg-gray-light p-1 ms-2">Lead:10</span>
                                    <span
                                        class="text-primary font-size-12 border-radius-6 bg-gray-light p-1 ms-2">People:0</span>
                                    <span
                                        class="text-primary font-size-12 border-radius-6 bg-gray-light p-1 ms-2">Company:10</span>
                                </td>
                                <td>unlimited</td>
                                <td>Per Organisation</td>
                            </tr>
                            <tr>
                                <td>Custom Fields</td>
                                <td>0</td>
                                <td>unlimited</td>
                                <td>Per Organisation</td>
                            </tr>
                            <tr>
                                <td>Pipeline</td>
                                <td>1</td>
                                <td>unlimited</td>
                                <td>Organisation</td>
                            </tr>
                            <tr>
                                <td>Dashboard</td>
                                <td>1</td>
                                <td>unlimited</td>
                                <td>Per User</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="display === 'wallet'">
        <div class="mt-3 bg-white p-4 border-radius-8">
            <h6 class="fw-600">Account Balance: ₹0</h6>
            <div class="border-light-1 border-radius-8 p-4 mt-4">
                <div class="row">
                    <div class="col-9">
                        <label class="mb-0 text-light font-size-14 mb-2" for="inlineFormInputGroup">Enter amount to
                            be added in your wallet</label>
                        <div class="d-flex align-items-center">
                            <div class="input-group w-50 me-3">
                                <div class="input-group-prepend">
                                    <div class="input-group-text border-radius-left">₹</div>
                                </div>
                                <input type="number"  (wheel)="$event.preventDefault()" class="form-control" id="inlineFormInputGroup" placeholder="0"
                                    [(ngModel)]="recharge_amount">
                            </div>
                            <button class="btn btn-primary" (click)="openRechargeModal(rechargeModal)">Recharge
                                Now</button>
                        </div>
                        <div class="d-flex align-items-center mt-4 on-active">
                            <button class="btn btn-outline-primary me-3" *ngFor="let item of amount_options"
                                (click)="onSelect(item)">₹{{item}}</button>
                        </div>
                    </div>
                    <div class="col-3"></div>
                </div>
            </div>
            <div class="border-light-1 border-radius-8 p-4 mt-5">
                <form>
                    <div class="row">
                        <div class="col-6">
                            <label class="mb-0 font-size-14 mb-4 fw-600" for="inlineFormInputGroup">Low Credit
                                Notification</label>
                            <div class="input-group mb-2">
                                <input type="email" class="form-control" id="inlineFormInputGroup"
                                    placeholder="Enter email address">
                            </div>
                            <small class="text-light">Receive a notification when your account balance fails below
                                threshold.</small>
                        </div>
                        <div class="col-6">
                            <label class="mb-0 font-size-14 mb-4 fw-600" for="inlineFormInputGroup">Low Credit
                                Threshold</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text border-radius-left">₹</div>
                                </div>
                                <input type="number" (wheel)="$event.preventDefault()" class="form-control" id="inlineFormInputGroup" placeholder="0">
                            </div>
                            <small class="text-light">When should we start sending the notifications?</small>
                        </div>
                    </div>
                    <button class="btn btn-primary mt-5">Update Notification</button>
                </form>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #rechargeModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title text-primary">
            Add Balance
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="recharge_form" (ngSubmit)="onSubmit()">
            <div class="bg-gray-light d-flex align-items-center justify-content-between border-radius-8 p-3 w-100 mb-4">
                <p class="mb-0"><span class="fw-600 me-2">Billing:</span>One-Time</p>
                <p class="mb-0">|</p>
                <p class="mb-0"><span class="fw-600 me-2">Total:</span>₹{{recharge_form.value.total_amount ? recharge_form.value.total_amount : 1000}}</p>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="mb-2 text-primary">Card number<small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <img src="../../../../assets/images/icons8-mastercard.svg" matSuffix>
                        <img src="../../../../assets/images/icons8-visa.svg" matSuffix>
                        <input style="outline: none" matInput placeholder="1234 1234 1234 1234"
                            formControlName="card_number" />
                        <mat-error class="error d-flex" *ngIf="submitted && f['card_number'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['card_number'].errors['required']">Card number
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="mb-2 text-primary">Expiration<small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="MM/YY" formControlName="expiration" />
                        <mat-error class="error d-flex" *ngIf="submitted && f['expiration'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['expiration'].errors['required']">Expiration
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="mb-2 text-primary">CVC<small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="CVC" formControlName="cvc" />
                        <mat-error class="error d-flex" *ngIf="submitted && f['cvc'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['cvc'].errors['required']">
                                CVC
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="mb-2 text-primary">Country<small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-select placeholder="Select" formControlName="country">
                            <mat-option value="India">
                                India
                            </mat-option>
                            <mat-option value="USA">
                                USA
                            </mat-option>
                        </mat-select>
                        <mat-error class="error d-flex" *ngIf="submitted && f['country'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['country'].errors['required']">
                                Country
                                {{errorjson.required}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <button type="submit" class="btn btn-primary mt-4">Proceed to pay ₹ {{recharge_form.value.total_amount ?
                    recharge_form.value.total_amount : 1000}} </button>
            </div>
        </form>
    </div>
</ng-template>