import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { CommonService } from 'src/app/core/service/common.service';
import { FILEEXTENSIONS, FILESJSON, IMAGE_EXTENSIONS } from '../../../../core/data/crm_modal_json';
import { PageEvent } from '@angular/material/paginator';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { environment } from 'src/environments/environment';
import { CrmService } from 'src/app/core/service/crm.service';
import { ErrorLoadImage } from 'src/app/core/common/common-functions';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-files-modal',
  templateUrl: './files-modal.component.html',
  styleUrls: ['./files-modal.component.scss']
})
export class FilesModalComponent implements OnInit,OnDestroy {
  @Input() is_task!: string;
  @Input() details: any;
  filesJSON = FILESJSON[0];
  selected_id!: string;
  selected_type!: string;
  files: File[] = [];
  fileName!: string;
  CurrentPage = 1;
  filesListArray: any = [];
  currentFilesList: any = [];
  presignedurl = environment.preSendUrl;
  currentPage = 0;
  pageEvent!: PageEvent;
  visibility = false;
  count!: number;
  show_loader = false;
  fileExtensionsType = FILEEXTENSIONS;
  fileId = '';
  readOnly = false;

  constructor(private route: ActivatedRoute, private location: Location, private service: CommonService, private awsUtilService: AwsUtilsService, private confirmationMsz: ConfirmationMszService, private crm_service: CrmService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    if (this.is_task === 'task') {
      this.selected_id = this.details;
      this.selected_type = this.is_task;
      this.getAllFilesList(this.selected_id, 1);
    } else {
      this.route.queryParams.subscribe((params: any) => {
        this.selected_id = params.id;
        this.selected_type = params.showtype;
        this.readOnly = params.contact_active === 'false' ? true : false;
        if (this.selected_id) {
          const fileId = new URLSearchParams(window.location.search).get('fileId');
          if (params?.fileId && fileId) {
            this.fileId = params?.fileId;
            this.getFilesById(params.fileId);
          } else {
            this.fileId = '';
            this.getAllFilesList(this.selected_id, 1);
          }
          if (this.selected_type === 'lead') this.getLeadsName(this.selected_id);
        }
      });
    }
  }

  getAllFilesList(id: string, page: any) {
    this.service.getAllFiles(id, page).subscribe({
      next: (resp: any) => {
        // console.log("files - ", resp);
        this.count = resp.total;
        this.filesListArray = resp.data;
      }
    });
  }

  getFilesById(id:string){
    this.service.getFilesById(id).subscribe({
      next: (res: any) => {
        this.filesListArray = [res?.data];
      },
      error:(err) => {
        if(err && err?.data.length === 0){
          console.log(err);
          this.clearFilter();
        }
      }
    });
  }

  clearFilter() {
    const currentUrl = this.location.path();
    const updatedUrl = currentUrl.replace(/[?&]fileId=[^&]+/, '').replace(/[?&]activeId=[^&]+/, '');
    this.location.replaceState(updatedUrl);
    this.fileId = '';
    this.getAllFilesList(this.selected_id, 1);
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getAllFilesList(this.selected_id, this.currentPage);
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }

  onSelect(event: any) {
    this.files = [...event.addedFiles];
    const media_dropzone = document.getElementById('media-dropzone');
    media_dropzone && (media_dropzone.style['height'] = 'fit-content');
    const files = this.files[0]?.name?.split('.');
    const fileExt = files && files.length > 0 ? files[files?.length - 1] : '';
    if (!IMAGE_EXTENSIONS.includes(fileExt)) {
      setTimeout(() => {
        const imageTag = media_dropzone?.getElementsByTagName('img');
        const fileExtention = FILEEXTENSIONS.find((elem: any) => elem.type === fileExt);
        if (imageTag && imageTag.length > 0 && fileExtention) {
          imageTag[0].src = fileExtention.file;
        } else if (imageTag && imageTag.length > 0) {
          imageTag[0].src = FILEEXTENSIONS[4].file;
        }
      }, 50);
    }

    // console.log("uploded file - ", this.files);
    // if (this.files && this.files.length > 1) {
    //   this.onRemove(this.files[0]);
    // }
  }

  onUpload() {
    // console.log("entered upload function");
    if (!this.files[0]) return;
    if(this.files[0].size > 15 * 1048576){
      this.toastrService.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.show_loader = true;    
    this.awsUtilService.getUploadFileLink(this.files[0].type,this.files[0].name.split('.')[1]).subscribe((resp) => {
      console.log("first api - ", resp);
      this.fileName = resp.data.file_name;
      this.awsUtilService.uploadFile(this.files[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
        this.fileSubmit();
      }, () => {
        this.files = [];
        this.show_loader = false;
      });
    }, () => {
      this.files = [];
      this.show_loader = false;
    });
  }

  fileSubmit() {
    const payload = {
      source_id: this.selected_id,
      source_type: this.selected_type,
      name: this.files[0].name,
      is_visible: this.visibility,
      media_url: this.presignedurl + this.fileName
    };

    this.service.fileUploadWorke(payload).subscribe(() => {
      this.show_loader = false;
      this.fileId ? this.clearFilter() : this.getAllFilesList(this.selected_id, 1);
      this.files = [];
    }, () => {
      this.files = [];
      this.show_loader = false;
    });
  }

  toggleVisibility(e: any, data: any) {
    this.visibility = e.target.checked;
    this.updateFile(data, this.visibility);
  }

  updateFile(data: any, visibility: boolean) {
    const updatePayload = {
      id: data.id,
      source_id: data.source_id,
      name: data.name,
      source_type: data.source_type,
      is_visible: visibility,
      media_url: data.media_url
    };
    this.service.fileUpdateWorke(updatePayload).subscribe(() => { //@typescript-eslint/no-empty-function
    });
  }

  deleteFile(id: string) {
    this.service.deleteFile(id).subscribe(() => {
      this.getAllFilesList(this.selected_id, 1);
    });
  }

  confirmDelete(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteFile(fileId);
      }
    });
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  getLeadsName(id: string) {
    this.crm_service.getLeadById(id).subscribe({
      next: (resp: any) => {
        if(resp.data[0].type === 'PIPELINE') this.readOnly = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.clearFilter();
  }
}
