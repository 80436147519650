import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  private VAPID_PUBLIC_KEY = "BFV-GDkfDjE7ieKvC-tmjgp0mHt7zh6LuZ9IvZWU6B2OMf1iksXgvUPd6qkWO-5FygMkB1PLVd6CR0NBYmcciGs";
  user_details: any;
  constructor(private http: HttpClient) {
    this.user_details = JSON.parse(sessionStorage.getItem('currentUser')!);
  }
  async registerServiceWorker() {
    try {
      await navigator.serviceWorker.register('push.js');
    } catch (err) {
      console.log(err, "error registering push");
    }
  }

  async subscribeToPush() {
    const registration: any = await navigator.serviceWorker.getRegistration();
    const isAlreadySubscribed = await registration.pushManager.getSubscription();
    if (!isAlreadySubscribed) {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlB64ToUint8Array(this.VAPID_PUBLIC_KEY)
      });
      this.postToServer(`${environment.messageUrl}/push/subscriptions`, {
        subscription: subscription,
        org: this.user_details.data.organisation,
        user: this.user_details.data.id,
        type: 'browser'
      });
    }
  }

  private urlB64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  private async postToServer(url: string, data: any) {
    await this.http.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).toPromise();
  }
  modal() {
    const mainContainer: any = document.createElement("div");
    mainContainer.style.height = "100vh";
    mainContainer.style.width = "100vw";
    mainContainer.style.zIndex = "1000";
    mainContainer.style.position = "fixed";
    mainContainer.style.top = 0;
    mainContainer.style.left = 0;
    mainContainer.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
    const modalContainer = document.createElement("div");
    modalContainer.style.display = "flex";
    modalContainer.style.flexDirection = "column";
    modalContainer.style.gap = "4px";
    modalContainer.style.alignItems = "center";
    modalContainer.style.position = "fixed";
    modalContainer.style.top = "50%";
    modalContainer.style.left = "50%";
    modalContainer.style.transform = "translate(-50%, -50%)";
    modalContainer.style.padding = "40px";
    modalContainer.style.backgroundColor = "white";
    modalContainer.style.borderRadius = "10px";
    modalContainer.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
    const heading: any = document.createElement("h2");
    heading.innerHTML = "Turn on notifications";
    heading.style.marginBlockStart = 0;
    //allwo button
    const allowButton = document.createElement("button");
    allowButton.textContent = "Allow notifications";
    allowButton.style.width = "100%";
    allowButton.style.border = "none";
    allowButton.style.color = "#fff";
    allowButton.style.fontWeight = "700";
    allowButton.style.backgroundColor = "black";
    allowButton.style.paddingBlock = "10px";
    allowButton.style.borderRadius = "50px";
    allowButton.style.cursor = "pointer";
    //skip button
    const skipButton = document.createElement("button");
    skipButton.style.width = "100%";
    skipButton.textContent = "Skip for now";
    skipButton.style.paddingBlock = "10px";
    skipButton.style.fontWeight = "700";
    skipButton.style.borderRadius = "50px";
    skipButton.style.border = "1px solid black";
    skipButton.style.backgroundColor = "white";
    skipButton.style.cursor = "pointer";
    mainContainer.appendChild(modalContainer);
    modalContainer.appendChild(heading);
    modalContainer.appendChild(allowButton);
    modalContainer.appendChild(skipButton);
    document.body.appendChild(mainContainer);
    allowButton.addEventListener("click", () => {
      document.body.removeChild(mainContainer);
      this.registerServiceWorker().then(() => {
        this.subscribeToPush();
      });
    });
    skipButton.addEventListener("click", () => {
      document.body.removeChild(mainContainer);
    });
  }
}
