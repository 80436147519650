<div class="draft-email-sec d-flex flex-column justify-content-between">
  <!-- <div class="d-flex align-items-center notes-select">
		<mat-select [(ngModel)]="notes_filter">
			<mat-option value="All Notes" class="notesfilteropt">All Notes</mat-option>
			<mat-option value="Dileep Notes" class="notesfilteropt">Dileep Notes</mat-option>
		</mat-select>
	</div> -->
  <div class="custom-table-div-more-height">
    <ul class="list-group mt-2">
      <li class="list-group-item cursor mb-3 border-radius-8" *ngFor="let draft of draft_converstion;let i = index">
        <div class="row d-flex align-items-center">
          <div class="col-md-10">
            <div class="d-flex align-items-center py-3">
              <h6 class="text-primary fw-600 font-size-16 me-2 mb-0">{{draft?.payload?.subject ? draft?.payload?.subject : 'Empty Subject'}}</h6>
              <h6 class="fw-400 font-size-13 mb-0">{{draft.date_created}}</h6>
            </div>
            <div class="ps-4 ms-2">
              <!-- <h6 class="text-primary fw-600 font-size-13" *ngIf="note.title">{{note.title}}</h6> -->
              <!-- <p class="m-0 font-size-13 text-break" [id]="i+'pTag'" [innerHTML]="draft.notes | safeHtml"> -->
              <!-- </p> -->
              <!-- <div class="mt-3 d-flex align-items-center justify-content-around border-1 border-radius-50 px-3 py-1"
								style="width:12rem;" *ngIf="note.file_name">
								<img src="{{presignedurl}} {{ note.media_url}}"
									(error)="onErrorLoadImage($event,presignedurl,note.media_url)" height="25px"
									width="25px" *ngIf="note.media_url">
								<p class="mb-0 me-2 ms-2 font-size-14 label-overflow" style="width:8rem;">
									{{note.file_name}}</p>
								<a class="d-flex align-items-center" href="{{presignedurl}}{{note.media_url}}"
									target="_blank" download>
									<mat-icon style="color: rgb(0, 0, 0);">file_download_outlined</mat-icon>
								</a>
							</div> -->
            </div>
          </div>
          <div class="col-md-2 visiblity-on-hover">
            <div class="d-flex justify-content-end align-items-center">
              <div class="bg-white p-2 border-radius-50 d-flex align-items-center" (click)="editDraft(draft,i)">
                <mat-icon style="font-size: 22px;">edit</mat-icon>
              </div>
              <mat-icon (click)="confirmDelete(draft)">delete</mat-icon>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <!-- <div class="mt-2">
		<mat-paginator [length]="" [pageSize]="20" (page)="'page($event)'" aria-label="Select page"
			showFirstLastButtons>
		</mat-paginator>
	</div> -->
</div>