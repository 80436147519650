/* eslint-disable prefer-const */
import { Component, EventEmitter, Input, Output, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ErrorLoadImage, onParseDomValue, pasteEventInCkeditor } from 'src/app/core/common/common-functions';
import { FILEEXTENSIONS, IMAGE_EXTENSIONS } from 'src/app/core/data/crm_modal_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { ClientsService } from 'src/app/core/service/clients.service';
import { CommonService } from 'src/app/core/service/common.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { SettingsService } from 'src/app/core/service/settings.service';
import { TaskBoardService } from 'src/app/core/service/task-board.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-task-detail-modal',
  templateUrl: './task-detail-modal.component.html',
  styleUrls: ['./task-detail-modal.component.scss']
})
export class TaskDetailModalComponent implements OnInit, AfterViewInit {
  @Input() details: any;
  @Input() is_task!: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  status_dropdown = false;
  contentExpand = false;
  notesContent!: string;
  activeId: any = 1;
  files: File[] = [];
  about_task!: string;
  task_id!: string;
  selected_id!: string;
  selected_type!: string;
  sourceUserInfo: any;
  checklist_array: any[] = [];
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  ckEditor = false;
  userLists: any[] = [];
  mention: any[] = [];
  mentionConfig: any = {
    triggerChar: '@',
    maxItems: 10,
    labelKey: 'fname',
    mentionSelect: (mention: any) => { return mention['fname'] + " " + mention['lname'] + " "; }
  };
  divInputBox: any;
  editNoteId!: string;
  notesList: any;
  addNotes = false;
  notesView = false;
  checklistView = false;
  newItemAdded = false;
  newItemText = '';
  fileView = false;
  addFiles = false;
  fileName: any;
  filesListArray: any[] = [];
  presignedurl = environment.preSendUrl;
  datesView = false;
  copyCurrentPageNotesList: any = [];
  loggedInUser: any;
  editItem = '';
  minDate = new Date();
  maxDate = new Date();
  boardResp: any;
  dublicateDetails: any;
  ckeditorLimit = {
    maxLimit: 1000,
    actualLimit: 0,
    type: false
  };

  constructor(private taskboard_service: TaskBoardService, private route: ActivatedRoute, private settingsService: SettingsService,
    private service: CommonService, private awsUtilService: AwsUtilsService, private confirmationMsz: ConfirmationMszService,
    private crm_Service: CrmService, private client_service: ClientsService) { }

  ngOnInit(): void {
    this.about_task = this.is_task;
    this.task_id = this.details.id;
    this.dublicateDetails = Object.assign({}, this.details);
    this.checklist_array = Array.isArray(this.dublicateDetails?.checklist) ? this.dublicateDetails?.checklist : [];
    if (this.checklist_array.length > 0) this.checklistView = true;
    this.sourceUserInfo = this.dublicateDetails;
    const dataWithoutTags = onParseDomValue(this.dublicateDetails?.description);
    this.ckeditorLimit['actualLimit'] = dataWithoutTags?.length || 0;
    console.log(this.dublicateDetails, "TASK DETAILS");
    this.minDate = new Date(this.dublicateDetails?.created_at);
    this.maxDate = new Date(this.dublicateDetails?.due_date);
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.route.queryParams.subscribe((params: any) => {
      this.selected_id = params.id;
      this.selected_type = params.showtype || 'task';
      if (this.dublicateDetails?.tags) this.getTagData(this.dublicateDetails?.tags);
    });
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'underline', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
    if (this.about_task === 'board-task') {
      this.getBoardDetails();
    } else {
      this.getAllStaffInfo();
      this.getAssignesBysourceId(this.dublicateDetails.source_id, this.dublicateDetails.source_type);
    }
    this.getNotes(1);
    this.getAllFilesList(1);
  }

  ngAfterViewInit() {
    this.divInputBox = document.getElementById('my-content');
  }

  getTagData(id: string) {
    this.settingsService.getClientTagById(id).subscribe({
      next: (resp: any) => {
        this.dublicateDetails.tag_name = resp.data[0].name;
        this.dublicateDetails.colour = resp.data[0].colour;
      }
    });
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
  }

  taskStatusUpdate() {
    this.taskboard_service.updateTask(this.selected_id, this.selected_type, this.dublicateDetails).subscribe(() => { //@typescript-eslint/no-empty-function
      this.ckEditor = false;
      this.editItem = '';
      this.passEntry.emit(this.dublicateDetails);
    });
  }

  onAssigneChange() {
    const staff_info_array: any = [];
    const owner_department: any = [];
    if (this.dublicateDetails.assignee) this.dublicateDetails.assignee.forEach((item: any) => {
      const staff_obj = this.userLists.filter((member: any) => member.id === item);
      // console.log(staff_obj);
      staff_info_array.push(staff_obj[0]);
      if (staff_obj[0].department_info?.id) {
        if (!owner_department.includes(staff_obj[0].department_info?.id)) owner_department.push(staff_obj[0].department_info?.id);
      }
    });
    this.dublicateDetails.info.staff_info_array = staff_info_array;
    this.dublicateDetails.assignee_department = owner_department;
    this.taskStatusUpdate();
  }

  getBoardDetails() {
    this.taskboard_service.getBoardsbyId(this.selected_id).subscribe((resp: any) => {
      this.boardResp = resp.data[0];
      this.getMembers();
    });
  }

  onItemEdit(item: string) {
    this.editItem = item;
  }

  getMembers(staffIds?: any) {
    this.taskboard_service.getStaffForSource({ id: staffIds ? staffIds : this.boardResp.members }).subscribe((resp: any) => {
      this.userLists = resp.data;
    });
  }

  async getAssignesBysourceId(source_id: string, source_type: string) {
    let result: any;
    if (source_type === 'contact') {
      result = await this.crm_Service.getContactById(source_id).toPromise();
    } else if (source_type === 'lead') {
      result = await this.crm_Service.getLeadById(source_id).toPromise();
    } else if (source_type === 'company') {
      result = await this.crm_Service.getCompanyById(source_id).toPromise();
    } else if (source_type === 'project') {
      result = await this.client_service.getProjectDetailsById(source_id).toPromise();
    } else if (source_type === 'deal') {
      result = await this.crm_Service.getCardsById(source_id).toPromise();
    }
    let staffIds: any = [];
    staffIds = result.data[0].assignee;

    if (!staffIds.includes(result.data[0].owner)) {
      staffIds.push(result.data[0].owner);
    }
    if (staffIds.length !== 0) this.getMembers(staffIds);
  }

  openDsp() {
    this.ckEditor = !this.ckEditor;
  }

  addItemCheckBox() {
    if (this.newItemAdded) return;
    this.newItemAdded = true;
  }

  openCheckBox() {
    this.checklistView = !this.checklistView;
  }

  newCheckListAdded() {
    if (this.newItemText) {
      this.checklist_array.push({ name: this.newItemText, value: false });
      this.dublicateDetails.checklist = this.checklist_array;
      this.taskStatusUpdate();
      this.newItemAdded = false;
      this.newItemText = '';
    }
  }

  onReady(editor: any, editorComponent: any) {
    editor.editing.view.document.on('paste', (event: any, data: any) => {
      const pasteContent = data.dataTransfer.getData('text/plain');
      const contentLength = pasteEventInCkeditor(editorComponent, pasteContent);
      this.ckeditorLimit = Object.assign(this.ckeditorLimit, { ...contentLength });
    });
  }

  editorkeyEvent(editorComponent: any) {
    const inputData = editorComponent.editorInstance.getData();
    const dataWithoutTags = onParseDomValue(inputData);
    this.ckeditorLimit = Object.assign(this.ckeditorLimit, { actualLimit: dataWithoutTags.length || 0 });
  }

  onCheckBoxChange(ev: any, index: number) {
    this.checklist_array[index].value = ev.checked;
    this.dublicateDetails.checklist = this.checklist_array;
    this.taskStatusUpdate();
  }

  openNotes() {
    this.notesView = !this.notesView;
  }

  expandContent(event: any) {
    this.contentExpand = true;
    const divElement = event.target as HTMLElement;
    divElement.focus();
  }

  handleInput() {
    this.notesContent = this.divInputBox.innerText;
  }

  itemSelected(ev: any) {
    this.mention.push(ev);
    setTimeout(() => {
      const searchString = ev.fname + ' ' + ev.lname + ' ';
      const regx = new RegExp(searchString, 'g');
      this.divInputBox.innerHTML = this.divInputBox.innerHTML.replace(
        regx,
        '<span class="mention" style="color: #0072f0;" contenteditable="false">' + ev.fname + ' ' + ev.lname + ' ' + `</span>`
      );
      this.notesContent = this.divInputBox.innerText;
      const range = document.createRange();
      range.selectNodeContents(this.divInputBox);
      range.collapse(false);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }, 10);
  }

  saveNotes() {
    this.contentExpand = false;
    const body: any = {
      source_name: '',
      source_id: this.task_id,
      source_type: 'task',
      notes: this.notesContent,
      is_visible: false,
      info: {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      }
    };
    let modifiedStr: string = this.notesContent + ' ';
    this.mention.forEach((element: any) => {
      const searchedStr = element['fname'] + ' ' + element['lname'];
      if (modifiedStr.includes(searchedStr)) {
        const obj = {
          id: element.id,
          fname: element.fname,
          lname: element.lname
        };
        const test = modifiedStr.replace(searchedStr, JSON.stringify(obj));
        modifiedStr = test;
      }
    });
    if (this.editNoteId) {
      body["id"] = this.editNoteId;
      this.service.updateNotes({ ...body, notes: modifiedStr }).subscribe(() => {
        this.getNotes(1);
        this.notesContent = '';
        this.divInputBox.innerHTML = '';
        this.contentExpand = false;
      });
    } else if (this.task_id) {
      this.service.createNotes({ ...body, notes: modifiedStr }).subscribe({
        next: (resp: any) => {
          this.getNotes(1);
          this.notesContent = '';
          this.divInputBox.innerHTML = '';
          this.contentExpand = false;
        }
      });
    }
  }

  getNotes(page: number) {
    this.service.getAllNotes(this.task_id, page).subscribe((resp: any) => {
      this.notesList = resp.data;
      if (this.notesList.length > 0) this.notesView = true;
      this.copyCurrentPageNotesList = JSON.parse(JSON.stringify(this.notesList));
      this.notesList.forEach((element: any) => {
        const inputString = element.notes;
        const regex = /{[^{}]*}/g;
        const modifiedString = inputString && inputString.replace(regex, (match: string) => {
          try {
            const jsonObject: any = JSON.parse(match);
            if (Object.keys(jsonObject).length !== 0 && (jsonObject.fname || jsonObject.lname)) {
              const searchString = jsonObject.fname + ' ' + jsonObject.lname;
              return '<span " style="color: #0072f0;">' +
                searchString + `</span>`;
            } else {
              return match;
            }
          } catch (error) {
            console.error('');
            return match;
          }
        });
        element.notes = modifiedString;
      });
    });
  }

  cancel() {
    this.contentExpand = false;
    this.notesContent = '';
    this.divInputBox.innerHTML = '';
  }

  onNotesAdd() {
    this.addNotes = !this.addNotes;
    setTimeout(() => {
      this.divInputBox = document.getElementById('my-content');
    }, 200);

    // if (this.notesList.length > 0) return;
  }

  deleteNote(id: any) {
    this.service.deleteNotes(id).subscribe(() => {
      this.getNotes(1);
    });
  }

  confirmNotesDelete(noteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteNote(noteId);
      }
    });
  }

  editNotes(note: any, index: number) {
    this.addNotes = true;
    setTimeout(() => {
      this.divInputBox = document.getElementById('my-content');
      this.editNoteId = note.id;
      const ele = document.querySelector('.notes-sec');
      ele && ele.scrollTo({ top: 0, behavior: 'smooth' });
      const dummyText = note.notes;
      const tempElement = document.createElement('div');
      tempElement.innerHTML = dummyText;
      this.notesContent = tempElement.innerText;
      this.divInputBox.innerHTML = this.notesContent;
      const inputString = this.copyCurrentPageNotesList[index].notes;
      const regex = /{[^{}]*}/g;
      const jsonObjects = inputString && inputString.match(regex);
      if (jsonObjects) {
        jsonObjects.forEach((element: any) => {
          try {
            const parsedObject = JSON.parse(element);
            const searchString = parsedObject.fname + ' ' + parsedObject.lname;
            const regx = new RegExp(searchString, 'g');
            this.divInputBox.innerHTML = this.divInputBox.innerHTML.replace(regx,
              '<span class="mention" style="color: #0072f0;" contenteditable=false>' + searchString + `</span>`);
            this.mention.push(parsedObject);
          } catch (error) {
            console.error('');
          }
        });
      }
    }, 100);

    this.contentExpand = true;
  }


  getAllStaffInfo() {
    this.settingsService.getStaff_info().subscribe((response: any) => {
      const ownersInfo = response?.data.find((elem: any) => elem.id === this.dublicateDetails.owner_info.id);
      if (ownersInfo) this.userLists.push(ownersInfo);
      this. dublicateDetails && this.dublicateDetails?.assignee?.forEach((elemId: any) => {
        const usersInfo = response?.data.find((elem: any) => {
          if (elem.id !== ownersInfo.id) {
            return elem.id === elemId;
          } else {
            return;
          }
        });
        usersInfo && this.userLists.push(usersInfo);

      });
    });
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }

  onSelect(event: any) {
    this.files = [...event.addedFiles];
    const media_dropzone = document.getElementById('media-dropzone');
    media_dropzone && (media_dropzone.style['height'] = 'fit-content');
    const files = this.files[0]?.name?.split('.');
    const fileExt = files && files.length > 0 ? files[files?.length - 1] : '';
    if (!IMAGE_EXTENSIONS.includes(fileExt)) {
      setTimeout(() => {
        const imageTag = media_dropzone?.getElementsByTagName('img');
        const fileExtention = FILEEXTENSIONS.find((elem: any) => elem.type === fileExt);
        if (imageTag && imageTag.length > 0 && fileExtention) {
          imageTag[0].src = fileExtention.file;
        } else if (imageTag && imageTag.length > 0) {
          imageTag[0].src = FILEEXTENSIONS[4].file;
        }
      }, 50);
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onUpload() {
    if (!this.files[0]) return;
    this.awsUtilService.getUploadFileLink(this.files[0].type,this.files[0].name.split('.')[1]).subscribe({
      next: (resp) => {
        // console.log("first api - ", resp);
        this.fileName = this.presignedurl + resp.data.file_name;
        this.awsUtilService.uploadFile(this.files[0], resp.data.url).subscribe({
          next: () => {//@typescript-eslint/no-empty-function
            this.fileSubmit();
          }, error: () => {
            this.files = [];
          }
        });
      }, error: () => {
        this.files = [];
      }
    });
  }

  fileSubmit() {
    const payload = {
      source_name: '',
      source_id: this.task_id,
      source_type: 'task',
      name: this.files[0].name,
      is_visible: false,
      media_url: this.fileName
    };

    this.service.fileUploadWorke(payload).subscribe({
      next: () => {
        this.getAllFilesList(1);
        this.files = [];
      }, error: () => {
        this.files = [];
      }
    });
  }

  getAllFilesList(page: any) {
    this.service.getAllFiles(this.task_id, page).subscribe({
      next: (resp: any) => {
        this.filesListArray = resp.data;
        if (this.filesListArray.length > 0) this.fileView = true;
      }
    });
  }


  deleteFile(id: string) {
    this.service.deleteFile(id).subscribe(() => {
      this.getAllFilesList(1);
    });
  }

  confirmDelete(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteFile(fileId);
      }
    });
  }

  openFiles() {
    this.fileView = !this.fileView;
  }

  addFilesAdd() {
    this.addFiles = !this.addFiles;
  }

  openCalendar() {
    this.datesView = !this.datesView;
  }
}
