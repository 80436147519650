import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/core/service/chat.services';

@Component({
  selector: 'app-team-chat',
  templateUrl: './team-chat.component.html',
  styleUrls: ['./team-chat.component.scss']
})
export class TeamChatComponent implements OnInit, OnDestroy {
  @ViewChild('iframeRefTeam', { static: true }) iframeRefTeam!: ElementRef;
  iframeLoaded = false;
  private subscriptions: Subscription;
  chatNavObj: any;
  iframe: any;
  private receiveMessage: ((event: any) => void);
  src: any;

  constructor(private router: Router, private chatServices: ChatService, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer) {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.brexa.ai') ? 'https://automations.brexa.ai/chats/teamchats/' : window.location.hostname.includes('demo.brexa.ai') ? 'https://demoautomation.brexa.ai/chats/teamchats/' : 'https://stageautomation.brexa.ai/chats/teamchats/');
    this.spinner.show();
    this.chatServices.clearAllMessagesSub$.next({ type: 'clear', id: '' });
    this.subscriptions = this.chatServices.chatRedirectUrlSubs$.subscribe((data) => {
      this.chatNavObj = data;
      if (this.router.routerState.snapshot.url === '/teamchat') {
        this.postMessageIFrame('updateParams');
      }
    });
    if (this.router.getCurrentNavigation()?.extras?.state?.['routerState']) {
      this.chatNavObj = this.router.getCurrentNavigation()?.extras?.state?.['routerState'];
    }
    this.receiveMessage = (event: any) => {
      const sessionValues = {
        ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
      };
      // const iframe: any = document.getElementById('teamchat-widget-value');
      if (event.data.message === "auth") {
        this.iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
      } else if (event.data.message === "updateUrl" && event.data.url !== "/") {
        sessionStorage.setItem('redirectteamchatUrl', event.data.url);
        this.chatServices.updateCountsSub$.next('');
      }
    };
  }

  ngOnInit(): void {
    // Parent page
    this.iframe = document.getElementById('teamchat-widget-value');
    window.addEventListener('message', this.receiveMessage, false);

    setTimeout(async () => {
      const iframeReload = this.iframeRefTeam.nativeElement;
      iframeReload.src = this.iframe.src;
      await this.delay(500);
      this.iframeLoaded = true;
      this.spinner.hide();
    }, 3000);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngAfterViewInit() {
    let url = sessionStorage.getItem('redirectteamchatUrl');
    this.iframe.onload = () => {
      if (this.chatNavObj?.redirectUrl && this.chatNavObj?.redirectUrl !== "") {
        url = this.chatNavObj?.redirectUrl;
      }
      setTimeout(() => {
        this.iframe?.contentWindow.postMessage({ type: "redirect", data: { redirectUrl: url } }, '*');
      }, 500);
    };
  }

  async postMessageIFrame(type: string) {
    if (type !== 'initilized') {
      this.iframe && this.iframe?.contentWindow.postMessage({ data: this.chatNavObj, type: "redirect" }, '*');
    }
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.receiveMessage, false);
    sessionStorage.removeItem('redirectteamchatUrl');
    this.subscriptions.unsubscribe();
    this.chatServices.updateCountsSub$.next('');
  }
}
