<div class="container-fluid custom-height tags">
    <ng-container *ngIf="loggedInUser.data.role === 'ADMIN'">
        <div class="card pt-2">
            <div class="d-flex justify-content-between">
                <div class="d-inline-flex text-wrap">
                    <nav mat-tab-nav-bar [backgroundColor]="background" [tabPanel]="tabPanel" mat-stretch-tabs="false"
                        mat-align-tabs="start" animationDuration="0ms">
                        <a mat-tab-link *ngFor="let link of listOfModuleNames" (click)="onTabChange(link.value)"
                            [active]="activeLink === link.value" class="text-primary">
                            {{link.label}}
                        </a>
                    </nav>
                </div>
                <div class="mt-1">
                    <button class="btn btn-primary btn-sm px-4 d-flex align-items-center"
                        (click)="openAddTagModal(addTag)">
                        <mat-icon>add</mat-icon>Add Tag
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column" *ngIf="activeLink === 'pipeline'">
            <label class="fw-600 mb-2 text-primary">Flow</label>
            <mat-form-field appearance="outline" class="w-40">
                <mat-select [(ngModel)]="selected_pipeline" (selectionChange)="onPipelineChange($event)">
                    <mat-option *ngFor="let item of pipeline_list" [value]="item.id">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-tab-nav-panel #tabPanel>
            <div class="d-flex flex-column justify-content-between" style="height: calc(100vh - 216px);">
                <div class="card p-2 h-100 mb-0 pb-0 " style="overflow-y: auto;">
                    <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="column_names.length"
                        [fromLocation]="'TAGS'"></app-table-skeleton>
                    <table *ngIf="!tableSkeletonLoader" class="table align-middle mb-0 file-table w-100">
                        <thead>
                            <tr>
                                <th scope="col" class="text-primary pb-3" *ngFor="let col of column_names">{{col}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" *ngFor="let item of tagsListArray; let i = index">
                                <!-- <td>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(item) : null"
                                    [checked]="selection.isSelected(item)"></mat-checkbox>
                                </td> -->
                                <td class="text-capitalize">
                                    {{ item.name }}
                                </td>
                                <td>
                                    {{ item.source }}
                                </td>
                                <td>
                                    <p class="border-radius-50 m-0 p-2 tag-color"
                                        [style]="{'background-color': item.colour}">
                                    </p>
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <mat-icon class="me-2 font-size-18 cursor"
                                            (click)="openAddTagModal(addTag,item)">edit</mat-icon>
                                        <mat-icon class="font-size-18 cursor"
                                            (click)="deleteSelectedTag(item.id,item.source)">delete</mat-icon>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <mat-paginator #paginator [length]="this.totalTags" [pageSize]="page_size" (page)="page($event)"
                    aria-label="Select page" hidePageSize="true" showFirstLastButtons>
                </mat-paginator>
            </div>
        </mat-tab-nav-panel>
    </ng-container>
    <ng-container *ngIf="loggedInUser.data.role !== 'ADMIN'">
        <div class="d-flex align-items-center justify-content-center">
            <h6 class="text-secondary">You are not authorized to view this.</h6>
        </div>
    </ng-container>
</div>
<ng-template #addTag let-modal>
    <div class="modal-header pb-0 border-0">
        <h6 class="modal-title fw-600" id="label">{{addTagForm.value.id ? 'Update' : 'Add'}} Tag</h6>
        <mat-icon class="cursor" (click)="modalRef.close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <form [formGroup]="addTagForm" (ngSubmit)="onSubmit()">
            <div class="d-flex justify-content-between align-items-baseline ">
                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <label class="mb-2 fw-500">Tag Name <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="Enter tag name" formControlName="name" />
                        <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['name'].errors">
                            <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                            <span *ngIf="f['name'].errors['required']">Tag name required</span>
                            <span *ngIf="f['name'].errors['pattern']">Invalid Tag name</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 add-tag-color">
                    <label class="mb-2 fw-500 d-flex align-items-center">Tag Color
                        <!-- <p
                            class="p-2 ms-3 mb-0 border-radius-50" [style]="'background:' + addTagForm.value.colour">
                        </p> -->
                    </label>
                    <input type="color" class="w-100" value="#000000" formControlName="colour">
                </div>
            </div>
            <!-- <div class="d-flex align-items-center flex-wrap">

                <p class="p-3 m-2 border-radius-50 border-light-1 cursor" id={{color}} (click)="selectedTagColor(color)"
                    *ngFor="let color of tagColorsArray" [style]="'background:' + color">
                </p>
            </div> -->
            <label class="mb-2 fw-500">Source <small class="text-danger">*</small></label>
            <!-- <ng-select class="form-control" *ngIf="!this.addTagForm?.value?.id" [items]="listOfModuleNames"
                bindValue="value" bindLabel="label" [addTag]="false" [multiple]="true" placeholder="Select"
                formControlName="source">
            </ng-select> -->
            <mat-form-field appearance="outline" class="w-100">
                <mat-select placeholder="Select" formControlName="source">
                    <mat-option value="contact">Contact</mat-option>
                    <mat-option value="company">Company</mat-option>
                    <mat-option value="pipeline">Flow</mat-option>
                    <mat-option value="lead">Lead</mat-option>
                    <mat-option value="client">Client</mat-option>
                    <mat-option value="projects">Client Projects</mat-option>
                    <mat-option value="task">Task</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="error d-flex mt-2" *ngIf="submitted && f['source'].errors">
                <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                <span *ngIf="f['source'].errors['required']">Source is required</span>
            </div>
            <div class="d-flex justify-content-end align-items-center mt-3">
                <button type="submit" class="btn btn-primary"><span *ngIf="addTagForm.value?.id">Update</span><span *ngIf="!addTagForm.value?.id">Save</span></button>
            </div>
        </form>
    </div>
</ng-template>