import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CampaignRoutingModule } from './campaign-routing.module';
import { CampaignComponent } from './campaign.component';
import { MaterialModule } from 'src/app/core/common/material/material.module';
import { TemplateComponent } from './template/template.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CampaignsListComponent } from './campaigns-list/campaigns-list.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MarketingComponent } from './marketing/marketing.component';
import { PagesModule } from "../pages.module";
import { ContactGroupsComponent } from './contact-groups/contact-groups.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { PipesModule } from 'src/app/core/layouts/pipes/pipes.module';

@NgModule({
  declarations: [
    CampaignComponent,
    TemplateComponent,
    CampaignsListComponent,
    MarketingComponent,
    ContactGroupsComponent,
    ContactsListComponent
  ],
  imports: [
    CommonModule,
    CampaignRoutingModule,
    MaterialModule,
    NgSelectModule,
    NgxDropzoneModule,
    CKEditorModule,
    NgbNavModule,
    PagesModule,
    PipesModule
  ],
  exports: [
    ContactGroupsComponent
  ]
})
export class CampaignModule { }
