import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { CONTACTSJSON } from '../../../core/data/contacts_json';
import { ERRORJSON } from '../../../core/data/error_json';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactsModalComponent } from '../../shared/contacts-modal/contacts-modal.component';
const fileJSON = [
  {
    id: 'sdewiljmngbxcdvzsc',
    position: 1,
    company_name: 'Syoft',
    last_updated: '01/02/2023',
    phone: '+91 9133726222',
    contact_name: 'Jeff Bezos',
    contact_owner: 'Jeff',
    email: 'jeffbezos@gmail.com'
  },
  {
    id: 'dxgjujgvxmjygcv',
    position: 2,
    company_name: 'Worke',
    last_updated: '01/02/2023',
    phone: '+91 9133726222',
    contact_name: 'Jeff Bezos',
    contact_owner: 'Jeff',
    email: 'jeffbezos@gmail.com'
  },
  {
    id: 'zxvfnhnzedyrcx',
    position: 3,
    company_name: 'Eazyrooms',
    last_updated: '01/02/2023',
    phone: '+91 9133726222',
    contact_name: 'Jeff Bezos',
    contact_owner: 'Jeff',
    email: 'jeffbezos@gmail.com'
  },
  {
    id: 'wdsegthzgfjfdxr',
    position: 4,
    company_name: 'Zepul',
    last_updated: '01/02/2023',
    phone: '+91 9133726222',
    contact_name: 'Jeff Bezos',
    contact_owner: 'Jeff',
    email: 'jeffbezos@gmail.com'
  },
  {
    id: '3y8yrhnsdf903',
    position: 5,
    company_name: 'Vava innovations',
    last_updated: '01/02/2023',
    phone: '+91 9133726222',
    contact_name: 'Jeff Bezos',
    contact_owner: 'Jeff',
    email: 'jeffbezos@gmail.com'
  },
  {
    id: 'lnecdhhjdoijlxc',
    position: 6,
    company_name: 'Localify Tech',
    last_updated: '01/02/2023',
    phone: '+91 9133726222',
    contact_name: 'Jeff Bezos',
    contact_owner: 'Jeff',
    email: 'jeffbezos@gmail.com'
  }
];

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent {
  showType: any = 'marketing-channels';
  public selection = new SelectionModel<any>(true, []);
  contactsJson = CONTACTSJSON[0];
  errorJson = ERRORJSON[0];
  @ViewChild('paginator') paginator!: MatPaginator;
  modalRef!: NgbModalRef;
  files: File[] = [];
  contactListArray = fileJSON;
  importModalType: any;
  channels_list: any[] = [
    {
      icon: 'assets/images/sms.png',
      name: 'Zepul',
      count: '30'
    },
    {
      icon: 'assets/images/sms.png',
      name: 'Eazyrooms',
      count: '32'
    },
    {
      icon: 'assets/images/sms.png',
      name: 'Worke',
      count: '19'
    }
  ];
  filtersLables = [
    {
      position: 1,
      lableName: 'Name'
    },
    {
      position: 2,
      lableName: 'Next Activity'
    },
    {
      position: 3,
      lableName: 'Email'
    },
    {
      position: 4,
      lableName: 'Phone'
    },
    {
      position: 5,
      lableName: 'Tag'
    },
    {
      position: 6,
      lableName: 'Company'
    },
    {
      position: 7,
      lableName: 'Owner'
    },
    {
      position: 8,
      lableName: 'Source'
    }
  ];

  constructor(private modalService: NgbModal) { }

  viewList() {
    this.showType = 'marketing-list';
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.contactListArray.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.contactListArray.forEach((row) => {
      this.selection.select(row);
    });
  }

  checkedContacts() {
    console.log(this.selection.selected); // list of checked contacts
  }

  openContactsModalDialog() {
    this.modalService.open(ContactsModalComponent, { size: 'lg' });
  }

  importEexportModalDialog(importExportModel: any, type: any) {
    this.modalService.open(importExportModel, { size: 'md' });
    this.importModalType = type;
  }

  selectFilterLable($event: any) {
    $event.stopPropagation();
    $event.preventDefault();

    console.log('evey taget value', $event.target);
    if ($event.target) {
      $event.target.classList.toggle('selected');
    }
  }
}
