import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyConversionPipe } from './currency/currency-conversion.pipe';
import { DateConversionPipe } from './date/date-conversion.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { MaterialModule } from '../../common/material/material.module';
import { SafeHtmlPipe } from './safe_HTML/safe_HTML.pipes';
import { DecimalPipe } from './decimal/decimal.pipe';
import { InnerHTMLPipe } from './innerHTML/inner-html.pipe';


@NgModule({
  declarations: [
    CurrencyConversionPipe,
    DateConversionPipe,
    FilterPipe,
    SafeHtmlPipe,
    DecimalPipe,
    InnerHTMLPipe
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports:[
    DateConversionPipe,
    CurrencyConversionPipe,
    FilterPipe,
    SafeHtmlPipe,
    DecimalPipe,
    InnerHTMLPipe
  ]
})
export class PipesModule { }
