<div class="container-fluid" *ngIf="featureShow()">
    <div class="row mb-2 mt-3 justify-content-end">
        <div class="col-sm-6">
            <div class="heading font-size-18 fw-700 d-flex" style="width: fit-content;">
                <mat-icon class="heading font-size-24 me-2 cursor" routerLink="/setup"
                    matTooltip="Back">keyboard_arrow_left</mat-icon>
                <h5 class="heading font-size-18 fw-800 text-primary">

                    {{formbuilderJson.heading}}
                </h5>

            </div>
        </div>
        <div class="col-sm-6 text-end">
            <button type="button" class="btn btn-primary mb-2 mr-2" (click)="openModel(createForm)">
                {{formbuilderJson.create_form}}
            </button>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table align-middle table-nowrap table mb-0 table-bordered">
            <thead>
                <tr class="table-header-color">
                    <th>S.No</th>
                    <th *ngFor="let item of tableHeads;">{{item}}</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let form of forms_list; let i=index;">
                    <td>
                        {{ i+ 1 }}
                    </td>
                    <td>{{form.name}}</td>
                    <td>
                        <p class="cursor badge bg-primary text-wrap fs-6 mt-2 ms-3" routerLink="../form-submissions"
                            [queryParams]="{ id: form.id }">
                            {{formbuilderJson.submissions}}</p>
                    </td>
                    <td>
                        <div class="d-flex align-items-between">
                            <span class="d-inline cursor text-center me-1" (click)="clickEvent($event)"
                                (cdkCopyToClipboardCopied)="onClipboardCopy($event)" #tp="matTooltip"
                                matTooltip="Copy to clipboard" [cdkCopyToClipboard]="LINK + form.id">
                                <img class="w-60" src="../../../assets/images/copy_Icon.svg">
                            </span>
                            <img class="me-2 cursor" src="../../../assets/images/edit-board-icon.svg"
                                routerLink="../form-settings" [queryParams]="{ id: form.id, form_name: form.name }">
                            <img src="../../../assets/images/delete-board-icon.svg" (click)="deleteForm(form.id)"
                                class="cursor">
                            <label class="switch ms-2" matTooltip="Client Visibility">
                                <input type="checkbox" [id]="form.id" [(ngModel)]="form.iframe_visibility"
                                    (change)="toggleVisibility(form)">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="h-30rem" *ngIf="display_no_data">
            <app-nodata-found></app-nodata-found>
        </div>

    </div>
</div>

<ng-template #createForm let-modal>
    <div class="modal-header">
        <h5 class="modal-title">
            {{formbuilderJson.create_heading}}
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('cross click');this.form.reset();"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form">
            <mat-label class="fw-600 mb-2">{{formbuilderJson.name_feild}}</mat-label>
            <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none;" matInput type="text" required placeholder={{formbuilderJson.name_feild}}
                    formControlName="name">
                <mat-error class="error text-danger d-flex" *ngIf="submitted && f['name'].errors">
                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                    <span *ngIf="f['name'].errors['required']"> {{formbuilderJson.name_feild}}
                        {{errorJson.required}}</span>
                    <span *ngIf="f['name'].errors['pattern']">Only alphanumeric are accepted.</span>
                </mat-error>
            </mat-form-field>
            <mat-label class="mb-2">Description</mat-label>
            <ckeditor [editor]="Editor" placeholder="Description" #editorComponent formControlName="description"
                [config]="ckconfigQue" class="ckeditor-height"
                [ngClass]="ckeditor.actualLimit > ckeditor.maxLimit ? 'ck-error':''"
                (ready)="onReady($event,editorComponent)" (keyup)="editorkeyEvent(editorComponent)">
            </ckeditor>
            <small [ngClass]="ckeditor.actualLimit > ckeditor.maxLimit ? 'text-danger':''" class="font-size-10 mb-2">Maximum
                1000 characters are accepted.</small><small
                [ngClass]="ckeditor.actualLimit > ckeditor.maxLimit ? 'text-danger':''"
                class="font-size-10 ps-1">(Character limit:
                {{ckeditor.actualLimit}}/{{ckeditor.maxLimit}})</small><br>

            <mat-label class="fw-600 mb-2">{{formbuilderJson.contact_type_field}}</mat-label>
            <mat-form-field appearance="outline" class="w-100">
                <mat-select formControlName="contact_type" placeholder={{formbuilderJson.contact_type_field}} class="">
                    <mat-option [value]="type.id" *ngFor="let type of contact_type">{{type.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <label class="fw-600 me-3">{{formbuilderJson.client_visibility}}</label>
                <label class="switch">
                    <input type="checkbox" formControlName="iframe_visibility">
                    <span class="slider round"></span>
                </label>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end mb-5">
        <button type="button" class="btn btn-light" (click)="modelService.dismissAll();this.form.reset();">
            {{formbuilderJson.cancel_btn}}
        </button>
        <button type="button" class="btn btn-primary" (click)="createformbuilder()">
            {{formbuilderJson.submit_btn}}
        </button>
    </div>
</ng-template>