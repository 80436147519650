import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/core/service/settings.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { STAFF } from 'src/app/core/data/settings_json';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { countryData } from 'src/app/core/data/countryData';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DayOffModelComponent } from '../settings/day-off-model/day-off-model.component';
import { PageEvent } from '@angular/material/paginator';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { NAVIGATION_KEYS, maximumNumberAllowed, onOnlyNumbers, onlyNumberDecimal } from 'src/app/core/common/common-functions';
import { time_zone_json } from 'src/app/core/data/mobile_json';
import { ProfileService } from 'src/app/core/service/profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();
  public bankFilterTimezoneCtrl: FormControl = new FormControl();
  public filteredTimezoneBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onTimezoneDestroy = new Subject<void>();
  timezonejson = time_zone_json;
  countryjson = countryData;
  errorjson = ERRORJSON[0];
  staffjson = STAFF[0];
  user_profile!: FormGroup;
  user_data: any;
  submited = false;
  loggedInUser: any;
  files: any;
  fileChosen: any;
  attachedFile: any;
  presignedurl = environment.preSendUrl;
  activeId = 1;
  day_table_cols = ['Day Off Title', 'Staff Name', 'Date Range', 'Time Range', 'Status'];//'Action'
  dayoffModel!: NgbModalRef;
  startValue: any;
  endValue: any;
  currentPage = 1;
  totalPages = 0;
  timeFilter: any;
  branches: any;
  isEditVisible = false;
  badgesList: any;
  daysListArray: any[] = [];
  isProfileDeleted = false;
  disableSave = false;
  defaultName: any;
  department = '';
  noDataFound = false;
  mailTableHeaders = ['Domain', 'Name', 'Email', 'Signature'];
  mailList: any;
  fileName = '';
  signature_image: any[] = [];
  seletedEmailItem: any;
  call_numbers: any[] = [];

  constructor(private awsUtilService: AwsUtilsService, private settings_service: SettingsService, private modelservice: NgbModal, private confirmationMsz: ConfirmationMszService, private profile_service: ProfileService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.filteredBanks.next(this.countryjson.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.filteredTimezoneBanks.next(this.timezonejson.slice());
    this.bankFilterTimezoneCtrl.valueChanges
      .pipe(takeUntil(this._onTimezoneDestroy))
      .subscribe(() => {
        this.filterTimezoneBanks();
      });
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
    this.initializeForm();
    this.getLoggedUserInfo();
    this.getLocationDetails();
    this.getBadges();
    this.user_profile.valueChanges.subscribe(() => {
      this.disableSave = true;
      this.submited = false;
    });
  }

  initializeForm() {
    this.submited = false;
    this.user_profile = new FormGroup({
      id: new FormControl(''),
      fname: new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z-'\s]{1,}$/)]),
      lname: new FormControl('', [Validators.pattern(/^[A-Za-z][A-Za-z-'\s]*$/)]),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/)])),//eslint-disable-line
      phone_number: new FormControl('', Validators.compose([Validators.required, Validators.pattern("[0-9]+"), Validators.minLength(6), Validators.maxLength(12)])),
      ccode: new FormControl([Validators.required]),
      designation: new FormControl(''),
      specialisation: new FormControl(''),
      qualification: new FormControl(''),
      experience: new FormControl(''),
      gender: new FormControl(''),
      age: new FormControl(''),
      image: new FormControl(''),
      badges: new FormControl(''),
      locations: new FormControl('', [Validators.required]),
      role: new FormControl(''),
      time_zone: new FormControl('', Validators.required),
      number: new FormControl(),
      number_type: new FormControl('TWILIO'),
      number_id: new FormControl()
    });
    // console.log(this.user_profile.value);
    this.getFromNumbers();
    this.user_profile.controls['email'].disable();
    this.user_profile.controls['role'].disable();
    if (this.loggedInUser.data.role !== 'ADMIN') {
      this.user_profile.controls["badges"].disable();
      this.user_profile.controls['locations'].disable();
      this.user_profile.controls['time_zone'].disable();
      this.user_profile.controls['designation'].disable();
      this.user_profile.controls['experience'].disable();
      this.user_profile.controls['specialisation'].disable();
      this.user_profile.controls['number'].disable();
    }
  }

  get f() {
    return this.user_profile.controls;
  }

  onSubmit() {
    if (!this.submited) {
      this.submited = true;
      if (this.user_profile.valid) {
        let valid_badge: string;
        let valid_locations, valid_time_zone: any[];
        let designation: any;
        let experience: any;
        let specialisation: any;
        const user_email: string = this.user_profile.controls['email'].value;
        let user_role: string;
        if (this.loggedInUser.data.role !== 'ADMIN') {
          user_role = this.user_data.role_info.id;
        } else {
          user_role = this.user_data.role_info.name;
        }
        if (this.user_profile.controls['badges'].status === 'DISABLED') {
          valid_badge = this.user_profile.controls['badges'].value;
        } else {
          valid_badge = this.user_profile.value.badges;
        }
        if (this.user_profile.controls['designation'].status === 'DISABLED') {
          designation = this.user_profile.controls['designation'].value;
        } else {
          designation = this.user_profile.value.designation;
        }

        if (this.user_profile.controls['locations'].status === 'DISABLED') {
          valid_locations = [this.user_profile.controls['locations'].value];
        } else {
          valid_locations = [this.user_profile.value.locations];
        }
        if (this.user_profile.controls['time_zone'].status === 'DISABLED') {
          valid_time_zone = this.user_profile.controls['time_zone'].value;
        } else {
          valid_time_zone = this.user_profile.value.time_zone;
        }

        if (this.user_profile.controls['experience'].status === 'DISABLED') {
          experience = this.user_profile.controls['experience'].value;
        } else {
          experience = this.user_profile.value.experience;
        }
        if (this.user_profile.controls['specialisation'].status === 'DISABLED') {
          specialisation = this.user_profile.controls['specialisation'].value;
        } else {
          specialisation = this.user_profile.value.specialisation;
        }
        this.settings_service.putStaffInfo(this.user_profile.value.id, { ...this.user_profile.value, badges: valid_badge, locations: valid_locations, time_zone: valid_time_zone, email: user_email, designation: designation, role_id: user_role, experience: experience, specialisation: specialisation }).subscribe({
          next: (resp: any) => {
            this.submited = false;
            this.isProfileDeleted = false;
            this.disableSave = false;
            // console.log(this.loggedInUser.time_zone);
            this.loggedInUser.time_zone = resp?.data?.time_zone ? resp?.data?.time_zone : this.loggedInUser.time_zone;
            this.loggedInUser.data.fname = resp?.data?.fname ? resp?.data?.fname : this.loggedInUser.fname;
            this.loggedInUser.data.lname = resp?.data?.lname ? resp?.data?.lname : this.loggedInUser.lname;
            sessionStorage.setItem('currentUser', JSON.stringify(this.loggedInUser));
            if (localStorage.getItem('currentUser')) {
              const local_storage_data = JSON.parse(localStorage.getItem('currentUser')!);
              local_storage_data.time_zone = resp?.data?.time_zone ? resp?.data?.time_zone : local_storage_data.time_zone;
              local_storage_data.data.fname = resp?.data?.fname ? resp?.data?.fname : local_storage_data.fname;
              local_storage_data.data.lname = resp?.data?.lname ? resp?.data?.lname : local_storage_data.lname;
              localStorage.setItem('currentUser', JSON.stringify(local_storage_data));
            }
            this.profile_service.passValue(true);
            // this.getLoggedUserInfo();
          }, error: () => {
            this.submited = false;
          }
        });
      }
    }
  }

  deleteProfilePic() {
    this.confirmationMsz.customDeleteView({ msg: 'Are you sure!' })
      .then((data) => {
        if (data.value) {
          this.user_profile.patchValue({ image: '' });
          this.isProfileDeleted = true;
        }
      });
  }

  getBadges() {
    this.settings_service.getBadges('STAFF').subscribe({
      next: (resp: any) => {
        this.badgesList = resp.data;
        // this.user_profile.patchValue({ badges: this.badgesList[0].id });
      }
    });
  }
  getLocationDetails() {
    this.settings_service.getLocationSettings().subscribe((resp: any) => {
      this.branches = resp.data;
    });
  }

  tabChange(event: any) {
    this.activeId = event.nextId;
    if (this.activeId === 2) {
      this.getDayOffs(this.currentPage);
    } else if (this.activeId === 4) {
      this.userSignature();
    }
  }

  getLoggedUserInfo() {
    this.settings_service.getStaff_infoById(this.loggedInUser.data.id).subscribe({
      next: (resp: any) => {
        this.user_data = resp['data'];
        const badges: any[] = [];
        this.user_data.badges.filter((ele: any) => badges.push(ele.id));
        this.department = this.user_data['department_info']['name'] ? this.user_data['department_info']['name'] : 'NA';
        this.user_profile.patchValue({ ...this.user_data, locations: this.user_data['locations'][0], role: this.user_data.role_info.name, badges: badges, number : this.user_data?.numbers_info[0]?.number, number_id : this.user_data?.numbers_info[0]?.id });
        this.defaultName = resp.data['fname'].charAt(0) + (resp.data['lname'] ? resp.data['lname'].charAt(0) : resp.data['fname'].charAt(1));
      }
    });
  }

  getFromNumbers() {
    this.settings_service.getTwilioNumbers().subscribe((resp: any) => {
      this.call_numbers = resp.phoneNumbers;
    });
  }

  FileUpload(e: any) {
    // console.log(event);
    this.files = e.target.files[0];
    if (this.files.size > 15 * 1048576) {
      this.toastrService.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    // console.log(e.target.files[0]);
    this.fileChosen = this.files.name;
    this.awsUtilService.getUploadFileLink(e.target.files[0].type, e.target.files[0].name.split('.')[1]).subscribe((resp: any) => {
      // console.log("first api - ", resp);
      this.attachedFile = this.presignedurl + resp.data.file_name;

      this.awsUtilService.uploadFile(e.target.files[0], resp.data.url).subscribe(() => {
        this.user_profile.patchValue({ image: this.attachedFile });
        this.isProfileDeleted = false;
      });
    });
  }

  protected filterBanks() {
    if (!this.countryjson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.countryjson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.countryjson.filter(bank => (bank.Entity.toLowerCase().indexOf(search) > -1) ||
        bank.IAC.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterTimezoneBanks() {
    if (!this.timezonejson) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterTimezoneCtrl.value;
    if (!search) {
      this.filteredTimezoneBanks.next(this.timezonejson.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    const filteredTimezones = this.timezonejson.map((element: any) => {
      const filteredCountries = element.countries.filter((bank: any) => bank.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      return { ...element, countries: filteredCountries };
    }).filter((element: any) => element.countries.length > 0);

    this.filteredTimezoneBanks.next(filteredTimezones);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onTimezoneDestroy.next();
    this._onTimezoneDestroy.complete();
  }

  addDayoff() {
    this.dayoffModel = this.modelservice.open(DayOffModelComponent);
    this.dayoffModel.componentInstance.dayoff_type = 'staff';
    this.dayoffModel.componentInstance.staffId = this.loggedInUser.data.id;
    this.dayoffModel.componentInstance.passEntry.subscribe(() => {
      this.getDayOffs(this.currentPage);
    });
  }
  getDayOffs(page: number) {
    this.settings_service.getDayOffById(this.loggedInUser.data.id, page).subscribe((resp: any) => {
      this.daysListArray = resp.data;
      this.totalPages = resp.count;
      this.daysListArray.length === 0 && (this.noDataFound = true);
    });
  }
  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getDayOffs(this.currentPage);
  }
  onStatusChange(item: any) {
    this.settings_service.putDayoff(item).subscribe();
  }

  keyDownEvent(event: any, maxLength: number) {
    const maxNum = maximumNumberAllowed(event, maxLength);
    const onlyNum = onOnlyNumbers(event);
    return (maxNum && onlyNum);
  }

  validateDecimalPoints(event: any) {
    if (event.target.value.length === 0) {
      return onOnlyNumbers(event);
    } else {
      if (event.key === '+' || event.key === '-' || event.key === 'e' || event.keyCode <= 45 || event.keyCode >= 58) {
        return false;
      }
      if (NAVIGATION_KEYS.indexOf(event.key) > -1) {
        return true;
      }

      const splittedValue = event.target.value.split('');
      if ((splittedValue && splittedValue.includes('.')) || event.key === '.') {
        const index = splittedValue.findIndex((el: any) => el === '.');
        if (index === -1) {
          return true;
        } else {
          const sampleText = splittedValue.slice(index, splittedValue.length);
          if (splittedValue[splittedValue.length - 1] === '.') {
            return true;
          } else if (splittedValue[splittedValue.length - 1] === '1' && sampleText.length <= 2 && (event.key === '0' || event.key === '1')) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return event.target.value.length < 2;

      }
    }
  }

  userSignature() {
    this.settings_service.getUserMailList().subscribe((resp: any) => {
      this.mailList = resp.data;
    });
  }

  openSignature(addSignature: any, item: any) {
    this.seletedEmailItem = item;
    this.modelservice.open(addSignature);
  }

  onSelect(event: any) {
    this.signature_image = [...event.addedFiles];
    if (this.signature_image && this.signature_image.length >= 1) {
      this.onRemove(this.signature_image[0]);
    }
    if (event.addedFiles[0].size > 15 * 1048576) {
      this.toastrService.error("File size is too large, please upload a file size less than 15MB");
      return;
    }
    this.signature_image = [...event.addedFiles];
    this.awsUtilService.getUploadFileLink(this.signature_image[0].type, this.signature_image[0].name.split('.')[1]).subscribe((resp: any) => {
      this.fileName = this.presignedurl + resp.data.file_name;
      this.awsUtilService.uploadFile(this.signature_image[0], resp.data.url).subscribe(() => {//@typescript-eslint/no-empty-function
      });
    });
  }

  onRemove(event: any) {
    this.fileName = '';
    this.signature_image.splice(this.signature_image.indexOf(event), 1);
  }

  updateStaffSignature() {
    this.settings_service.addStaffSignature(this.seletedEmailItem.id, { signature: this.fileName }).subscribe(() => {
      this.userSignature();
      this.modelservice.dismissAll();
    });
  }
}