import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  getInvoiceList(page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.invoice}?page=${page}&limit=20`);
  }

  getInvoiceById(invoiceId: string) {
    return this.http.get(`${environment.urlPrefix}${constant.estimate_proforma_invoice}?id=${invoiceId}`);
  }

  getInvoiceListBySearch(search_word: any,page:number) {
    return this.http.get(`${environment.urlPrefix}${constant.invoice}?search=${search_word}&page=${page}&limit=20`);
  }

  retryForInvoice(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.invoice}${id}${constant.invoice_retry}`);
  }

  getPaymentTransactions(page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.payment_transactions}?page=${page}&limit=20`);
  }
  getPaymentTransactionsOnSearch(keyword: any, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.payment_transactions}?search=${keyword}&page=${page}&limit=20`);
  }

  postPaymentTransaction(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.payment_transactions}`, body);
  }

  //Taxes

  getTaxes(page?: number) {
    let pagination;
    if (page) {
      pagination = `?page=${page}&limit=20`;
    } else {
      pagination = '';
    }
    return this.http.get(`${environment.urlPrefix}${constant.tax}${pagination}`);
  }

  postTaxes(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.tax}`, body);
  }

  putTaxes(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.tax}${body.id}/`, body);
  }

  deleteTax(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.tax}${id}/`);
  }

  //manual invoice 
  postManualInvoice(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.manual_invoice}`, body);
  }

  postEstimateAndProformaInvoice(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.estimate_proforma_invoice}`, body);
  }

  putEstimateAndProformaInvoice(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.estimate_proforma_invoice}`, body);
  }

  convertProformaToInvoice(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.proforma_to_invoice}${id}/`);
  }

  partialInvoice(body: any){
    return this.http.post(`${environment.urlPrefix}${constant.partial_invoice}${body.id}/`,body);
  }

  //terms & conditions
  getTermsAndConditions() {
    return this.http.get(`${environment.urlPrefix}${constant.terms_settings}`);
  }

  updateTermsAndCondition(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.terms_settings}${body.id}/`, body);
  }
}
