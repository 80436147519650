<div class="conversations h-100">
    <!-- <div class="start-conversation text-center">
        <p class="bg-conversation px-3 py-2 border-radius-8 d-inline-flex">{{date | date:'mediumDate'}}</p>
        <p class="text-secondary">{{convJSON.start_conversation}}</p>
    </div> -->
    <iframe allow="camera; microphone;screenobtainer; obtainscreenfromgetdisplaymedia;clipboard-read; clipboard-write" #iframeRef [src]=src id="chat-widget-value" width="100%" height="100%" style="min-height: 80vh;"
        [ngClass]="{'d-none': !iframeLoaded}"></iframe>
    <div class="middle-chat-section d-none">
        <section class="middle">
            <div class="chat h-100 d-flex flex-column justify-content-between">
                <div class="messages-chat" #messageschat>
                    <div class="" infinite-scroll [scrollWindow]="false" (scrolledUp)="onScroll()"
                        [infiniteScrollContainer]="messageschat" [infiniteScrollUpDistance]="2">
                        <ng-container *ngFor="let item of converstion">
                            <div class="">
                                <div class="message">
                                    <div class="text"
                                        [ngClass]="(item.createdBy?.user === loggedInUser.data.id || item.createdBy === null) ? 'send-message' : 'text-radius'"
                                        *ngIf="item.payload.type !== 'system'">
                                        <p *ngIf="item.payload.type === 'text'">{{item.payload?.text}}</p>
                                        <a *ngIf="item.payload.type === 'link'" [href]="item.payload.link"
                                            target="_blank">{{item.payload.text}}</a>
                                        <div class="image" *ngIf="item.payload.type === 'image'">
                                            <ng-container *ngFor="let image of item.payload.files">
                                                <img [src]="image.path" width="200">
                                            </ng-container>
                                        </div>
                                        <div class="video" *ngIf="item.payload.type === 'video'">
                                            <ng-container *ngFor="let video of item.payload.files">
                                                <video controls width="320" height="240">
                                                    <source [src]="video.path" [type]="video.mimetype">
                                                </video>
                                            </ng-container>
                                        </div>
                                        <div class="audio" *ngIf="item.payload.type === 'audio'">
                                            <ng-container *ngFor="let audio of item.payload.files">
                                                <audio [src]="audio.path" controls></audio>
                                            </ng-container>
                                        </div>
                                        <div class="document" *ngIf="item.payload.type === 'document'">
                                            <!--Testing pending-->
                                            <ng-container *ngFor="let doc of item.payload.files">
                                                <a [href]="doc.path" target="_blank">{{doc.name}} </a>
                                            </ng-container>
                                        </div>
                                        <div class="location" *ngIf="item.payload.type === 'location'">
                                            <a [href]="item.payload?.location.link" target="_blank">
                                                <img src="https://maps.googleapis.com/maps/api/staticmap?center={{item.payload?.location.latitude}},{{item.payload?.location.longitude}}&zoom=15&size=400x300&markers={{item.payload?.location.latitude}},{{item.payload?.location.longitude}}&key={{apiKey}}"
                                                    width="400" height="240">
                                            </a>
                                        </div>
                                        <div class="contact" *ngIf="item.payload.type === 'contacts'">
                                            <table>
                                                <ng-container *ngFor="let col of item.payload?.contacts;let i=index">
                                                    <tr>
                                                        <caption class="text-center">
                                                            {{col.name?.first_name}}</caption>
                                                    </tr>
                                                    <tr>
                                                        <th>Email</th>
                                                        <th>Phone Number</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <ng-container *ngFor="let email of col.emails">
                                                                {{email.email}}
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngFor="let phone of col.phones">
                                                                {{phone.phone}}
                                                            </ng-container>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </table>
                                        </div>
                                        <div class="list" *ngIf="item.payload.type === 'list'">
                                            <h5 class="font-size-14 text-black fw-600">{{item.payload?.text}}</h5>
                                            <h6 class="font-size-12"
                                                *ngFor="let opt of item.payload?.options;let i = index;">
                                                {{opt}}
                                            </h6>
                                        </div>
                                        <div class="replyButtons" *ngIf="item.payload.type === 'replyButtons'">
                                            <h5 class="font-size-14 text-black fw-600">{{item.payload?.text}}</h5>
                                            <div class="mb-2" *ngFor="let opt of item.payload?.buttons;let i = index;">
                                                <button class="font-size-12 btn btn-primary">
                                                    {{opt.label}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="system w-100 text-center" *ngIf="item.payload.type === 'system'">
                                        <span>{{item.payload.text}}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <textarea _ngcontent-gfn-c176="" rows="2" cols="30" placeholder="write a message"
                        class="form-control mt-2" (keyup.enter)="onMessageSend()" [(ngModel)]="message"></textarea>
                    <label class="ms-2 text-primary cursor d-flex align-items-center" for="attach-file"
                        (change)="fileUpload($event,'chatbot')">
                        <mat-icon style="transform: rotate(152deg);">attachment</mat-icon>
                        <input type="file" id="attach-file" accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.xml,.csv"
                            hidden />
                    </label>
                    <mat-icon class="ms-2 cursor text-primary" (click)="onMessageSend()">send</mat-icon>
                </div>
            </div>
        </section>
    </div>
</div>

<ng-container *ngIf="display_mail_popup">
    <div class="mail-template">
        <div class="modal-header bg-light px-4 py-3">
            <h5 class="text-white font-size-14 mb-0">New Message</h5>
            <mat-icon class="text-white cursor" (click)="openMailTemplate(false)">close</mat-icon>
        </div>
        <div class="d-flex align-items-center px-4 my-2 py-1">
            <p class="mb-0 me-3">To</p>
            <p class="mb-0 text-primary"><b>{{source_email}}</b></p>
        </div>
        <mat-divider class="my-0 mx-4"></mat-divider>
        <form [formGroup]="mail_template" (ngSubmit)="onMailSubmit()">
            <div class="px-4 my-2 py-1">
                <input class="border-0 bg-white w-100" type="text" placeholder="Subject" formControlName="subject">
            </div>
            <!-- <mat-divider class="my-0 mx-3"></mat-divider> -->
            <div class="modal-body px-4 py-1">
                <ckeditor [editor]="Editor" formControlName="msg_body" [config]="ckconfigQue">
                </ckeditor>
                <div class="d-flex align-items-center mt-3 w-100">
                    <button mat-raised-button matTooltip="Use AI to write the mail.Type the prompt and click here"
                        aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                        type="button" class="btn btn-primary font-size-12"
                        (click)="workeAI(mail_template.value.msg_body)">Brexa AI</button>
                    <div class="d-flex align-items-center justify-content-end w-100">
                        <div class="d-flex align-items-center" *ngIf="fileChoosen">
                            <p class="mb-0 me-2 ms-2 font-size-14 w-75 text-ellipsis">{{fileChoosen}}</p>
                            <mat-icon (click)="removeFile()">close</mat-icon>
                        </div>
                        <div class="d-flex align-items-center justify-content-end me-2 cursor w-75" id="upload-btn"
                            (change)="fileUpload($event)">
                            <label class="fw-400 cursor d-flex align-items-center" for="attach-file">
                                <mat-icon style="transform: rotate(152deg);">attachment</mat-icon>
                                <input type="file" id="attach-file"
                                    accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.xml,.csv" hidden />
                            </label>
                        </div>
                    </div>
                    <!-- <div class="d-flex align-items-center justify-content-end"> -->
                    <button type="submit" class="btn btn-primary font-size-12">Send</button>
                    <!-- </div> -->
                </div>
            </div>

        </form>
    </div>
</ng-container>