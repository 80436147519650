import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, AfterViewInit, OnDestroy{
  activeId: any = 2;
  iframeLoaded = false;
  private receiveMessage: ((event: any) => void);
  iframe: any;

  @ViewChild('iframeRef', { static: true }) iframeRef!: ElementRef;
  src: any;

  constructor(private spinner: NgxSpinnerService,private sanitizer: DomSanitizer) {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(window.location.hostname.includes('app.worke.io') ? 'https://campaigns.worke.io/' : window.location.hostname.includes('demo.worke.io') ? 'https://democampaigns.worke.io/' : 'https://stagecampaigns.worke.io/');
    this.spinner.show();
    this.receiveMessage = (event: any) => {
      const sessionValues = {
        ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
      };
      if (event.data.message === "auth") {
        this.iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
      }
      else if (event.data.message === "updateUrl" && event.data.url !== "/") {
        sessionStorage.setItem('redirectCampaignUrl', event.data.url);
      }
    };
  }

  // tabChange(event: any) {
  //   this.activeId = event.nextId;
  // this.router.navigate([],{ relativeTo: this.route,queryParams: { showType: this.activeId } })
  // }
  ngOnInit(): void {
    // Parent page

    // function receiveMessage(event: any) {
    //   // if (event.origin !== environment.chatBotUrl) {
    //   //   return;
    //   // }
    //   console.log("event received", event);
    //   const sessionValues = {
    //     ...JSON.parse(sessionStorage.getItem('currentUser') ?? "{}")
    //   };

    //   const iframe: any = document.getElementById('campaign-widget-value');
    //   if (event.data.message === "auth") {
    //     iframe?.contentWindow.postMessage({ type: "auth", data: sessionValues }, '*');
    //   }
    //   else if (event.data.message === "updateUrl" && event.data.url !== "/") {
    //     sessionStorage.setItem('redirectCampaignUrl', event.data.url);
    //   }
    // }
    this.iframe = document.getElementById('campaign-widget-value');
    window.addEventListener('message', this.receiveMessage, false);

    setTimeout(async () => {
      const iframeReload = this.iframeRef.nativeElement;
      iframeReload.src = this.iframe.src;
      await this.delay(500);
      this.iframeLoaded = true;
      this.spinner.hide();
    }, 3000);

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngAfterViewInit() {
    this.iframe.onload = () => {
      const url = sessionStorage.getItem('redirectCampaignUrl');
      setTimeout(() => {
        this.iframe?.contentWindow.postMessage({ type: "redirect", data: { redirectCampaignUrl: url } }, '*');
      }, 500);
    };
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.receiveMessage, false);
    sessionStorage.removeItem('redirectCampaignUrl');
  }
}
