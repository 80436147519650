import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { HomeService } from 'src/app/core/service/home.service';
import { SetUpService } from 'src/app/core/service/set-up.service';

@Component({
  selector: 'app-booked-calendar-details',
  templateUrl: './booked-calendar-details.component.html',
  styleUrls: ['./booked-calendar-details.component.scss']
})
export class BookedCalendarDetailsComponent implements OnInit {
  @ViewChild('tp') _matTooltip!: MatTooltip;
  @ViewChild('customer') _matTooltipCustomer!: MatTooltip;
  calendardata: any;
  calendarId = '';
  minDate: Date = new Date();
  noslots = false;
  slotsData: any[] = [];
  calendarForm!: FormGroup<any>;
  submitted = false;
  errorJson = ERRORJSON[0];
  currentTime = '';
  isMeetingEnabled = false;
  disabledMetting = false;
  rescheduleBtnDisable = false;

  constructor(private homeService: HomeService, private route: ActivatedRoute, private router: Router,
    private modalService: NgbModal, private setupService: SetUpService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.calendarId = params['id'];
        this.getCalendarDetails();
      }
    });
  }

  getCalendarDetails() {
    this.homeService.getPersonalCalendarById(this.calendarId).subscribe((resp: any) => {
      console.log(resp);
      this.calendardata = resp.data;
      const today = new Date();
      const formattedDate = new Date(this.calendardata?.startStr);
      const [hours, minutes] = this.calendardata.start_time.split(':').map(Number);
      formattedDate.setHours(hours);
      formattedDate.setMinutes(minutes);
      if ((formattedDate.toISOString() < today.toISOString())) {
        this.rescheduleBtnDisable = true;
      } else {
        this.rescheduleBtnDisable = false;
      }
      this.onCheckingMeetingLinkStatus();
    });
  }

  onCheckingMeetingLinkStatus() {
    // const options = { year: 'numeric', month: '2-digit', day: '2-digit'} as any;
    const todayDate = this.formatDate(new Date(), 'format');
    const currentTime = this.convertToMinutes(this.formatTime(new Date()));
    const appointmentDate = this.formatDate(new Date(this.calendardata.startStr.split('T')[0]), 'format');
    const appointmentTime = this.calendardata.start_time;
    const originalDate = new Date(`${appointmentDate}T${appointmentTime}`);
    const minutesBeforeTime = this.convertToMinutes(this.formatTime(new Date(originalDate.getTime() - (10 * 60000))));
    const meetingDurations = this.calendardata.duration;
    const minutesAfterTime = this.convertToMinutes(this.formatTime(new Date(originalDate.getTime() + (meetingDurations * 60000))));
    const minutesBeforeDate = this.formatDate(new Date(originalDate.getTime() - (10 * 60000))).split('/').reverse().join('-');
    const minutesAfterDate = this.formatDate(new Date(originalDate.getTime() + (meetingDurations * 60000))).split('/').reverse().join('-');
    if ((new Date(todayDate).getTime() > new Date(minutesAfterDate).getTime())) {
      this.disabledMetting = true;
    }
    if ((todayDate === minutesBeforeDate || todayDate === minutesAfterDate) && (currentTime >= minutesBeforeTime && currentTime <= minutesAfterTime)) {
      this.isMeetingEnabled = true;
    } else {
      this.isMeetingEnabled = false;
    }
  }

  convertToMinutes(time: any) {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 60 + minutes + seconds / 60;
  }

  formatDate(date: any, type?: string) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    if (type) {
      return `${year}-${month}-${day}`;
    }
    return `${day}/${month}/${year}`;
  }

  formatTime(date: any) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  onNaviagtetoContactInnerScreen(userId: string) {
    const queryParams: any = {
      id: userId,
      showtype: 'contact'
    };
    this.router.navigate(['/crm/crm-details'], { queryParams: queryParams });
  }

  onStatusChange() {
    this.homeService.updatePersonalCalendar(this.calendarId, { booking_status: this.calendardata.booking_status }).subscribe((resp: any) => {
      this.getCalendarDetails();
    }, (err: any) => {
      if (err.status === 400) {
        this.getCalendarDetails();
      }
    });
  }

  onCustomerClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltipCustomer.show();
      this._matTooltipCustomer.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }
  onCustomerMouseEnter() {
    this._matTooltipCustomer.message = 'Copy to clipboard';
  }

  openModal(modal: any) {
    this.calendarForm = new FormGroup({
      date: new FormControl('', [Validators.required]),
      start_time: new FormControl('', [Validators.required])
    });
    this.modalService.open(modal, { size: 'md' });
  }

  get f() {
    return this.calendarForm.controls;
  }

  getSlots() {
    const todaysDate = new Date();
    this.currentTime = '';
    if (this.convert(this.calendarForm.value.date) === this.convert(todaysDate)) {
      const currentHour = todaysDate.getHours();
      const currentMinute = todaysDate.getMinutes();
      this.currentTime = (currentHour.toString().length === 1 ? ('0' + currentHour) : currentHour) + ':' + (currentMinute.toString().length === 1 ? ('0' + currentMinute) : currentMinute);
    }
    if (this.calendarForm.value.date) {
      this.noslots = false;
      const body = {
        "staff": this.calendardata.staff,
        "date": this.convert(this.calendarForm.value.date),
        "appointment_duration": Number(this.calendardata.duration),
        "slot_duration": Number(this.calendardata.duration),
        "time_zone": this.calendardata.staff_timezone,
        "type": "meeting"
      };
      this.setupService.getSlotsBasedOnStaffId(body).subscribe((resp: any) => {
        this.slotsData = [];
        resp.data.forEach((element: any) => {
          if (element.slot_status) this.slotsData.push({ ...element, startTime: element.start_time.split('T')[1].slice(0, -3) });
        });
        if (this.slotsData.length === 0) this.noslots = true;
      }, () => {
        this.slotsData = [];
        this.noslots = true;
      });
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  rescheduleCalendar() {
    this.submitted = true;
    if (this.calendarForm.valid) {
      const body = {
        date: this.convert(this.calendarForm.value.date),
        start_time: this.calendarForm.value.start_time
      };
      this.homeService.reschedulePersonalCalendar(this.calendardata.id, body).subscribe(() => {
        this.modalService.dismissAll();
        this.getCalendarDetails();
      });
    }
  }
}
