import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FILEEXTENSIONS, FILESJSON, IMAGE_EXTENSIONS } from 'src/app/core/data/crm_modal_json';
import { AwsUtilsService } from 'src/app/core/service/aws-utils.service';
import { CommonService } from 'src/app/core/service/common.service';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { HomeService } from 'src/app/core/service/home.service';
import { environment } from 'src/environments/environment';
import { ActivityMarkedModelComponent } from '../activity-marked-model/activity-marked-model.component';
import { SettingsService } from 'src/app/core/service/settings.service';
import { ErrorLoadImage } from 'src/app/core/common/common-functions';
import { MatTooltip } from '@angular/material/tooltip';
import { AddTaskComponent } from '../add-task/add-task.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements AfterViewInit {
  status_dropdown = 'pending';
  contentExpand = false;
  notesContent!: string;
  files: File[] = [];
  show_loader = false;
  filesTotal!: number;
  notesPageEvent!: PageEvent;
  notesCurrentPage = 0;
  modalRef: any;
  notesList: any[] = [];
  filesJSON = FILESJSON[0];
  filesListArray: any = [];
  currentPage = 0;
  pageEvent!: PageEvent;
  presignedurl = environment.preSendUrl;
  @Input() taskId!: string;
  @Input() showType!: string;
  @ViewChild('tp') _matTooltip!: MatTooltip;
  taskDetails: any;
  stageList: any;
  notesData!: any;
  edit = false;
  fileName!: string;
  editNoteId!: string;
  selectedNote: any;
  showEdit = false;
  selectedIndex = -1;
  activity_status!: string;
  noteId='';
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  pipeline: any = {};
  mentionConfig: any = {
    triggerChar: '@',
    maxItems: 10,
    labelKey: 'fname',
    mentionSelect: (mention: any) => {
      console.log(mention); return mention['fname'] + " " + mention['lname'] + " ";
    }
  };
  mention: any[] = [];
  currentPageNotesList: any = [];
  copyCurrentPageNotesList: any = [];
  totalNotesList: any = [];
  userLists: any[] = [];
  notes_total_count!: number;
  divInputBox: any;
  checklist_array: any[] = [];
  newItemText: any;
  newItemAdded = false;
  companyUrl = '';
  src = '';
  fileId='';

  constructor(
    private confirmationMsz: ConfirmationMszService,
    private homeService: HomeService,
    private commonService: CommonService,
    private awsUtilService: AwsUtilsService,
    private crmService: CrmService,
    private settingServices: SettingsService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private location:Location
  ) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.showType = params?.show_type;
      this.taskId = params?.id;
      if (this.showType === 'task') {
        this.getTaskById();
      } else {
        this.getActivitiesTaskById();
      }
      if(params && params.noteId || params.fileId){
        this.noteId = params?.noteId;
        this.fileId = params?.fileId;
      }

      if (this.showType === 'MEETING') {
        this.src = window.location.hostname.includes('app.brexa.ai') ? '.brexa.ai' : window.location.hostname.includes('demo.brexa.ai') ? '.demo.brexa.ai' : '.stage.brexa.ai';
        this.getCompanySettingsDetails();
      }
    });
  }

  ngOnInit() {
    console.log(this.taskId, this.showType);
    // if (this.showType === 'task') {
    //   this.getTaskById();
    // } else {
    //   this.getActivitiesTaskById();
    // }
    // if (this.showType === 'MEETING') {
    //   this.src = window.location.hostname.includes('app.brexa.ai') ? '.brexa.ai' : window.location.hostname.includes('demo.brexa.ai') ? '.demo.brexa.ai' : '.stage.brexa.ai';
    //   this.getCompanySettingsDetails();
    // }
  }
  ngAfterViewInit() {
    this.divInputBox = document.getElementById('NAmyContent');
    this.scroolIntoView();
  }

  scroolIntoView() {
    if (this.fileId) {
      setTimeout(() => {
        const pageScreen = document.querySelector('.task-details-screen')!;
        if (pageScreen) {
          pageScreen.scrollTo({ top: pageScreen.scrollHeight + 1200, behavior: 'smooth' });
        }
      }, 300);
    }else if(this.noteId){
      setTimeout(() => {
        const pageScreen = document.querySelector('.task-details-screen')!;
        const notesSec:any = document.querySelector('.notes-sec');
        if (pageScreen && notesSec) {
          pageScreen.scrollTo({ top: notesSec.offsetTop, behavior: 'smooth' });
        }
      }, 300);
    }
  }

  getFilesById(id: string) {
    this.commonService.getFilesById(id).subscribe({
      next: (res: any) => {
        this.filesListArray = [res?.data];
        this.scroolIntoView();
      },
      error: (err) => {
        if (err && err?.data.length === 0) {
          console.log(err);
          this.clearFilter();
        }
      }
    });
  }
  getNotesById(id: string) {
    this.commonService.getNotesById(id).subscribe({
      next: (res: any) => {
        this.currentPageNotesList = [res.data];
        this.copyCurrentPageNotesList = JSON.parse(JSON.stringify(this.currentPageNotesList));
        this.parseNotesData();
        this.scroolIntoView();
      },
      error: (err) => {
        if (err && err?.data.length === 0) {
          console.log(err);
          this.clearFilter();
        }
      }
    });
  }

  clearFilter() {
    const currentUrl = this.location.path();
    let updatedUrl ='';
    this.fileId && (updatedUrl = currentUrl.replace(/[?&]fileId=[^&]+/, '').replace(/[?&]activeId=[^&]+/, ''));
    this.noteId && (updatedUrl = currentUrl.replace(/[?&]noteId=[^&]+/, '').replace(/[?&]noteId=[^&]+/, ''));
    this.location.replaceState(updatedUrl);
    this.fileId && this.getFiles(1);
    this.noteId && this.getNotes(1);
    this.fileId = '';
    this.noteId = '';
  }


  getCompanySettingsDetails() {
    this.settingServices.getCompanySettings().subscribe((resp: any) => {
      if (resp.data) {
        this.companyUrl = `https://${resp.data.slug}${this.src}`;
      }
    });
  }

  onOpenTaskModal() {
    const task_modelref = this.modalService.open(AddTaskComponent, { size: 'lg', scrollable: true });
    task_modelref.componentInstance.is_global = true;
    task_modelref.componentInstance.task = this.taskDetails;
    task_modelref.componentInstance.passEntry.subscribe((data: any) => {
      if (data && data?.update) {
        this.getTaskById();
      }
    });
  }

  getTaskById() {
    this.homeService.getTaskById(this.taskId).subscribe((response: any) => {
      const today = new Date();
      (response.data?.due_date < today.toISOString() && !response.data?.stage_isclosed) ? response.data['due_expired'] = true : response.data['due_expired'] = false;
      this.taskDetails = response.data;

      if (response.data?.tags) {
        this.getTheTagById(response.data?.tags);
      }
      this.getAllStaffInfo('filter');
      this.status_dropdown = this.taskDetails.stage_name;
      this.checklist_array = Array.isArray(this.taskDetails?.checklist) ? this.taskDetails?.checklist : [];
      if (this.taskDetails.source_type === 'deal') {
        this.getCardById();
      } else {
        this.getTaskStages();
      }
      if (this.fileId) {
        this.getFilesById(this.fileId);
        this.getNotes(1);
      } else if(this.noteId) {
        this.getNotesById(this.noteId);
        this.getFiles(1);
      }else{
        this.getNotes(1);
        this.getFiles(1);
      }
    });
  }
  onCheckBoxChange(ev: any, index: number) {
    this.checklist_array[index].value = ev.checked;
    this.taskDetails.checklist = this.checklist_array;
    this.updateTask();
  }

  newCheckListAdded() {
    if (this.newItemText) {
      this.checklist_array.push({ name: this.newItemText, value: false });
      this.taskDetails.checklist = this.checklist_array;
      this.updateTask();
      this.newItemAdded = false;
      this.newItemText = '';
    }
  }

  addItemCheckBox() {
    if (this.newItemAdded) return;
    this.newItemAdded = true;
  }

  getTheTagById(id: any) {
    this.settingServices.getClientTagById(id).subscribe({
      next: (tag_resp: any) => {
        this.taskDetails.tag_name = tag_resp.data[0].name;
        this.taskDetails.colour = tag_resp.data[0].colour;
      }
    });
  }

  getActivitiesTaskById() {
    this.homeService
      .getActivitiesTaskById(this.taskId)
      .subscribe((response: any) => {
        const today = new Date();
        ((response['data']?.due_date < today.toISOString()) && !response['data']?.mark_as_done) ? response['data']['due_expired'] = true : response['data']['due_expired'] = false;
        this.taskDetails = response.data;
        this.getAllStaffInfo('noFilter');
        if (this.taskDetails.mark_as_done) {
          this.status_dropdown = 'Pending';
        }
        this.status_dropdown = this.taskDetails.stage_name;
        // console.log(this.taskDetails);
        this.stageList = [{ name: 'pending' }, { name: 'completed' }];
        this.taskDetails.mark_as_done ? this.status_dropdown = 'completed' : this.status_dropdown = 'pending';
        this.taskDetails.mark_as_done ? this.activity_status = 'completed' : this.activity_status = 'pending';
        this.getNotes(1);
        this.getFiles(1);
      });
  }

  getTaskStages() {
    this.homeService
      .getTaskStages(this.pipeline?.source_id ? this.pipeline : this.taskDetails)
      .subscribe((response: any) => {
        this.stageList = response['data'];
        // console.log(this.stageList);
      });
  }
  getNotes(page: number) {
    this.commonService.getAllNotes(this.taskDetails.id, page).subscribe({
      next: (resp: any) => {
        this.notes_total_count = resp.total;
        this.currentPageNotesList = resp.data;
        this.copyCurrentPageNotesList = JSON.parse(JSON.stringify(this.currentPageNotesList));
        this.parseNotesData();
        // console.log(resp);
        // this.notesData = response;
        this.contentExpand = false;
        // this.totalNotesList.push(...this.currentPageNotesList);
      }

    });
  }

  parseNotesData(){
    this.currentPageNotesList.forEach((element: any) => {
      // console.log(element.notes);
      const inputString = element.notes;
      const regex = /{[^{}]*}/g;
      const modifiedString = inputString && inputString.replace(regex, (match: string) => {
        try {
          const jsonObject: any = JSON.parse(match);
          if (Object.keys(jsonObject).length !== 0 && (jsonObject.fname || jsonObject.lname)) {
            const searchString = jsonObject.fname + ' ' + jsonObject.lname;
            return '<span " style="color: #0072f0;">' +
              searchString + `</span>`;
          } else {
            return match;
          }
        } catch (error) {
          console.error('');
          return match;
        }
      });
      element.notes = modifiedString;
    });
  }

  getAllStaffInfo(type: string) {
    this.settingServices.getStaff_info().subscribe((response: any) => {
      if (type === 'filter') {
        const ownersInfo = response?.data.find((elem: any) => elem.id === this.taskDetails.owner);
        ownersInfo && this.userLists.push(ownersInfo);
        this.taskDetails?.assignee.forEach((elemId: any) => {
          const usersInfo = response?.data.find((elem: any) => {
            if (elem.id !== this.loggedInUser['data'].id) {
              return elem.id === elemId;
            } else {
              return;
            }
          });
          usersInfo && this.userLists.push(usersInfo);
        });
      } else {
        response?.data && (this.userLists = response['data']);
      }
    });
  }

  itemSelected(ev: any) {
    this.mention.push(ev);
    setTimeout(() => {
      const searchString = ev.fname + ' ' + ev.lname + ' ';
      const regx = new RegExp(searchString, 'g');
      this.divInputBox.innerHTML = this.divInputBox.innerHTML.replace(
        regx,
        '<span class="mention" style="color: #0072f0;" contenteditable="false">' + ev.fname + ' ' + ev.lname + ' ' + `</span>`
      );
      this.notesContent = this.divInputBox.innerText;
      const range = document.createRange();
      range.selectNodeContents(this.divInputBox);
      range.collapse(false);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }, 10);
  }
  handleInput() {
    this.notesContent = this.divInputBox.innerText;
  }

  saveNotes(index: number) {
    const body = {
      notes: this.notesContent,
      is_visible: false,
      info: {
        fname: this.loggedInUser.data.fname,
        lname: this.loggedInUser.data.lname
      },
      source_id: this.taskDetails.id,
      source_type: 'task'
    };
    const modifiedStr = this.getMentionedNotes();
    this.homeService.saveNotes({ ...body, notes: modifiedStr }).subscribe(() => {
      this.mention = [];
      this.notesContent = '';
      this.divInputBox.innerHTML = '';
      this.getNotes(1);
    });
  }
  updateNotes(index: number) {
    this.selectedNote.notes = this.notesContent;
    const modifiedStr = this.getMentionedNotes();
    this.homeService.editNotes({ ...this.selectedNote, notes: modifiedStr }).subscribe((response: any) => {
      this.mention = [];
      this.edit = false;
      this.notesContent = '';
      this.showEdit = false;
      this.contentExpand = false;
      this.updateDivBox('NA');
      const pTag = document.getElementById(index + 'pTag');
      pTag && (pTag.style.display = 'block');
      this.divInputBox.innerHTML = '';
      this.getNotes(1);
    });
  }

  updateDivBox(index: any) {
    setTimeout(() => {
      this.divInputBox = document.getElementById(index + 'myContent');
      this.divInputBox.innerHTML = '';
      this.contentExpand = false;
      this.notesContent = '';
      this.selectedIndex = -1;
      this.edit = false;
    });
  }

  getMentionedNotes() {
    let modifiedStr: string = this.notesContent + ' ';
    this.mention.forEach((element: any) => {
      const searchedStr = element['fname'] + ' ' + element['lname'];
      if (modifiedStr.includes(searchedStr)) {
        const obj = {
          id: element.id,
          fname: element.fname,
          lname: element.lname
        };
        const test = modifiedStr.replace(searchedStr, JSON.stringify(obj));
        modifiedStr = test;
      }
    });
    return modifiedStr;
  }

  clearNotes(index: number) {
    this.updateDivBox('NA');
    this.showEdit = false;
    this.edit = false;
    const pTag = document.getElementById(index + 'pTag');
    pTag && (pTag.style.display = 'block');
    this.notesContent = '';
    this.mention = [];
    this.divInputBox.innerHTML = '';
    this.contentExpand = false;
  }

  getFiles(page: number) {
    this.homeService
      .getFiles(this.taskDetails.id, page)
      .subscribe((response: any) => {
        this.filesTotal = response.total;
        this.filesListArray = response.data;
      });
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getFiles(this.currentPage);
  }

  fileSubmit() {
    const payload = {
      source_id: this.taskDetails.id,
      source_type: 'task',
      name: this.files[0].name,
      is_visible: true,
      media_url: this.fileName
    };
    this.show_loader = false;
    this.commonService.fileUploadWorke(payload).subscribe(() => {
      this.getFiles(1);
      this.files = [];
      this.show_loader = false;
    }, () => {
      this.files = [];
      this.show_loader = false;
      this.show_loader = false;
    });
  }
  expandContent(event: any) {
    this.contentExpand = true;
    const divElement = event.target as HTMLElement;
    divElement.focus();
  }

  updateTask() {
    this.homeService.editTask(this.taskDetails).subscribe((response: any) => {
      this.getTaskById();
    });
  }

  onSelectStatus(event: any) {
    if (this.showType === 'task') {
      if (event.value === this.taskDetails.stage_name) {
        //@typescript-eslint/no-empty-function
      } else {
        this.stageList.forEach((element: any) => {
          if (element.name === event.value) {
            event.id = element.id;
          }
        });
        const body = {
          id: this.taskDetails.id,
          source_id: this.taskDetails.source_id,
          source_type: this.taskDetails.source_type,
          current_stage: event.id
        };
        this.homeService.editTask(body).subscribe((response: any) => {
          this.getTaskById();
        });
      }
    } else {
      if (event.value !== this.activity_status) {
        this.updateActivity(this.taskDetails.id);
        // const body = {
        //   id: this.taskDetails.id,
        //   mark_as_done: event.value === 'pending' ? false : true,
        //   outcome: event.value === 'pending' ? 'pending' : 'completed'
        // };
        // this.homeService.editActivity(body).subscribe((response: any) => {
        //   this.getActivitiesTaskById();
        // });
      }
    }

  }

  updateActivity(id: any) {
    this.modalRef = this.modalService.open(ActivityMarkedModelComponent, { size: 'md', centered: true });
    this.modalRef.componentInstance.data = { id };
    this.modalRef.result.then((data: any) => {
      data && this.getActivitiesTaskById();
    }).catch(() => {
      this.taskDetails.mark_as_done ? this.status_dropdown = 'completed' : this.status_dropdown = 'pending';
    });
  }

  onSelect(event: any) {
    this.files = [...event.addedFiles];
    const media_dropzone = document.getElementById('media-dropzone');
    media_dropzone && (media_dropzone.style['height'] = 'fit-content');
    const files = this.files[0]?.name?.split('.');
    const fileExt = files && files.length > 0 ? files[files?.length - 1] : '';
    if (!IMAGE_EXTENSIONS.includes(fileExt)) {
      setTimeout(() => {
        const imageTag = media_dropzone?.getElementsByTagName('img');
        const fileExtention = FILEEXTENSIONS.find((elem: any) => elem.type === fileExt);
        if (imageTag && imageTag.length > 0 && fileExtention) {
          imageTag[0].src = fileExtention.file;
        } else if (imageTag && imageTag.length > 0) {
          imageTag[0].src = FILEEXTENSIONS[4].file;
        }
      }, 50);
    }
  }

  onErrorLoadImage(event: any, target: any, file: any) {
    event.target.src = ErrorLoadImage(target, file);
  }


  onUpload() {
    if (!this.files[0]) return;
    this.show_loader = true;
    this.awsUtilService
      .getUploadFileLink(this.files[0].type, this.files[0].name.split('.')[1])
      .subscribe((resp) => {
        // console.log("first api - ", resp);
        this.fileName = this.presignedurl + resp.data.file_name;
        this.awsUtilService
          .uploadFile(this.files[0], resp.data.url)
          .subscribe(() => {
            //@typescript-eslint/no-empty-function
            this.fileSubmit();
          }, () => {
            this.files = [];
          });
      }, () => {
        this.files = [];
      });
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  editNotes(note: any, index: number) {
    this.contentExpand = true;
    this.showEdit = true;
    this.edit = true;
    this.selectedNote = note;
    this.notesContent = note.notes;
    this.editNoteId = note.id;
    // const ele = document.querySelector('.notes-sec');
    // ele && ele.scrollTo({ top: 0, behavior: 'smooth' });
    this.selectedIndex = index;
    const pTag = document.getElementById(index + 'pTag');
    pTag && (pTag.style.display = 'none');
    setTimeout(() => {
      this.divInputBox = document.getElementById(index + 'myContent');
      const dummyText = note.notes;
      const tempElement = document.createElement('div');
      tempElement.innerHTML = dummyText;
      this.notesContent = tempElement.innerText;
      this.divInputBox.innerHTML = this.notesContent;
      const inputString = this.copyCurrentPageNotesList[index].notes;
      const regex = /{[^{}]*}/g;
      const jsonObjects = inputString && inputString.match(regex);
      if (jsonObjects) {
        jsonObjects.forEach((element: any) => {
          try {
            const parsedObject = JSON.parse(element);
            const searchString = parsedObject.fname + ' ' + parsedObject.lname;
            const regx = new RegExp(searchString, 'g');
            this.divInputBox.innerHTML = this.divInputBox.innerHTML.replace(regx,
              '<span class="mention" style="color: #0072f0;" contenteditable=false>' + searchString + `</span>`);
            this.mention.push(parsedObject);
          } catch (error) {
            console.error('');
          }
        });
      }
    });
    this.contentExpand = true;
  }

  deleteNote(id: any) {
    this.updateDivBox('NA');
    this.showEdit = false;
    this.homeService.deleteNotes(id).subscribe((response: any) => {
      this.getNotes(1);
    });
  }
  deleteFile(id: any) {
    this.homeService.deleteFiles(id).subscribe((response: any) => {
      this.getFiles(1);
    });
  }
  confirmDeleteFile(fileId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteFile(fileId);
      }
    });
  }
  confirmDelete(noteId: string) {
    this.confirmationMsz.delete().then((result) => {
      if (result.value) {
        this.deleteNote(noteId);
      }
    });
  }

  notesPage(e: PageEvent) {
    this.notesPageEvent = e;
    // console.log("pagination event", e, lead_type);
    if (this.notesPageEvent.pageIndex > this.notesPageEvent.previousPageIndex!) {
      this.notesCurrentPage = this.notesPageEvent.pageIndex + 1;
    } else if (this.notesPageEvent.pageIndex < this.notesPageEvent.previousPageIndex!) {
      this.notesCurrentPage -= 1;
    }
    this.getNotes(this.notesCurrentPage);
  }
  onClipboardCopy(e: any) {
    setTimeout(() => {
      this._matTooltip.show();
      this._matTooltip.message = e ? 'Copied!' : 'Copy to clipboard';
    });
  }

  getCardById() {
    this.crmService.getCardsById(this.taskDetails.source_id).subscribe((cards: any) => {
      this.pipeline.source_id = cards.data[0].pipeline;
      this.pipeline.source_type = this.taskDetails.source_type;
      this.getTaskStages();
    });
  }
}