import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { PaymentsService } from 'src/app/core/service/payments.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {
  errorjson = ERRORJSON[0];
  keysForm!: FormGroup;
  StripeKeysForm!: FormGroup;
  stripeKeys: any = {};
  razorPayKeys: any = {};
  razorSecretKey = '';
  stripeSecretKey = '';
  submitted = false;
  s_submitted = false;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  payment_type!: string;
  constructor(
    private modal: NgbModal,
    private _paymentService: PaymentsService,
    private confirmationMsz: ConfirmationMszService
  ) {
    this.getKeys('stripe');
    this.getKeys('razorpay');
  }


  initializeRazorpayForm() {
    this.keysForm = new FormGroup({
      id: new FormControl(""),
      secret_key: new FormControl("", Validators.required),
      api_key_id: new FormControl("", Validators.required),
      mode: new FormControl("STAGE", Validators.required),
      currency: new FormControl("INR", Validators.required),  // [TODO]: change this to get from general settings
      gateway_name: new FormControl(this.payment_type)
    });
  }

  initializeStripeForm() {
    this.StripeKeysForm = new FormGroup({
      id: new FormControl(""),
      sk_key: new FormControl("", Validators.required),
      pk_key: new FormControl("", Validators.required),
      mode: new FormControl("STAGE", Validators.required),
      currency: new FormControl("INR", Validators.required),  // [TODO]: change this to get from general settings
      gateway_name: new FormControl(this.payment_type)
    });
  }

  get f() {
    return this.keysForm.controls;
  }

  get s_f() {
    return this.StripeKeysForm.controls;
  }

  openModal(ref: any, type: string) {
    this.payment_type = type;
    if (this.payment_type === 'stripe' && this.stripeKeys.data) {
      this.initializeStripeForm();
      this.StripeKeysForm.patchValue(
        {
          id: this.stripeKeys?.data ? this.stripeKeys.data.gateway_id : '',
          pk_key: this.stripeKeys?.data ? this.stripeKeys.data.publishable_key : '',
          gateway_name: type
        });
      this.stripeSecretKey = this.stripeKeys.data?.sk_key;

    } else if (this.payment_type === 'razorpay' && this.razorPayKeys.data) {
      this.initializeRazorpayForm();
      this.keysForm.patchValue(
        {
          id: this.razorPayKeys?.data ? this.razorPayKeys?.data?.gateway_id : '',
          api_key_id: this.razorPayKeys?.data ? this.razorPayKeys.data.api_key_id : '',
          gateway_name: type
        });
      this.razorSecretKey = this.razorPayKeys.data?.secret_key;
    }
    this.modal.open(ref, { size: 'md' });
  }

  getKeys(gateway_type: string) {
    this._paymentService.getPaymentKeys(gateway_type).subscribe((resp: any) => {
      if (gateway_type === 'stripe') {
        this.stripeKeys = resp;
      } else if (gateway_type === 'razorpay') {
        this.razorPayKeys = resp;
      }
    });
  }

  onKeySave() {
    if (this.payment_type === 'stripe') {
      this.s_submitted = true;
      if (this.StripeKeysForm.invalid) {
        this.StripeKeysForm.markAllAsTouched();
        return;
      }
    } else if (this.payment_type === 'razorpay') {
      this.submitted = true;
      if (this.keysForm.invalid) {
        this.keysForm.markAllAsTouched();
        return;
      }
    }

    if (this.payment_type === 'stripe') {
      if (this.stripeKeys.data.gateway_id) {
        this._paymentService.updatePaymentKeys(this.stripeKeys.data.gateway_id, this.StripeKeysForm.value).subscribe(() => {
          this.getKeys('stripe');
          this.modal.dismissAll();
          this.s_submitted = false;
        });
      } else {
        this._paymentService.postPaymentKeys(this.StripeKeysForm.value).subscribe(() => {
          this.getKeys('stripe');
          this.modal.dismissAll();
          this.s_submitted = false;
        });
      }
    } else if (this.payment_type === 'razorpay') {
      if (this.razorPayKeys.data.gateway_id) {
        this._paymentService.updatePaymentKeys(this.razorPayKeys.data.gateway_id, this.keysForm.value).subscribe(() => {
          this.getKeys('razorpay');
          this.modal.dismissAll();
          this.submitted = false;
        });
      } else {
        this._paymentService.postPaymentKeys(this.keysForm.value).subscribe(() => {
          this.getKeys('razorpay');
          this.modal.dismissAll();
          this.submitted = false;
        });
      }
    }

  }

  disconnectPayment(type: string, gateway_id: string) {
    this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: 'You want to disconnect!', button: 'Yes Disconnect!' }).then((result) => {
      // let gateway_id: string;
      if (result.value) {
        this._paymentService.deletePaymentKeys(gateway_id, type).subscribe(() => {
          this.getKeys(type);
        });
      }
    });
  }
}
