<div class="external-link">
    <div>
        <h6 class="text-danger font-size-18 p-3 text-center">External Link warning</h6>

    </div>
    <div class="px-3 mx-1 font-size-14" style="color: #626262;">
        <p>You are about to visit an external link and we would like to pause for a moment out of an abundance of
            caution. Links to
            proof-of-concepts are generally benign but may often be accompanied by unintentional consequences.
        </p>
        <p class="mb-0">
            At a minimum, you should consider using a separate browser or virtual machine before proceeding.</p>
        <div class="link-box mt-2 d-flex align-items-center justify-content-center position-relative"
            style="padding: 12px 30px 12px 12px;">
            {{linkUrl}}
            <mat-icon (cdkCopyToClipboardCopied)="onClipboardCopy($event)" matTooltip="Copy to clipboard"
                matTooltipPosition="above" #tp="matTooltip" [cdkCopyToClipboard]="linkUrl">content_copy</mat-icon>
        </div>
    </div>
    <div class="d-flex justify-content-end px-3">
        <button class="btn btn-primary d-flex align-items-center font-size-13 my-3 mx-1"
            (click)="ngbActiveModal.dismiss()">cancel</button>
        <button class="btn btn-primary d-flex align-items-center font-size-13 my-3 mx-1 proceed-btn"
            (click)="ngbActiveModal.close('true')">Proceed</button>
    </div>
</div>