import { Component } from '@angular/core';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-subscription-plan-details',
  templateUrl: './subscription-plan-details.component.html',
  styleUrls: ['./subscription-plan-details.component.scss']
})
export class SubscriptionPlanDetailsComponent {
  plan_details: any;
  table_cols =["Feature","Limit","Renewed On","Next Renewal On"];
  details_fetch: any;

  constructor(private service: SettingsService) { 
    this.getPlanDetails();
  }

  getPlanDetails() {
    this.service.getcurrenPlan_usage().subscribe((resp: any)=>{
      console.log(resp,"resp");
      this.plan_details = resp.data.plan_current_usage;
      this.details_fetch = Object.assign({},resp.data.plan_current_usage);
      delete this.details_fetch.month_start_date;
      delete this.details_fetch.month_end_date;
    });
  }

  replaceUnderscore(key: any) {
    if (typeof key === 'string') {
      return key.replace(/_/g, ' ');
    }
    return String(key);
  }
}
