import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ERRORJSON } from 'src/app/core/data/error_json';
import { dayoffJson } from 'src/app/core/data/settings_json';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-day-off-model',
  templateUrl: './day-off-model.component.html',
  styleUrls: ['./day-off-model.component.scss']
})
export class DayOffModelComponent implements OnInit {
  dayoffForm!: FormGroup;
  dayoffjson = dayoffJson[0];
  errorjson = ERRORJSON[0];
  dayoff: any;
  submitted = false;
  @Input() dayoff_type: any;
  @Input() staffId: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  currentDate = new Date();

  constructor(public ngbActiveModal: NgbActiveModal, private settingService: SettingsService) { }

  ngOnInit() {
    this.intilizeForm();
  }

  intilizeForm(data?: any) {
    this.dayoffForm = new FormGroup({
      id: new FormControl(data ? data.id : ''),
      title: new FormControl(data ? data.title : '', [Validators.required]),// Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      start_date: new FormControl(data ? data.start_date : '', Validators.required),
      end_date: new FormControl(data ? data.end_date : '', Validators.required),
      start_time: new FormControl(data ? data.start_time : '', Validators.required),
      end_time: new FormControl(data ? data.end_time : '', Validators.required),
      type: new FormControl(data ? data.type : this.dayoff_type),
      source_id: new FormControl(data ? data.source_id : (this.staffId ? this.staffId : this.loggedInUser.data.organisation))
    });
  }

  get f() {
    return this.dayoffForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.dayoffForm.valid) {
      const body = {
        ...this.dayoffForm.value,
        start_date: this.convert(this.dayoffForm.value.start_date),
        end_date: this.convert(this.dayoffForm.value.end_date)
      };
      // if (this.dayoffForm.value.id) {
      //   console.log(body,"body");

      //   this.settingService.putDayoff(this.dayoffForm.value).subscribe(() => {
      //     this.ngbActiveModal.dismiss();
      //   });
      // } else {
      this.settingService.postDayoff(body).subscribe(() => {
        this.passEntry.emit(this.dayoffForm.value.type);
        this.ngbActiveModal.dismiss();
      });
      // }
    }
  }

  convert(str: any) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
