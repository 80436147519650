import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-support-bot',
  templateUrl: './support-bot.component.html',
  styleUrls: ['./support-bot.component.scss']
})
export class SupportBotComponent implements OnInit, OnDestroy {
  iframe: any;
  iframeLoaded = false;
  scriptElement: any;
  // @ViewChild('iframeRef') iframe!: ElementRef;
  userDetails = JSON.parse(sessionStorage.getItem('currentUser')!);

  constructor(public modal: NgbModal, private renderer: Renderer2, private elementRef: ElementRef) { }
  myLoadEvent() {
    this.iframeLoaded = true;
  }

  ngOnInit() {
    const widgetScript = this.renderer.createElement('script');
    const widgetContainer = this.elementRef.nativeElement.querySelector('#widgetContainer');

    widgetScript.setAttribute('data-orgid', 'ea9a361d-5838-4524-9295-965e14dfdaf7');
    widgetScript.setAttribute('data-chatbotid', '66e1b199836bf7a85c6e0e20');
    widgetScript.setAttribute('data-orgname', 'brexa');
    widgetScript.setAttribute('data-appId', '66e1b199836bf7a85c6e0e23');
    widgetScript.setAttribute('environment', environment.messageUrl);
    widgetScript.setAttribute('data-userorgid', this.userDetails.data.organisation);
    widgetScript.setAttribute('data-userid', this.userDetails.data.id);

    widgetScript.src = 'assets/scripts/widgetScript.js';

    widgetScript.onload = () => {
      console.log('Script loaded successfully: assets/scripts/widgetScript.js');
    };

    widgetScript.onerror = (error: any) => {
      console.error('Script loading error assets/scripts/widgetScript.js', error);
    };
    this.renderer.appendChild(widgetContainer, widgetScript);
    this.scriptElement = widgetScript;

  }
  ngOnDestroy(): void {
    this.removingScriptFromDOM();
  }

  onClosingModal() {
    this.removingScriptFromDOM();
    this.modal.dismissAll('Cross click');
  }
  removingScriptFromDOM() {
    if (this.scriptElement) {
      const widgetContainer = this.elementRef.nativeElement.querySelector('#widgetContainer');
      this.renderer.removeChild(widgetContainer, this.scriptElement);
      this.scriptElement = null;
      document.querySelector('#chat-wrapper')?.remove();
    }
  }

}