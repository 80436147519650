<div class="mail-template mt-4 px-4 pb-3">
    <div class="header">
        <div class="modal-header bg-light px-4 pb-3">
            <h5 class="text-white font-size-14 mb-0">{{showSchedule ? 'Schedule Message' : 'New Message' }} </h5>
            <mat-icon class="text-white cursor" (click)="activeModal.close()">close</mat-icon>
        </div>
    </div>
    <div class="d-flex align-items-center border-radius-4 email-to mt-3 mb-3">
        <p class="mb-0 me-3">To</p>
        <p class="mb-0 text-primary"><b>{{source_email}}</b></p>
    </div>
    <!-- <mat-divider class="my-0 mx-4"></mat-divider> -->
    <form [formGroup]="mail_template">
        <div formGroupName="message">
            <div class="pt-1">
                <div class="d-flex align-items-center border-radius-4 email-to mt-3 mb-3">
                    <p class="mb-0 me-3">From</p>
                    <p class="mb-0 text-primary"><b>{{emailAddress}}</b></p>
                </div>
            </div>
            <p class="d-flex align-items-center" *ngIf="!showCc || !showBcc">
                <span (click)="onAddRemoveCcBcc('cc')" *ngIf="!showCc"
                    class="d-flex align-items-center custom-hover-bg py-2">
                    <mat-icon class="ms-1">add</mat-icon> <span class="me-2 ms-1 cursor">Add Cc</span>
                </span>
                <span (click)="onAddRemoveCcBcc('bcc')" *ngIf="!showBcc"
                    class="d-flex align-items-center custom-hover-bg py-2">
                    <mat-icon class="ms-1">add</mat-icon><span class="cursor ms-1 me-2">Add Bcc</span>
                </span>
            </p>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3" *ngIf="showCc">
                <label class="fw-600 mb-2 text-primary">Cc <small class="text-danger">*</small></label>
                <div class="d-flex align-items-center">
                    <ng-select class="form-control" bindValue="email" bindLabel="email" [items]="staffMembers"
                        [addTag]="false" [multiple]="true" placeholder="Select staff's email"
                        formControlName="cc_recipients" (input)="onEmailInputChange($event)">
                    </ng-select>
                    <mat-icon class="ms-3 pointer" (click)="onAddRemoveCcBcc('cc')">delete</mat-icon>
                </div>
                <mat-error class="mt-1 error d-flex align-item-center" *ngIf="f['cc_recipients'].errors && submited">
                    <mat-icon class="font-size-12 me-1 w-auto h-auto my-auto">info</mat-icon>
                    <span *ngIf="f['cc_recipients'].errors['required']">Cc field required</span>
                </mat-error>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3" *ngIf="showBcc">
                <label class="fw-600 mb-2 text-primary">Bcc <small class="text-danger">*</small></label>
                <div class="d-flex align-items-center">
                    <ng-select class="form-control" bindValue="email" bindLabel="email" [items]="staffMembers"
                        [addTag]="false" [multiple]="true" placeholder="Select staff's email"
                        formControlName="bcc_recipients" (input)="onEmailInputChange($event)">
                    </ng-select>
                    <mat-icon class="ms-3 pointer" (click)="onAddRemoveCcBcc('bcc')">delete</mat-icon>
                </div>
                <mat-error class="mt-1 error d-flex align-item-center" *ngIf="f['bcc_recipients'].errors && submited">
                    <mat-icon class="font-size-12 me-1 w-auto h-auto my-auto">info</mat-icon>
                    <span *ngIf="f['bcc_recipients'].errors['required']">Bcc field required</span>
                </mat-error>
            </div>
            <div class="pt-1">
                <label class="fw-600 mb-2 w-100">Subject</label>
                <mat-form-field appearance="outline" class="w-100 subject-field">
                    <input class="border-0 bg-white w-100" type="text" style="outline: none" matInput
                        placeholder="Subject" formControlName="subject">
                </mat-form-field>
            </div>
            <div class="modal-body px-0 pb-1 pt-0">
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <label class="fw-600">Body <small class="text-danger">*</small></label>
                    <button class="btn btn-primary font-size-12 px-3" style="height: auto;" (click)="onOpenAIModal()">
                        <img src="assets/images/magic-pen.svg" width="24px" height="24px" class="me-1" alt="">Auto
                        Generate</button>
                </div>

                <ckeditor [editor]="Editor" formControlName="body" class="ckeditor-height" #editorComponent
                    [config]="ckconfigQue">
                </ckeditor>
                <mat-error class="error text-danger d-flex mt-1" *ngIf="submited && f['body'].errors">
                    <mat-icon class="font-size-12 me-1 my-auto info w-auto h-auto">info</mat-icon>
                    <span *ngIf="f['body'].errors['required']">
                        Message Body required </span>
                </mat-error>
                <div class="d-flex align-items-start mt-3 pt-2 w-100">
                    <!-- <mat-form-field appearance="outline" class="me-3" style="width: 50%;" *ngIf="showSchedule">
                    <input matInput [ngxMatDatetimePicker]="picker" formControlName="schedule_mail" [min]="minDate"
                        placeholder="Choose a date & time">
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                </mat-form-field> -->
                    <button (click)="onMailSubmit('sent')" class="btn btn-primary font-size-12 px-3">{{showSchedule ?
                        'Schedule' :
                        'Send'}}</button>
                    <!-- <button type="text" (click)="onSaveToDraft()" class="btn btn-primary font-size-12 px-3 ms-2">Save as
                        draft</button> -->
                </div>
            </div>
        </div>
    </form>
</div>