import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { CompaniesComponent } from './companies/companies.component';
import { ContactsComponent } from './contacts/contacts.component';
import { LeadsComponent } from './leads/leads.component';
import { BulkImportComponent } from '../shared/bulk-import/bulk-import.component';
import { ImportedFilesComponent } from '../shared/imported-files/imported-files.component';
import { AuthGuardService } from 'src/app/core/service/auth-guard.service';
import { map } from 'rxjs';

const routes: Routes = [
  {
    path: 'contacts', component: ContactsComponent, canActivate: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('contact').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'companies', component: CompaniesComponent,
    canActivate: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('company').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'leads', component: LeadsComponent,
    canActivate: [() => {
      const router: Router = inject(Router);
      return inject(AuthGuardService).featureAccessPermission('enquiry').pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
    }]
  },
  {
    path: 'crm-details', loadChildren: () => import('./management-modal/management-modal.module').then(m => m.ManagementModalModule), canMatch: [() => {
      const searchParams = new URLSearchParams(new URL(window.location.href).search);
      const module = searchParams.get('showtype');
      let featureName: any;
      //Logic for Copy pasting URL. No click validation for each crm module files
      if (module) {
        if (module === 'lead') {
          featureName = 'enquiry';
        } else if (module === 'deal') {
          featureName = 'pipeline';
        } else {
          featureName = module;
        }
        const router: Router = inject(Router);
        return inject(AuthGuardService).featureAccessPermission(featureName).pipe(map(is_assessable => is_assessable || router.createUrlTree(['/unauthorised'])));
      } else {
        return true;
      }

    }]
  },
  {
    path: 'import', component: BulkImportComponent
  },
  {
    path: 'import-files', component: ImportedFilesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CrmRoutingModule { }
