//Storing Filter Data
export interface FilterInfo {
  searchKey: string,
  sortValue: any,
  mainFilter: any,
  dropdown_filter: string,
  dropdown_filter_value: string,
  page_name: string,
  currentPageIndex: number,
  tab?: string
}
export class filterConstant {
  filterInfoData: FilterInfo = {
    searchKey: '',
    sortValue: null,
    mainFilter: null,
    dropdown_filter: '',
    dropdown_filter_value: '',
    page_name: '',
    currentPageIndex: 0,
    tab: ''
  };
  static filterInfoData: any;
}
