export const COMPANIESJSON = [
  {
    heading_label: 'Companies',
    Filter_label: 'Filters',
    // back_label: 'Back',
    search_placeholder: 'Search column',
    label_add_companie: 'Add Company',
    modal_heading: 'Add New Company',
    feild_company_name: 'Company Name',
    feild_webiste: 'Website',
    feild_owner: 'Owner',
    modal_settings: 'Customize your companies form',
    btn_cancel: 'Close',
    btn_save: 'Save',
    label_import: "Import"
  }
];