<div class="modal-container h-100">
    <div class="modal-header">
        <h5 class="modal-title font-size-18 fw-800 text-primary">{{settingsformJson.heading}}</h5>
        <!-- <button type="button" class="btn-close" aria-label="Close"></button> -->
    </div>

    <div class="modal-body h-75 overflow-auto">
        <!-- <div class="row">
            <mat-label class="mb-2 font-size-14 fw-600">Feild Properties</mat-label>
            <mat-form-field appearance="outline" class="w-65">
                <mat-select placeholder="Select type" [(ngModel)]="selectedFieldType">
                    <mat-option *ngFor="let opt of options" value="{{opt.value}}">{{opt.type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <form [formGroup]="fieldoption" *ngIf="type !== 'dynamic_app'">
            <div class="row mt-3">
                <div class="col-4 m-auto text-end">
                    <label> {{settingsformJson.label_name}} </label>
                </div>
                <div class="col-8 m-auto">
                    <div>
                        <mat-form-field appearance="outline" class="mat-hint-none">
                            <input class="field mb-3" style="outline: none" matInput
                                placeholder="{{settingsformJson.name}}" type="text" formControlName="label">
                        </mat-form-field>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'CHECK_BOX'" formArrayName="options">
                        <div class="d-inline-flex align-items-center" *ngFor="let item of FormOpt.controls;let i =index"
                            style="margin: 5px 0px;">
                            <!-- <input class="field propertyfeilds" type="checkbox">&nbsp; -->
                            <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                <input class="checkbox" [formControlName]="i" type="text" style="outline: none" matInput
                                    placeholder="{{settingsformJson.option_name}}" (input)="onInputChange()">
                            </mat-form-field>&nbsp;
                            <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                class="text-light">{{settingsformJson.icon_close}}</mat-icon>
                        </div>
                        <br>
                        <div class="d-inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <input class="field m-auto" type="checkbox" style="width: 13px;height: 15px;">&nbsp;
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'RADIO_BUTTON'" formArrayName="options">
                        <div class="d-inline-flex align-items-center" *ngFor="let item of FormOpt.controls;let i =index"
                            style="margin: 5px 0px;">
                            <!-- <input class="field" type="radio" style="width: 17px;height: 17px;">&nbsp; -->
                            <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                <input class="checkbox" [formControlName]="i" type="text" style="outline: none" matInput
                                    placeholder="{{settingsformJson.option_name}}" (input)="onInputChange()">
                            </mat-form-field>&nbsp;
                            <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                class="text-light">{{settingsformJson.icon_close}}</mat-icon>
                        </div>
                        <br>
                        <div class="d-inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <input class="field m-auto" type="radio" style="width: 17px;height: 17px;">&nbsp;
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'SELECT_DROPDOWN' && fieldoption.value.label !== 'Service' && fieldoption.value.original_label !== 'company' && fieldoption.value.original_label !== 'product_category' &&fieldoption.value.original_label !== 'product' 
                        && fieldoption.value.original_label !== 'scheduler_category' && fieldoption.value.original_label !== 'scheduler' && fieldoption.value.original_label !== 'service' && fieldoption.value.original_label !== 'service_category' && fieldoption.value.original_label !== 'priority' && fieldoption.value.original_label !== 'currency' && fieldoption.value.original_label !== 'country'"
                        formArrayName="options">
                        <ol class="mb-0 p-0">
                            <li *ngFor="let item of FormOpt.controls;let i =index" class="d-flex mb-2">
                                <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                    <input class="checkbox" [formControlName]="i" type="text" style="outline: none"
                                        matInput placeholder="{{settingsformJson.option_name}}"
                                        (input)="onInputChange()">
                                </mat-form-field>&nbsp;
                                <mat-icon (click)="deleteFormOption(item,i)" matSuffix
                                    class="text-light">{{settingsformJson.icon_close}}</mat-icon>

                            </li>
                        </ol>
                        <div style="display: inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <div *ngIf="fieldoption.value.type === 'MULTI_SELECT_DROPDOWN' && fieldoption.value.label !== 'Service' && fieldoption.value.original_label !== 'company' && fieldoption.value.original_label !== 'product_category' &&fieldoption.value.original_label !== 'product' 
                        && fieldoption.value.original_label !== 'scheduler_category' && fieldoption.value.original_label !== 'scheduler' && fieldoption.value.original_label !== 'service' && fieldoption.value.original_label !== 'service_category' && fieldoption.value.original_label !== 'priority' && fieldoption.value.original_label !== 'currency' && fieldoption.value.original_label !== 'country'"
                        formArrayName="options">
                        <ol class="mb-0 p-0">
                            <li *ngFor="let item of FormOpt.controls;let i =index" class="d-flex mb-2">
                                <mat-form-field appearance="outline" class="mat-hint-none mb-2">
                                    <input class="checkbox" [formControlName]="i" type="text" style="outline: none"
                                        matInput placeholder="{{settingsformJson.option_name}}"
                                        (input)="onInputChange()">
                                </mat-form-field>&nbsp;
                            </li>
                        </ol>
                        <div style="display: inline-flex" *ngIf="checkboxesDataList.length<=500">
                            <button class="font-size-14 btn btn-light"
                                (click)="addNew(true)">{{settingsformJson.add_option}}</button>
                        </div>
                    </div>
                    <!-- <div *ngIf="fieldoption.value.type === 'SELECT_DROPDOWN' && fieldoption.value.label === 'Service'">
                        <mat-form-field appearance="outline" class="w-65">
                            <mat-select placeholder="{{settingsformJson.placeholder_select}}" multiple>
                                <mat-option *ngFor="let item of serviceData"
                                    value="{{item.id}}">{{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div style="display: inline-flex">
                            <button class="font-size-14 btn btn-light">{{settingsformJson.create_service}}</button>
                        </div>
                    </div> -->

                    <div
                        *ngIf="formItem.key === 'APPOINTMENT_CATEGORIES' || formItem.key === 'APPOINTMENT' || formItem.key === 'PRODUCT_CATEGORIES' || formItem.key === 'PRODUCT' || formItem.key === 'SERVICE' || formItem.key === 'SERVICE_CATEGORIES'">
                        <ng-select bindLabel="mail" bindValue="mail" formControlName="select_options" [multiple]="true"
                            placeholder="Select Options">
                            <ng-option *ngFor="let item of API_LIST"
                                value='{"id":"{{item.id}}","name":"{{item.name}}"}'>{{item.name}}</ng-option>
                        </ng-select>

                    </div>

                </div>
            </div>
            <div class="row mt-3">
                <div class="col-4 m-auto text-end">
                    <label> {{settingsformJson.placeholder_name}} </label>
                </div>
                <div class="col-8 m-auto">
                    <mat-form-field appearance="outline" class="mat-hint-none">
                        <input class="field mb-3" style="outline: none" matInput
                            placeholder="Enter Placeholder" type="text" formControlName="place_holder">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2" *ngIf="!fieldoption.value.is_default">
                <div class="col-md-5 text-end">
                    <input type="checkbox" value="false" class="form-checkbox font-size-18 checkbox-design"
                        formControlName="is_mandatory" />
                    <span class="ms-1 mb-2">
                        Required <mat-icon class="font-size-16">info</mat-icon>
                    </span>
                </div>
            </div>
            <div class="row mt-4"
                *ngIf="formItem.is_form_field && (fieldoption.value.type === 'TEXT' || fieldoption.value.type === 'DATETIME' || fieldoption.value.type === 'DATE_TIME' || fieldoption.value.type === 'DATE' || fieldoption.value.type === 'TEXT_AREA' || fieldoption.value.type === 'NUMBER')"
                formArrayName="variables">
                <div class="col-4 m-auto text-end">
                    <label> Contact Variables </label>
                </div>
                <div class="col-md-8 m-auto">
                    <mat-form-field appearance="outline" formGroupName="0" class="w-80 mat-hint-none">
                        <mat-select placeholder="Select Variable" (selectionChange)="onVaribleChange($event)"
                            formControlName="original_label">
                            <mat-option *ngIf="contactFields.length === 0">No variable</mat-option>
                            <mat-option *ngFor="let opt of contactFields;let i =index" [disabled]="opt?.disable"
                                value="{{opt.original_label}}">{{opt.label.replace("_"," ")}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
        <p *ngIf="showError" class="text-center mt-3 text-danger bold">
            {{settingsformJson.please_fill_all_fields}}
        </p>
        <form [formGroup]="fieldoption" *ngIf="type === 'dynamic_app'">
            <div class="row mt-3">
                <div class="col-4 m-auto text-end">
                    <label> {{settingsformJson.label_name}} </label>
                </div>
                <div class="col-8 m-auto">
                    <mat-form-field appearance="outline">
                        <input class="field mb-3" style="outline: none" matInput placeholder="{{settingsformJson.name}}"
                            type="text" formControlName="label">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3" *ngIf="fieldoption.value.type !== 'BUTTON'">
                <div class="col-4 m-auto text-end">
                    <label> Show on card </label>
                </div>
                <div class="col-8 m-auto">
                    <label class="switch me-3">
                        <input type="checkbox" formControlName="show_on_card">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <label class="fw-600 mb-2"
                        *ngIf="fieldoption.value.type !== 'HEADING' && fieldoption.value.type !== 'SUB_HEADING' && fieldoption.value.type !== 'BUTTON'">Description</label><br>
                    <!--TEXT_AREA, BODY_TEXT-->
                    <ckeditor [editor]="Editor" placeholder="Description" formControlName="form_value"
                        [config]="ckconfigQue" class="ckeditor-height" #editorComponent
                        *ngIf="fieldoption.value.type === 'TEXT_AREA' || fieldoption.value.type === 'BODY_TEXT'">
                    </ckeditor>
                    <!--NUMBER-->
                    <input class="field mb-3" style="outline: none" matInput type="number" formControlName="form_value"
                        *ngIf="fieldoption.value.type === 'NUMBER'">
                    <!--Number Range-->
                    <div class="d-flex align-items-center" *ngIf="fieldoption.value.type === 'NUMBER_RANGE'">
                        <input class="field me-3 w-90" style="outline: none" matInput type="number"
                            formControlName="start" placeholder="Start Number">
                        <input class="field me-3 w-90" style="outline: none" matInput type="number"
                            formControlName="end" placeholder="End Number">
                    </div>
                    <!--Tag node-->
                    <ng-select class="form-control" bindLabel="label" [addTag]="true" formControlName="form_value"
                        [multiple]="true" placeholder="Options"
                        *ngIf="fieldoption.value.type === 'MULTI_SELECT'"></ng-select>
                    <!--PRICE-->
                    <div class="d-flex align-items-center" *ngIf="fieldoption.value.type === 'PRICE'">
                        <mat-form-field appearance="outline" class="w-20 me-2">
                            <mat-select formControlName="currency">
                                <mat-option *ngFor="let currency of currencyjson" value='{{currency.symbol}}'>
                                    {{currency.currency + " (" + currency.abbreviation + "," + currency.symbol + ")"}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-80">
                            <input class="field mb-3" style="outline: none" matInput type="number"
                                formControlName="price">
                        </mat-form-field>
                    </div>
                    <!--TEXT-->
                    <input class="field mb-3" style="outline: none" matInput placeholder="Description" type="text"
                        formControlName="form_value" *ngIf="fieldoption.value.type === 'TEXT'">
                    <!--FILE-->
                    <div class="upload-avatar" *ngIf="fieldoption.value.type === 'FILE'">
                        <div *ngIf="fieldoption.value.form_value">
                            <img [src]="getSafeImageUrl()" (error)="onErrorLoadImage($event,fieldoption.value.form_value)" alt="" width="150px"
                                style="height: 150px;">
                        </div>
                        <button type="button" class="btn btn-primary font-size-14 mb-3 m-auto"
                            (click)="fileInput.click()">Choose File</button>
                        <input type="file" #fileInput style="display: none;" (change)="onSelect($event)">
                    </div>
                    <!--MULTIPLE_FILE-->
                    <div class="" *ngIf="fieldoption.value.type === 'MULTIPLE_FILE'">
                        <div>
                            <div *ngIf="multipleFiles.length > 0"
                                class="mb-3 mt-1 d-flex align-items-center flex-wrap upload-avatar">
                                <div class="on-delete-img-hover" (click)="deleteSelectedImage(i)"
                                    *ngFor="let img of multipleFiles; let i = index">
                                    <img src="{{img}}" alt="" width="150px" (error)="onErrorLoadImage($event,img)">
                                    <mat-icon [id]="img" class="visiblity-on-hover-delete text-danger cursor"
                                        style="top:0px;right:0px;">
                                        delete
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mt-2">
                            <button type="button" class="btn btn-primary font-size-14 mb-3 m-auto"
                                (click)="fileInput.click()">Choose File</button>
                            <input type="file" #fileInput style="display: none;" multiple
                                (change)="onMultipleSelect($event)">
                        </div>
                    </div>
                    <!--DATE-->
                    <input class="field mb-3 w-70" style="outline: none" matInput placeholder="Description" type="date"
                        formControlName="form_value" *ngIf="fieldoption.value.type === 'DATE'">
                    <!--DATE_TIME-->
                    <input class="field mb-3 w-70" style="outline: none" matInput placeholder="Description"
                        type="datetime-local" formControlName="form_value"
                        *ngIf="fieldoption.value.type === 'DATE_TIME'">
                    <!--DATE_RANGE-->
                    <mat-form-field appearance="outline" class="w-100" *ngIf="fieldoption.value.type === 'DATE_RANGE'">
                        <mat-date-range-input [rangePicker]="dateRangepicker">
                            <input matStartDate formControlName="start" placeholder="Start date">
                            <input matEndDate formControlName="end" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                    </mat-form-field>
                    <div *ngIf="fieldoption.value.type === 'FILTER'">
                        <ng-select class="form-control" bindLabel="label" [items]="formItem.options"
                            formControlName="form_value" [multiple]="true" placeholder="Options"
                            [maxSelectedItems]="10"></ng-select>
                    </div>
                    <!--error Message-->
                    <div class="error d-flex mt-2" *ngIf="submitted && !fieldoption.value.form_value">
                        <mat-icon class="font-size-12 me-1 mt-1 text-center">info</mat-icon>
                        <span>Description is required</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-light" (click)="modelService.dismissAll()">
            Cancel
        </button>
        <button class="btn btn-primary" (click)="passBack()">
            Save
        </button>
    </div>
</div>