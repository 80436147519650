<div class="conversations h-100 p-3 mt-3 d-none">
    <div class="start-conversation text-center">
        <p class="bg-conversation px-3 py-2 border-radius-8 d-inline-flex">{{date | date:'mediumDate'}}</p>
        <p class="text-secondary">{{convJSON.start_conversation}}</p>
    </div>
    <div
        class="conversation-select bg-conversation d-flex align-items-center justify-content-between mt-5 p-3 border-radius-15">
        <mat-select class="medium-filter border-radius-15 py-2 px-3 bg-primary" [(ngModel)]="medium_type">
            <!-- <mat-option value="SMS">SMS</mat-option> -->
            <mat-option value="Mail">Mail</mat-option>
        </mat-select>
        <input type="text" class="border-0 py-2 w-60 bg-conversation" style="outline: none;"
            placeholder="Start conversation" [(ngModel)]="send_text">
        <img class="cursor" src="../../../../../assets/images/_link.svg" alt="attachment">
        <button class="btn btn-primary d-flex align-items-center border-radius-15 py-2 for-disabled"
            [disabled]="!send_text">
            {{convJSON.send_btn_label}}
            <mat-icon class="ms-2" style="font-size: 22px;">send</mat-icon>
        </button>
    </div>
</div>

<div class="conversations h-100 container">
    <div class="middle-chat-section h-100">
        <section class="middle h-100">
            <div class="chat h-100 d-flex flex-column justify-content-between">
                <div class="messages-chat" style="height: calc(100vh - 230px);" #mailchat>
                    <div class="">
                        <ng-container *ngFor="let item of email_converstion;let i = index">
                            <div class="message d-flex align-items-start justify-content-between cursor col-12"
                                (click)="onToogle(i)">
                                <div class="d-flex align-items-start justify-content-between col-11" style=" flex-grow:1">
                                    <div class="d-flex align-items-start" style="width: 70%; flex-grow:1">
                                        <div class="avatar" [style.background-color]="item?.colour">
                                            {{item?.message?.from?.charAt(0)?.toUpperCase()}}</div>
                                        <div class="ms-2 me-3 w-100" >
                                            <p class="m-0">To: <span
                                                class="fw-600">{{item.message.to}}</span> </p>
                                            <p class="message-from from-content my-2 pb-1 line-height-1" [id]="'fromId'+i">From:{{item.message.from}}</p>
                                            <div class="messageCC to-content mb-2 " [id]="'ccId'+i"
                                                *ngIf="item?.message?.cc_recipients?.length > 0">
                                                <div class="d-flex">
                                                    <p class="me-1 mb-0 width-fit-content font-size-12 col-1 line-height-1">Cc:</p>
                                                    <div class="col-11 d-flex flex-wrap font-size-12 line-height-1 mb-1">
                                                        <div *ngFor="let cc of item.message.cc_recipients;let index=index">
                                                            <span>{{cc}}</span><span class="me-1"
                                                                *ngIf="index+1 !== item.message.cc_recipients.length">,</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="messageBCC to-content" [id]="'bccId'+i"
                                                *ngIf="item?.message?.bcc_recipients?.length > 0">
                                                <div class="d-flex">
                                                    <p class="me-1 width-fit-content mb-0 font-size-12 col-1 line-height-1">Bcc:</p>
                                                    <div class="col-11 d-flex flex-wrap font-size-12 line-height-1 mb-1">
                                                        <div *ngFor="let bcc of item.message.bcc_recipients;let index=index">
                                                            <span>{{bcc}}</span><span class="me-1"
                                                                *ngIf="index+1 !== item.message.bcc_recipients.length">,</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="mt-1 mb-0 font-size-14 message-from content-height" [id]="'subject'+i" >Subject: <span style="color: #706d6d;">{{item?.message?.subject}}</span></p>
                                            <p class="message-body mt-2  limit-line-length-1 m-0 content-height" [id]="'item'+i"
                                            style="color: #706d6d;" [innerHTML]="item?.message?.body"></p>
                                        </div>
                                    </div>
                                    <div class="mx-2">
                                        <p class="font-size-12 m-0 text-light">{{item.formattedTime}}</p>
                                    </div>
                                </div>
                                <!-- <div class="text mb-2 py-3 position-relative"
                                    [ngClass]="item.type === 'sent' ? 'send-message' : 'text-radius'">
                                    <p class="font-size-16">Subject: {{item?.message?.subject}}</p>
                                    <p [innerHTML]="item?.message?.body" class="fixed-height"></p>
                                    <div class="schedule-icon position-absolute " *ngIf="item?.scheduleIcon">
                                        <mat-icon>query_builder</mat-icon>
                                    </div>
                                </div> -->
                                <mat-icon class="col-1" [id]="'arrow'+i">keyboard_arrow_down</mat-icon>
                            </div>
                            <hr *ngIf="i !== email_converstion.length - 1">
                        </ng-container>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end mt-2">
                    <!-- <button class="btn ms-2 btn-primary" type="button"
                        (click)="openSendMailTemplate('schedule')">Schedule a
                        Mail</button> -->
                    <button class="btn ms-2 btn-primary" type="button" [disabled]="readOnly" (click)="openSendMailTemplate('sent')">Send a
                        Mail</button>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- <ng-template #display_mail_modal let-modal>
    <div class="mail-template mt-3 px-4 py-3">
        <div class="modal-header bg-light px-4 py-3">
            <h5 class="text-white font-size-14 mb-0">{{showSchedule ? 'Schedule Message' : 'New Message' }} </h5>
            <mat-icon class="text-white cursor" (click)="modalRef.close()">close</mat-icon>
        </div>
        <div class="d-flex align-items-center border-radius-4 email-to mt-3 mb-3">
            <p class="mb-0 me-3">To</p>
            <p class="mb-0 text-primary"><b>{{source_email}}</b></p>
        </div>
        <form [formGroup]="mail_template" (ngSubmit)="onMailSubmit()">
            <div class="pt-1" *ngIf="email_converstion.length === 0">
                <label class="fw-600 mb-2 w-100">Subject</label>
                <mat-form-field appearance="outline" class="w-100 subject-field">
                    <input class="border-0 bg-white w-100" type="text" style="outline: none" matInput
                        placeholder="Subject" formControlName="subject">
                </mat-form-field>
            </div>
            <div class="modal-body px-0 pb-1 pt-0">
                <label class="fw-600 mb-2 w-100">Body <small class="text-danger">*</small></label>
                <ckeditor [editor]="Editor" formControlName="content" #editorComponent [config]="ckconfigQue">
                </ckeditor>
                <mat-error class="error text-danger d-flex mt-1" *ngIf="submited && f['content'].errors">
                    <mat-icon class="font-size-12 me-1 my-auto info w-auto h-auto">info</mat-icon>
                    <span *ngIf="f['content'].errors['required']">
                        Message Body required </span>
                </mat-error>
                <div class="d-flex align-items-start mt-3 pt-2 w-100">
                    <mat-form-field appearance="outline" class="me-3" style="width: 50%;" *ngIf="showSchedule">
                        <input matInput [ngxMatDatetimePicker]="picker" formControlName="schedule_mail" [min]="minDate"
                            placeholder="Choose a date & time">
                        <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                    </mat-form-field>
                    <button type="submit" class="btn btn-primary font-size-12 px-3">{{showSchedule ? 'Schedule' :
                        'Send'}}</button>
                    <button type="text" (click)="onSaveToDraft()" class="btn btn-primary font-size-12 px-3 ms-2">Save as
                        draft</button>
                </div>
            </div>
        </form>
    </div>
</ng-template> -->