import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrmService } from 'src/app/core/service/crm.service';
import { Location } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-imported-files',
  templateUrl: './imported-files.component.html',
  styleUrls: ['./imported-files.component.scss']
})
export class ImportedFilesComponent {
  field_data: any[] = [];
  params: any;
  showType = '';
  total_resp: any;
  page_size = 20;
  pageIndex = 0;
  pageEvent!: PageEvent;
  currentPage = 1;

  constructor(private service: CrmService, private activatedRoute: ActivatedRoute, private _location: Location) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.params = params?.type;
      this.showType = params.showType;
      if (this.showType === 'upload') {
        this.getFiles();
      } else {
        this.getDownloadedFiles();
      }
    });
  }

  getFiles() {
    this.service.getCSVFiles(this.params,this.currentPage).subscribe((resp: any) => {
      this.field_data = resp.data;
      this.total_resp = resp;
    });
  }
  getDownloadedFiles() {
    const serviceMethod = (this.params === 'client' || this.params === 'project')
      ? this.service.getCLIENTCSV(this.params,this.currentPage)
      : this.service.getCRMCSV(this.params,this.currentPage);
    serviceMethod.subscribe({
      next: (resp: any) => {
        if (resp.data && resp.data.length > 0) {
          this.field_data = resp.data;
          this.total_resp = resp;
        }
      }
    }); 
  }

  onBack() {
    this._location.back();
  }

  page(e: PageEvent) {
    this.pageEvent = e;
    if (this.pageEvent.pageIndex > this.pageEvent.previousPageIndex!) {
      this.currentPage = this.pageEvent.pageIndex + 1;
      this.pageIndex = e.pageIndex++;
    } else if (this.pageEvent.pageIndex === 0) {
      this.currentPage = 1;
      this.pageIndex = 0;
    } else if (this.pageEvent.pageIndex < this.pageEvent.previousPageIndex!) {
      this.currentPage -= 1;
      this.pageIndex = e.pageIndex--;
    }
    console.log(this.currentPage,this.pageIndex);
    if (this.showType === 'upload') {
      this.getFiles();
    } else {
      this.getDownloadedFiles();
    }
  }
}
