import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit {
  badgeForm!: FormGroup;
  submitted = false;
  badgesArray: any = [];
  badges_text = 'badges';
  field_text = 'Badge';
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() badge_type: any;
  @Input() badge_category: any;

  constructor(public ngbActiveModal: NgbActiveModal, private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.initilizeForm();
    this.getBadges();
    if(this.badge_type === 'DYNAMICAPP') this.badges_text = "category"; this.field_text = 'Category';
  }

  initilizeForm(data?: any) {
    this.badgeForm = new FormGroup({
      id: new FormControl(data ? data.id : ''),
      name: new FormControl(data ? data.name : '', [Validators.required]),//Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      colour: new FormControl(data ? data.colour : '#ffffff', [Validators.required]),
      text_colour: new FormControl(data ? data.text_colour : '#000', [Validators.required]),
      type: new FormControl(this.badge_type),
      category: new FormControl(this.badge_category)
    });
  }

  get f() {
    return this.badgeForm.controls;
  }

  getBadges() {
    this.settingsService.getBadges(this.badge_type,this.badge_category).subscribe({
      next: (resp: any) => {
        this.badgesArray = resp.data;
      }
    });
  }

  onSubmit(formDirective: any) {
    // console.log(this.badgeForm.value);
    this.submitted = true;
    if (this.badgeForm.valid) {
      if (this.badgeForm.value.id) {
        this.settingsService.updateBadge(this.badgeForm.value).subscribe({
          next: () => {
            this.getBadges();
            this.passEntry.emit('true');
            formDirective.resetForm();
            // this.badgeForm.reset();
            this.submitted = false;
            this.initilizeForm();

          }
        });
      } else {
        this.settingsService.postBadge(this.badgeForm.value).subscribe({
          next: () => {
            this.getBadges();
            this.passEntry.emit('true');
            formDirective.resetForm();
            // this.badgeForm.reset();
            this.submitted = false;
            this.initilizeForm();


          }
        });
      }

    }
  }

  editBadge(data: any) {
    this.initilizeForm(data);
    this.badgeForm.patchValue(data);
  }

  deleteBadge(id: string) {
    this.settingsService.deleteBadge(id,this.badge_type).subscribe({
      next: (resp: any) => {
        this.getBadges();
        this.passEntry.emit('true');
      }
    });
  }

  resetTagForm(formDirective: any) {
    formDirective.resetForm();
    this.submitted = false;
    this.initilizeForm();
  }

}
