<div class="container-fluid">
    <div class="d-flex align-items-center ms-2">
        <div class="background-on-hover d-flex align-items-center">
            <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
                aria-label="Button that displays a tooltip when focused or hovered over" class="text-primary cursor"
                [routerLink]="['/home']" routerLinkActive="router-link-active">arrow_back_ios </mat-icon>
        </div>
        <h5 class="text-primary fw-500 font-size-16 mb-0">Meetings</h5>
    </div>
    <div class="row p-2">
        <div class="col-md-12"> 
            <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0 me-3">
                <ng-container *ngFor="let item of tabs_menu">
                    <li [ngbNavItem]="item.id" class="me-2">
                        <a ngbNavLink class="d-flex" [routerLink]="[item.route]">
                            <span class="d-sm-block font-size-12">{{item.title}}</span>
                        </a>
                        <ng-template ngbNavContent>
                            <router-outlet></router-outlet>
                        </ng-template>
                    </li>
                </ng-container>
            </ul>
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
</div>