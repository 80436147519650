<div class="w-100 overflow-auto task-details">
    <div class="p-4">
        <div class="row">
            <div class="col-10">
                <div class="owner">
                    <h6 class="fw-600 mb-3 text-primary">Owner & Assignees</h6>
                    <div class="card">
                        <div class="card-body rounded">
                            <div class="row task_item">
                                <div class="col-4">
                                    <label>Owner</label>
                                </div>
                                <div class="col-8">
                                    <div class="d-flex align-items-center justify-content-between"
                                        *ngIf="editItem !== 'owner_info'">
                                        <label class="text-primary text-capitalize">:
                                            &nbsp;{{dublicateDetails.owner_info.fname}}
                                            {{dublicateDetails.owner_info.lname}}</label>
                                        <mat-icon (click)="onItemEdit('owner_info')"
                                            class="cursor font-size-15 d-flex align-items-center edit-item">edit</mat-icon>
                                    </div>
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none"
                                        *ngIf="editItem === 'owner_info'">
                                        <mat-select placeholder='Owner' [(ngModel)]="dublicateDetails.owner"
                                            (selectionChange)="taskStatusUpdate()">
                                            <mat-option *ngFor="let item of userLists"
                                                [value]="item?.id">{{item?.fname}} {{item?.lname}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row task_item">
                                <div class="col-4">
                                    <label>Assignees</label>
                                </div>
                                <div class="col-8 d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center justify-content-between w-100"
                                        *ngIf="editItem !== 'assignee'">
                                        <label class="text-primary"
                                            *ngIf="dublicateDetails?.info?.staff_info_array?.length !== 0">:
                                            &nbsp; <span
                                                *ngFor="let item of dublicateDetails?.info?.staff_info_array; let i = index">
                                                {{item.fname | titlecase}} {{item.lname | titlecase}},</span></label>
                                        <label *ngIf="dublicateDetails?.info?.staff_info_array?.length === 0"
                                            class="text-primary">:&nbsp; NA</label>
                                        <mat-icon (click)="onItemEdit('assignee')"
                                            class="cursor font-size-15 d-flex align-items-center edit-item">edit</mat-icon>
                                    </div>

                                    <ng-select class="form-control" *ngIf="editItem === 'assignee'" bindLabel="fname"
                                        [addTag]="false" [multiple]="true" placeholder="Assignees"
                                        [(ngModel)]="dublicateDetails.assignee" (change)="onAssigneChange()">
                                        <ng-option *ngFor="let item of userLists" [value]="item.id">{{item.fname}}
                                            {{item.lname}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dates">
                    <div class="d-flex justify-content-between">
                        <div class="" *ngIf="datesView || dublicateDetails.start_date">
                            <h6 class="fw-600 mb-2 text-primary">Start Date</h6>
                            <mat-form-field appearance="outline" class="w-80">
                                <input matInput type="text" placeholder="Start Date" matInput [ngxMatDatetimePicker]="picker"
                                    [(ngModel)]="dublicateDetails.start_date" (dateChange)="taskStatusUpdate()">
                                <mat-datepicker-toggle matIconSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                            </mat-form-field>
                        </div>
                        <div class="" *ngIf="datesView || dublicateDetails.due_date">
                            <h6 class="fw-600 mb-2 text-primary">Due Date</h6>
                            <mat-form-field appearance="outline" class="w-80">
                                <input matInput type="text" placeholder="Due Date" matInput [ngxMatDatetimePicker]="picker1"
                                    [(ngModel)]="dublicateDetails.due_date" (dateChange)="taskStatusUpdate()">
                                <mat-datepicker-toggle matIconSuffix [for]="$any(picker1)"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="" *ngIf="datesView || dublicateDetails.working_hours">
                        <h6 class="fw-600 mb-2 text-primary">Time estimate in hours</h6>
                        <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                            <input style="outline: none" matInput placeholder='Time estimate in hours'
                                [(ngModel)]="dublicateDetails.working_hours" (blur)="taskStatusUpdate()" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="details mt-3">
                    <h6 class="fw-600 mb-3 text-primary">Task Details</h6>
                    <div class="card">
                        <div class="card-body rounded">
                            <div class="row">
                                <div class="col-4">
                                    <label>Task Name</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp;{{dublicateDetails?.name || 'NA'}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Task Id</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp;{{dublicateDetails?.display_id || 'NA'}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Priority</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp;{{dublicateDetails?.priority || 'NA'}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Tag</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp;{{dublicateDetails?.tag_name || 'NA'}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Stage</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp;{{dublicateDetails?.stage_name || 'NA'}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Remainder</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp; Due Date is {{(dublicateDetails.due_date ?
                                        (dublicateDetails.due_date| dateConversion) : 'NA') }}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Created by</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp; {{dublicateDetails?.created_by_name ||
                                        'NA'}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Created on</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp; {{dublicateDetails?.created_at ?
                                        (dublicateDetails?.created_at |
                                        dateConversion) : 'NA'}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Updated by</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp; {{dublicateDetails?.updated_by_name ||
                                        'NA'}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <label>Updated on</label>
                                </div>
                                <div class="col-8">
                                    <label class="text-primary">: &nbsp; {{dublicateDetails.updated_at ?
                                        (dublicateDetails.updated_at |
                                        dateConversion) :
                                        'NA'}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="description" *ngIf="dublicateDetails.description || ckEditor">
                    <div class="d-flex justify-content-between text-primary">
                        <h6 class="fw-600 mb-3 d-flex align-items-center"><mat-icon class="me-2">notes</mat-icon>
                            Description</h6>
                        <span class="fw-600 cursor" (click)="openDsp()">Edit</span>
                    </div>
                    <div class="card">
                        <div class="card-body rounded">
                            <p [innerHTML]="dublicateDetails.description" *ngIf="!ckEditor"></p>
                            <ckeditor [editor]="Editor" placeholder="Description" #editorComponent
                                [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'ck-error':''"
                                (ready)="onReady($event,editorComponent)" (keyup)="editorkeyEvent(editorComponent)"
                                (blur)="ckeditorLimit.actualLimit <= ckeditorLimit.maxLimit && taskStatusUpdate()"
                                *ngIf="ckEditor" [(ngModel)]="dublicateDetails.description" [config]="ckconfigQue"
                                class="ckeditor-height">
                            </ckeditor>
                            <small *ngIf="ckEditor"
                                [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                                class="font-size-10">Maximum
                                1000 characters are accepted.</small><small *ngIf="ckEditor"
                                [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                                class="font-size-10 ps-1">(Character limit:
                                {{ckeditorLimit.actualLimit}}/{{ckeditorLimit.maxLimit}})</small>
                        </div>
                    </div>
                </div>
                <div class="files" *ngIf="fileView">
                    <div class="d-flex justify-content-between text-primary">
                        <h6 class="fw-600 mb-3 d-flex align-items-center text-primary"><mat-icon
                                class="me-2">attach_file</mat-icon> Attachments</h6>
                        <span class="fw-600 cursor" (click)="addFilesAdd()">+ Add</span>
                    </div>
                    <div class="card">
                        <div class="card-body rounded">
                            <div class="w-100" *ngIf="addFiles">
                                <ngx-dropzone (change)="onSelect($event)" id="media-dropzone" [multiple]="false"
                                    [draggable]="false" class="bg-gray-light" style="height: 61px;">
                                    <ngx-dropzone-label><span class="text-primary fw-500">Browse</span>
                                        for a file to upload
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                                        ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                                    </ngx-dropzone-image-preview>
                                </ngx-dropzone>
                                <div class=" d-flex justify-content-end">
                                    <button type="button" class="btn btn-primary my-3" [disabled]="!this.files[0]"
                                        (click)="onUpload()">Upload</button>
                                </div>
                            </div>
                            <div class="row mb-3" *ngFor="let file of filesListArray">
                                <div class="col-md-4">
                                    <img class="w-100 rounded"
                                        (error)="onErrorLoadImage($event,presignedurl,file?.name)"
                                        src="{{file.media_url}}">
                                </div>
                                <div class="col-md-8">
                                    <div class="d-flex justify-content-between">
                                        <a href="{{file.media_url}}" target="_blank" class="position-relative ms-2 text-dark fw-medium limit-line-length-1"
                                            download>
                                            <h6>{{file.name}}</h6>
                                        </a>
                                        
                                        <mat-icon class="cursor font-size-15 text-danger"
                                            (click)="confirmDelete(file.id)">delete</mat-icon>
                                    </div>
                                    <label>{{file.updated_at | dateConversion}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="checklist" *ngIf="checklistView">
                    <h6 class="fw-600 mb-3 d-flex align-items-center text-primary"><mat-icon
                            class="me-2">check_box</mat-icon> Checklist</h6>
                    <div class="card">
                        <div class="card-body rounded">
                            <ul class="p-0">
                                <li *ngFor="let item of checklist_array;let i=index">
                                    <mat-checkbox [checked]="item.value" (change)="onCheckBoxChange($event,i)">
                                        {{item.name}}
                                    </mat-checkbox>
                                </li>
                            </ul>
                            <div class="example-box mb-3 row d-flex align-items-center" *ngIf="newItemAdded">
                                <div class="col-md-10">
                                    <mat-form-field appearance="outline" class="w-100 mat-hint-none">
                                        <input style="outline: none" matInput placeholder='Item'
                                            [(ngModel)]="newItemText" />
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 px-0" style="overflow: visible;">
                                    <div class="d-flex align-items-center">
                                        <mat-icon
                                            class="d-flex align-items-center font-size-16 cursor position-relative top-8"
                                            style="color: rgb(0, 128, 55);width: 16px;"
                                            (click)="newCheckListAdded()">done</mat-icon>
                                        <!-- <mat-icon
                                            class="text-danger cursor stage-delete font-size-20 position-relative top-8">delete</mat-icon>      -->
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary  d-flex align-items-center"
                                (click)="addItemCheckBox()"><mat-icon>check_box</mat-icon>&nbsp; Add an item</button>
                        </div>
                    </div>
                </div>
                <div class="files" *ngIf="notesView">
                    <div class="d-flex justify-content-between text-primary">
                        <h6 class="fw-600 mb-3 d-flex align-items-center"><mat-icon class="me-2">assignment</mat-icon>
                            Notes</h6>
                        <span class="fw-600 cursor" (click)="onNotesAdd()">+ Add</span>
                    </div>
                    <div class="notes bg-white border-radius-8 mb-3 position-relative" *ngIf="addNotes">
                        <div data-text="What's this note about?"
                            class="form-control overflow-y contenteditable-div content-input border-radius-0 m-0 font-size-15"
                            aria-label="With textarea" [mention]="userLists" [mentionConfig]="mentionConfig"
                            (itemSelected)="itemSelected($event)" (click)="expandContent($event)"
                            [ngClass]="{'height-class' : contentExpand === true}" contenteditable="true" id="my-content"
                            (keyup)="handleInput()"></div>
                        <div class="d-flex align-items-center justify-content-end mb-2" *ngIf="contentExpand">
                            <button
                                class="btn border-radius-50 border-1 bg-primary fw-600 font-size-13 text-white px-4 me-3 mb-3"
                                [disabled]="!notesContent" (click)="saveNotes()">Save</button>
                            <button
                                class="btn border-radius-50 border-1 bg-white fw-600 font-size-13 text-primary px-4 me-3 mb-3"
                                (click)="cancel()">Cancel</button>
                        </div>
                    </div>
                    <div class="card" *ngFor="let item of notesList;let i = index">
                        <div class="card-body rounded">
                            <div class="d-flex justify-content-between">
                                <p [innerHTML]="item?.notes | safeHtml"></p>
                                <div class="d-flex ms-1">
                                    <mat-icon class="cursor font-size-15" (click)="editNotes(item,i)">edit</mat-icon>
                                    <mat-icon class="cursor font-size-15 text-danger"
                                        (click)="confirmNotesDelete(item.id)">delete</mat-icon>
                                </div>
                            </div>
                            <label class="text-light">{{item?.created_by_name}}</label><br>
                            <label class="text-light">{{item?.created_at | dateConversion}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2 icons text-center">
                <!-- <button class="btn btn-primary mb-3"><mat-icon>person_add</mat-icon></button> -->
                <button mat-raised-button matTooltip="Description"
                    aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                    class="btn btn-primary mb-3" (click)="openDsp()"><mat-icon>notes</mat-icon></button><!--dsp-->
                <button mat-raised-button matTooltip="File"
                    aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                    class="btn btn-primary mb-3" (click)="openFiles()"><mat-icon>attach_file</mat-icon></button>
                <button mat-raised-button matTooltip="Calendar"
                    aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                    class="btn btn-primary mb-3" (click)="openCalendar()"><mat-icon>calendar_today</mat-icon></button>
                <button mat-raised-button matTooltip="Checklist"
                    aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                    class="btn btn-primary mb-3" (click)="openCheckBox()"><mat-icon>check_box</mat-icon></button>
                <button mat-raised-button matTooltip="Notes"
                    aria-label="Button that displays a tooltip when focused or hovered over" mat-button
                    class="btn btn-primary mb-3"
                    (click)="openNotes()"><mat-icon>assignment</mat-icon></button><!--Notes-->
            </div>
        </div>
    </div>
</div>