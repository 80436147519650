import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CrmService } from 'src/app/core/service/crm.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-home-custom-filters',
  templateUrl: './home-custom-filters.component.html',
  styleUrls: ['./home-custom-filters.component.scss']
})
export class HomeCustomFiltersComponent implements OnInit {
  @Input() is_filter_for!: string;
  @Input() filters_custm: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() customFiltersArray!: any;
  @Input() filterType!: string;
  @Input() selectedSortOptions!: any;
  submitted = false;
  columnFilters!: FormGroup;
  staffMembers: any[] = [];
  contactListArray: any[] = [];
  startingDate!: any;
  endingDate!: any;
  betweenFilterDates: any[] = [];
  appointmentsArray: any[] = [];

  constructor(public ngbActiveModal: NgbActiveModal, private fb: FormBuilder, private setupService: SetUpService, private settingsService: SettingsService, private service: CrmService) { }

  ngOnInit() {
    this.initilizeFiltersForm();
    if (this.filters_custm && Object.keys(this.filters_custm).length > 0) {
      this.filters_custm.map((ele: any, index: any) => {
        this.initilizeFromArray(ele);
        this.forCustomFieldStatus(ele, index);
        if (ele.column === 'owner' || ele.column === 'staff_name' || ele.column === 'assignee' || ele.column === 'staff_id' || (this.is_filter_for === 'task' && ele.column === 'created_by') || (this.is_filter_for === 'activity' && ele.column === 'created_by')) {
          this.organisationMembersList();
        }
        if (ele.column === 'source_id' || ele.column === 'contact' || (this.is_filter_for !== 'task' && ele.column === 'source_type') || ele.column === 'contact_id') {
          this.getContactById(ele.data);
        }
        if (ele.column === 'appointment_name') {
          this.getAppointmentLists();
        }
        if (ele.operation === 'is_empty') {
          this.updateFormValidations(index, 'disable');
        }
      });
      if (this.filters_custm && this.filters_custm.length > 0) {
        for (let i = 0; i < this.filters_custm.length; i++) {
          this.betweenFilterDates.push({
            startDate: this.columnFilters.value.filter[i].operation === 'between' ? new Date(this.columnFilters.value.filter[i].data.split(',')[0]) : '',
            endDate: this.columnFilters.value.filter[i].operation === 'between' ? new Date(this.columnFilters.value.filter[i].data.split(',')[1]) : ''
          });
        }
      }
    } else {
      if (this.filterType === 'filters') {
        this.initilizeFromArray();
      }
    }
    if (this.selectedSortOptions) this.columnFilters.get('sorting')?.patchValue(this.selectedSortOptions);
  }

  initilizeFiltersForm() {
    this.columnFilters = this.fb.group({
      filter: new FormArray([]),
      sorting: this.fb.group({
        sort_by: new FormControl(''),
        sort_order: new FormControl('desc'), // Assuming a default value for sort_order
        sort_by_label:new FormControl('')
      })
    });
  }

  get form_array() {
    return this.columnFilters.controls['filter'] as FormArray;
  }
  deleteFilter(index: number) {
    this.betweenFilterDates.splice(index, 1);
    this.form_array.removeAt(index);
  }

  initilizeFromArray(data?: any) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formData: any = new FormGroup({
      column: new FormControl(data?.column ? data?.column : '', Validators.required),
      data: new FormControl(data?.data ? data?.data : '', Validators.required),
      operation: new FormControl(data?.operation ? data?.operation : '', Validators.required),
      label: new FormControl(data?.label ? data?.label : ''),
      timezone: new FormControl(timeZone)
    });
    if (this.form_array.length < 3) {
      this.form_array.push(formData);
    }
  }

  updateFormValidations(index: number, type: string) {
    if (type === 'disable') {
      this.form_array.controls[index].get('data')?.patchValue('');
      this.form_array.controls[index].get('data')?.clearValidators();
      this.form_array.controls[index].get('data')?.markAsUntouched();
      this.form_array.controls[index].get('data')?.updateValueAndValidity();
    } else {
      if (!(this.form_array.controls[index].get('data')?.hasValidator(Validators.required))) {
        this.form_array.controls[index].get('data')?.patchValue('');
        this.form_array.controls[index].get('data')?.addValidators([Validators.required]);
        this.form_array.controls[index].get('data')?.updateValueAndValidity();
      }
    }
  }

  forCustomFieldStatus(field: any, index: number) {
    if (field.key === 'owner' || field.key === 'assignee' || field.key === 'staff_id' || field.key === 'staff_name' || field.key === 'created_by' || this.form_array.controls[index].value.column === 'created_by' &&
      this.is_filter_for === 'task' ||
      this.form_array.controls[index].value.column === 'created_by' &&
      this.is_filter_for === 'activity') {
      this.organisationMembersList();
    } else if (field.key === 'source_id' || field.key === 'contact' || (this.is_filter_for !== 'task' && field.key === 'source_type') || field.key === 'contact_id') {
      this.getContactsDropdownList();
    } else if (field.key === 'appointment_name') {
      this.getAppointmentLists();
    }
    this.form_array.controls[index].patchValue({ label: field.label });
  }
  onChangeDropDown(index: number) {
    this.form_array.controls[index].patchValue({ data: '', operation: '' });
    this.form_array.controls[index].get('data')?.markAsUntouched();
    this.form_array.controls[index].get('data')?.updateValueAndValidity();
    this.form_array.controls[index].get('operation')?.markAsUntouched();
    this.form_array.controls[index].get('operation')?.updateValueAndValidity();
  }
  onChangeSortDropDown(option: any) {
    this.columnFilters.get('sorting')?.patchValue({ sort_by_label: option.label });
  }

  getAppointmentLists() {
    this.setupService.getAllConsulations().subscribe({
      next: (resp: any) => {
        this.appointmentsArray = resp.data;
      }
    });
  }

  updateDateFormat(event: any, index: number, type?: string) {
    const inputDate = new Date(event.value);
    const year = inputDate.getFullYear();
    const month = ('0' + (inputDate.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + inputDate.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    if (type === 'date') {
      this.form_array.controls[index].patchValue({ data: formattedDate });
      return;
    } else {
      return formattedDate;
    }
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.staffMembers = resp?.data;
      }
    });
  }
  getContactsDropdownList() {
    this.service.getContactsDropdownList().subscribe((resp: any) => {
      this.contactListArray = resp?.data;
    });
  }

  getContactById(id: string) {
    this.service.getContactById(id).subscribe((resp: any) => {
      this.contactListArray = resp?.data;
    });
  }

  searchTriggerConatct(change: any) {
    if (change.term.length >= 3) {
      this.service.getContactsBySearch(change.term).subscribe({
        next: (resp: any) => {
          this.contactListArray = resp.data;
        }
      });
    } else if (change.term.length < 1) {
      this.getContactsDropdownList();
    }
  }

  onDateChange(event: any, type: string, index: number) {
    if (type === 'start') {
      this.startingDate = this.updateDateFormat(event, index, '');
    } else if (type === 'end') {
      this.endingDate = this.updateDateFormat(event, index, '');
    }
    if (this.startingDate && this.endingDate) {
      const dateRangeString = `${this.startingDate},${this.endingDate}`;
      this.form_array.controls[index].patchValue({ data: dateRangeString });
    }
  }


  filterFormSubmit() {
    this.submitted = true;
    if (this.columnFilters.valid) {
      if (this.filterType === 'sort') {
        this.passEntry.emit({ data: this.columnFilters.value, type: 'save', from: 'sort' });
        this.ngbActiveModal.dismiss();
      } else {
        this.passEntry.emit({ data: this.columnFilters.value, type: 'save', from: 'filters' });
        this.ngbActiveModal.dismiss();
      }
    }
  }
  resetFilters() {
    if (this.filterType === 'sort') {
      this.columnFilters.get('sorting')?.reset();
      this.columnFilters.get('sorting')?.patchValue({ sort_by: '', sort_order: 'desc', sort_by_label: '' });
      if (this.selectedSortOptions) this.passEntry.emit({ data: this.columnFilters.value, type: 'reset', from: 'sort' });
      this.selectedSortOptions = null;
    } else {
      const filterArray = this.columnFilters.get('filter') as FormArray;
      filterArray.clear();
      this.betweenFilterDates = [];
      if (this.filters_custm) this.passEntry.emit({ data: this.columnFilters.value, type: 'reset', from: "filters" });
      this.initilizeFromArray();
      this.filters_custm = null;
    }

  }

}
