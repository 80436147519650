import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { SETUPJSON } from 'src/app/core/data/setup_module_json';
import { AuthService } from 'src/app/core/service/auth.service';
import { SetUpService } from 'src/app/core/service/set-up.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboarding-screens',
  templateUrl: './onboarding-screens.component.html',
  styleUrls: ['./onboarding-screens.component.scss']
})
export class OnboardingScreensComponent implements OnInit {
  setupJson = SETUPJSON[0];
  appointment_setup_status!: boolean;
  setupData: any;
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  stepperCheck: any;
  @ViewChild('stepper') stepper!: MatStepper;
  featuresData: any;
  urlPrefix = environment.urlPrefix;
  
  constructor(private setupService: SetUpService,private authService: AuthService) { }

  ngOnInit() {
    this.getInfo('');
    this.getFeatues();
    console.log(this.stepperCheck);
    
  }

  getInfo(type: string) {
    this.setupService.getSetupInformation(type).subscribe((data: any) => {
      this.setupData = data;
    });
  }

  onToggleChange(ev: any, item: any) {
    this.setupService.updateSetupStatus(item.id, { status: ev.target.checked }).subscribe(() => {
      this.getInfo('forceUpdate');
    });
  }

  onStepperCheck(ev: any){
    console.log(ev);
    if(ev){
      this.stepper.next();
    }
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }
}
