<div class="container-fluid profile-sec">
  <div class="row p-2">
    <div class="col-md-12">
      <ul ngbNav #customNav="ngbNav" [(activeId)]="activeId" class="nav-tabs nav-tabs-custom mt-0 me-3"
        (navChange)="tabChange($event)">
        <li [ngbNavItem]="1" class="me-2">
          <a ngbNavLink class="d-flex">
            <span class="d-sm-block font-size-12">Profile</span>
          </a>
          <ng-template ngbNavContent>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 m-auto mt-3">
              <form [formGroup]="user_profile" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="mb-2">
                    <div class="d-flex align-items-center">
                      <div (mouseenter)="isEditVisible = true" class="position-relative"
                        (mouseleave)="isEditVisible = false" class="width-fit-content position-relative">
                        <img *ngIf="user_profile.value?.image" class="border-radius-50" width="100px" height="100px"
                          src="{{user_profile.value.image}}">
                        <div class="default-image d-flex justify-content-center align-items-center"
                          *ngIf="!user_profile.value?.image">
                          <p class="m-0 text-center text-white font-size-40  text-uppercase ">{{defaultName}}</p>
                        </div>
                        <!-- <img class="border-radius-50" width="100px" height="100px" 
                          src="../../../../assets/images/default_image.png"> -->
                        <div class="circle position-absolute" id="upload-btn" [hidden]="!isEditVisible">
                          <div class="color-bg position-absolute"
                            [ngClass]="{'text-center':!user_profile.value?.image}">
                            <label for="attach-file" class="text-center border-radius-50" (change)="FileUpload($event)">
                              <span class="text-center font font-size-12 pt-1" *ngIf="!user_profile.value?.image"> Add
                              </span>
                              <span *ngIf="user_profile.value?.image"
                                class="text-center font-size-12 d-flex pt-1 ps-2 ms-1"> <mat-icon
                                  class="me-1 font-size-16 h-1rem ps-2 ms-1 me-2">edit</mat-icon> |
                              </span>
                              <!-- <span class="text-center font font-size-12" [innerText]="user_profile.value?.image ? 'Edit' : 'Add'"></span> -->
                              <input type="file" id="attach-file" accept="image/*" hidden />
                            </label>
                            <mat-icon class="ms-2 font-size-16 h-1rem position-absolute delete-opt "
                              *ngIf="user_profile.value?.image" (click)="deleteProfilePic()">delete</mat-icon>
                          </div>
                        </div>
                      </div>
                      <div class="role-section ms-3">
                        <div class="d-flex align-items-center">
                          <p class="pb-1 m-0 fw-600 ">Role: </p>
                          <p class=" pb-1 m-0 ps-2 ">{{user_data?.role_info?.name}}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="pb-1 m-0 fw-600 ">Department: </p>
                          <p class="pb-1 m-0 ps-2 fw-500 ">{{department}}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p class="pb-1 m-0 fw-600 ">Email Id: </p>
                          <p class="pb-1 m-0  ps-2 fw-500">{{user_data?.email}}</p>
                        </div>
                      </div>
                    </div>
                    <span class="text-danger font-size-12 pt-2" *ngIf="isProfileDeleted">Please Save to Update </span>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_name}} <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder='{{staffjson.enter_placeholder}} {{staffjson.field_name}}'
                        formControlName="fname" />
                      <mat-error class="error text-danger d-flex" *ngIf="f['fname'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['fname'].errors['required']">{{staffjson.field_name}}
                          {{errorjson.required}}</span>
                        <span *ngIf="f['fname'].errors['pattern']">Only alphabets and minimum 2 characters are
                          accepted.</span>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_lastname}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_lastname}}"
                        formControlName="lname" />
                      <mat-error class="error text-danger d-flex" *ngIf="f['lname'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['lname'].errors['pattern']">Only alphabets and no special characters at
                          beginning.</span>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_email}} <small class="text-danger">*</small></label>

                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_email}}" formControlName="email"
                        type="email" />
                      <mat-error class="error text-danger d-flex" *ngIf="f['email'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']">{{staffjson.field_email}}
                          {{errorjson.required}}</span>
                        <span *ngIf="f['email'].errors['pattern']">{{errorjson.invalid}}
                          {{staffjson.field_email}}</span>
                      </mat-error>
                    </mat-form-field>
                    <p class="text-light font-size-10">This email is used for user’s account
                      settings and changing it will affect the user’s log in.</p>
                  </div> -->

                  <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">Role</label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput placeholder="Role" formControlName="role" type="text" />
                    </mat-form-field>
                  </div> -->

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_number}} <small class="text-danger">*</small></label>
                    <div class="d-flex">
                      <mat-form-field appearance="outline" class="me-2">
                        <mat-select formControlName="ccode" placeholder="{{staffjson.enter_placeholder}} Country Code">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                              noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let country of filteredBanks | async"
                            [ngClass]="{'d-none': country.IAC === ''}"
                            value={{country.IAC}}>{{country.IAC}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="{{staffjson.field_number}}"
                          formControlName="phone_number" type="text" appNumbersOnly
                          (keydown)="keyDownEvent($event,12)" />
                        <mat-error class="error text-danger d-flex" *ngIf="f['phone_number'].errors">
                          <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                          <span *ngIf="f['phone_number'].errors['required']">{{staffjson.field_number}}
                            {{errorjson.required}}</span>
                          <span
                            *ngIf="f['phone_number'].errors['pattern'] || f['phone_number'].errors['minlength'] || f['phone_number'].errors['maxlength']">{{errorjson.invalid}}
                            {{staffjson.field_number}}</span>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_designation}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_designation}}"
                        formControlName="designation" />
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_specialisation}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_specialisation}}"
                        formControlName="specialisation" />
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_qualification}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput type="text"
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_qualification}}"
                        formControlName="qualification" />
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_experience}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput type="text" (wheel)="$event.preventDefault()"
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_experience}}"
                        (keypress)="validateDecimalPoints($event)" formControlName="experience" />
                    </mat-form-field>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_gender}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select formControlName="gender" placeholder="Select">
                        <mat-option value="Female">Female</mat-option>
                        <mat-option value="Male">Male</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_age}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input style="outline: none" matInput (keydown)="keyDownEvent($event,2)"
                        placeholder="{{staffjson.enter_placeholder}} {{staffjson.field_age}}" formControlName="age" />
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="d-flex align-items-center justify-content-between">
                      <label class="fw-600 mb-2">{{staffjson.field_badge}} </label>
                    </div>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select formControlName="badges" placeholder="Select {{staffjson.field_badge}}" multiple>
                        <mat-option [value]="item.id" *ngFor="let item of badgesList"><span
                            class="py-1 px-2 border-radius-8"
                            [ngStyle]="{'background-color': item.colour}">{{item.name}}</span></mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_location}} <small class="text-danger">*</small></label>
                    <ng-select class="form-control py-1 border-radius-4" style="background: none !important;"
                      [ngClass]="{'disabled-field': loggedInUser.data.role !== 'ADMIN'}" bindLabel="group_name"
                      placeholder='Select Location' formControlName="locations" bindLabel="name" bindValue="id"
                      [multiple]="false">
                      <ng-option *ngFor="let item of branches" value="{{item.id}}">
                        {{item.name}}
                      </ng-option>
                    </ng-select>
                    <mat-error class="error d-flex mt-2" *ngIf="f['locations'].errors && f['locations'].touched">
                      <mat-icon class="font-size-12 me-1 mt-1 width-fit-content">info</mat-icon>
                      <span *ngIf="f['locations'].errors['required']">
                        {{staffjson.field_location}}
                        {{errorjson.required}}</span>
                    </mat-error>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <label class="fw-600 mb-2">{{staffjson.field_timeZone}} <small class="text-danger">*</small></label>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select placeholder='Select {{staffjson.field_timeZone}}'
                        [ngClass]="{'disabled-field': loggedInUser.data.role !== 'ADMIN'}" formControlName="time_zone">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="bankFilterTimezoneCtrl" placeholderLabel="Search"
                            noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-optgroup *ngFor="let time_zone of filteredTimezoneBanks | async" [label]="time_zone.name">
                          <mat-option class="mb-2 mat-hint-none" *ngFor="let countries of time_zone.countries"
                            value="{{countries.timezone}}">{{countries.name}}</mat-option>
                        </mat-optgroup>
                      </mat-select>
                      <mat-error class="error text-danger d-flex" *ngIf="submited && f['time_zone'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['time_zone'].errors['required']">{{staffjson.field_timeZone}}
                          {{errorjson.required}}</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="call_numbers?.length !== 0">
                    <label class="fw-600 mb-2">{{staffjson.field_call_number}} </label>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select placeholder='Select {{staffjson.field_call_number}}' formControlName="number">
                        <mat-option class="mb-2 mat-hint-none" *ngFor="let number of call_numbers"
                          value="{{number}}">{{number}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="text-center my-2">
                  <button type="submit" class="btn btn-primary px-5" [disabled]="!disableSave">
                    {{staffjson.save_btn}}
                  </button>
                </div>
              </form>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" class="me-2">
          <a ngbNavLink class="d-flex">
            <span class="d-sm-block font-size-12">Day Off</span>
          </a>
          <ng-template ngbNavContent>
            <div class="my-3">
              <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-10 d-flex justify-content-end align-items-center">
                  <button class="btn btn-primary d-flex align-items-center me-3" (click)="addDayoff()">
                    <mat-icon>add</mat-icon>
                    Add Dayoff
                  </button>
                </div>

              </div>
              <div class="table-responsive table-height mt-4">
                <table class="table align-middle table-nowrap table-hover mb-0 users-table w-100">
                  <thead>
                    <tr>
                      <th scope="col" *ngFor="let col of day_table_cols" class="text-primary pb-3">
                        {{col}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of daysListArray; let i = index">
                      <td>{{ item?.title }}</td>
                      <td>{{ item?.staff_name }}</td>
                      <td>{{ item?.start_date | dateConversion }} - {{ item?.end_date | dateConversion }}</td>
                      <td>{{ item?.start_time }} - {{ item?.end_time }}</td>
                      <td>{{ item.status ? 'Approved' : 'Pending' }}</td>
                      <!-- <td>
                        <div class="d-flex" *ngIf="item.type !== 'organisation'">
                          <label class="switch me-3">
                            <input type="checkbox" [(ngModel)]="item.status" (change)="onStatusChange(item)">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
                <div class="h-30rem">
                  <app-nodata-found *ngIf="noDataFound"></app-nodata-found>
                </div>
              </div>
            </div>
            <div *ngIf="totalPages > 20">
              <mat-paginator [length]="totalPages" [pageSize]="20" (page)="page($event)" aria-label="Select page"
                hidePageSize="true" showFirstLastButtons>
              </mat-paginator>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" class="me-2">
          <a ngbNavLink class="d-flex">
            <span class="d-sm-block font-size-12">Working Hours</span>
          </a>
          <ng-template ngbNavContent>
            <app-work-hours [type]="'appointment'" [staff_id]="loggedInUser.data.id"></app-work-hours>
          </ng-template>
        </li>
        <li [ngbNavItem]="4" class="me-2 d-none">
          <a ngbNavLink class="d-flex">
            <span class="d-sm-block font-size-12">Mail Signature</span>
          </a>
          <ng-template ngbNavContent>
            <div class="table-responsive table-height fixed-table mt-5">
              <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100 mt-2">
                <thead>
                  <tr class="border-top-table bg-white" style="z-index: 999;">
                    <th scope="col" *ngFor="let col of mailTableHeaders"
                      class="text-primary font-size-14 pb-3 table-sticky-header bg-white" style="z-index: 999;">
                      {{col}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of mailList; let i = index">
                    <td>
                      {{ item?.domain ? item.domain.domain : "NA" }}
                    </td>
                    <td>{{ item?.name ? item.name : "NA" }}
                    </td>
                    <td>{{ item?.email ? item.email : "NA"}}</td>
                    <td>
                      <a href="{{item?.signature}}" target="_blank">
                        View
                      </a>
                      <button type="button" class="btn btn-primary ms-2"
                        (click)="openSignature(signatureModal,item)">{{item?.signature ? 'Update' : 'Add'}}
                        Signature</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="customNav"></div>
    </div>
  </div>
</div>

<ng-template #signatureModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Add Signature</h5>
    <button class="btn-close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ngx-dropzone accept="image/jpeg,image/jpg,image/png,image/gif" (change)="onSelect($event)" [multiple]="false"
      class="bg-gray-light">
      <ngx-dropzone-label>Drag a image here or <span class="text-primary fw-500">Browse</span> for a signature to upload
      </ngx-dropzone-label>
      <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)" ngProjectAs="ngx-dropzone-preview"
        *ngFor="let f of signature_image" [file]="f">
      </ngx-dropzone-image-preview>
    </ngx-dropzone>
    <div class="modal-footer">
      <div class="text-end">
        <button class="btn btn-light me-3" (click)="modal.dismiss('Cross click')">
          Close
        </button>
        <button class="btn btn-primary" (click)="updateStaffSignature()">Save</button>
      </div>
    </div>
  </div>
</ng-template>