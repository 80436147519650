<div class="w-100 overflow-hidden" [ngStyle]="{'min-height': filterType === 'filters' ? 'calc(100vh - 500px)' : '0vh'}">
  <div class="pt-3 mt-2 bg-white">
    <div class="d-flex align-items-center justify-content-between mx-3 mb-3">
      <h6 class="fw-600 text-primary">{{filterType === 'filters' ? 'Filter' : 'Sort'}}</h6>
      <mat-icon class="bg-gray-light cursor" (click)="ngbActiveModal.dismiss()">close</mat-icon>
    </div>
    <form [formGroup]="columnFilters" (ngSubmit)="filterFormSubmit()"
      class="d-flex flex-column justify-content-between">
      <div [ngStyle]="{'min-height': filterType === 'filters' ? 'calc(100vh - 634px)' : '0vh'}">
        <div class="d-flex justify-content-center px-3 mb-3" *ngIf="filterType === 'sort'" formGroupName="sorting">
          <div class="col-md-6 pe-3" style="display: grid;">
            <label class="mb-2 text-primary">Sort by</label>
            <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none">
              <mat-select placeholder="Select" formControlName="sort_by">
                <ng-container *ngFor="let option of customFiltersArray">
                  <mat-option class="font-size-12" *ngIf="option?.showType" (click)="onChangeSortDropDown(option)"
                    [value]="option.key">{{option.label}}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6 p-0" style="display: grid;">
            <label class="mb-2 text-primary ms-3">Sort order</label>
            <mat-form-field appearance="outline" class="contactsfilter main-filter ms-3 mat-hint-none">
              <mat-select placeholder="Select" formControlName="sort_order">
                <mat-option class="font-size-12" value="ascend">Asc</mat-option>
                <mat-option class="font-size-12" value="desc">Desc</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <ng-container formArrayName="filter" *ngIf="filterType === 'filters'">
          <ng-container *ngFor="let item of form_array.controls; let i = index">
            <div class="d-flex px-3" [formGroupName]="i" [ngClass]="i >= 1 ? 'mt-2' : ''">
              <div class="" style="width:31%">
                <mat-form-field appearance="outline" class="contactsfilter main-filter w-100 mat-hint-none">
                  <mat-select placeholder="Select" (selectionChange)="onChangeDropDown(i)" formControlName="column"
                   >
                    <mat-option *ngFor="let option of customFiltersArray" (click)="forCustomFieldStatus(option, i)"
                      class="font-size-12"
                      [value]="option?.type === 'booking' ? 'name' : option.key">{{option.label}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="mx-3" style="width:31%">
                <mat-form-field appearance="outline" class="contactsfilter w-100 main-filter mat-hint-none">
                  <mat-select placeholder="Select" formControlName="operation"
                    (selectionChange)="columnFilters.value.filter[i].operation === 'is_empty' ? updateFormValidations(i,'disable') : updateFormValidations(i,'enable')">
                    <mat-option class="font-size-12" value="contains" *ngIf="
                  columnFilters.value.filter[i].column === 'name' || 
                  columnFilters.value.filter[i].column === 'booking_name' ||
                  columnFilters.value.filter[i].column === 'order_id' ||
                  ((columnFilters.value.filter[i].column === 'created_by' &&
                    is_filter_for === 'meetings') || 
                    (columnFilters.value.filter[i].column === 'created_by' &&
                    is_filter_for === 'appointments') ||
                    (columnFilters.value.filter[i].column === 'created_by' &&
                    is_filter_for === 'orders'))">Contains</mat-option>

                    <mat-option class="font-size-12" value="is" *ngIf="
                  columnFilters.value.filter[i].column === 'name' || 
                  columnFilters.value.filter[i].column === 'order_id' ||
                  columnFilters.value.filter[i].column === 'booking_name' || (
                    (columnFilters.value.filter[i].column === 'created_by' &&
                    is_filter_for === 'meetings') || 
                    (columnFilters.value.filter[i].column === 'created_by' &&
                    is_filter_for === 'appointments') ||
                    (columnFilters.value.filter[i].column === 'created_by' &&
                    is_filter_for === 'orders')
                  )">Is equal to</mat-option>

                    <mat-option class="font-size-12" value="greater_than" *ngIf="
                  columnFilters.value.filter[i].column === 'created_at' || 
                  columnFilters.value.filter[i].column === 'updated_at' ||
                  columnFilters.value.filter[i].column === 'due_date' ||
                  columnFilters.value.filter[i].column === 'staff_timezone_date' ||
                  columnFilters.value.filter[i].column === 'date' ||
                  columnFilters.value.filter[i].column === 'ordered_at' ||
                  columnFilters.value.filter[i].column === 'booked_at'">Greater than</mat-option>

                    <mat-option class="font-size-12" value="less_than" *ngIf="
                  columnFilters.value.filter[i].column === 'created_at' || 
                  columnFilters.value.filter[i].column === 'updated_at' ||
                  columnFilters.value.filter[i].column === 'due_date' ||
                  columnFilters.value.filter[i].column === 'staff_timezone_date' ||
                  columnFilters.value.filter[i].column === 'date' ||
                  columnFilters.value.filter[i].column === 'ordered_at' ||
                  columnFilters.value.filter[i].column === 'booked_at'">Less than</mat-option>

                    <mat-option class="font-size-12" value="between" *ngIf="
                    columnFilters.value.filter[i].column === 'created_at' || 
                    columnFilters.value.filter[i].column === 'updated_at' ||
                    columnFilters.value.filter[i].column === 'due_date' ||
                    columnFilters.value.filter[i].column === 'staff_timezone_date' ||
                    columnFilters.value.filter[i].column === 'date' ||
                    columnFilters.value.filter[i].column === 'ordered_at' ||
                    columnFilters.value.filter[i].column === 'booked_at'">Between</mat-option>

                    <mat-option class="font-size-12" value="starts_with" *ngIf="
                  columnFilters.value.filter[i].column === 'name' || 
                  columnFilters.value.filter[i].column === 'booking_name' || (
                    (columnFilters.value.filter[i].column === 'created_by' &&
                    is_filter_for === 'meetings') || 
                    (columnFilters.value.filter[i].column === 'created_by' &&
                    is_filter_for === 'appointments') ||
                    (columnFilters.value.filter[i].column === 'created_by' &&
                    is_filter_for === 'orders')
                  )">Starts with</mat-option>

                    <mat-option class="font-size-12" value="ends_with" *ngIf="
                  columnFilters.value.filter[i].column === 'name' || 
                  columnFilters.value.filter[i].column === 'booking_name' ||
                  ((columnFilters.value.filter[i].column === 'created_by' &&
                  is_filter_for === 'meetings') || 
                  (columnFilters.value.filter[i].column === 'created_by' &&
                  is_filter_for === 'appointments') ||
                  (columnFilters.value.filter[i].column === 'created_by' &&
                  is_filter_for === 'orders'))">Ends with</mat-option>

                    <mat-option class="font-size-12" value="is" *ngIf="
                  !(columnFilters.value.filter[i].column === 'name' || 
                  columnFilters.value.filter[i].column === 'booking_name' ||
                  columnFilters.value.filter[i].column === 'order_id' ||
                  ((columnFilters.value.filter[i].column === 'created_by' &&
                  is_filter_for === 'meetings') || 
                  (columnFilters.value.filter[i].column === 'created_by' &&
                  is_filter_for === 'appointments') ||
                  (columnFilters.value.filter[i].column === 'created_by' &&
                  is_filter_for === 'orders')))">Is</mat-option>

                    <mat-option class="font-size-12" value="is_not" *ngIf="
                  !(columnFilters.value.filter[i].column === 'name' || 
                  columnFilters.value.filter[i].column === 'booking_name' ||
                  columnFilters.value.filter[i].column === 'order_id' ||
                  ((columnFilters.value.filter[i].column === 'created_by' &&
                  is_filter_for === 'meetings') || 
                  (columnFilters.value.filter[i].column === 'created_by' &&
                  is_filter_for === 'appointments') ||
                  (columnFilters.value.filter[i].column === 'created_by' &&
                  is_filter_for === 'orders')))">Is Not</mat-option>
                    <mat-option class="font-size-12" value="is_empty" *ngIf="(columnFilters.value.filter[i].column === 'staff_id' &&
                  is_filter_for === 'appointments')">Is Empty</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="" style="width:31%" *ngIf="columnFilters.value.filter[i].operation !== 'is_empty'">
                <mat-form-field *ngIf="
              !columnFilters.value.filter[i].column ||
              columnFilters.value.filter[i].column === 'name'||
              columnFilters.value.filter[i].column === 'order_id'||
              columnFilters.value.filter[i].column === 'booking_name' ||
              (columnFilters.value.filter[i].column === 'created_by' &&
               is_filter_for === 'meetings') || 
               (columnFilters.value.filter[i].column === 'created_by' &&
               is_filter_for === 'appointments') ||
               (columnFilters.value.filter[i].column === 'created_by' &&
               is_filter_for === 'orders'
               )" appearance="outline" class="contactsfilter main-filter w-100 mat-hint-none">
                  <input style="outline: none" matInput placeholder="Enter" formControlName="data" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none" *ngIf="
              columnFilters.value.filter[i].column === 'owner' ||
              columnFilters.value.filter[i].column === 'staff_name' || 
              columnFilters.value.filter[i].column === 'staff_id' ||
              columnFilters.value.filter[i].column === 'assignee' ||
              (columnFilters.value.filter[i].column === 'created_by' &&
               is_filter_for === 'task') || 
               (columnFilters.value.filter[i].column === 'created_by' &&
               is_filter_for === 'activity')">
                  <mat-select placeholder="Value" formControlName="data">
                    <mat-option *ngFor="let item of staffMembers" class="font-size-12" [value]="item.id">{{item?.fname}}
                      {{item?.lname}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-select
                  *ngIf="columnFilters.value.filter[i].column === 'contact' || columnFilters.value.filter[i].column === 'source_id' || (columnFilters.value.filter[i].column === 'source_type' && is_filter_for !== 'appointments' && is_filter_for !== 'task') || columnFilters.value.filter[i].column === 'contact_id'"
                  class="search-ng-select ms-0" [items]="contactListArray" bindLabel="first_name" bindValue="id"
                  [multiple]="false" placeholder="Search name" (search)="searchTriggerConatct($event)"
                  formControlName="data">
                </ng-select>
                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none w-100" *ngIf=" columnFilters.value.filter[i].operation !== 'between' &&
                (columnFilters.value.filter[i].column === 'due_date' || 
                columnFilters.value.filter[i].column === 'created_at' ||
                columnFilters.value.filter[i].column === 'updated_at' ||
                    columnFilters.value.filter[i].column === 'booked_at' ||
                    columnFilters.value.filter[i].column === 'staff_timezone_date' ||
                    columnFilters.value.filter[i].column === 'date' ||
                  columnFilters.value.filter[i].column === 'ordered_at')">
                  <input style="outline: none" matInput [matDatepicker]="picker" placeholder="Date"
                    formControlName="data" (dateChange)="updateDateFormat($event,i,'date')" />
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none w-100" *ngIf="columnFilters.value.filter[i].operation === 'between' &&
                (columnFilters.value.filter[i].column === 'due_date' || 
                columnFilters.value.filter[i].column === 'created_at' ||
                columnFilters.value.filter[i].column === 'updated_at' ||
                    columnFilters.value.filter[i].column === 'booked_at' ||
                    columnFilters.value.filter[i].column === 'staff_timezone_date' ||
                    columnFilters.value.filter[i].column === 'date' ||
                  columnFilters.value.filter[i].column === 'ordered_at')">
                  <mat-date-range-input [rangePicker]="picker">
                    <input #startDateInput matStartDate (dateChange)="onDateChange($event,'start',i)"
                      placeholder="Start date" [value]="betweenFilterDates[i]?.startDate">
                    <input matEndDate #endDateInput (dateChange)="onDateChange($event,'end',i)"
                      placeholder="End date" [value]="betweenFilterDates[i]?.endDate">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none w-100"
                  *ngIf="columnFilters.value.filter[i].column === 'priority'">
                  <mat-select formControlName="data" placeholder="Priority">
                    <mat-option value="High" class="font-size-12">High</mat-option>
                    <mat-option value="Low" class="font-size-12">Low</mat-option>
                    <mat-option value="Medium" class="font-size-12">Medium</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none w-100"
                  *ngIf="columnFilters.value.filter[i].column === 'appointment_name'">
                  <mat-select formControlName="data" placeholder="Appointment Name">
                    <mat-option *ngFor="let appointment of appointmentsArray" value="{{appointment.name}}" class="font-size-12">{{appointment.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none w-100"
                  *ngIf="columnFilters.value.filter[i].column === 'booking_status'">
                  <mat-select formControlName="data" placeholder="Booking status">
                    <!-- <mat-option value="HOLD" class="font-size-12">Hold</mat-option>
                    <mat-option value="WAITING_FOR_ACCEPTANCE " class="font-size-12">Waiting for acceptance</mat-option> -->
                    <mat-option value="ACCEPTED" class="font-size-12">Accepted</mat-option>
                    <mat-option value="REJECTED" class="font-size-12">Rejected</mat-option>
                    <mat-option value="COMPLETED" class="font-size-12">Completed</mat-option>
                    <mat-option value="NO_SHOW" class="font-size-12">No show</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none w-100"
                  *ngIf="columnFilters.value.filter[i].column === 'meeting_type'">
                  <mat-select formControlName="data" placeholder="Appointment Type">
                    <mat-option value="on_line" class="font-size-12">Online</mat-option>
                    <mat-option value="off_line" class="font-size-12">Offline</mat-option>
                    <mat-option value="door_step" class="font-size-12">Door Steps</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="contactsfilter main-filter mat-hint-none w-100"
                  *ngIf="columnFilters.value.filter[i].column === 'appointment_status'">
                  <mat-select formControlName="data" placeholder="Appointment Status">
                    <mat-option value="ACCEPTED" class="font-size-12">Accepted</mat-option>
                    <mat-option value="REJECTED" class="font-size-12">Rejected</mat-option>
                    <mat-option value="COMPLETED" class="font-size-12">Completed</mat-option>
                    <mat-option value="NO_SHOW" class="font-size-12">No Show</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="main-filter mat-hint-none w-100"
                  *ngIf="columnFilters.value.filter[i].column === 'source_type' && is_filter_for === 'appointments'">
                  <mat-select formControlName="data" placeholder='Channel Lists'>
                    <mat-option value="ADMIN_PANEL" class="font-size-12">Admin Panel</mat-option>
                    <mat-option value="IFRAME" class="font-size-12">Iframe</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="main-filter mat-hint-none w-100"
                  *ngIf="columnFilters.value.filter[i].column === 'source_type' && is_filter_for === 'task'">
                  <mat-select formControlName="data" placeholder='Related to'>
                    <mat-option value="lead" class="font-size-12">Lead</mat-option>
                    <mat-option value="company" class="font-size-12">Company</mat-option>
                    <mat-option value="contact" class="font-size-12">Contact</mat-option>
                    <mat-option value="deal" class="font-size-12">Pipeline</mat-option>
                    <mat-option value="project" class="font-size-12">Client project</mat-option>
                    <mat-option value="board" class="font-size-12">Board</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div style="width: 7%;">
                <div class="col-md-1 d-flex align-items-center w-100 justify-content-end h-100"
                  *ngIf="form_array.length > 1">
                  <mat-icon class="cursor" style="font-size:20px;" (click)="deleteFilter(i)">delete</mat-icon>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="d-flex align-items-center justify-content-end px-3">
        <button class="btn btn-primary d-flex align-items-center font-size-13 my-3 ms-3 me-1"
          *ngIf="filterType === 'filters'" [disabled]="form_array.length > 2" type="button"
          (click)="initilizeFromArray()">
          Add additional filter
        </button>
        <button class="btn btn-primary d-flex align-items-center font-size-13 my-3 mx-1" type="submit">
          Apply
        </button>
        <button *ngIf="filterType === 'sort'" [disabled]="!columnFilters.value?.sorting?.sort_by"
          class="btn btn-primary d-flex align-items-center font-size-13 my-3 mx-1" type="button" (click)="resetFilters()">
          Reset
        </button>
        <button *ngIf="filterType !== 'sort'" [disabled]="!columnFilters.value?.filter[0]?.column"
          class="btn btn-primary d-flex align-items-center font-size-13 my-3 mx-1" type="button" (click)="resetFilters()">
          Reset
        </button>

      </div>
    </form>
  </div>
  <!-- </div> -->
</div>