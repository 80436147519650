export const LEADSJSON = [
  {
    heading_label: 'Leads',
    Filter_label: 'Filters',
    search_placeholder: 'Search',
    label_open_enquries: 'Open Leads',
    label_lost_enquries: 'Lost Leads',
    label_junk_enquries: 'Junk Leads',
    // back_label: 'Back',
    label_add_lead: 'Add Lead',
    modal_heading: 'Add New Lead',
    btn_cancel: 'Close',
    btn_save: 'Save'
  }
];

export const ADDLEADJSON = [
  {
    heading_label: 'Add Lead',
    label_name: 'Lead Title',
    label_company: 'Company Name',
    label_phone: 'Phone Number',
    btn_add_phone: 'Add Phone',
    label_email: 'Email',
    btn_add_email: 'Add Email',
    label_tag: 'Tag',
    btn_add_tag: 'Add Tag',
    Source: 'Channel',
    product: 'Product',
    label_owner: 'Owner',
    customize_text: 'Customize your lead form',
    save_btn: 'Save',
    cancel_btn: 'Close',
    btn_cancel: 'Cancel'
  }
];