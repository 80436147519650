import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NOTIFICATIONSETTINGS } from 'src/app/core/data/form_builder_json';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {

  @Input() formId = '';
  @Input() type = '';

  form_fields_list: any = [];


  showType = 'notification';
  notificationJson = NOTIFICATIONSETTINGS[0];
  notificationData: any = [];

  notifyMeForm!: FormGroup;
  public Editor: any = ClassicEditor;
  ckconfigQue: any;
  inputEmailString = '';
  allowToAddItem = false;
  emailLists: any[] = [];

  constructor(
    private _formServices: FormBuilderService,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService
  ) {
    this.initializeNotifyMeForm();
  }

  ngOnInit(): void {
    if (this.formId) {
      this.getFormInfo();
    }
    this.getFormFields(this.formId);
    this.ckconfigQue = {
      height: 200,
      toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Outdent', 'Indent', '|', 'undo', 'redo', '|', 'Link'],
      removeButtons: 'Subscript,Superscript'
    };
  }

  initializeNotifyMeForm(data?: any) {
    this.notifyMeForm = this.formBuilder.group({
      id: [data?.id ? data.id : ''],
      status: [data?.status ? data.status : false],
      send_as_pdf: [data?.send_as_pdf ? data.send_as_pdf : false],
      notification_type: [data?.notification_type ? data.notification_type : "USER"],
      // send_to: [data?.send_to ? data.send_to : ''],
      to_emails: [data?.to_emails ? data.to_emails : ''],
      subject: [data?.subject ? data.subject : '', Validators.required],
      body: [data?.body ? data.body : '', Validators.required],
      email: [data?.email ? data.email : ''],
      // reply_to: [data?.reply_to ? data.reply_to : ''],
      sender_name: [data?.sender_name ? data.sender_name : ''],
      form_id: [this.formId, Validators.required]
    });
  }

  getFormInfo(id: string = this.formId) {
    this._formServices.getFormNotificationsMe(id, this.type).subscribe((resp: any) => {
      this.notificationData = resp.data;
      this.organisationMembersList();
      if (this.showType === 'email_notify') {
        this.onEmailaddress();
      } else if (this.showType === 'email_submitter') {
        this.onEmailsubmitter();
      }
    });
  }

  onEmailaddress() {
    this.showType = 'email_notify';
    this.initializeNotifyMeForm(this.notificationData.filter((item: any) => item.notification_type === 'USER')[0]);
    this.notifyMeForm.patchValue({
      notification_type: "USER"
    });
  }

  onEmailsubmitter() {
    this.showType = "email_submitter";
    this.initializeNotifyMeForm(this.notificationData.filter((item: any) => item.notification_type !== 'USER')[0]);
    this.notifyMeForm.patchValue({
      notification_type: "SUBMITTER"
    });
  }

  onBack() {
    this.showType = 'notification';
  }

  onNotifyMeFormSubmit() {
    if (this.showType === 'email_notify') {
      this.notifyMeForm.get('to_emails')?.addValidators([Validators.required]);
      this.notifyMeForm.get('to_emails')?.updateValueAndValidity();
    } else {
      this.notifyMeForm.get('to_emails')?.clearValidators();
      this.notifyMeForm.get('to_emails')?.updateValueAndValidity();
    }
    if (!this.notifyMeForm.valid) {
      return;
    }

    if (this.notifyMeForm.value.id) {
      this._formServices.putFormNotificationsMe(this.notifyMeForm.value, this.notifyMeForm.value.id, this.type).subscribe((resp: any) => {
        this.getFormInfo();
      });
    } else {
      this._formServices.postFormNotificationsMe(this.notifyMeForm.value, this.type).subscribe((resp: any) => {
        this.getFormInfo();
      });
    }
  }

  getFormFields(id: string) {
    this._formServices.getFormFields(id,this.type).subscribe((resp: any) => {
      this.form_fields_list = resp.data;
    });
  }

  onEmailInputChange(event: any) {
    this.inputEmailString = event.target.value;
    const regex = /^\w+([\.+-]\w+)*@\w+([\.-]\w+)*(\.\w{2,15})$/; //eslint-disable-line 
    if (!regex.test(this.inputEmailString)) {
      this.allowToAddItem = false;
    } else {
      this.allowToAddItem = true;
    }
  }

  organisationMembersList() {
    this.settingsService.getStaff_info().subscribe({
      next: (resp: any) => {
        this.emailLists = resp?.data;
      }
    });
  }
}
