import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { onOnlyNumbers, onlyNumberDecimal } from 'src/app/core/common/common-functions';
import { AuthService } from 'src/app/core/service/auth.service';
import { InvoiceService } from 'src/app/core/service/invoice.service';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss']
})
export class TaxComponent {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  table_cols = ['Tax Rate Name', 'Rate %', 'Status', 'Actions'];
  page = 1;
  taxes_list: any;
  display_no_data = false;
  addTaxForm!: FormGroup;
  modalRef: any;
  submitted = false;
  tableSkeletonLoader = true;
  featuresData: any;
  constructor(private service: InvoiceService, private modalService: NgbModal,private authService: AuthService) {
    this.getFeatues();
    this.getTaxes();
  }

  getTaxes() {
    this.tableSkeletonLoader = true;
    this.service.getTaxes(this.page).subscribe((resp: any) => {
      this.tableSkeletonLoader = false;
      this.taxes_list = resp.data;
      if (this.taxes_list.length === 0) {
        this.display_no_data = true;
      }
    });
  }

  initilizeTaxForm(data?: any) {
    this.addTaxForm = new FormGroup({
      name: new FormControl(data ? data.name : '', [Validators.required]),// Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      percentage: new FormControl(data ? data.percentage : '', Validators.required),
      type: new FormControl(data ? data.type : 2, Validators.required),
      id: new FormControl(data ? data.id : '')
    });
  }

  get f() {
    return this.addTaxForm.controls;
  }

  openAddTaxModal(content: any, data?: any) {
    this.submitted = false;
    this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
    this.initilizeTaxForm(data);
  }

  onTaxSubmit() {
    this.submitted = true;
    if (this.addTaxForm.valid) {
      if (this.addTaxForm.value.id) {
        this.service.putTaxes(this.addTaxForm.value).subscribe(() => {
          this.modalRef.dismiss('modal');
          this.getTaxes();
        });
      } else {
        this.service.postTaxes(this.addTaxForm.value).subscribe(() => {
          this.modalRef.dismiss('modal');
          this.getTaxes();
        });
      }
    }
  }

  keyDownEvent(event: any) {
    const decimalPoints = 1;
    const type = 'PERCENTAGE';
    if (event.target.value.length === 0) {
      return onOnlyNumbers(event);
    } else {
      return onlyNumberDecimal(event, decimalPoints, type);
    }
  }

  onToggleChange(ev: any, item: any) {
    this.service.putTaxes({ id: item.id, status: ev.target.checked }).subscribe(() => {
      this.getTaxes();
    });
  }

  getFeatues() {
    this.authService.getFeaturesManagement().subscribe((data: any) => {
      this.featuresData = data?.data;
    });
  }
}
