import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { debounceTime, Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { TwilioVoiceService } from '../../service/twilio-voice.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-call-feedback',
  templateUrl: './call-feedback.component.html',
  styleUrls: ['./call-feedback.component.scss']
})
export class CallFeedbackComponent implements OnInit, OnDestroy {
  @Input() contact: any;
  @Input() call_duration: any;
  contactsList: any[] = [];
  searchSubject: Subject<any> = new Subject<any>();
  feedForm!: FormGroup;
  disposition = ['No Answer', 'left voice mail', 'busy', 'gatekeeper', 'bad/wrong call',
    'not in service', 'connected- positive', 'connected - netural', 'connected - negative'];
  constructor(private authservice: AuthService,private twilioVoiceService: TwilioVoiceService,public modalService: NgbModal) {
    this.searchSubject.pipe(
      debounceTime(300)
    ).subscribe((searchTerm: any) => {
      if (searchTerm.length >= 3) {
        this.authservice.getContactsBySearch(searchTerm).subscribe((result: any) => {
          this.contactsList = [];
          result.data.filter((item: any) => {
            if (item.phone_number) {
              item.number = item.ccode + item.phone_number;
              this.contactsList.push(item);
            }
          });
        });
      } else if (searchTerm.length === 0) {
        this.getContacts();
      }
    });
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('CallSid');
  }

  ngOnInit(): void {
    this.initilizeForm();
    this.getContacts();
  }

  getContacts() {
    this.authservice.getContacts().subscribe((resp: any) => {
      this.contactsList = [];
      resp.data.filter((item: any) => {
        if (item.phone_number) {
          item.number = item.ccode + item.phone_number;
          this.contactsList.push(item);
        }
      });
    });
  }

  initilizeForm() {
    this.feedForm = new FormGroup({
      contact: new FormControl(this.contact ? this.contact.contact_id : ''),
      purpose: new FormControl(),
      disposition: new FormControl(),
      note: new FormControl(),
      experience: new FormControl(),
      CallSid: new FormControl(sessionStorage.getItem('CallSid')),
      callDuration: new FormControl(this.call_duration ? this.call_duration : '00:00:00')
    });
  }

  callExpience(text: string){
    this.feedForm.controls['experience'].setValue(text);
  }

  submitForm(){
    this.twilioVoiceService.postCallFeedback(this.feedForm.value).subscribe(() => {
      this.modalService.dismissAll();
    });
  }
}
