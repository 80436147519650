export const ADDPIPELINEMODAL = [
  {
    heading_label: 'Card',//Pipeline Records
    add_label: 'Add',
    update_label: 'Update',
    label_name: 'Flow',
    label_stage: 'Flow Stages',
    pipeline_name: 'Record Name',
    contact_name: 'Contact Name',
    email: 'Email',
    phone_number: 'Phone Number',
    due_date: 'Due Date',
    budget: 'Budget',
    label_owner: 'Owner',
    label_assignee: 'Assignee',
    label_tag: 'Tag',
    label_source: 'Channel',
    customize_text: 'Customize your flow form',
    save_btn: 'Save',
    cancel_btn: 'Close',
    btn_cancel: 'Cancel'
  }
];