import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  public stringSubject = new Subject<string>();
  public firstTabSubject = new Subject<string>();

  passValue(data: any) {
    this.stringSubject.next(data);
  }

  //home related function
  passResetToTask(data: any) {
    this.firstTabSubject.next(data);
  }
}
