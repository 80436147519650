import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'innerHTML'
})
export class InnerHTMLPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const parser = new DOMParser();
    const parsedData = parser.parseFromString(value, 'text/html');
    const dataWithoutTags = parsedData.body.innerText;
    return dataWithoutTags;
  }

}
