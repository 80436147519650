import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  constructor(private http: HttpClient) { }

  getOrders(page: number, filter_param?:string, filter?:string) {
    filter_param = filter_param ? `&${filter_param}=` : '';
    return this.http.get(
      `${environment.urlPrefix}${constant.orders}?page=${page}${filter_param ? filter_param : ''}${filter ? filter : ''}&limit=20`
    );
  }
  getOrdersBySearch(search_word: any,page:number) {
    return this.http.get(`${environment.urlPrefix}${constant.orders}?search=${search_word}&page=${page}&limit=20`);
  }

  getOrderById(id: any) {
    return this.http.get(`${environment.urlPrefix}${constant.orders}${id}/`);
  }

  updateOrderStatus(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.orders}${body.id}/`, body);
  }
  getOrdersCustomFilteredData(body:any,page:number){
    return this.http.post(`${environment.urlPrefix}${constant.custom_order_filter}?page=${page}&offset=20`, body);
  }

  //user address

  getUserAddressById(id: any) {
    // return this.http.get(`${environment.urlPrefix}${constant.profile_address}${id}/`);
  }
}
