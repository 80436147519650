<div class="container-fluid">
    <ng-container *ngIf="showType === 'Templates'">
        <div class="d-flex align-items-center flex-wrap">
            <div class="card template-cards">
                <div class="d-flex align-items-center p-3">
                    <mat-icon class="me-2">email</mat-icon>
                    <h6 class="m-0">Email</h6>
                </div>
                <div class="card-body px-3 py-1">
                    <p class="mb-0">With supporting text below as a natural lead-in to additional content.</p>
                </div>
                <div class="card-footer bg-white p-3">
                    <button class="btn btn-primary font-size-14" (click)="openTemplate()">View Templates</button>
                </div>
            </div>
            <div class="card template-cards">
                <div class="d-flex align-items-center p-3">
                    <svg class="me-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 30 30">
                        <path
                            d="M 15 3 C 8.373 3 3 8.373 3 15 C 3 17.251208 3.6323415 19.350068 4.7109375 21.150391 L 3.1074219 27 L 9.0820312 25.431641 C 10.829354 26.425062 12.84649 27 15 27 C 21.627 27 27 21.627 27 15 C 27 8.373 21.627 3 15 3 z M 10.892578 9.4023438 C 11.087578 9.4023438 11.287937 9.4011562 11.460938 9.4101562 C 11.674938 9.4151563 11.907859 9.4308281 12.130859 9.9238281 C 12.395859 10.509828 12.972875 11.979906 13.046875 12.128906 C 13.120875 12.277906 13.173313 12.453437 13.070312 12.648438 C 12.972312 12.848437 12.921344 12.969484 12.777344 13.146484 C 12.628344 13.318484 12.465078 13.532109 12.330078 13.662109 C 12.181078 13.811109 12.027219 13.974484 12.199219 14.271484 C 12.371219 14.568484 12.968563 15.542125 13.851562 16.328125 C 14.986562 17.342125 15.944188 17.653734 16.242188 17.802734 C 16.540187 17.951734 16.712766 17.928516 16.884766 17.728516 C 17.061766 17.533516 17.628125 16.864406 17.828125 16.566406 C 18.023125 16.268406 18.222188 16.319969 18.492188 16.417969 C 18.766188 16.515969 20.227391 17.235766 20.525391 17.384766 C 20.823391 17.533766 21.01875 17.607516 21.09375 17.728516 C 21.17075 17.853516 21.170828 18.448578 20.923828 19.142578 C 20.676828 19.835578 19.463922 20.505734 18.919922 20.552734 C 18.370922 20.603734 17.858562 20.7995 15.351562 19.8125 C 12.327563 18.6215 10.420484 15.524219 10.271484 15.324219 C 10.122484 15.129219 9.0605469 13.713906 9.0605469 12.253906 C 9.0605469 10.788906 9.8286563 10.071437 10.097656 9.7734375 C 10.371656 9.4754375 10.692578 9.4023438 10.892578 9.4023438 z">
                        </path>
                    </svg>
                    <h6 class="m-0">Whatsapp</h6>
                </div>
                <div class="card-body px-3 py-1">
                    <p class="mb-0">With supporting text below as a natural lead-in to additional content.</p>
                </div>
                <div class="card-footer bg-white p-3">
                    <button class="btn btn-primary font-size-14" (click)="openTemplate()">View Templates</button>
                </div>
            </div>
            <div class="card template-cards">
                <div class="d-flex align-items-center p-3">
                    <mat-icon class="me-2">sms</mat-icon>
                    <h6 class="m-0">SMS</h6>
                </div>
                <div class="card-body px-3 py-1">
                    <p class="mb-0">With supporting text below as a natural lead-in to additional content.</p>
                </div>
                <div class="card-footer bg-white p-3">
                    <button class="btn btn-primary font-size-14" (click)="openTemplate()">View Templates</button>
                </div>
            </div>
            <div class="card template-cards">
                <div class="d-flex align-items-center p-3">
                    <svg class="me-2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="26" height="26" viewBox="0 0 30 30">
                        <path
                            d="M 15.003906 3 C 8.3749062 3 3 8.373 3 15 C 3 21.627 8.3749062 27 15.003906 27 C 25.013906 27 27.269078 17.707 26.330078 13 L 25 13 L 22.732422 13 L 15 13 L 15 17 L 22.738281 17 C 21.848702 20.448251 18.725955 23 15 23 C 10.582 23 7 19.418 7 15 C 7 10.582 10.582 7 15 7 C 17.009 7 18.839141 7.74575 20.244141 8.96875 L 23.085938 6.1289062 C 20.951937 4.1849063 18.116906 3 15.003906 3 z">
                        </path>
                    </svg>
                    <h6 class="m-0">Google RCS</h6>
                </div>
                <div class="card-body px-3 py-1">
                    <p class="mb-0">With supporting text below as a natural lead-in to additional content.</p>
                </div>
                <div class="card-footer bg-white p-3">
                    <button class="btn btn-primary font-size-14" (click)="openTemplate()">View Templates</button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="showType === 'list'">
        <div class="p-3">
            <div class="row" *ngIf="!newTemplate">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-6">
                            <h6 class="fw-700 mb-0">WhatsApp Templates (30)</h6>
                        </div>
                        <div class="col-6">
                            <mat-select class="form-control float-end" style="width: 200px;"
                                [(value)]="selectedTemplateType">
                                <mat-option value="all">All</mat-option>
                                <mat-option value="Promotional">Promotional</mat-option>
                                <mat-option value="OTP">OTP</mat-option>
                                <mat-option value="Transactional">Transactional</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                [value]="selectedTemplate.id" (change)="onTemplateChange($event)">
                                <mat-radio-button class="example-radio-button" *ngFor="let list of templates_lists"
                                    [value]="list.id">
                                    <div class="row">
                                        <div class="col-9">
                                            <h6 class="text-dark">{{list.name}}</h6>
                                            <label>{{list.dsp}}</label>
                                        </div>
                                        <div class="col-3 text-center">
                                            <label class="type text-white">{{list.type}}</label><br>
                                            <label class="type status"
                                                [ngStyle]="{'background-color': list.status === 'Approved' ? '#DCE9D0' : '#E88282'}">{{list.status}}</label>
                                        </div>
                                    </div>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <h6 class="text-dark fw-600 d-flex align-items-center mt-2">Preview <mat-icon
                            class="ms-2">play_circle</mat-icon></h6>

                    <div class="preview border-radius-8 p-4 mt-5">
                        <img src="assets/images/product-sample.webp" height="300" width="320" class="rounded">
                        <h6 class="text-dark fw-700 mt-3">{{selectedTemplate.name}}</h6>
                        <p>{{selectedTemplate.dsp}}</p>
                        <h6 class="text-dark fw-700">How do you get it ?</h6>
                        <ol>
                            <li>Open the store link below</li>
                            <li>Add your favorite product </li>
                            <li>Use code <span class="fw-800 text-dark">FRESH20</span></li>
                        </ol>
                        <a style="color: #00000073;">Reply Stop to Unsubscribe</a>
                    </div>
                    <div class="text-center mt-3">
                        <div class="d-flex justify-content-between align-items-center text-center">
                            <mat-divider class="w-100 m-0"></mat-divider>
                            <p class="m-0 w-50 text-light">or</p>
                            <mat-divider class="w-100 m-0"></mat-divider>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-3">
                            <button type="button" class="btn btn-outline-primary d-flex align-items-center me-2"
                                (click)="openModel()" *ngIf="!newTemplate">
                                <mat-icon>add</mat-icon>
                                Create New Template
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="newTemplate">
                <div class="col-7">
                    <div class="d-flex align-items-center mb-4">
                        <mat-icon class="text-light d-inline-flex align-items-center cursor me-2"
                            (click)="back('template')" style="font-size: 24px;">arrow_back_ios
                        </mat-icon>
                        <h6 class="text-primary mb-0 fw-600">Create New Campaign</h6>
                    </div>
                    <form [formGroup]="templateForm">
                        <label class="mb-2 text-primary">Template Name</label>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" matInput placeholder="Name" formControlName="name" />
                        </mat-form-field>
                        <label class="mb-2 text-primary">Category</label>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-select placeholder="Select category" formControlName="category">
                                <mat-option value="all">All</mat-option>
                                <mat-option value="Promotional">Promotional</mat-option>
                                <mat-option value="OTP">OTP</mat-option>
                                <mat-option value="Transactional">Transactional</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="row">
                            <label class="mb-2 text-primary">Description</label>
                            <ckeditor [editor]="Editor" placeholder="Description" class="ckeditor-height" #editorComponent formControlName="description"
                                [config]="ckconfigQue"></ckeditor>
                        </div>
                        <div class="mt-3 mb-3">
                            <label class="mb-2 text-primary">Upload Image/Video</label>
                            <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="circle-border"
                                style="height: 205px;">
                                <ngx-dropzone-label>Drag & Drop Image/Video</ngx-dropzone-label>
                                <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)"
                                    ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f">
                                </ngx-dropzone-image-preview>
                            </ngx-dropzone>
                        </div>
                        <label class="mb-2 text-primary">URL Redirection</label>
                        <mat-form-field appearance="outline" class="w-100">
                            <label class="text-light mx-2 mb-0" matPrefix>http://</label>
                            <input style="outline: none" matInput placeholder="" formControlName="redirection_url" />
                        </mat-form-field>
                    </form>
                </div>
                <div class="col-1"></div>
                <div class="col-4">
                    <h6 class="text-dark fw-600 d-flex align-items-center mt-2">Preview <mat-icon
                            class="ms-2">play_circle</mat-icon></h6>

                    <div class="preview p-4">
                        <img src="assets/images/product-sample.webp" height="300" width="320" class="rounded">
                        <h6 class="text-dark fw-700 mt-3">{{selectedTemplate.name}}</h6>
                        <p>{{selectedTemplate.dsp}}</p>
                        <h6 class="text-dark fw-700">How do you get it ?</h6>
                        <ol>
                            <li>Open the store link below</li>
                            <li>Add your favorite product </li>
                            <li>Use code <span class="fw-800 text-dark">FRESH20</span></li>
                        </ol>
                        <a style="color: #00000073;">Reply Stop to Unsubscribe</a>
                    </div>
                    <div class="d-flex justify-content-end mt-5">
                        <button type="button" class="btn btn-light me-4">Save</button>
                        <button type="button" class="btn btn-primary">Submit for Approval</button>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <h6 class="text-primary">Guidelines to follow</h6>
                    <p style="width: 900px;" class="mt-4">Marketing Opt-Out Button
                        We recommend that you add an opt-out button to your marketing templates. This will give users an
                        option to opt out of your marketing messages without having to block your business. As a result,
                        you
                        may be able to scale messaging volume faster on WhatsApp.</p>
                </div>
            </div>
        </div>
    </ng-container>
</div>