import { Component, OnInit } from '@angular/core';
import { TIMELINEJSON } from '../../../../core/data/crm_modal_json';
import { CommonService } from 'src/app/core/service/common.service';
import { ActivatedRoute } from '@angular/router';
import { customDateTimeConversion } from 'src/app/core/common/common-functions';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent implements OnInit {
  timelineJSON = TIMELINEJSON[0];
  selected_id!: string;
  selected_type!: string;
  timeline_filter = 'All';
  CurrentPage = 1;
  currentPageTimelineList: any = [];
  orderedTimelineList: any = [];

  constructor(private route: ActivatedRoute, private service: CommonService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      // console.log("route params timeline - ", params);
      this.selected_id = params.id;
      this.selected_type = params.showtype;
      this.currentPageTimelineList = [];
      this.orderedTimelineList = [];
      if (this.selected_id) this.getTimelineList(this.selected_id, 1);
    });
  }

  getTimelineList(id: any, page: any) {
    const todayDate = this.dateConversations(new Date().toISOString());
    this.service.getUserTimeline(id, page).subscribe((resp: any) => {
      // console.log("timeline - ", resp);
      this.currentPageTimelineList = resp.data;
      if (resp.data.length !== 0) {
        this.currentPageTimelineList.forEach((item: any, index: number) => {
          const activity_array: any = [];
          this.currentPageTimelineList[index].on_date.forEach((timestamp: any, sub_index: number) => {
            const mapping_object: any = {};
            mapping_object['time_stamp'] = timestamp;
            mapping_object['activity'] = item.text[sub_index];
            const convertedDate = this.dateConversations(timestamp);
            const convertedTime = customDateTimeConversion(timestamp);
            if (convertedDate && todayDate) {
              if (convertedDate === todayDate) {
                item['convertedDate'] = 'Today';
              } else {
                const prevDay: any = convertedDate.split('/')[0];
                const toDay: any = todayDate.split('/')[0];
                if (+prevDay === toDay - 1) {
                  item['convertedDate'] = 'Yesterday';
                } else {
                  item['convertedDate'] = convertedDate;
                }
              }
            }
            mapping_object['convertedTime'] = convertedTime;
            activity_array.push(mapping_object);
          });
          this.currentPageTimelineList[index]['timeline'] = activity_array;
          this.orderedTimelineList.push(this.currentPageTimelineList[index]);
        });
      }
    });
  }

  logScrolling(event:any){
    // console.log(event)
    // if ((scrollPosition - (allMenuList[this.itemCounter] as HTMLElement)?.offsetTop) - totalHeightFromTop > 0) {
    //   this.currentlyActiveMenu = this.getMenuNameLength(this.categoryLists[this.itemCounter])
    //   this.itemCounter += 1

    // } else if ((scrollPosition - (allMenuList[this.itemCounter - 1] as HTMLElement)?.offsetTop) - totalHeightFromTop < 0) {
    //   this.itemCounter -= 1
    //   this.currentlyActiveMenu = this.getMenuNameLength(this.categoryLists[this.itemCounter - 1])
    // }
  }

  dateConversations(dateString: string) {
    const parts = dateString.split('-');
    if (parts.length === 3) {
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[0], 10);
      const dateObject = `${day.toString().padStart(2, '0')}/${(month + 1).toString().padStart(2, '0')}/${year}`;
      return dateObject;
    }
    return null;
  }


  onScroll(): void {
    this.getTimelineList(this.selected_id, ++this.CurrentPage);
  }
}
