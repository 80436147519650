<div class="card w-75 m-auto mt-5 h-15rem">
    <div class="card-body">
        <div class="row">
            <div class="col-md-6">
                <h6 class="font-size-18 fw-800">{{shareJson.heading}}</h6>
            </div>
            <div class="col-md-6 text-end">

            </div>
        </div>
        <div>
            <div class="d-flex justify-content-between my-2 align-items-center">
                <p>
                    URL
                </p>
                <button class="btn btn-light border d-flex align-items-center float-end"
                    (cdkCopyToClipboardCopied)="onClipboardCopy($event)" #tp="matTooltip" matTooltip="Copy to clipboard"
                    matTooltipPosition="above" [cdkCopyToClipboard]="LINK">
                    <mat-icon class="font-size-14 pt-1">filter_none</mat-icon>
                    {{shareJson.btn_copy}}
                </button>
            </div>

            <div class="border p-2 border-radius-10 text-light">
                {{LINK}}
            </div>
        </div>

        <div class="d-flex justify-content-between mb-2 mt-3">
            <p>
                HTML CODE
            </p>
            <button class="btn btn-light border d-flex align-items-center float-end"
                (cdkCopyToClipboardCopied)="onClipboardCopyHtml($event)" #htmltp="matTooltip" matTooltip="Copy to clipboard"
                [cdkCopyToClipboard]="HTML_CODE" matTooltipPosition="above">
                <mat-icon class="font-size-14 pt-1">filter_none</mat-icon>
                {{shareJson.btn_html}}
            </button>
        </div>
        <div class="border p-2 border-radius-10 text-light mt-2">
            <pre>{{HTML_CODE}}</pre>
        </div>
    </div>
</div>