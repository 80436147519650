<div class="table-skeleton-loader">
  <table class="table align-middle table-nowrap table-hover mb-0 file-table w-100">
    <thead>
      <tr class="position-sticky">
        <td *ngIf="fromLocation === 'CRM'" class="table-data crm">
          <div class="skeleton-loader w-100"></div>
        </td>
        <td *ngFor="let tableCol of tableColumns" class="table-data">
          <div class="skeleton-loader w-100"></div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let index of tableLength">
        <td *ngIf="fromLocation === 'CRM'" class="crm">
          <div class="skeleton-loader"></div>
        </td>
        <td *ngFor="let tableCol of tableColumns">
          <div class="skeleton-loader w-100 py-2 my-1"></div>
        </td>
      </tr>
    </tbody>
  </table>
</div>