<div class="bg-crm-info h-100 p-3 ps-4 border-0">
  <div class="container-fluid info-screen-overflow table-responsive p-0 bg-white border-none" (scroll)="onScroll()">
    <!-- <div class="d-flex align-items-center py-3 bg-white fixed-top-info"> -->
    <!-- <div class="background-on-hover me-2 d-flex align-items-center">
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over"
          class="text-primary cursor font-size-16 d-flex align-items-center justify-content-around"
          *ngIf="selected_type === 'lead'" [routerLink]="['/crm/leads']"
          routerLinkActive="router-link-active">arrow_back_ios </mat-icon>
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over"
          class="text-primary cursor font-size-16 d-flex align-items-center justify-content-around"
          *ngIf="selected_type === 'contact'" [routerLink]="['/crm/contacts']"
          routerLinkActive="router-link-active">arrow_back_ios </mat-icon>
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over"
          class="text-primary cursor font-size-16 d-flex align-items-center justify-content-around"
          *ngIf="selected_type === 'company'" [routerLink]="['/crm/companies']"
          routerLinkActive="router-link-active">arrow_back_ios </mat-icon>
        <mat-icon mat-raised-button matTooltip="Back" class="btn border-0 p-0"
          aria-label="Button that displays a tooltip when focused or hovered over"
          class="text-primary cursor font-size-16 d-flex align-items-center justify-content-around"
          *ngIf="selected_type === 'deal'" (click)="goBack()" routerLinkActive="router-link-active">arrow_back_ios
        </mat-icon>
      </div> -->
    <!-- <div class="person-name">
        <h5 class="text-primary line-height-1 fw-600 font-size-18 mb-0" *ngIf="selected_type === 'contact'">
          {{intro_obj?.first_name | titlecase}} {{intro_obj.last_name}}</h5>
        <h5 class="text-primary line-height-1 fw-600 font-size-18 mb-0" *ngIf="selected_type === 'lead'">
          {{intro_obj?.requirement_title | titlecase}}</h5>
        <h5 class="text-primary line-height-1 fw-600 font-size-18 mb-0" *ngIf="selected_type === 'company'">
          {{intro_obj.name
          | titlecase}}
        </h5>
        <h5 class="text-primary line-height-1 fw-600 font-size-18 mb-0" *ngIf="selected_type === 'deal'">
          {{cardDetails?.title
          | titlecase}}
        </h5>
         <div class="d-flex align-items-center">
          <mat-icon style="color: #9197B3;font-size: 22px">check_circle_outline</mat-icon>
          <p class="text-name-secondary ps-1 m-0">Not contacted yet</p>
        </div>
      </div> -->
    <!-- <mat-icon>more_horiz</mat-icon> -->
    <!-- </div> -->
    <div>
      <!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)"> -->
      <!-- <div class="example-box mt-3"> -->
      <div class="card border-radius-16" *ngIf="selected_type === 'deal'">
        <div class="card-body">
          <div class="d-flex align-items-center overflow-x-auto mb-2">
            <span class="h-2rem w-100 stageclip cursor"
              [ngClass]="{'fillstage': cardStageIndex >= i, 'withoutfillstage': cardStageIndex < i,'stageclip_start': i===0,'stageclip_end': i === stagesList.length-1}"
              *ngFor="let item of stagesList;let i=index" matTooltip="{{item.name}}" matTooltipPosition="above"
              (click)="stageUpdate(item.id,i)"></span>
          </div>
          <div class="font-size-12 mt-2">Created at : {{ cardDetails?.created_at | dateConversion }}</div>
        </div>
      </div>
      <div class="card border-radius-16" *ngIf="selected_type === 'lead' && intro_obj?.lost_reason_text && intro_obj.type === 'LOST'">
        <div class="card-body m-0">
          <div class="overflow-auto">
            <p class="fw-600 mb-2">Lost reason</p>
            <p class="m-0">{{intro_obj.lost_reason_text}}</p>
          </div>
        </div>
      </div>
            <div class="card border-radius-16"
              *ngIf="selected_type === 'deal' && cardDetails?.lost_reason_text">
              <div class="card-body m-0">
                <div class="overflow-auto">
                  <p class="fw-600 mb-2">Outcome</p>
                  <p class="m-0">{{cardDetails.lost_reason_text}}</p>
                </div>
              </div>
            </div>
      <div class="mb-3" *ngIf="selected_type === 'lead'">
        <div class="mb-3">
          <mat-accordion class="enquire-accord">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="w-100">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <!-- <img src="../../../../../assets/images/Vector (4).svg"> -->
                    <p class="fw-600 font-size-18 text-inner-info m-0 header-name-sec-dynamic limit-line-length-1"></p>
                    <!-- <mat-icon class="mb-1 font-size-18 d-flex align-items-center ms-3">expand_more</mat-icon> -->
                  </div>
                </mat-panel-title>
                <!-- <mat-icon class="cursor-move" cdkDragHandle>reorder</mat-icon> -->
              </mat-expansion-panel-header>
              <!-- <ng-container *ngIf="item.title === 'Contact'"> -->

              <ng-container *ngIf="intro_obj">
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Lead Id </p>
                  </div>
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <p class="text-name text-capitalize limit-line-length-1">
                      {{intro_obj?.enquiry_id ? intro_obj.enquiry_id : ""}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Title </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7 cursor">
                    <p class="text-name text-wrap w-100" (click)="intro_obj?.requirement_title?.length > 12 ? onOpenDetailBox($event,{title:'Requirement Title',value:intro_obj?.requirement_title}) : ''">
                      <span class="pe-1">:</span>
                      <!-- : {{intro_obj.requirement_title ? intro_obj.requirement_title : "-"}} -->
                      <app-table-popover class="w-100" [inputType]="'text'" [inputValue]="intro_obj?.requirement_title"
                        [formName]="'requirement_title'" [labelName]="'Title'"
                        (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Type </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <p class="text-name" *ngIf="intro_obj?.type !== 'ACTIVE'">{{intro_obj?.type ? (intro_obj?.type |
                      titlecase) : "NA" }}</p>
                    <p class="text-name" *ngIf="intro_obj?.type === 'ACTIVE'"> Open</p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Owner </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7 cursor">
                    <p class="text-name text-ellipsis w-100"
                      (click)="enquiry_owner && (enquiry_owner?.fname || enquiry_owner?.lname) ? onOpenDetailBox($event,{title:'Owner',value:enquiry_owner?.fname + ' ' + enquiry_owner?.lname}) : ''"
                      >
                      <span class="pe-1">:</span>
                      <app-table-popover class="w-100" [inputType]="'dropdown'"
                        [inputValue]="enquiry_owner?.fname + ' ' + enquiry_owner?.lname" [formName]=""
                        [labelName]="'Owner'" [dropDownItems]="staffMembers" [selectedItem]="intro_obj?.owner"
                        (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <ng-container *ngIf="!enquiry_seemore">
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Assignee </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord col-7 d-flex cursor">
                      <p class="text-name text-wrap w-100 d-flex">
                        <span class="pe-1">:</span>
                        <app-table-popover class="w-100" [inputType]="'multiselect'" [inputValue]="leadAssignee"
                          [formName]="'assignee'" [labelName]="'Assignee'" [dropDownItems]="staffMembers"
                          [selectedItem]="intro_obj.assignee"
                          (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class=" info-accord col-5">
                      <p class="text-inner-info">Due Date </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord col-7 d-flex">
                      <span class="pe-1">:</span>
                      <p class="text-name text-wrap w-100 d-flex cursor"
                        (click)="intro_obj?.due_date ? onOpenDetailBox($event,{title:'Due Date',value:intro_obj?.due_date}) : ''"
                        >
                        <app-table-popover class="w-100" [inputType]="'dateTime'" [inputValue]="intro_obj?.due_date"
                          [formName]="'due_date'" [labelName]="'Due Date'"
                          (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Tag </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord col-7 d-flex ">
                      <span class="pe-1">:</span>
                      <!-- <p class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                      {{intro_obj?.tags
                      ? intro_obj?.tags : "-"}}</p> -->
                      <div class="d-flex justify-content-between align-items-center cursor w-100"
                        (click)="intro_obj?.tag_name?.length > 12 ? onOpenDetailBox($event,{title:'Tag',value:intro_obj?.tag_name}) : ''"
                        >
                        <span class="border-radius-50 m-0 p-2 tag-color me-2" *ngIf="intro_obj?.tag_colour"
                          [style]="{'background-color': intro_obj?.tag_colour}"></span>
                        <p class="text-name text-wrap w-100 cursor" style="margin-bottom: 0px !important;">
                          <app-table-popover class="w-100" [inputType]="'dropdown'" [inputValue]="intro_obj?.tag_name"
                            [formName]="'tags'" [labelName]="'Tag'" [dropDownItems]="tagsListArray"
                            [selectedItem]="intro_obj?.tags"
                            (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3 d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Channel </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord col-7 d-flex cursor"
                      (click)="intro_obj?.channel?.length > 12 ? onOpenDetailBox($event,{title:'Channel',value:intro_obj?.channel.replace('_', ' '),isTitleCase:true}) : ''">
                      <span class="pe-1">:</span>
                      <p class="text-name text-capitalize ">{{intro_obj?.channel ? (intro_obj?.channel.replace("_", " ")|
                        titlecase) : "NA"}}</p>
                    </div>
                  </div>
                  <ng-container>
                    <div class="no-gutters" *ngFor="let item of intro_obj | keyvalue">
                      <div class="" *ngIf="item.value">
                        <div class="bottom-border p-3 cursor d-flex"
                          *ngIf="(item.key === 'scheduler_category' || item.key === 'scheduler' || item.key === 'product_category' || item.key === 'product' || item.key === 'service_category' || item.key === 'service') && isObject(item.value)">
                          <div class="d-flex w-100">
                            <div class="info-accord col-5 ">
                              <p class="limit-line-length-1" style="width: 90%;"
                                [matTooltip]="item.key.replace('_', ' ') | titlecase">{{item.key.replace("_", " ") |
                                titlecase}}</p>
                            </div>
                            <!-- <div class="col-md-1"></div> -->
                            <div class="info-accord col-7 d-flex">
                              <span class="pe-1">:</span>
                              <ng-container *ngIf="item.value">
                                <span class="text-name text-ellipsis limit-line-length-1 cursor"
                                  (click)="ele.value ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:ele.value,isTitleCase:false}) : ''"
                                  *ngFor="let ele of item.value | keyvalue">
                                  {{ ele.value }}
                                </span>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="no-gutters" *ngFor="let item of intro_obj | keyvalue">
                    <div class="">
                      <div class="" *ngIf="item.key === 'budget'">
                        <div class="no-gutters bottom-border p-3  d-flex">
                          <div class="info-accord col-5 limit-line-length-1">
                            <p class="text-inner-info limit-line-length-1" style="width: 90%;"
                              [matTooltip]="item.key.replace('_', ' ') | titlecase">{{item.key.replace("_", " ") |
                              titlecase}}</p>
                          </div>
                          <span class="pe-1">:</span>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord d-flex col-7 cursor">
                            <p class="text-name text-wrap w-100" 
                            (click)="item.value ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:item.value,isTitleCase:false}) : ''">
                              <app-table-popover class="w-100" [inputType]="'NUMBER'" [inputValue]="item.value"
                                [formName]="item.key" [labelName]="item.key"
                                (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="" *ngIf="item.key === 'priority'">
                        <div class="no-gutters bottom-border p-3  d-flex">
                          <div class="info-accord col-5 limit-line-length-1">
                            <p class="text-inner-info limit-line-length-1" style="width: 90%;"
                              [matTooltip]="item.key.replace('_', ' ') | titlecase">{{item.key.replace("_", " ") |
                              titlecase}}</p>
                          </div>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord d-flex col-7 cursor">
                            <p class="text-name text-ellipsis w-100"
                              (click)="item.value ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:item.value}) : ''"
                              >
                              <span class="pe-1">:</span>
                              <app-table-popover class="w-100" [inputType]="'SELECT_DROPDOWN'" [inputValue]="item.value"
                                [formName]="item.key" [labelName]="item.key" [dropDownItems]="priorityItems"
                                [selectedItem]="item.value"
                                (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="" *ngIf="item.key === 'country' || item.key === 'currency'">
                        <div class="no-gutters bottom-border p-3  d-flex">
                          <div class="info-accord col-5 limit-line-length-1">
                            <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}} :</p>
                          </div>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord d-flex col-7">
                            <p class="text-name text-ellipsis w-100 cursor"
                              (click)="item.value ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:item.value}) : ''"
                              >
                              <span class="pe-1">:</span>
                              <app-table-popover class="w-100" [inputType]="'SELECT_DROPDOWN'" [inputValue]="item.value"
                                [formName]="item.key" [labelName]="item.key" [dropDownItems]="countryjson"
                                [selectedItem]="item.value" *ngIf="item.key === 'country'"
                                (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                              <app-table-popover class="w-100" [inputType]="'SELECT_DROPDOWN'" [inputValue]="item.value"
                                [formName]="item.key" [labelName]="item.key" [dropDownItems]="currencyjson"
                                [selectedItem]="item.value" *ngIf="item.key === 'currency'"
                                (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Created By </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="intro_obj?.created_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Created By',value:intro_obj?.created_by_name}) : ''">
                      <span class="pe-1">:</span>
                      <p class="text-name">{{intro_obj?.created_by_name ? intro_obj.created_by_name : "NA"}}
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Created At </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7">
                      <span class="pe-1">:</span>
                      <p class="text-name">{{intro_obj?.created_at ? (intro_obj?.created_at | dateConversion) : "NA"}}
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Updated By </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="intro_obj?.updated_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Updated By',value:intro_obj?.updated_by_name}) : ''">
                      <span class="pe-1">:</span>
                      <p class="text-name">{{intro_obj?.updated_by_name ? intro_obj.updated_by_name : "NA"}}
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Updated At </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7">
                      <span class="pe-1">:</span>
                      <p class="text-name"
                        *ngIf="intro_obj?.created_at.split('.')[0] === intro_obj?.updated_at.split('.')[0]">NA</p>
                      <p class="text-name"
                        *ngIf="intro_obj?.created_at.split('.')[0] !== intro_obj?.updated_at.split('.')[0]">
                        {{intro_obj?.updated_at ? (intro_obj?.updated_at | dateConversion) : "NA"}}</p>
                    </div>
                  </div>

                  <!-- <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Description </p>
                    </div>
                    <div class="info-accord d-flex col-7 cursor">
                      <p class="text-name text-wrap w-100"
                        (click)="(intro_obj?.description?.length > 12) ? onOpenDetailBox($event,{title:'Description',value:intro_obj?.description}) : ''">
                        <span class="pe-1">:</span>
                        <app-table-popover class="w-100" [inputType]="'textarea'" [inputValue]="intro_obj?.description"
                          [formName]="'description'" [labelName]="'Description'"
                          (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                      </p>
                    </div>
                  </div> -->
                </ng-container>
                <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMore('enquiry')"><span
                    *ngIf="enquiry_seemore">See
                    more</span><span *ngIf="!enquiry_seemore">See less</span></p>
              </ng-container>
              <!-- </ng-container> -->
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="mb-3">
          <mat-accordion class="enquire-accord">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="w-100">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <p class="fw-600 font-size-18 text-inner-info m-0">Description</p>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="no-gutters bottom-border p-3  d-flex">
                <div class="info-accord d-flex cursor">
                  <p class="text-name text-wrap w-100"
                    (click)="(intro_obj?.description?.length > 12) ? onOpenDetailBox($event,{title:'Description',value:intro_obj?.description}) : ''">
                    <app-table-popover class="w-100" [inputType]="'textarea'" [inputValue]="intro_obj?.description"
                      [formName]="'description'" [labelName]="'Description'"
                      (update)="onUpdatePopOver($event, intro_obj, 'lead')"></app-table-popover>
                  </p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <mat-accordion class="additional-accord">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="w-100">
                  <div class="d-flex align-items-center justify-content-between editableData">
                    <p class="fw-600 font-size-18 text-inner-info m-0">Additional Fields</p>
                    <div class="d-flex align-items-center">
                      <!-- <mat-icon class="mb-1 font-size-18">expand_more</mat-icon> -->
                      <img src="assets/images/edit_pen.svg" class="me-3 pe-1" alt="" (click)="openEnquiryModalDialog()"
                        (click)="$event.stopPropagation();">
                      <!-- <mat-icon class="edit-icon font-size-13 rounded-top rounded-bottom text-end me-3" mat-button
                        (>edit</mat-icon> -->
                    </div>
                  </div>
                </div>

              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="intro_obj?.custom_lead_fields?.length !== 0 else no_additional_lead_data">
              <div class="no-gutters bottom-border p-3  d-flex"
                *ngFor="let item of custom_field | slice:0: slice_additional_Item" [ngStyle]="{'opacity':item.is_active ? '1' : '0.3'}"> 
                <!-- | slice:0: slice_additional_Item-->
                <div class="info-accord col-5">
                  <p class="text-inner-info">{{item.label.replace("_", " ") | titlecase}}</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <span class="pe-1">:</span>
                <div class="info-accord d-flex col-7" >
                  <!-- <p class="text-name text-ellipsis w-100"
                    *ngIf="item.data_type !== 'DATE_TIME' && item.data_type !== 'DATE' && item.data_type !== 'DATE_RANGE' && item.data_type !== 'FILE'">
                    {{item?.data ? item.data : 'NA'}}
                  </p>
                  <p class="text-name text-ellipsis w-100"
                    *ngIf="item.data_type === 'DATE_TIME' || item.data_type === 'DATE' || item.data_type === 'DATE_RANGE'">
                    <span *ngIf="item.data_type === 'DATE_RANGE'">{{item.data ? ((item.data.split('&')[0] |
                      dateConversion) + '&' + (item.data.split('&')[1] | dateConversion)) : "-"}}</span>
                    <span *ngIf="item.data_type !== 'DATE_RANGE'">{{item.data ? (item.data | dateConversion) :
                      "NA"}}</span>
                  </p> -->
                  <p class="text-name text-wrap w-100 cursor" (click)="(item.data)?.length > 12 ? onOpenDetailBox($event,{title:item.data_type,value:item.data}) : ''" >
                    <app-table-popover class="w-100"  [is_active]="item?.is_active"  [editEnable]="validateUserAccess" [inputType]="item.data_type"
                      [fieldType]="'customField'" [inputValue]="item.data" [formName]="item.id" [labelName]="item.label"
                      [dropDownItems]="item.options"
                      *ngIf="item.data_type !== 'DATE_TIME' && item.data_type !== 'DATE' && item.data_type !== 'DATE_RANGE'"
                      (update)="onUpdatePopOver($event, intro_obj, 'lead','custom_field',item)"></app-table-popover>
                    <app-table-popover class="w-100" [is_active]="item?.is_active"  [editEnable]="validateUserAccess" [inputType]="item.data_type"
                      [inputValue]="item.data" [formName]="item.id" [labelName]="item.label" [fieldType]="'customField'"
                      *ngIf="item.data_type === 'DATE_TIME' || item.data_type === 'DATE' || item.data_type === 'DATE_RANGE'"
                      (update)="onUpdatePopOver($event, intro_obj, 'lead','custom_field',item)"></app-table-popover>
                  </p>
                  <!-- <p class="text-name text-wrap w-100" *ngIf="item.data_type === 'FILE'">
                    <img src="{{item?.data?.path}}" alt="" width="150px"
                      (error)="onErrorLoadImage($event,presignedurl,item?.data?.file_name)"><br>
                    <a href="{{item?.data?.path}}" target="_blank" class="ms-2 text-dark fw-medium mt-3" download>
                      {{item?.data?.file_name}} </a>
                  </p> -->
                </div>
              </div>
              <p class="text-primary cursor m-0 p-3 fw-500" (click)="seeMoreAdditionalFields('lead')"
                *ngIf="custom_field.length>5"><span *ngIf="!addmore">See more</span><span *ngIf="addmore">See
                  less</span></p>
            </ng-container>

            <ng-template #no_additional_lead_data>
              <div class="text-center text-name-secondary py-4">No Data</div>
            </ng-template>
            <!-- </ng-container> -->
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3" *ngIf="selected_type === 'deal'">
        <div class="mb-3">
          <mat-accordion class="pipeline-accord">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <p class="fw-400 mb-0 text-primary">Flow</p>
                    <!-- <mat-icon class="mb-1 font-size-18 d-flex align-items-center ms-3">expand_more</mat-icon> -->
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container *ngIf="cardDetails">
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Flow Id</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <span class="pe-1">:</span>
                  <div class="info-accord d-flex col-7">
                    <p class="text-name text-ellipsis w-100">
                      <span>{{cardDetails.card_id}}</span>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Flow Name</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <span class="pe-1">:</span>
                  <div class="info-accord d-flex col-7 cursor"
                    (click)="(cardDetails.pipeline_name)?.length > 12 ? onOpenDetailBox($event,{title:'Flow Name',value:cardDetails.pipeline_name}) : ''">
                    <p class="text-name text-ellipsis w-100">
                      <span>{{cardDetails.pipeline_name}}</span>

                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Flow Title</p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <span class="pe-1">:</span>
                  <div class="info-accord d-flex col-7 cursor"
                      (click)="(cardDetails.title)?.length > 12 ? onOpenDetailBox($event,{title:'Flow Title',value:cardDetails.title}) : ''">
                    <p class="text-name text-ellipsis w-100">
                      <span>{{cardDetails.title}}</span>          
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Due Date</p>
                  </div>
                  <span class="pe-1">:</span>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <p class="text-name text-wrap w-100">
                      {{cardDetails?.due_date ? (cardDetails?.due_date | dateConversion) : 'NA'}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Owner</p>
                  </div>
                  <span class="pe-1">:</span>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7 cursor"
                    (click)="(cardDetails.owner_info?.fname)?.length > 8 ? onOpenDetailBox($event,{title:'Owner',value:cardDetails?.owner_info?.fname + ' ' +cardDetails?.owner_info?.lname}) : ''">
                    <p class="text-name text-ellipsis w-100">
                      {{cardDetails?.owner_info?.fname + " " +cardDetails?.owner_info?.lname }}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Assignee</p>
                  </div>
                  <span class="pe-1">:</span>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <p class="text-name text-wrap w-100" *ngFor="let staff of dealAssignee">
                      {{staff.fname + ' ' + staff.lname}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Tag</p>
                  </div>
                  <span class="pe-1">:</span>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord col-7 cursor"
                    (click)="(cardDetails.tag_name)?.length > 12 ? onOpenDetailBox($event,{title:'Tag',value:cardDetails.tag_name}) : ''"
                    >
                    <div class="d-flex justify-content-between align-items-center" *ngIf="cardDetails?.tag_colour">
                      <span class="border-radius-50 m-0 p-2 tag-color me-2"
                        [style]="{'background-color': cardDetails?.tag_colour}"></span>
                      <p class="text-name text-ellipsis w-100" style="margin-bottom: 0px !important;">
                        {{cardDetails?.tag_name ? cardDetails.tag_name : 'NA' }}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" *ngIf="!cardDetails?.tag_colour">
                      <p class="text-name">NA</p>
                    </div>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Channel </p>
                  </div>
                  <span class="pe-1">:</span>
                  <div class="info-accord d-flex col-7 cursor"
                  (click)="(cardDetails.channel)?.length > 12 ? onOpenDetailBox($event,{title:'Channel',value:cardDetails?.channel.replace('_', ''
                  ),isTitleCase:true}) : ''"
                  >
                    <p class="text-name text-capitalize ">{{cardDetails?.channel ? (cardDetails?.channel.replace("_", "
                      ") | titlecase) : "NA"}}</p>
                  </div>
                </div>
                <ng-container *ngIf="!pipeline_seemore">
                  <div class="no-gutters" *ngFor="let item of cardDetails | keyvalue">
                    <div class="" >
                      <div class=" bottom-border p-3  d-flex"
                        *ngIf="item.key === 'priority' || item.key === 'description'  || item.key === 'price' || item.key === 'company_name'  || item.key === 'scheduler_category' || item.key === 'scheduler' || item.key === 'product_category' || item.key === 'product'">
                        <!-- <div class="mb-3 bottom-border mt-2 cursor "> -->
                        <div class="info-accord col-5 text-break">
                          <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}}</p>
                        </div>
                        <span class="pe-1">:</span>
                        <!-- <div class="col-md-1"></div> -->
                        <div class="info-accord col-7 d-flex cursor"
                          (click)="item.value ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:item.value}) : ''"
                          >
                          <p class="text-name text-break limit-line-length-1 w-100 m-0" [innerHTML]="item.value">
                          </p>
                        </div>
                        <!-- </div> -->
                      </div>
                      <div class="" *ngIf="item.key === 'currency'">
                        <div class="p-3 d-flex bottom-border ">
                          <div class="info-accord col-5 text-break">
                            <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}}</p>
                          </div>
                          <span class="pe-1">:</span>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord col-7 d-flex cursor"
                            (click)="item.value ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:item.value}) : ''">
                            <p class="text-name text-wrap w-100">
                              <app-table-popover class="w-100" [inputType]="'SELECT_DROPDOWN'" [inputValue]="item.value"
                                [formName]="item.key" [labelName]="item.key" [dropDownItems]="currencyjson"
                                [selectedItem]="item.value" *ngIf="item.key === 'currency'"
                                (update)="onUpdatePopOver($event, cardDetails, 'deal')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Created By</p>
                  </div>
                  <span class="pe-1">:</span>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord col-7 d-flex cursor"
                    (click)="cardDetails?.created_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Created By',value:cardDetails?.created_by_name}) : ''"
                    >
                    <p class="text-name">{{cardDetails?.created_by_name ? cardDetails.created_by_name : "NA"}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Created At </p>
                  </div>
                  <span class="pe-1">:</span>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <p class="text-name">{{cardDetails?.created_at ? (cardDetails?.created_at | dateConversion) : "NA"}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Updated By</p>
                  </div>
                  <span class="pe-1">:</span>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7 cursor"
                    (click)="cardDetails?.updated_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Updated By',value:cardDetails?.updated_by_name}) : ''"
                    >
                    <p class="text-name">{{cardDetails?.updated_by_name ? cardDetails.updated_by_name : "NA"}}
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Updated At </p>
                  </div>
                  <span class="pe-1">:</span>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <p class="text-name">
                      {{cardDetails?.updated_at ? (cardDetails?.updated_at | dateConversion) : "NA"}}</p>
                  </div>
                </div>
                <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMore('pipeline')"><span
                    *ngIf="pipeline_seemore">See
                    more</span><span *ngIf="!pipeline_seemore">See less</span></p>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <mat-accordion class="pipeline-accord">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="w-100 d-flex align-items-center justify-content-between">
                  <p class="fw-600 text-primary mb-1">Additional Fields</p>
                  <img src="assets/images/edit_pen.svg" class="me-3 pe-1" alt=""
                    (click)="openPipelineModalDialog($event)" (click)="$event.stopPropagation();">
                  <!-- <mat-icon class="edit-icon font-size-13 rounded-top rounded-bottom text-end me-3" mat-button
                    (click)="openPipelineModalDialog($event)">edit</mat-icon> -->
                  <!-- <mat-icon class="mb-1 font-size-18 d-flex align-items-center ms-3">expand_more</mat-icon> -->
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="no-gutters " *ngIf="intro_obj">
              <div class=" d-flex align-items-center p-3 bottom-border w-100"
                *ngFor="let field of cardFields | slice:0:sliceItem">
                <div class="info-accord col-5">
                  <p class="text-inner-info">{{field.label | titlecase}}</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7 cursor">
                  <span class="pe-1">:</span>
                  <p class="text-name text-wrap w-100" (click)="field.data ? onOpenDetailBox($event,{title:field.data_type.split('_',' ') ,value:field.data}) : ''"
                    *ngIf="!field.url && field.data_type !== 'DATE_TIME' && field.data_type !== 'DATE' && field.data_type !== 'DATE_RANGE' && field.data_type !== 'FILE' && field.data_type !== 'PHONE_NUMBER'">
                    {{field.data ? ((field.data_type === "NUMBER" || field.data_type === "MULTI_SELECT_DROPDOWN") ? field.data : (field.data | titlecase)) : ""}}</p>
                  <p class="text-name text-wrap w-100"
                    *ngIf="!field.url && (field.data_type === 'DATE_TIME' || field.data_type === 'DATE' || field.data_type === 'DATE_RANGE')">
                    <span *ngIf="field.data_type === 'DATE_RANGE'"
                      (click)="field.data ? onOpenDetailBox($event,{title:field.data_type,value:field.data}) : ''">
                      {{field.data ? ((field.data.split('&')[0] |
                      dateConversion) + '&' + (field.data.split('&')[1] | dateConversion)) : ""}}</span>
                    <span *ngIf="field.data_type !== 'DATE_RANGE'">{{field.data ? (field.data | dateConversion) :
                      ""}}</span>
                  </p>
                  <p class="text-name text-wrap w-100" *ngIf="field.url === 'status' || field.url === 'source'">
                    {{field.data ? (field.data | titlecase) : "" }}</p>
                  <p class="text-name text-wrap w-100"
                    *ngIf="field.url && field.url !== 'status' && field.url !=='source'"> {{field.data_name ?
                    field.data_name : ""}}</p>
                  <p class="text-name text-wrap w-100 d-flex align-items-center limit-line-length-1" *ngIf="field.data_type === 'FILE'">
                    <img src="{{field?.data?.path}}" alt="" width="20px"
                      (error)="onErrorLoadImage($event,presignedurl,field?.data?.file_name)">
                    <a href="{{field?.data?.path}}" target="_blank" class="ms-2 text-dark fw-medium " download>
                      {{field?.data?.file_name}} </a>
                  </p>
                  <p class="text-name text-wrap w-100" *ngIf="field.data_type === 'PHONE_NUMBER'">
                    {{field.data?.custom_ccode}} {{field.data?.custom_phone_number}}</p>
                </div>
              </div>
              <p class="text-primary  fw-500 p-3 m-0" (click)="seeMoreFields()" *ngIf="cardFields.length>=5"><span
                  *ngIf="pipeline_addFields_seemore">See
                  more</span><span *ngIf="!pipeline_addFields_seemore">See less</span></p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3" *ngIf="selected_type !== 'company'">
        <div class="mb-3">
          <mat-accordion class="contact-accord">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center justify-content-between">
                      <p class="fw-600 text-primary mb-1">Related Contact</p>
                      <!-- <mat-icon class="mb-1 font-size-18 d-flex align-items-center ms-3">expand_more</mat-icon> -->
                    </div>
                    <div class="d-flex align-items-center justify-content-between" (click)="$event.stopPropagation();">
                      <!-- <div class="d-flex align-items-center">
                        <div class="avatar-group d-flex align-items-center">
                          <div
                            class="cursor avatar-xss font-size-12 avatar-group-item bg-primary text-white fw-400 border-radius-50 d-flex align-items-center justify-content-center">
                            {{contact_info?.first_name ? (contact_info?.first_name[0] | titlecase) : "-"}}
                          </div>
                        </div>
                        <p class="fw-400 mb-0 ms-2 text-inner-info"
                          (click)="onNavigteToContact(contact_info?.id,'contact')">
                          {{contact_info?.first_name ?
                          contact_info?.first_name : "-"}} {{contact_info?.last_name ?
                          contact_info?.last_name : ""}}</p>
                      </div> -->
                      <label *ngIf="contact_info && !contact_info?.is_active"
                        class="bg-danger p-1 text-white border-radius-6 font-size-10 me-3">In Activate</label>
                    </div>
                  </div>

                </mat-panel-title>
                <!-- <mat-icon class="cursor-move" cdkDragHandle>reorder</mat-icon> -->
              </mat-expansion-panel-header>
              <!-- <ng-container *ngIf="item.title === 'Contact'"> -->
              <ng-container *ngIf="contact_info else no_contact_data">
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Contact Id </p>
                  </div>
                  <div class="info-accord d-flex col-7 cursor"
                    (click)="(contact_info?.contact_id)?.length > 12 ? onOpenDetailBox($event,{title:'Contact Id',value:contact_info?.contact_id}) : ''"
                    >
                    <span class="pe-1">:</span>
                    <p class="text-name text-capitalize">{{contact_info?.contact_id ? contact_info.contact_id : ""}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex"
                  (click)="onNavigteToContact(contact_info?.id,'contact')">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">First name </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7" [ngClass]="{'cursor':validateUserAccess}">
                    <p class="text-name text-ellipsis w-100 cursor"
                      (click)="(contact_info?.first_name)?.length > 12 ? onOpenDetailBox($event,{title:'First Name',value:contact_info?.first_name}) : ''"
                      >
                      <span class="pe-1">:</span>
                      <!-- : {{contact_info.first_name ? contact_info.first_name : "-"}} -->
                      <app-table-popover class="w-100" [editEnable]="validateUserAccess" [inputType]="'text'"
                        [inputValue]="contact_info?.first_name" [formName]="'first_name'" [labelName]="'Firstname'"
                        (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Last name </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7" [ngClass]="{'cursor':validateUserAccess}">
                    <p class="text-name text-wrap w-100 cursor"
                      (click)="(contact_info?.last_name)?.length > 12 ? onOpenDetailBox($event,{title:'Last Name',value:contact_info?.last_name}) : ''">
                      <span class="pe-1">:</span>
                      <!-- {{contact_info.last_name ? contact_info.last_name : "-"}} -->
                      <app-table-popover class="w-100" [editEnable]="validateUserAccess" [inputType]="'text'"
                        [inputValue]="contact_info?.last_name" [formName]="'last_name'" [labelName]="'Lastname'"
                        (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Email </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7 cursor" [ngClass]="{'cursor':validateUserAccess}"
                    (click)="(contact_info?.email)?.length > 12 ? onOpenDetailBox($event,{title:'Email',value:contact_info?.email}) : ''"
                    >
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap w-100 limit-line-length-1"> {{contact_info?.email ?
                      contact_info?.email : "NA"}}</p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Phone </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7" [ngClass]="{'cursor':validateUserAccess}">
                    <p class="text-name text-wrap w-100 d-flex">
                      <span class="pe-1">:</span>
                      <span *ngIf="contact_info?.phone_number">{{contact_info?.ccode ? contact_info?.ccode :
                        ""}}</span>&nbsp;
                      <app-table-popover class="w-100" [editEnable]="validateUserAccess" [inputType]="'text'"
                        [cCode]="contact_info?.ccode ? contact_info?.ccode :''"
                        [inputValue]="contact_info?.phone_number" [labelName]="'Phone Number'"
                        [formName]="'phone_number'"
                        (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                    </p>
                  </div>
                </div>
                <ng-container *ngIf="!contact_seemore">
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Owner </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7" [ngClass]="{'cursor':validateUserAccess}">
                      <p class="text-name text-ellipsis w-100 cursor"
                        (click)="(contact_owner?.fname)?.length > 8 ? onOpenDetailBox($event,{title:'Owner',value:contact_owner?.fname + ' ' + contact_owner?.lname}) : ''">
                        <span class="pe-1">:</span>
                        <app-table-popover class="w-100" [editEnable]="validateUserAccess" [inputType]="'dropdown'"
                          [inputValue]="contact_owner?.fname + ' ' + contact_owner?.lname" [formName]=""
                          [labelName]="'Owner'" [dropDownItems]="staffMembers" [selectedItem]="contact_info?.owner"
                          (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Assignee </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7" [ngClass]="{'cursor':validateUserAccess}">
                      <p class="text-name text-wrap w-100">
                        <span class="pe-1">:</span>
                        <app-table-popover class="w-100" [editEnable]="validateUserAccess"
                          [inputValue]="contactAssignee" [inputType]="'multiselect'" [formName]="'assignee'"
                          [labelName]="'Assignee'" [selectedItem]="contact_info.assignee" [dropDownItems]="staffMembers"
                          (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Tag </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord col-7" [ngClass]="{'cursor':validateUserAccess}">
                      <!-- <p class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                      {{contact_info?.tags
                      ?
                      contact_info?.tags : "-"}}</p> -->
                      <div class="d-flex justify-content-between align-items-center">
                        <span class="pe-1">:</span>
                        <span class="border-radius-50 m-0 p-2 tag-color me-2" *ngIf="contact_info?.tag_colour"
                          [style]="{'background-color': contact_info?.tag_colour}"></span>
                        <p class="text-name text-ellipsis w-100" style="margin-bottom: 0px !important;" (click)="(contact_info?.tag_name)?.length > 12 ? onOpenDetailBox($event,{title:'Tag',value:contact_info.tag_name}) : ''">
                          <app-table-popover class="w-100" [editEnable]="validateUserAccess" [inputType]="'dropdown'"
                            [inputValue]="contact_info?.tag_name" [formName]="'tags'" [labelName]="'Tag'"
                            [dropDownItems]="contactstagsListArray" [selectedItem]="contact_info?.tags"
                            (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Channel </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7" [ngClass]="{'cursor':validateUserAccess}">
                      <span class="pe-1">:</span>
                      <p class="text-name text-capitalize"
                        (click)="(contact_info?.channel)?.length > 12 ? onOpenDetailBox($event,{title:contact_info?.channel.replace('_',' '),value:contact_info?.channel.replace('_',' ')}) : ''">
                        {{contact_info?.channel ? (contact_info?.channel.replace("_",
                        " ")
                        |
                        titlecase) : "NA"}}</p>
                    </div>
                  </div>
                  <div class="no-gutters" *ngFor="let item of contact_info | keyvalue">
                    <!-- <div class=""
                    *ngIf="item.key !== 'first_name' && item.key !== 'last_name' && item.key !== 'email' && item.key !== 'phone_number' && item.key !== 'owner' && item.key !== 'tags' && item.key !== 'source' && item.key !== 'updated_at' && item.key !== 'created_at'
                    && item.key !== 'assignee_department' && item.key !== 'ccode' && item.key !== 'contact_groups' && item.key !== 'custom_contact_fields' && item.key !== 'deleted_at' && item.key !== 'deleted_by' && item.key !== 'department' && item.key !== 'info' 
                    && item.key !== 'organisation' && item.key !== 'status' && item.key !== 'user_id' && item.key !== 'id' && item.key !== 'assignee' && item.key !== 'updated_by' && item.key !== 'created_by'"> -->
                    <div class=""><!--*ngIf="item.value"-->
                      <div class="no-gutters" *ngIf="item.key === 'description'">
                        <div class="bottom-border p-3  d-flex">
                          <div class="info-accord col-5">
                            <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}}</p>
                          </div>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord d-flex col-7" [ngClass]="{'cursor':validateUserAccess}">
                            <p class="text-name text-wrap w-100 cursor"
                              (click)="item.value ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:item.value}) : ''"
                              >
                              <span class="pe-1">:</span>
                              <app-table-popover class="w-100" [editEnable]="validateUserAccess"
                                [inputType]="'textarea'" [inputValue]="item?.value" [labelName]="item.key"
                                [formName]="item.key"
                                (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class=""
                        *ngIf="item.key === 'website' || item.key === 'facebook' || item.key === 'linkedin' || item.key === 'twitter' || item.key === 'instagram' || item.key === 'city' || item.key === 'billing_address' || item.key === 'billing_city' || item.key === 'shipping_address' || item.key === 'shipping_city' || item.key === 'job_title' || item.key === 'title' || item.key === 'designation' || item.key === 'zipcode'">
                        <div class="no-gutters bottom-border p-3  d-flex">
                          <div class="info-accord col-5 text-break">
                            <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}}</p>
                          </div>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord col-7 d-flex cursor"
                            (click)="onOpenLink(item)"
                            >
                            <p class="text-name text-wrap w-100">
                              <span class="pe-1">:</span>
                              <app-table-popover class="w-100" [editEnable]="validateUserAccess" [inputType]="'TEXT'"
                                [inputValue]="item.value" [formName]="item.key" [labelName]="item.key"
                                (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="" *ngIf="item.key === 'country' || item.key === 'time_zone'">
                        <div class="no-gutters bottom-border p-3  d-flex">
                          <div class="info-accord col-5 text-break">
                            <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}} </p>
                          </div>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord col-7 d-flex" [ngClass]="{'cursor':validateUserAccess}">
                            <p class="text-name text-wrap w-100"
                              (click)="item.value ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:item.value}) : ''">
                              <span class="pe-1">:</span>
                              <app-table-popover class="w-100" [editEnable]="validateUserAccess"
                                [inputType]="'SELECT_DROPDOWN'" [inputValue]="item.value" [formName]="item.key"
                                [labelName]="item.key"
                                [dropDownItems]="item.key === 'country' ? countryjson : timezonejson"
                                [selectedItem]="item.value"
                                (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="" *ngIf="item.key === 'contact_type'">
                        <div class="no-gutters bottom-border p-3  d-flex">
                          <div class="info-accord col-5 text-break">
                            <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}} </p>
                          </div>
                          <div class="info-accord d-flex col-7" [ngClass]="{'cursor':validateUserAccess}">
                            <p class="text-name text-wrap w-100"
                            (click)="item.value ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:item.value}) : ''">
                              <span class="pe-1">:</span>
                              <app-table-popover class="w-100" [editEnable]="validateUserAccess"
                                [inputType]="'dropdown'" [inputValue]="item.value" [formName]="item.key"
                                [labelName]="item.key" [dropDownItems]="contactTypeItems" [selectedItem]="item.value"
                                (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class=""
                        *ngIf="item.key === 'whatsapp_subscription' || item.key === 'email_subscription' || item.key === 'sms_subscription'">
                        <div class="no-gutters bottom-border p-3  d-flex">
                          <div class="info-accord text-break col-5">
                            <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}} </p>
                          </div>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord d-flex col-7" [ngClass]="{'cursor':validateUserAccess}">
                            <p class="text-name text-wrap w-100">
                              <span class="pe-1">:</span>
                              <app-table-popover class="w-100" [editEnable]="validateUserAccess"
                                [inputType]="'RADIO_BUTTON'" [inputValue]="item.value" [formName]="item.key"
                                [labelName]="item.key"
                                (update)="onUpdatePopOver($event, contact_info, 'contact')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex" >
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Resume</p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="contact_info?.resume?.length > 12 ? onOpenDetailBox($event,{title:'Resume',value:contact_info?.resume}) : ''">
                      <span class="pe-1">:</span>
                      <p *ngIf="contact_info.resume" class="text-name"><a href="{{contact_info.resume}}" target="_blank" class=" ms-2 text-dark fw-medium" download>
                        {{'Download Now'}}
                      </a>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Created By </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="contact_info?.created_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Created By',value:contact_info?.created_by_name}) : ''"
                      >
                      <span class="pe-1">:</span>
                      <p class="text-name">{{contact_info?.created_by_name ? contact_info.created_by_name : "NA"}}
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Created At </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7">
                      <span class="pe-1">:</span>
                      <p class="text-name">{{contact_info?.created_at ? (contact_info?.created_at | dateConversion) :
                        "NA"}}
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Updated By </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="contact_info?.updated_by_name?.length > 12 ? onOpenDetailBox($event,{title:'Updated By',value:contact_info?.updated_by_name}) : ''"
                      >
                      <span class="pe-1">:</span>
                      <p class="text-name">{{contact_info?.updated_by_name ? contact_info.updated_by_name : "NA"}}
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Updated At </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7">
                      <span class="pe-1">:</span>
                      <p class="text-name"
                        *ngIf="contact_info?.created_at.split('.')[0] === contact_info?.updated_at.split('.')[0]">NA</p>
                      <p class="text-name"
                        *ngIf="contact_info?.created_at.split('.')[0] !== contact_info?.updated_at.split('.')[0]">
                        {{contact_info?.updated_at ? (contact_info?.updated_at | dateConversion) :
                        "NA"}}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMore('contact')"><span
                    *ngIf="contact_seemore">See
                    more</span><span *ngIf="!contact_seemore">See less</span></p>
              </ng-container>

              <ng-template #no_contact_data>
                <div class="text-center text-name-secondary py-4">No Data</div>
              </ng-template>
              <!-- </ng-container> -->
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <mat-accordion *ngIf="selected_type === 'contact'" class="additional-accord">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="w-100">
                  <div class="d-flex align-items-center justify-content-between editableData">
                    <p class="fw-600 text-primary mb-1">Additional Fields</p>
                    <div class="d-flex align-items-center">
                      <!-- <mat-icon class="mb-1 font-size-18 ms-3">expand_more</mat-icon> -->
                      <img src="assets/images/edit_pen.svg" class="me-3 pe-1" alt=""
                        (click)="openContactsModalDialog(contact_info)" (click)="$event.stopPropagation();">
                      <!-- <mat-icon class="edit-icon font-size-13 rounded-top rounded-bottom text-end me-3" mat-button
                        (click)="openContactsModalDialog(contact_info)">edit</mat-icon> -->
                    </div>
                  </div>
                </div>

              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="contact_info?.custom_contact_fields.length !== 0 else no_additional_contact_data">
              <div class="" *ngIf="custom_contact_fields" >
                <div class="no-gutters bottom-border p-3  d-flex" [ngStyle]="{'opacity':item.is_active ? '1' : '0.3'}"
                  *ngFor="let item of custom_contact_fields | slice:0: slice_additional_Item">
                  <!--| slice:0: slice_additional_Item-->
                  <div class="info-accord col-5">
                    <p class="text-inner-info">{{item.label.replace("_", " ") | titlecase}} </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7 cursor">
                    <p class="text-name text-wrap w-100" (click)="item?.data?.length > 12 ? onOpenDetailBox($event,{title:item.label.replace('_', ' '),value:item?.data}) : ''">
                      <span class="pe-1">:</span>
                      <app-table-popover class="w-100" [editEnable]="validateUserAccess" [inputType]="item.data_type"
                        [fieldType]="'customField'" [is_active]="item?.is_active" [selectedItem]="item.data" [inputValue]="item.data" [formName]="item.id"
                        [labelName]="item.label" [dropDownItems]="item.options"
                        *ngIf="item.data_type !== 'DATE_TIME' && item.data_type !== 'DATE' && item.data_type !== 'DATE_RANGE'"
                        (update)="onUpdatePopOver($event, contact_info, 'contact','custom_field',item)"></app-table-popover>
                      <app-table-popover class="w-100" [is_active]="item?.is_active" [editEnable]="validateUserAccess" [inputType]="item.data_type"
                        [inputValue]="item.data" [formName]="item.id" [selectedItem]="item.data" [labelName]="item.label"
                        [fieldType]="'customField'" 
                        *ngIf="item.data_type === 'DATE_TIME' || item.data_type === 'DATE' || item.data_type === 'DATE_RANGE'"
                        (update)="onUpdatePopOver($event, contact_info, 'contact','custom_field',item)"></app-table-popover>
                    </p>
                    <!-- <p class="text-name text-wrap w-100"
                      *ngIf="item.data_type !== 'DATE_TIME' && item.data_type !== 'DATE' && item.data_type !== 'DATE_RANGE' && item.data_type !== 'FILE'">
                      {{item?.data}}
                    </p>
                    <p class="text-name text-wrap w-100"
                      *ngIf="item.data_type === 'DATE_TIME' || item.data_type === 'DATE' || item.data_type === 'DATE_RANGE'">
                      <span *ngIf="item.data_type === 'DATE_RANGE'">{{item.data ? ((item.data.split('&')[0] |
                        dateConversion) + '&' + (item.data.split('&')[1] | dateConversion)) : "-"}}</span>
                      <span *ngIf="item.data_type !== 'DATE_RANGE'">{{item.data ? (item.data | dateConversion) :
                        "NA"}}</span>
                    </p>
                    <p class="text-name text-wrap w-100" *ngIf="item.data_type === 'FILE'">
                      <img src="{{item?.data?.path}}" alt="" width="150px"
                        (error)="onErrorLoadImage($event,presignedurl,item?.data?.file_name)"><br>
                      <a href="{{item?.data?.path}}" target="_blank" class="ms-2 text-dark fw-medium mt-3" download>
                        {{item?.data?.file_name}}
                      </a>
                    </p> -->
                  </div>
                </div>
              </div>
              <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMoreAdditionalFields('contact')"
                *ngIf="custom_contact_fields.length>5"><span *ngIf="!addmore">See more</span><span *ngIf="addmore">See
                  less</span></p>
            </ng-container>

            <ng-template #no_additional_contact_data>
              <div class="text-center text-name-secondary py-4">No Data</div>
            </ng-template>
            <!-- </ng-container> -->
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="selected_type === 'contact'" class="additional-accord">
          <mat-expansion-panel [expanded]="false" class="mt-3">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="w-100">
                  <div class="d-flex align-items-center justify-content-between editableData">
                    <p class="fw-600 text-primary mb-1">Additional Info</p>
                    <!-- <mat-icon class="mb-1 font-size-18 d-flex align-items-center ms-3">expand_more</mat-icon> -->
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="chatbot_Variable.length !== 0">
              <div class="">
                <div class="no-gutters bottom-border p-3  d-flex" *ngFor="let item of chatbot_Variable | keyvalue">

                  <div class="info-accord col-5">
                    <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}} </p>
                  </div>
                  <div class="info-accord d-flex col-7 cursor">
                    <span class="pe-1">:</span>
                    <p class="text-name text-wrap w-100" (click)="item?.value?.length > 12 ? onOpenDetailBox($event,{title:item.key.replace('_', ' '),value:item?.value}) : ''">
                      {{item?.value}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="chatbot_Variable.length === 0">
              <div class="text-center text-name-secondary py-4">No Data</div>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="mb-3" *ngIf="selected_type !== 'deal'">
        <div class="mb-3">
          <mat-accordion class="company-accord">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title (click)="$event.stopPropagation();">
                  <div class="w-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <p class="fw-600 text-primary mb-1">Related Company</p>
                      <!-- <mat-icon class="mb-1 font-size-18 d-flex align-items-center ms-3">expand_more</mat-icon> -->
                    </div>
                    <!-- <div class="d-flex align-items-center" (click)="$event.stopPropagation();">
                      <div class="avatar-group d-flex align-items-center">
                        <div
                          class="avatar-xss font-size-12 avatar-group-item bg-primary text-white fw-400 border-radius-50 d-flex align-items-center justify-content-center">
                          {{company_info?.name ? (company_info?.name[0] | titlecase) : "NA" }}
                        </div>
                      </div>
                      <p class="fw-400 mb-0 ms-2 text-inner-info" [routerLink]="['../crm-details']"
                        [queryParams]="{ id: company_info?.id, showtype: 'company'}">
                        <span *ngIf="selected_type==='company'">{{company_info?.name ? company_info?.name :
                          "NA"}}</span>

                        <app-table-popover [inputType]="'dropdown'" [inputValue]="company_info?.name" [formName]=""
                          [labelName]="'Company Name'" [dropDownItems]="companiesListArray"
                          [selectedItem]="intro_obj?.company"
                          (update)="onUpdatePopOver($event, intro_obj, selected_type)"
                          *ngIf="selected_type!=='company'"></app-table-popover>
                      </p>
                    </div> -->
                  </div>
                </mat-panel-title>
                <!-- <mat-icon class="cursor-move" cdkDragHandle>reorder</mat-icon> -->
              </mat-expansion-panel-header>
              <!-- <ng-container *ngIf="item.title === 'Company'"> -->
              <ng-container *ngIf="company_info else no_company_data">
                <div class="no-gutters bottom-border p-3 cursor d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Company Id </p>
                  </div>
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <p class="text-name text-capitalize limit-line-length-1 cursor"
                      (click)="(company_info?.company_id)?.length > 12 ? onOpenDetailBox($event,{title:'Company Id',value:company_info?.company_id}) : ''"
                      >
                      {{company_info?.company_id ? company_info.company_id : "NA"}}
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Name </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7 cursor">
                    <p class="text-name w-100" (click)="onNavigteToCompany(company_info?.id,'company')">
                      <span class="pe-1">:</span>
                      <span *ngIf="selected_type!=='company'">{{company_info?.name ? company_info?.name : ""}}</span>
                      <app-table-popover class="w-100" [inputType]="'text'" [inputValue]="company_info?.name"
                        [labelName]="'Name'" [formName]="'name'"
                        (update)="onUpdatePopOver($event, company_info, 'company')"
                        *ngIf="selected_type==='company'"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Email </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7 cursor">
                    <span class="pe-1">:</span>
                    <p class="text-name limit-line-length-1" (click)="(company_info?.email)?.length > 12 ? onOpenDetailBox($event,{title:'Email',value:company_info?.email}) : ''" >
                      {{company_info?.email ? company_info?.email : "NA"}}</p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Phone </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7">
                    <span class="pe-1">:</span>
                    <span *ngIf="company_info?.phonenumber">{{company_info?.ccode ? company_info?.ccode : ""}}</span>
                    <p class="text-name d-flex cursor w-100">
                      <span *ngIf="selected_type!=='company'">{{company_info?.phonenumber ? company_info?.phonenumber :
                        "-"}}</span>&nbsp;<app-table-popover [inputType]="'text'" class="w-100"
                        [cCode]="company_info?.ccode ? company_info?.ccode : ''"
                        [inputValue]="company_info?.phonenumber" [labelName]="'Phone Number'"
                        [formName]="'phonenumber'" (update)="onUpdatePopOver($event, company_info, 'company')"
                        *ngIf="selected_type==='company'"></app-table-popover>
                    </p>
                  </div>
                </div>
                <div class="no-gutters bottom-border p-3  d-flex">
                  <div class="info-accord col-5">
                    <p class="text-inner-info">Website </p>
                  </div>
                  <!-- <div class="col-md-1"></div> -->
                  <div class="info-accord d-flex col-7 cursor">
                    <p class="text-name w-100"
                      (click)="company_info?.website?.length > 12 ? onOpenDetailBox($event,{title:'Website',value:company_info?.website}) : ''"
                      >
                      <span class="pe-1">:</span>
                      <span *ngIf="selected_type!=='company'">{{company_info?.website ? company_info?.website :
                        "NA"}}</span>

                      <app-table-popover class="w-100" [inputType]="'text'" [inputValue]="company_info?.website"
                        [labelName]="'Website'" [formName]="'website'"
                        (update)="onUpdatePopOver($event, company_info, 'company')"
                        *ngIf="selected_type==='company'"></app-table-popover>
                    </p>
                  </div>
                </div>
                <ng-container *ngIf="!company_seemore">
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Owner </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor">
                      <p class="text-name w-100"
                        (click)="(company_owner.fname)?.length > 12 ? onOpenDetailBox($event,{title:'Owner',value:company_owner.fname +' '+company_owner.lname}) : ''"
                        >
                        <span class="pe-1">:</span>
                        <span *ngIf="selected_type!=='company'">{{(company_owner.fname +' '+company_owner.lname) ||
                          ''}}</span>
                        <app-table-popover class="w-100" [inputType]="'dropdown'"
                          [inputValue]="(company_owner?.fname || '') + ' ' + (company_owner?.lname || '')"
                          [formName]="" [labelName]="'Owner'" [dropDownItems]="staffMembers"
                          [selectedItem]="company_info?.owner"
                          (update)="onUpdatePopOver($event, company_owner, 'company')"
                          *ngIf="selected_type==='company'"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Assignee </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor">
                      <p class="text-name text-wrap w-100">
                        <span class="pe-1">:</span>
                        <app-table-popover class="w-100" [inputType]="'multiselect'" [inputValue]="companyAssignee"
                          [formName]="'assignee'" [labelName]="'Assignee'" [dropDownItems]="staffMembers"
                          [selectedItem]="company_info.assignee"
                          (update)="onUpdatePopOver($event, company_info, 'company')"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class=" info-accord col-5">
                      <p class="text-inner-info">Tag </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord col-7">
                      <!-- <p class="text-name bg-tag d-inline-flex align-items-center px-2 border-radius-50">
                {{company_info?.tags? company_info?.tags : "-"}}</p> -->
                      <div class="d-flex justify-content-between align-items-center cursor"
                        (click)="(company_info?.tag_name)?.length > 12 ? onOpenDetailBox($event,{title:'Tag',value:company_info?.tag_name}) : ''"
                        >
                        <span class="border-radius-50 m-0 p-2 tag-color me-2" *ngIf="company_info?.tag_colour"
                          [style]="{'background-color': company_info?.tag_colour}"></span>
                        <p class="text-name text-ellipsis w-100 cursor" style="margin-bottom: 0px !important;">
                          <app-table-popover class="w-100" [inputType]="'dropdown'"
                            [inputValue]="company_info?.tag_name" [formName]="'tags'" [labelName]="'Tag'"
                            [dropDownItems]="companytagsListArray" [selectedItem]="company_info?.tags"
                            (update)="onUpdatePopOver($event, company_info, 'company')"></app-table-popover>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Channel </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7">
                      <span class="pe-1">:</span>
                      <p class="text-name text-capitalize cursor"
                        (click)="(company_info?.channel)?.length > 12 ? onOpenDetailBox($event,{title:company_info?.channel.replace('_',' '),value:company_info?.channel}) : ''"
                        >
                        {{company_info?.channel? (company_info?.channel.replace("_",
                        " ")
                        |
                        titlecase) : "NA"}}</p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Address </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor">
                      <p class="text-name w-100 text-ellipsis"
                        (click)="(company_info?.address)?.length > 12 ? onOpenDetailBox($event,{title:'Address',value:company_info?.address}) : ''"
                        >
                        <span class="pe-1">:</span>
                        <span *ngIf="selected_type!=='company'">{{company_info?.address ? company_info?.address :
                          ""}}</span>

                        <app-table-popover class="w-100" [inputType]="'text'" [inputValue]="company_info?.address"
                          [labelName]="'Address'" [formName]="'address'"
                          (update)="onUpdatePopOver($event, company_info, 'company')"
                          *ngIf="selected_type==='company'"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">City </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="(company_info?.city)?.length > 12 ? onOpenDetailBox($event,{title:'City',value:company_info?.city}) : ''"
                      >
                      <p class="text-name w-100">
                        <span class="pe-1">:</span>
                        <span *ngIf="selected_type!=='company'">{{company_info?.city ? company_info?.city :
                          ""}}</span>

                        <app-table-popover class="w-100" [inputType]="'text'" [inputValue]="company_info?.city"
                          [labelName]="'City'" [formName]="'city'"
                          (update)="onUpdatePopOver($event, company_info, 'company')"
                          *ngIf="selected_type==='company'"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Zipcode </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor">
                      <p class="text-name w-100">
                        <span class="pe-1">:</span>
                        <span *ngIf="selected_type!=='company'">{{company_info?.zipcode ? company_info?.zipcode :
                          ""}}</span>

                        <app-table-popover class="w-100" [inputType]="'text'" [inputValue]="company_info?.zipcode"
                          [labelName]="'Zipcode'" [formName]="'zipcode'"
                          (update)="onUpdatePopOver($event, company_info, 'company')"
                          *ngIf="selected_type==='company'"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Country </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="(company_info?.country)?.length > 12 ? onOpenDetailBox($event,{title:'Country',value:company_info?.country}) : ''"
                      >
                      <p class="text-name w-100">
                        <span class="pe-1">:</span>
                        <span *ngIf="selected_type!=='company'">{{company_info.country}}</span>

                        <app-table-popover class="w-100" [inputType]="'dropdown'" [inputValue]="company_info?.country"
                          [labelName]="'Country'" [formName]="'country'" [dropDownItems]="countryjson"
                          [selectedItem]="company_info?.country"
                          (update)="onUpdatePopOver($event, company_info, 'company')"
                          *ngIf="selected_type==='company'"></app-table-popover>

                      </p>
                    </div>
                  </div>

                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Created By </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="(company_info?.created_by_name)?.length > 12 ? onOpenDetailBox($event,{title:'Created By',value:company_info?.created_by_name}) : ''"
                      >
                      <span class="pe-1">:</span>
                      <p class="text-name">{{company_info?.created_by_name ? company_info.created_by_name : ""}}
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Created At </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7">
                      <span class="pe-1">:</span>
                      <p class="text-name">{{company_info?.created_at ? (company_info?.created_at | dateConversion) :
                        ""}}
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Updated By </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="(company_info?.updated_by_name)?.length > 12 ? onOpenDetailBox($event,{title:'Updated By',value:company_info?.updated_by_name}) : ''"
                      >
                      <span class="pe-1">:</span>
                      <p class="text-name">{{ company_info?.updated_by_name ? company_info.updated_by_name : ""}}
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Updated At </p>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7">
                      <span class="pe-1">:</span>
                      <p class="text-name"
                        *ngIf="company_info?.created_at.split('.')[0] === company_info?.updated_at.split('.')[0]"></p>
                      <p class="text-name"
                        *ngIf="company_info?.created_at.split('.')[0] !== company_info?.updated_at.split('.')[0]">
                        {{company_info?.updated_at ? (company_info?.updated_at | dateConversion) :
                        ""}}
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters bottom-border p-3  d-flex">
                    <div class="info-accord col-5">
                      <p class="text-inner-info">Description </p>
                    </div>
                    <!-- -->
                    <!-- <div class="col-md-1"></div> -->
                    <div class="info-accord d-flex col-7 cursor"
                      (click)="(company_info?.description)?.length > 12 ? onOpenDetailBox($event,{title:'Description',value:company_info?.description}) : ''"
                      >
                      <p class="text-name text-wrap w-100">
                        <span class="pe-1">:</span>
                        <span *ngIf="selected_type!=='company'" [innerHTML]="company_info?.description ? company_info?.description :
                  ''"></span>

                        <app-table-popover class="w-100" [inputType]="'textarea'"
                          [inputValue]="company_info?.description" [labelName]="'Description'"
                          [formName]="'description'" (update)="onUpdatePopOver($event, company_info, 'company')"
                          *ngIf="selected_type==='company'"></app-table-popover>
                      </p>
                    </div>
                  </div>
                  <div class="no-gutters" *ngFor="let item of company_info | keyvalue">
                    <div class="">
                      <div class=""
                        *ngIf="item.key === 'facebook' || item.key === 'linkedin' || item.key === 'twitter' || item.key === 'instagram' || item.key === 'billing_address' || item.key === 'shipping_address'">
                        <div class="bottom-border p-3  d-flex">
                          <div class="info-accord text-break col-5">
                            <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}} </p>
                          </div>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord d-flex col-7 cursor"
                            (click)="onOpenLink(item)"
                            >
                            <p class="text-name text-ellipsis w-100">
                              <span class="pe-1">:</span>
                              <app-table-popover class="w-100" [inputType]="'TEXT'" [inputValue]="item.value"
                                [formName]="item.key" [labelName]="item.key"
                                (update)="onUpdatePopOver($event, company_info, 'company')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="" *ngIf="item.key === 'number_of_employees' || item.key === 'annual_revenue'">
                        <div class="bottom-border p-3  d-flex">
                          <div class="info-accord text-break col-5">
                            <p class="text-inner-info">{{item.key.replace("_", " ") | titlecase}} </p>
                          </div>
                          <!-- <div class="col-md-1"></div> -->
                          <div class="info-accord d-flex col-7 cursor">
                            <p class="text-name text-wrap w-100">
                              <span class="pe-1">:</span>
                              <app-table-popover class="w-100" [inputType]="'NUMBER'" [inputValue]="item.value"
                                [formName]="item.key" [labelName]="item.key"
                                (update)="onUpdatePopOver($event, company_info, 'company')"></app-table-popover>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMore('company')"><span
                    *ngIf="company_seemore">See
                    more</span><span *ngIf="!company_seemore">See less</span></p>
              </ng-container>

              <ng-template #no_company_data>
                <div class="text-center text-name-secondary py-4">No Data</div>
              </ng-template>
              <!-- </ng-container> -->
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <mat-accordion *ngIf="selected_type === 'company'" class="company-accord">
          <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="w-100">
                  <div class="d-flex align-items-center justify-content-between editableData">
                    <p class="fw-600 text-primary mb-1">Additional Fields</p>
                    <div class="d-flex align-items-center">
                      <!-- <mat-icon class="mb-1 font-size-18">expand_more</mat-icon> -->
                      <img src="assets/images/edit_pen.svg" class="me-3 pe-1" alt=""
                        (click)="openCompanyModalDialog(company_info)" (click)="$event.stopPropagation();">
                      <!-- <mat-icon class="font-size-13 edit-icon rounded-top rounded-bottom text-end" mat-button
                        (click)="openCompanyModalDialog(company_info)"
                        (click)="$event.stopPropagation();">edit</mat-icon> -->
                    </div>
                  </div>
                </div>

              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="company_info?.custom_company_fields?.length !== 0 else no_additional_data">
              <div class="no-gutters bottom-border p-3 d-flex"  [ngStyle]="{'opacity':item.is_active ? '1' : '0.3'}" *ngFor="let item of custom_field">
                <!-- | slice:0: slice_additional_Item-->
                <div class="info-accord col-5">
                  <p class="text-inner-info">{{item.label.replace("_", " ") | titlecase}}</p>
                </div>
                <!-- <div class="col-md-1"></div> -->
                <div class="info-accord d-flex col-7">
                  <!-- <p class="text-name text-wrap w-100"
                    *ngIf="item.data_type !== 'DATE_TIME' && item.data_type !== 'DATE' && item.data_type !== 'DATE_RANGE' && item.data_type !== 'FILE'">
                    {{item?.data}}
                  </p>
                  <p class="text-name text-wrap w-100"
                    *ngIf="item.data_type === 'DATE_TIME' || item.data_type === 'DATE' || item.data_type === 'DATE_RANGE'">
                    <span *ngIf="item.data_type === 'DATE_RANGE'">{{item.data ? ((item.data.split('&')[0] |
                      dateConversion) + '&' + (item.data.split('&')[1] | dateConversion)) : "-"}}</span>
                    <span *ngIf="item.data_type !== 'DATE_RANGE'">{{item.data ? (item.data | dateConversion) :
                      "NA"}}</span>
                  </p>
                  <p class="text-name text-wrap w-100" *ngIf="item.data_type === 'FILE'">
                    <img src="{{item?.data?.path}}" alt="" width="150px"
                      (error)="onErrorLoadImage($event,presignedurl,item?.data?.file_name)"><br>
                    <a href="{{item?.data?.path}}" target="_blank" class="ms-2 text-dark fw-medium mt-3" download>
                      {{item?.data?.file_name}} </a>
                  </p> -->
                  <p class="text-name text-wrap w-100 cursor" (click)="item.data ? onOpenDetailBox($event,{title:item.label.replace('_', ' '),value:item.data}) : ''">
                    <span class="pe-1">:</span>
                    <app-table-popover class="w-100" [is_active]="item?.is_active" [editEnable]="validateUserAccess" [inputType]="item.data_type"
                      [fieldType]="'customField'" [inputValue]="item.data" [selectedItem]="item.data" [formName]="item.id" [labelName]="item.label"
                      [dropDownItems]="item.options"
                      *ngIf="item.data_type !== 'DATE_TIME' && item.data_type !== 'DATE' && item.data_type !== 'DATE_RANGE'"
                      (update)="onUpdatePopOver($event, company_info, 'company','custom_field',item)"></app-table-popover>
                    <app-table-popover class="w-100" [is_active]="item?.is_active"[editEnable]="validateUserAccess" [inputType]="item.data_type"
                      [inputValue]="item.data" [selectedItem]="item.data" [formName]="item.id" [labelName]="item.label" [fieldType]="'customField'"
                      *ngIf="item.data_type === 'DATE_TIME' || item.data_type === 'DATE' || item.data_type === 'DATE_RANGE'"
                      (update)="onUpdatePopOver($event, company_info, 'company','custom_field',item)"></app-table-popover>
                  </p>
                </div>
              </div>
              <p class="text-primary cursor fw-500 p-3 m-0" (click)="seeMoreAdditionalFields('company')"
                *ngIf="custom_field.length>5"><span *ngIf="!addmore">See more</span><span *ngIf="addmore">See
                  less</span></p>
            </ng-container>

            <ng-template #no_additional_data>
              <div class="text-center text-name-secondary py-4">No Data</div>
            </ng-template>
            <!-- </ng-container> -->
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
<div class="position-absolute details-box bg-white border-radius-12 custom">
  <div class="title position-sticky limit-line-length-1 text-capitalize  border-bottom px-3 py-2 text-center fs-14 text-primary fw-500">
    {{detailsBox.detailsBoxTitle}}
  </div>
  <div class="p-3 pt-2 body-sec fs-14 text-primary" [ngClass]="detailsBox.isTitleCase ? 'text-capitalize' : '' " *ngIf="detailsBox.detailsBoxTitle !== 'DATE RANGE'" [innerHTML]="detailsBox.detailBoxBody">
  </div>
  <div class="p-3 pt-2 body-sec fs-14 text-primary" *ngIf="detailsBox.detailsBoxTitle === 'DATE RANGE'">
    {{((detailsBox.detailsBoxTitle.split('&')[0] | dateConversion) + '&' + (detailsBox.detailsBoxTitle.split('&')[1] | dateConversion))}}
  </div>
</div>


<!-- <div class="d-flex align-items-center mb-3">
                  <input class="border-0 w-100" type="text" (click)="conditionalInputs('input_field_cmpny_name',true)"
                    [(ngModel)]="company_info.name">
                  <div class="d-flex align-items-center" *ngIf="input_field_cmpny_name">
                    <mat-icon class="d-flex align-items-center font-size-16 mx-2" style="color: rgb(0, 128, 55);"
                      (click)="updateFieldChanges(company_info,'company','input_field_cmpny_name')">done</mat-icon>
                    <mat-icon class="d-flex align-items-center font-size-16" style="color: rgb(255, 0, 0);"
                      (click)="conditionalInputs('input_field_cmpny_name',false)">clear</mat-icon>
                  </div>
                </div> -->

<!-- <div class="d-flex align-items-center mb-3">
                  <input class="border-0 w-100" type="text" (click)="conditionalInputs('input_field_cmpny_email',true)"
                    [(ngModel)]="company_info.email">
                  <div class="d-flex align-items-center" *ngIf="input_field_cmpny_email">
                    <mat-icon class="d-flex align-items-center font-size-16 mx-2" style="color: rgb(0, 128, 55);"
                      (click)="updateFieldChanges(company_info,'company','input_field_cmpny_email')">done</mat-icon>
                    <mat-icon class="d-flex align-items-center font-size-16" style="color: rgb(255, 0, 0);"
                      (click)="conditionalInputs('input_field_cmpny_email',false)">clear</mat-icon>
                  </div>
                </div> -->

<!-- <div class="d-flex align-items-center mb-3">
                  <input class="border-0 w-100" type="text" (click)="conditionalInputs('input_field_cmpny_phno',true)"
                    [(ngModel)]="company_info.phonenumber">
                  <div class="d-flex align-items-center" *ngIf="input_field_cmpny_phno">
                    <mat-icon class="d-flex align-items-center font-size-16 mx-2" style="color: rgb(0, 128, 55);"
                      (click)="updateFieldChanges(company_info,'company','input_field_cmpny_phno')">done</mat-icon>
                    <mat-icon class="d-flex align-items-center font-size-16" style="color: rgb(255, 0, 0);"
                      (click)="conditionalInputs('input_field_cmpny_phno',false)">clear</mat-icon>
                  </div>
                </div> -->

<!-- <div class="d-flex align-items-center mb-3">
                  <input class="border-0 w-100" type="text"
                    (click)="conditionalInputs('input_field_cmpny_website',true)" [(ngModel)]="company_info.website">
                  <div class="d-flex align-items-center" *ngIf="input_field_cmpny_website">
                    <mat-icon class="d-flex align-items-center font-size-16 mx-2" style="color: rgb(0, 128, 55);"
                      (click)="updateFieldChanges(company_info,'company', 'input_field_cmpny_website')">done</mat-icon>
                    <mat-icon class="d-flex align-items-center font-size-16" style="color: rgb(255, 0, 0);"
                      (click)="conditionalInputs('input_field_cmpny_website',false)">clear</mat-icon>
                  </div>
                </div> -->