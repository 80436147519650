<div class="modal-header">
    <h5 class="modal-title text-primary fw-600">Manage Taxes
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>
<div class="px-3 pt-3">
    <p class="text-primary">Define new or edit existing Taxes.</p>
</div>

<div class="modal-body mx-1 my-1 py-0">
    <form [formGroup]="addTaxForm" (ngSubmit)="onTaxSubmit(formDirective)" #formDirective="ngForm">

        <div class="row border-radius-8 py-2 d-flex align-items-center w-100 m-0" style="background-color: #F2F6FE;">
            <div class="col-md-3">
                <label class="mb-2 fw-500 text-primary">Tax Rate Name <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder="Enter tax name" formControlName="name" />
                    <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']">Tax Rate is required</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <label class="mb-2 fw-500 text-primary">Tax Rate <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput type="number" (keydown)="keyDownEvent($event)"  placeholder="Enter tax rate"  (wheel)="$event.preventDefault()"
                        formControlName="percentage" />
                    <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['percentage'].errors">
                        <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                        <span *ngIf="f['percentage'].errors['required']">Tax Rate is required</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3 d-none">
                <label class="mb-2 fw-500 text-primary">Type <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select placeholder="Type" formControlName="type">
                        <mat-option [value]=1>Inclusive</mat-option>
                        <mat-option [value]=2>Exclusive</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['type'].errors">
                        <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                        <span *ngIf="f['type'].errors['required']">Tax Rate is required</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-2 d-flex align-items-center">
                <button class="btn bg-white border-1 px-4 mt-2" (click)="resetTaxForm(formDirective)"
                    type="button">Clear</button>
            </div>
            <div class="col-md-2 d-flex align-items-center">
                <button class="btn btn-primary px-4 mt-2" *ngIf="!this.addTaxForm?.value?.id" type="submit">Add</button>
                <button class="btn btn-primary px-4 mt-2" *ngIf="this.addTaxForm?.value?.id"
                    type="submit">Update</button>
            </div>
        </div>
    </form>
    <div class="my-3" style="max-height:300px; overflow: auto;">
        <div class="table-responsive table-height mt-4">
            <table class="table align-middle table-nowrap table-hover mb-0 file-table">
                <thead>
                    <tr class="border-top-table">
                        <th scope="col" *ngFor="let col of table_cols" class="text-primary pb-3">{{col}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor" *ngFor="let item of taxes_list; let i = index">
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            {{ item.percentage }}
                        </td>
                        <td>
                            <label class="switch ms-3" *ngIf="loggedInUser.data.role === 'ADMIN'">
                                <input type="checkbox" [id]="item.id" [checked]="item.status"
                                    (change)="onToggleChange($event,item)">
                                <span class="slider round"></span>
                            </label>
                        </td>
                        <td><mat-icon style="font-size: 22px;" (click)="editTax(item)">edit</mat-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <!-- <mat-paginator [length]="total_count" [pageSize]="10" (page)="page($event)" aria-label="Select page"
                    hidePageSize="true" showFirstLastButtons>
                </mat-paginator> -->
            </div>
        </div>
    </div>
</div>