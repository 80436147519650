import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FORMBUILDER } from 'src/app/core/data/form_builder_json';
import { FormBuilderService } from 'src/app/core/service/form-builder.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-form-submissions',
  templateUrl: './form-submissions.component.html',
  styleUrls: ['./form-submissions.component.scss']
})
export class FormSubmissionsComponent implements OnInit {
  formId = '';
  formFields = [];
  submissions: any = [];
  headers: any = [];
  COMPONENT_LABELS: any = FORMBUILDER[0];
  submittedFormView: any;
  tableRow: any;
  tableRowView: any;
  formType: any;


  constructor(
    private route: ActivatedRoute,
    private _formServices: FormBuilderService,
    private modalService: NgbModal,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.formId = params.id;
      this.formType = params?.type;
      this.getFormSubmissions();
    });
  }

  // ngAfterViewInit() {
  // this.updateTheTableHeight();
  // }

  // updateTheTableHeight(){
  //   setTimeout(() => {
  //     this.tableRow = document.querySelectorAll(".tr");
  //     this.tableRowView = document.querySelectorAll('.trv');
  //     if (this.tableRow) {
  //       this.tableRow.forEach((data: any, index: number) => {
  //         this.renderer.setStyle(this.tableRowView[index], 'height', data.clientHeight +2+ 'px');
  //       });
  //     }
  //   },100);
  // }
  getLength(data: any, index: number) {
    return data.length - 1 !== index;
  }

  getFormSubmissions() {


    this._formServices.getFormSubmissions(this.formId,this.formType).subscribe((resp: any) => {
      this.submissions = resp.data;
      if (this.tableRow?.length === 0 && this.tableRowView?.length === 0) {
        // this.updateTheTableHeight();
      }
      this.generateList();
    });
  }

  generateList() {
    // Iterate through the array and remove duplicates
    this.submissions.forEach((ele: any) => {
      const items = Object.entries(ele.data);
      items.forEach((it: any) => {
        if (!this.headers.find((ite: any) => ite.id === it[0])) {
          this.headers.push({ name: it[1].name, id: it[0] });
        }
        if(it[1].name === "phone_number") it[1].value = it[1].value.toString();       
      });
    });

    /***
     * Code to arrang header as per form fields
    */
    this._formServices.getFormFields(this.formId).subscribe((formResp: any) => {
      this.formFields = formResp.data.map((item: any) => { return { id: item.id, label: item.label, original_label: item.original_label }; });
      const tempHeader: any[] = [];
      this.formFields.forEach((item: any) => {
        if (item.original_label === "ccode") {
          return;
        }
        const temp = this.headers.find((headerItem: any) => headerItem.id === item.id);
        this.headers = this.headers.filter(((headerItem: any) => headerItem.id !== item.id));

        if (temp && item.original_label === "phone_number") {
          // Assuming CCode name can't be changed we are using this
          const tempCCode = this.headers.find((headerItem: any) => headerItem.name === "ccode");
          this.headers = this.headers.filter(((headerItem: any) => headerItem.name !== "ccode"));
          tempHeader.push(tempCCode);
          tempHeader.push(temp);
          return;
        }

        if (temp) {
          tempHeader.push(temp);
        } else if (!temp && (item.original_label !== "ccode" || item.original_label !== "phone_number")) {
          tempHeader.push({ name: item.label, id: item.id });
        }
      });
      this.headers = [...tempHeader, ...this.headers];
    });


  }

  isObject(value: any): boolean {
    // console.log("Value: ", value);
    // console.log("typeof value === 'object' && value !== null && !Array.isArray(value)", typeof value === 'object' && value !== null && !Array.isArray(value));
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

  getType(data: any) {
    if (Array.isArray(data)) {
      return 'array';
    } else if (typeof data === 'object') {
      return 'object';
    } else {
      return 'string';
    }
  }

  onOpenFormView(content: any, form: any) {
    const originalFormFields = Object.entries(form.data);
    const tempFormFields: any = [];
    this.headers.forEach((item: any) => {
      const field = originalFormFields.find((fieldItem: any) => fieldItem[0] === item.id);
      if (field) {
        tempFormFields.push(field[1]);
      }
    });
    this.submittedFormView = form;
    this.submittedFormView.formFields = tempFormFields;

    this.modalService.open(content, { size: 'lg', centered: true });
  }

  onBack(){
    this._location.back();
  }
}
