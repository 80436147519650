<div class="container-fluid">
  <div class="h-100 overflow-auto p-2">
    <div class="d-flex align-items-center">
      <mat-icon class="text-light d-inline-flex align-items-center font-size-16 cursor" (click)="onBack()"
        matTooltip="Back">arrow_back_ios
      </mat-icon>
      <h5 class="fw-500  text-primary my-2">{{showType === 'upload' ? 'Imported Files' : 'Exported Files'}}
      </h5>
    </div>
    <div style="height: calc(100vh - 136px);" class="custom-table-div-crm-tabs-height new-design-padding d-flex flex-column justify-content-between ps-0 mt-2">
      <div class="table-responsive table-height border-radius-16">
        <table class="table align-middle table-nowrap table-hover mb-0 w-100">
          <thead>
            <tr class="border-top-table">
              <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                S.NO
              </th>
              <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                {{showType === 'upload' ? 'Uploaded File' : 'File Name'}}
              </th>
              <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                Status
              </th>
              <th *ngIf="showType === 'upload'" scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                Success Records
              </th>
              <th *ngIf="showType === 'upload'" scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                Failed Records
              </th>
              <th *ngIf="showType === 'upload'" scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                Total Records
              </th>
              <th scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                {{showType === 'upload' ? 'Uploaded at' : 'Created at'}}
              </th>
              <th *ngIf="showType !== 'upload'" scope="col" class="table-sticky-header bg-white" style="z-index: 999;">
                {{'Download'}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="cursor" *ngFor="let item of field_data; let i = index">
              <td>{{i + 1}}</td>
              <td *ngIf="showType === 'upload'">
                <a href="{{item?.file}}" target="_blank">{{item?.file_name}}</a>
              </td>
              <td *ngIf="showType !== 'upload'">
                <span>{{item?.file_name}}</span>
              </td>
  
              <td>
                <div *ngIf="item?.file_status === 'PROCESSING'">
                  <p style="background-color: #FCEED8;color: #73321B;"
                    class="text-name m-0 d-inline-flex align-items-center px-2 border-radius-50">
                    <span class="border-radius-50 m-0 tag-color me-1" style="height: 2px; padding: 3px;"
                      [style]="{'background-color': '#73321B'}"></span>
                    <span class="limit-line-length-1 word-break"> Processing...</span>
                  </p>
                </div>
                <div *ngIf="item?.file_status === 'FAIL'">
                  <p class="text-name m-0 bg-high d-inline-flex align-items-center px-2 border-radius-50">
                    <span class="border-radius-50 m-0 tag-color me-1" style="height: 2px; padding: 3px;"
                      [style]="{'background-color': '#752522'}"></span>
                    <span class="limit-line-length-1 word-break"> Failed</span>
                  </p>
                  <a class="ms-1" matTooltip="Download File" href="{{item?.faild_csv_file}}" target="_blank"
                    *ngIf="item?.faild_csv_file">
                    <img
                      style="filter: invert(27%) sepia(47%) saturate(788%) hue-rotate(316deg) brightness(98%) contrast(98%);"
                      src="assets/images/newUI/CRM/notes_download_icon.svg" alt="">
                  </a>
                </div>
                <div *ngIf="item?.file_status === 'SUCCESS'">
                  <p style="background-color: rgba(39, 174, 96, 0.06);color:rgba(39, 174, 96, 1)"
                    class="text-name m-0 d-inline-flex align-items-center px-2 border-radius-50">
                    <span class="border-radius-50 m-0 tag-color me-1" style="height: 2px; padding: 3px;"
                      [style]="{'background-color': 'rgba(39, 174, 96, 1)'}"></span>
                    <span class="limit-line-length-1 word-break"> Success</span>
                  </p>
                </div>
              </td>
              <td *ngIf="showType === 'upload'">
                {{item.success_records}}
              </td>
              <td *ngIf="showType === 'upload'">
                {{item.failed_records}}
              </td>
              <td *ngIf="showType === 'upload'">
                {{item.total_records}}
              </td>
              <td>{{item?.updated_at | dateConversion}}</td>
              <td *ngIf="showType !== 'upload'">
                <a *ngIf="item?.file_status === 'SUCCESS'" class="ms-1" matTooltip="Download File"
                  href="{{item?.csv_file}}" target="_blank">
                  <img width="24" height="24" src="assets/images/newUI/CRM/notes_download_icon.svg" alt="">
                </a>
                <span *ngIf="item?.file_status === 'PROCESSING'">Waiting</span>
                <span class="ps-1" *ngIf="item?.file_status === 'FAIL'">NA</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mat-page-custom">
        <mat-paginator [length]="total_resp?.total" [pageSize]="page_size" [pageIndex]="pageIndex"
          (page)="page($event)" aria-label="Select page" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
  </div>
</div>