<div class="overflowY-auto">
  <div class="noification-header px-2 d-flex justify-content-between py-2">
    <p class="font-size-16 m-0 me-1 text-dark "> Chat Notifications </p>
    <p class="font-size-8 m-0 ms-1 px-2 bg-color-noti pointer border-radius-50" (click)="clearAllChats()">Clear all</p>
  </div>
  <div class="d-flex flex-column-reverse align-items-center w-100 ">
    <div class="d-flex align-items-center flex-column-reverse w-100 pt-2 mt-1" *ngIf="pushNotificationLists.length > 0">
      <!-- This Section is for Messages/Live Chat/Transfer Chat -->
      <div class="px-3 w-100" *ngFor="let notification of pushNotificationLists;let i = index"
        [@slideInOut]="notification.state">
        <div class="new-message-notifications border-radius-16 bg-color-noti pointer mb-2 w-100"
          (mouseenter)="showCancel[i] = true" (mouseleave)="showCancel[i] = false" (click)="onNavigateToChat(i)">
          <div class="" *ngIf="showCancel[i]" (click)="removeNotifications(i)">
            <mat-icon class="close-btn font-size-16 bg-color-noti pointer">close</mat-icon>
          </div>
          <div class="message-content">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div class="d-flex align-items-center">
                <mat-icon> chat</mat-icon>
                <p class="m-0 font-size-16 ps-1"
                  *ngIf="notification?.message?.payload?.mentions?.length === 0 && !notification?.customText_heading">A
                  new message received</p>
                <p class="m-0 font-size-16 ps-1" *ngIf="notification?.customText_heading">
                  {{notification?.customText_heading}}</p>
                <p class="m-0 font-size-16 ps-1"
                  *ngIf="notification?.message?.payload?.mentions?.length > 0 && !notification?.customText_heading">
                  Someone mentioned you</p>
              </div>
              <p class="m-0 font-size-12">{{notification.formatedTime}}</p>
            </div>
            <div>
              <p class="m-0 font-size-14 mb-2 text-capitalize limit-line-length-1">{{notification?.userName}} <span
                  *ngIf="notification.requestType === 'exitChat'" class="ms-1 text-lowercase ">left the chat.</span></p>
              <p class="m-0 font-size-14"
                [ngClass]="(notification.requestType === 'addUser' || notification.type === 'transferInitiated') ? 'd-flex':'limit-line-length-1'">
                {{notification?.message?.payload?.text || notification?.customText}} <span
                  *ngIf="notification.requestType === 'addUser' || notification.type === 'transferInitiated'"><mat-icon
                    class="snooz-icon ms-2">notification_important</mat-icon></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- This Section is for New Audio / Video Calls -->
    <div class="d-flex align-items-center pt-2 w-100 flex-column-reverse audio-video-card"
      *ngIf="pushCallNotificationLists.length > 0">
      <div class="px-3 w-100" *ngFor="let notification of pushCallNotificationLists;let i = index"
        [@slideInOut]="notification.state">
        <div class="new-call-notifications border-radius-16 animation mb-2 bg-color-noti">
          <div class="message-content">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <div class="d-flex align-items-center justify-content-between">
                <mat-icon *ngIf="notification?.callType === 'audio'"> phone_callback</mat-icon>
                <mat-icon *ngIf="notification?.callType === 'video'">video_call</mat-icon>
                <p class="m-0 font-size-16 ps-1 text-capitalize">Incoming {{ notification?.callType}} Call </p>
              </div>
              <mat-icon class="pointer snooz-icon" (click)="muteCall()">snooze</mat-icon>
            </div>
            <p class="m-0 font-size-14 pb-3">{{notification?.userName}}</p>
            <div class="d-flex align-items-center justify-content-between">
              <button
                class="w-45 border-radius-8 text-green py-2 d-flex align-items-center justify-content-center call-action pointer "
                (click)="onAcceptCall(i)">
                <mat-icon class="me-2"> call</mat-icon> Accept</button>
              <button
                class="w-45 border-radius-8 text-danger py-2 d-flex align-items-center justify-content-center call-action pointer"
                (click)="onRejectCall(i)">
                <mat-icon class="me-2"> call_end</mat-icon> Reject</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>