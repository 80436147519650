import { style } from '@angular/animations';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CrmService } from 'src/app/core/service/crm.service';

function containsValidator(control: any) {
  const prompt = control.value;
  if (prompt && !prompt.match(/email/i)) {
    return { contains: true };
  }
  return null;
}

@Component({
  selector: 'app-ai-suggestion-modal',
  templateUrl: './ai-suggestion-modal.component.html',
  styleUrls: ['./ai-suggestion-modal.component.scss']
})
export class AiSuggestionModalComponent {
  responseObject: any[] = [];
  dropDownLists = [
    {
      id:'51a44d62-1593-4a01-88fd-c2c27b6b1ba8',
      value:"Initial Outreach Email"
    },
    {
      id:"a68e02d8-8013-40a3-9747-584c2856d89b",
      value: "Follow-Up Email"
    },
    {
      id:"da6592f5-7965-4c5e-b828-83b4cfc9b0ba",
      value: "Informational Email"
    },
    {
      id:"329e8849-e54b-4e2f-bb5f-bf68b31f80ea",
      value:"Demo or Presentation Request Email"
    },
    {
      id:"10cb95a0-c2e1-4770-8252-2d7b2f969c2e",
      value:"Reminder Email for Scheduled Demo"
    },
    {
      id:"7a0e2414-e5a9-4448-bdc7-3c1a64d89f8f",
      value:"Proposal or Quote Email"
    },
    {
      id: "a1ece598-bc7a-49a1-a0fe-0d62fb47f2e5",
      value: "Follow-Up on Proposal Email"
    },
    {
      id: "0506c337-0a58-458a-ba9d-28c3297043ba",
      value: "Negotiation Email"
    },
    {
      id: "3bed8d08-5ecb-4207-99af-fdf8c8f4dcfc",
      value: "Closing Email"
    },
    {
      id: "0cff17aa-54f4-4095-8fd4-db323503b4e7",
      value: "Post-Sale Follow-Up Email"
    }
  ];
  prompt_box_form!: FormGroup;
  isSubmitted = false;
  dropDownSelections = '';

  constructor(private activeModal: NgbActiveModal, private crmService: CrmService, private fb: FormBuilder) {
    console.log('error');
    this.initForm();
  }

  initForm() {
    this.prompt_box_form = this.fb.group({
      prompt: [''],
      suggestionBox: ['', [Validators.required]]
    });
  }
  get f() {
    return this.prompt_box_form.controls;
  }

  onSelectingItems(response: any) {
    this.activeModal.close(response);
    this.responseObject = [];
  }
  onCheckboxChange(event: any) {
    if (event.target.checked) {
      this.dropDownSelections = '';
      const dropDownBox: HTMLElement = document.querySelector('.drop-down-box')!;
      dropDownBox && (dropDownBox.style.display = 'none');
      const inputBox: HTMLElement = document.querySelector('.prompt-box')!;
      inputBox && (inputBox.style.display = 'block');
      this.prompt_box_form.get('suggestionBox')?.clearValidators();
      this.prompt_box_form.get('suggestionBox')?.markAsUntouched();
      this.prompt_box_form.get('suggestionBox')?.updateValueAndValidity();
      this.prompt_box_form.get('prompt')?.setValidators([Validators.required, containsValidator]);
      this.prompt_box_form.get('prompt')?.updateValueAndValidity();
      this.prompt_box_form.get('prompt')?.markAsUntouched();
    } else {
      this.prompt_box_form.controls['prompt'].setValue('');
      const inputBox: HTMLElement = document.querySelector('.prompt-box')!;
      inputBox && (inputBox.style.display = 'none');
      const dropDownBox: HTMLElement = document.querySelector('.drop-down-box')!;
      dropDownBox && (dropDownBox.style.display = 'block');
      this.prompt_box_form.get('prompt')?.clearValidators();
      this.prompt_box_form.get('prompt')?.markAsUntouched();
      this.prompt_box_form.get('prompt')?.updateValueAndValidity();
      this.prompt_box_form.get('suggestionBox')?.setValidators([Validators.required, containsValidator]);
      this.prompt_box_form.get('suggestionBox')?.updateValueAndValidity();
      this.prompt_box_form.get('suggestionBox')?.markAsUntouched();
    }
  }
  onChangeSelections(event: any) {
    this.dropDownSelections = event.value;
  }

  onGenerateResponse() {
    this.isSubmitted = true;
    if (this.prompt_box_form.valid) {
      let payload = {};
      if (this.dropDownSelections) {
        payload = {
          promptId: this.dropDownSelections
        };
      } else {
        payload = {
          prompt: this.prompt_box_form.controls['prompt'].value
        };
      }
      this.crmService.aiGenerateMails(payload).subscribe((response: any) => {
        if (response.data) {
          response.data.subject = response.data.subject.replace('Subject: ', '');
          this.responseObject.unshift(response.data);
        }
      });
    }
  }
}
