export const FORMBUILDER = [
  {
    create_form: "New Form",
    heading: "Web Forms",
    submissions: "View",
    col_tab_1: "S.No",
    create_heading: 'Create Form',
    name_feild: 'Form Name',
    contact_type_field: "Contact Type",
    cancel_btn: 'Cancel',
    submit_btn: 'Create',
    submissions_data: 'Submissions',
    back_icon: 'arrow_back_ios',
    client_visibility: 'Client Visibility'

  }
];
export const CREATEFORM = [
  {
    icon_search: "search",
    heading: "Fields",
    palcehold_search: 'Search',
    icon_indicator: 'drag_indicator',
    icon_delete: "delete",
    icon_settings: "settings",
    drop_text: 'Drop your cards',
    name_feild: 'Form Name',
    appointment_text_field: 'Appointments list displayed dynamically',
    product_text_field: 'Products list displayed dynamically',
    cancel_btn: 'Cancel',
    submit_btn: 'Save'
  }
];
export const SETTINGSFORM = [
  {
    heading: 'Settings',
    label_name: 'Label Name',
    placeholder_name: 'Placeholder',
    name: 'Form Name',
    option_name: 'Option',
    add_option: 'Add Option',
    placeholder_select: 'Select',
    create_service: 'Create Service',
    cancel_btn: 'Cancel',
    submit_btn: 'Done',
    icon_close: 'close',
    please_fill_all_fields: "Please fill all fields"
  }
];
export const FORMTABS = [
  {
    backward_icon: 'arrow_backward',
    tab_one: 'Edit Form',
    tab_two: 'Notifications',
    tab_three: 'Integrations',
    tab_four: 'Configure Web Form Settings',
    tab_five: 'Thank You Page Settings',
    tab_six: 'Share',
    tab_show_user: "Show User",
    tab_ask_user: "Ask User"
  }
];
export const NOTIFICATIONSETTINGS = [
  {
    heading: 'Notification Settings',
    circle_icon: 'circle',
    notify_email: 'Notify to my email address',
    notify_submiter: 'Notify to submitter email address',
    forward_icon: 'arrow_forward',
    back_icon: 'arrow_back_ios',
    back_text: 'Back',
    heading_email_notify: 'Email notification',
    pdf_attachment: 'Send answers as PDF attachments',
    feild_email: 'Email',
    field_send_from: 'Send From',
    feild_subject: 'Subject',
    feild_reply_to: 'Reply To Email',
    message: 'Message',
    heading_submitter_email: 'Send email to the submitter',
    feild_label_email: 'Send an email notification to',
    feild_label_replyto: 'Define an email address for “Reply To”',
    feild_sender_name: 'Define a sender name',
    placeholder_sendername: 'Sender Name'
  }
];

export const THANKUPAGE = [
  {
    heading: 'Thank You Page Settings',
    show_checkbox: 'Show Thank You Page',
    feild_title: 'Title',
    feild_description: 'Description',
    btn_save: 'Save',
    field_phone: 'Phone Number',
    field_link: 'Redirect Link'
  }
];
export const FORMLEAD = [
  {
    heading: 'Configure Web Form Settings',
    show_checkbox: 'Do you wish to add form submissions to Lead?',
    show_pipeline: 'Do you wish to add form submissions to Flow?',
    pipeline_select: 'Choose Flow',
    stage_select: 'Choose Stage',
    feild_owner: 'Assign Owner',
    placeholder_owner: 'Select Owner',
    name: "Name",
    email: "Email",
    phone_number: "Phone Number",
    title: "Card Title",
    budget: "Budget",
    due_date: "Due Date",
    btn_save: 'Save'
  }
];
export const FORMINTEGRATIONS = [
  {
    heading: 'Integrations',
    tab_one: 'Web Hooks',
    tab_heading: 'Webhooks',
    webtook_dsp: 'When your form is submitted, provide information',
    webtook_dsp_two: 'to your external services via webhooks.',
    btn_add_hook: 'Add Webhook',
    model_heading: 'Add a Webhook',
    placeholder_enter: 'Enter',
    feild_url: 'URL',
    field_header: 'Headers',
    field_body: 'Body',
    field_params: 'Params',
    feild_key: 'Secret Key',
    btn_cancel: 'Cancel',
    btn_save: 'Save',
    table_series: 'S.no',
    actions: 'Actions'
  }
];
export const SHAREFORM = [
  {
    heading: 'Form URL',
    btn_copy: 'Copy link',
    btn_html: 'Copy HTML Code'
  }
];