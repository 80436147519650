<div class="container-fluid">
    <div class="row my-2 py-1 customised-sec">
        <div class="col-md-4 d-flex align-items-center">
            <!-- <mat-icon class="text-primary">arrow_back_ios </mat-icon>
            <p class="m-0 text-primary fw-600 font-size-16 text-primary">{{ invoiceJson.back_label }}</p> -->
        </div>
        <div class="col-md-8 d-flex justify-content-end align-items-center">
            <mat-form-field appearance="outline" class="me-2 mat-hint-none position-relative searching-box">
                <input style="outline: none" matInput placeholder="Search Payment" [(ngModel)]="search_global" (ngModelChange)="search($event)" />
                <mat-icon matPrefix class="d-flex" style="scale: 0.7;"><img src="assets/images/search_log.svg"
                  alt=""></mat-icon>
            </mat-form-field>
            <!-- <div ngbDropdown>
                <button class="btn btn-primary d-flex align-items-center me-3" id="addinvoice" ngbDropdownToggle>
                    <mat-icon>add</mat-icon>
                    {{invoiceJson.label_add_invoice}}
                </button>
                <div ngbDropdownMenu="addinvoice">
                    <button ngbDropdownItem>Normal Invoice</button>
                    <button ngbDropdownItem>Recurring Payment</button>
                    <button ngbDropdownItem>Split Payment</button>
                </div>
            </div> -->
            <!-- <mat-icon class="me-3 text-primary">local_offer</mat-icon>
            <mat-icon class="me-3 text-primary">cloud_upload</mat-icon>
            <mat-icon> reorder</mat-icon> -->
        </div>
    </div>
    <div class="flex-column justify-content-between d-flex custom-table-div-crm-height">
        <div class="table-responsive table-height">
            <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="table_cols.length"
                [fromLocation]="'PAYMENTS'"></app-table-skeleton>
            <table *ngIf="!tableSkeletonLoader" class="table align-middle table-nowrap table-hover mb-0 file-table">
                <thead>
                    <tr class="table-header-color">
                        <!-- <th scope="col">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                    </th> -->
                        <th scope="col" *ngFor="let col of table_cols">{{col}}</th>
                    </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                    <tr *ngFor="let item of paymentArray; let i = index">
                        <!-- <td>
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(item) : null"
                            [checked]="selection.isSelected(item)"></mat-checkbox>
                    </td> -->
                        <td>
                            {{ item.reference_id }}
                        </td>
                        <td>
                            {{ item.invoice_display_id }}
                        </td>
                        <td>
                            {{ item.user_info?.first_name ? (item.user_info?.first_name + " " +
                            item.user_info?.last_name)
                            : '-' }}
                        </td>
                        <td>
                            {{ item.post_transaction
                            ?.amount ? (item.post_transaction
                            ?.amount | currencyConversion) : '-' }}
                        </td>
                        <!-- <td>
                        {{ item.issue_date | date: 'M/d/yy'}}
                    </td> -->
                        <td>
                            {{ item.mode}}
                        </td>
                        <td>
                            {{ item.transaction_state}}
                        </td>
                        <!-- <td>{{ item.billed_to[0].value }}</td>
                    <td>{{ item.billed_to[2].value }}</td>
                    <td>{{ item.billed_from[1].value }}</td> -->
                        <td>{{ item.source_type }}</td>
                        <td>{{ item.related_to | titlecase}}</td>
                        <!-- <td>{{ item.total }}</td>
                    <td>{{ item.creation_status }}</td> -->
                        <td>{{ item.created_at | dateConversion}}</td>
                        <!-- <td>
                        <div class="dropdown" ngbDropdown>
                            <mat-icon [matMenuTriggerFor]="menuadd">more_vert</mat-icon>
                            <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
                                <button mat-menu-item class="py-2">
                                    Download
                                </button>
                            </mat-menu>
                        </div>
                    </td> -->
                    </tr>
                </tbody>
            </table>
            <app-nodata-found *ngIf="display_no_data"></app-nodata-found>

        </div>
        <div>
            <mat-paginator [length]="total_count" [pageSize]="20" [pageIndex]="pageIndex" (page)="page($event)" aria-label="Select page"
                hidePageSize="true" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
</div>