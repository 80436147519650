import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { SettingsService } from 'src/app/core/service/settings.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  activeId = 1;
  activity_table_cols = ['Activity Type Name', 'Activate/Deactivate', 'Actions'];
  modalRef!: NgbModalRef;
  activityForm!: FormGroup;
  isSubmitted = false;
  activityData: Array<any> = [];
  currentPage = 1;
  totalPages = 0;
  loggedInUser:any;
  tableSkeletonLoader = true;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private settingsService: SettingsService, private confirmationMsz: ConfirmationMszService) { 
    this.loggedInUser = JSON.parse(sessionStorage.getItem('currentUser')!);
  }

  ngOnInit() {
    console.log('ngOnnit called');
    this.getAllActivities(this.currentPage);
  }

  getAllActivities(page?: any) {
    this.tableSkeletonLoader = true;
    this.settingsService.getAllActivities(page).subscribe((response: any) => {
      this.tableSkeletonLoader = false;
      this.activityData = response.data;
      this.totalPages = response.count;
    });
  }

  openAddActivityModal(content: any, data?: any) {
    if (this.loggedInUser.data.role === 'ADMIN'){
      this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
      this.initForm(data);
    }
  }

  initForm(data?: any) {
    this.activityForm = this.fb.group({
      name: [data ? data.name : '', [Validators.required]],// Validators.pattern(/^[A-Za-z][A-Za-z0-9!@#$&%?()-<>`.+,:;/-|"'\s]{1,}$/)
      id: [data ? data.id : ''],
      icon: ['NA']
    });
  }

  get f() {
    return this.activityForm.controls;
  }

  page(e: PageEvent) {
    if (e.pageIndex > e.previousPageIndex!) {
      this.currentPage = e.pageIndex + 1;
    } else if (e.pageIndex < e.previousPageIndex!) {
      this.currentPage -= 1;
    }
    this.getAllActivities(this.currentPage);
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.activityForm.valid) {
      if (this.activityForm.value.id) {
        //Update Activities
        const data = {
          id: this.activityForm.value.id,
          name: this.activityForm.value.name
        };
        this.settingsService.updateActivities(data).subscribe(() => {
          this.getAllActivities(this.currentPage);
          this.modalRef.dismiss();
        });
      } else {
        //Add Activities
        const data = {
          name: this.activityForm.value.name,
          icon: this.activityForm.value.icon
        };
        this.settingsService.postActivities(data).subscribe(() => {
          this.getAllActivities(this.currentPage);
          this.modalRef.dismiss();
        });
      }
    }
  }

  openEditActivityModal(content: any, activity: any) {
    if (!activity.standard && this.loggedInUser.data.role === 'ADMIN') {
      this.openAddActivityModal(content, activity.name);
      this.initForm(activity);
    }
  }

  onDeleteActivity(activity: any) {
    if (this.loggedInUser.data.role === 'ADMIN') {
      this.confirmationMsz.customDeleteView({ title: 'Are you sure?', text: `All the existing ${activity.name} will get deleted permanently!`, button: 'Yes Delete!' })
        .then((data) => {
          if (data.value)
            this.settingsService.deleteActivities(activity.id).subscribe(() => {
              this.getAllActivities(this.currentPage);
            });
        });
    }
  }

  onToggleChange(event: any, activity: any) {
    if (activity.standard || this.loggedInUser.data.role !== 'ADMIN') {
      event.preventDefault();
    }
    const body = {
      id: activity.id,
      status: !activity.status
    };
    this.settingsService.updateActivities(body).subscribe(() => {
      this.getAllActivities(this.currentPage);
    });
  }
}
