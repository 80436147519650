<div class="container-fluid mt-3">
    <h5 class="heading font-size-18 fw-700">{{thankupageJson.heading}}</h5>
    <form [formGroup]="thankYouForm">
        <div class="row mt-3">

            <div class="col-md-12">
                <div class="d-flex align-items-center">
                    <label class="switch me-3">
                        <input type="checkbox" formControlName="show" (change)="onThankYouFormSubmit()">
                        <span class="slider round"></span>
                    </label>
                    <label>{{thankupageJson.show_checkbox}}</label>
                </div>
            </div>
            <div class="mt-3 w-75" *ngIf="thankYouForm.value.show">
                <div class="">
                    <mat-label>{{thankupageJson.feild_title}} <small class="text-danger">*</small></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="{{thankupageJson.feild_title}}"
                            formControlName="title" />
                        <mat-error class="error text-danger d-flex" *ngIf="isSubmited && f['title'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['title'].errors['required']">{{thankupageJson.feild_title}}
                                {{errorjson.required}}</span>
                            <span *ngIf="f['title'].errors['pattern']">Invalid {{thankupageJson.feild_title}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="">
                    <mat-label>{{thankupageJson.field_phone}} </mat-label>
                    <div class="d-flex align-items-center">
                        <mat-form-field appearance="outline" class="me-1">
                            <mat-select placeholder="Country code" formControlName="ccode">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                        noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of filteredBanks | async"
                                    [ngClass]="{'d-none': country.IAC === ''}"
                                    value={{country.IAC}}>{{country.IAC}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput placeholder="{{thankupageJson.field_phone}}"
                                formControlName="phone_number" type="number" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="">
                    <mat-label>{{thankupageJson.field_link}} </mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder="{{thankupageJson.field_link}}"
                            formControlName="redirect_link" />
                    </mat-form-field>
                </div>
                <div class="">
                    <mat-label>{{thankupageJson.feild_description}}</mat-label>
                    <ckeditor [editor]="Editor" placeholder="Description" [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'ck-error':''" (ready)="onReady($event,editorComponent)" (keyup)="editorkeyEvent(editorComponent)" formControlName="description" #editorComponent
                        class="ckeditor-height" [config]="ckconfigQue"></ckeditor>
                        <small [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''" class="font-size-10">Maximum
                            1000 characters are accepted.</small><small
                            [ngClass]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit ? 'text-danger':''"
                            class="font-size-10 ps-1">(Character limit: {{ckeditorLimit.actualLimit}}/{{ckeditorLimit.maxLimit}})</small>
                </div>
                <div class="d-flex justify-content-end ">
                    <button class="btn btn-primary mt-3 mr-5" [disabled]="ckeditorLimit.actualLimit > ckeditorLimit.maxLimit" (click)="ckeditorLimit.actualLimit <= ckeditorLimit.maxLimit && onThankYouFormSubmit()">
                        {{thankupageJson.btn_save}}
                    </button>
                </div>

            </div>



        </div>
    </form>
</div>