<div class="modal-header">
    <h5 class="modal-title fw-600" *ngIf="!this.clientData?.id">{{addclient.heading}}</h5>
    <h5 class="modal-title fw-600" *ngIf="this.clientData?.id">Update Client</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>

<div class="modal-body">
    <form [formGroup]="clientForm">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2" *ngIf="this.clientData?.id">
                <label class="fw-600 mb-2 ">{{addclient.label_name}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="name" placeholder={{addclient.label_name}}>
                        <mat-option *ngFor="let contact of contactListArray"
                            [value]="contact?.first_name">{{contact?.first_name}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex align-items-center"
                        *ngIf="submitted && f['name'].errors">
                        <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
                        <span *ngIf="f['name'].errors['required']">{{addclient.label_name}}
                            {{errorJson.required}}</span>
                        <span *ngIf="f['name'].errors['pattern']">Only Alphanumeric are accepted.</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" *ngIf="!this.clientData?.id">
                <label class="fw-600 mb-2 ">{{addclient.label_name}} <small class="text-danger">*</small></label>
                <ng-select class="form-control" [items]="contactListArray" bindLabel="first_name" [addTag]="addNewName"
                    [multiple]="false" (change)="onSelectContact($event)" placeholder={{addclient.label_name}}
                    formControlName="name" (search)="searchSubject.next($event)" (clear)="clearContactFields($event)">
                </ng-select>
                <div *ngIf="submitted && f['name'].errors"
                    class="text-danger error font-size-12 d-flex align-items-center">
                    <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
                    <span *ngIf="f['name'].errors['required']">{{addclient.label_name}}
                        {{errorJson.required}}</span>
                    <span *ngIf="f['name'].errors['pattern']">{{errorJson.invalid}} {{addclient.label_name}}</span>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{addclient.label_email}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <input style="outline: none" matInput placeholder={{addclient.label_email}}
                        formControlName="email" />
                    <mat-error class="error text-danger d-flex align-items-center"
                        *ngIf="submitted && f['email'].errors">
                        <mat-icon class="font-size-12 d-flex align-items-center me-1">info</mat-icon>
                        <span *ngIf="f['email'].errors['required']">{{addclient.label_email}}
                            {{errorJson.required}}</span>
                        <span *ngIf="f['email'].errors['pattern']">{{errorJson.invalid}}
                            {{addclient.label_email}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{addclient.label_phone}} </label>
                <div class="d-flex align-items-center">
                    <mat-form-field appearance="outline" class="me-1">
                        <mat-select placeholder="Country code" formControlName="ccode">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                    noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let country of filteredBanks | async"
                                [ngClass]="{'d-none': country.IAC === ''}"
                                value={{country.IAC}}>{{country.IAC}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                            placeholder="{{addclient.label_phone}}" formControlName="phone_number" />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['phone_number'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['phone_number'].errors['required']">
                                {{addclient.label_phone}} {{errorJson.required}}
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2"
                *ngIf="featuresData['create_company'] && featuresData['company']">
                <label class="fw-600 mb-2 ">{{addclient.label_company}}</label>
                <ng-select class="form-control" [items]="companiesListArray" bindLabel="name" bindValue="id"
                    [addTag]="addNewCompanyName" [multiple]="false" (search)="searchCompanySubject.next($event)"
                    (clear)="clearCompany($event)" (change)="onSelectCompany($event)" placeholder="Company name"
                    formControlName="source_id">
                </ng-select>
                <!-- <ng-select *ngIf="clientForm.value.id" class="form-control" [items]="companiesListArray"
                    bindLabel="name" bindValue="id" [multiple]="false" (search)="searchCompany($event)"
                    (change)="onSelectCompany($event)" placeholder="Company name" formControlName="source_id">
                </ng-select> -->
                <!-- <mat-form-field appearance="outline" class="w-100" *ngIf="clientForm.value.id">
                    <mat-select formControlName="source_id" placeholder="{{addclient.label_company}}" class="pt-1">
                        <mat-option *ngFor="let company of companiesListArray"
                            [value]="company.id">{{company.name}}</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
            <!-- <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{addclient.label_company}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="source_id" placeholder="{{addclient.label_company}}" class="pt-1">
                        <mat-option *ngFor="let company of companiesListArray"
                            [value]="company.id">{{company.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <label class="fw-600 mb-2 ">{{addclient.label_tag}}</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="tags" placeholder="Select Tag">
                        <mat-option [value]="item.id" *ngFor="let item of tagsListArray">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">Channel </label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="channel" placeholder="Select channel">
                        <mat-option *ngFor="let item of channels"
                            [value]="item.value">{{item.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6">
                <label class="fw-600 font-size-14 mb-2">Country</label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select placeholder='Select Country' formControlName="country">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let country of filteredBanks | async"
                            value={{country.Entity}}>{{country.Entity}}</mat-option>
                    </mat-select>
                    <mat-error class="error d-flex" *ngIf="submitted && f['country'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['country'].errors['required']">Country
                            {{errorJson.required}}</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <label class="fw-600 mb-2 ">{{addclient.owner}} <small class="text-danger">*</small></label>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="owner" placeholder={{addclient.owner}}>
                        <mat-option *ngFor="let member of staffMembers" [value]="member.id"
                            (click)="onOwnerSelect(member)">{{member.fname}}
                            {{member.lname}}</mat-option>
                    </mat-select>
                    <mat-error class="error text-danger d-flex" *ngIf="submitted && f['owner'].errors">
                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                        <span *ngIf="f['owner'].errors['required']">
                            {{addclient.owner}} {{errorJson.required}}
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <hr class="mt-2">
        <h5>
            Additional Fields
        </h5>
        <div formArrayName="custom_client_fields" class="row">
            <div *ngFor="let field of custom_client_fields.controls; let i=index"
                class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                <div class="row" [formGroupName]="i" *ngIf="field.value.on_form">
                    <div>
                        <label class="fw-600 mb-2 ">{{field.value.label.replace("_", " ")}}</label>&nbsp;
                        <small class="text-danger" *ngIf="field.value.is_mandatory">*</small>

                        <ng-container *ngIf="field.value.data_type === 'TEXT'  ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'TEXT_AREA'">
                            <ckeditor [editor]="Editor" placeholder="write a message" formControlName="data"
                                [config]="ckconfigQue" class="ckeditor-height" #editorComponents
                                (ready)="onReady($event,'cust'+i)"
                                [ngClass]="ckeditorLimitLists['cust'+i]?.actualLimit > ckeditorLimitLists['cust'+i]?.maxLimit ? 'ck-error':''"
                                (keyup)="editorkeyEvent($event,editorComponents,'cust'+i)">
                            </ckeditor>
                            <small
                                [ngClass]="ckeditorLimitLists['cust'+i]?.actualLimit > ckeditorLimitLists['cust'+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10">Maximum
                                1000 characters are accepted.</small><small
                                [ngClass]="ckeditorLimitLists['cust'+i]?.actualLimit > ckeditorLimitLists['cust'+i]?.maxLimit ? 'text-danger':''"
                                class="font-size-10 ps-1">(Character
                                limit:
                                {{ckeditorLimitLists['cust'+i]?.actualLimit}}/{{ckeditorLimitLists['cust'+i]?.maxLimit}})</small>
                            <!-- <textarea class="form-control background-color" rows="2" cols="30" formControlName="data"
                                placeholder="write a message"></textarea> -->
                            <div class="error d-flex align-items-center" *ngIf="submitted && getFieldValidity(i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'CHECK_BOX'">
                            <div *ngFor="let options of field.value.options">
                                <mat-checkbox [value]="options" (change)="toggleCheckbox(options, i)">
                                    {{options}}
                                </mat-checkbox>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'SELECT_DROPDOWN' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-select formControlName="data">
                                    <!-- <mat-option [value]="'N/A'">
                                        None
                                    </mat-option> -->
                                    <mat-option *ngFor="let options of field.value.options" [value]="options">
                                        {{options}}
                                    </mat-option>
                                </mat-select>
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'MULTI_SELECT_DROPDOWN'">
                            <div *ngIf="field.value.options.length > 0">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-select formControlName="data" multiple>
                                        <mat-option *ngFor="let options of field.value.options" [value]="options">
                                            {{options}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getFieldValidity(i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", "
                                            ")}}</span>&nbsp;{{errorJson.required}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'RADIO_BUTTON'">
                            <div>
                                <mat-radio-group formControlName="data">
                                    <mat-radio-button *ngFor="let options of field.value.options" [value]="options">
                                        {{options}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <div class="error d-flex align-items-center" *ngIf="submitted && getFieldValidity(i)">
                                    <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                    <span class="text-capitalize">{{field.value.label.replace("_", "
                                        ")}}</span>&nbsp;{{errorJson.required}}
                                </div>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="field.value.data_type === 'TOGGLE_BUTTON' ">
                            <label class="switch d-none">
                                <input type="checkbox" formControlName="data">
                                <span class="slider round"></span>
                            </label>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'NUMBER' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" type="number"
                                    (keydown)="keyDownCustomField($event,0,'custom_field')"
                                    (wheel)="$event.preventDefault()" />
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'WEBSITE'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" type="url" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex"
                                    *ngIf="submitted && getFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}} or Invalid
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput type="text" matInput [matDatepicker]="picker" formControlName="data"
                                    placeholder="{{field.value.label}}">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_TIME' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <input matInput [ngxMatDatetimePicker]="dateTimePicker" placeholder="Choose a date"
                                    formControlName="data">
                                <mat-datepicker-toggle matSuffix [for]="$any(dateTimePicker)"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #dateTimePicker>
                                </ngx-mat-datetime-picker>
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'DATE_RANGE' ">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-date-range-input [rangePicker]="dateRangepicker">
                                    <input matStartDate formControlName="start" placeholder="Start date">
                                    <input matEndDate formControlName="end" placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="dateRangepicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #dateRangepicker></mat-date-range-picker>
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'FILE'">
                            <div class="" *ngIf="clientData?.id">
                                <img src="{{field.value?.data?.path}}" alt="" width="150px" class="mb-2"
                                    (error)="onErrorLoadImage($event,presignedurl,field.value?.data?.file_name)">
                                <a href="{{field.value?.data?.path}}" target="_blank"
                                    class="ms-2 text-dark fw-medium mt-3" download>{{field.value?.data?.file_name}}</a>
                            </div>
                            <input type="file" style="outline: none" placeholder="{{field.value.label}}"
                                (change)="onFileUpload($event,i)" />
                            <div class="error d-flex align-items-center" *ngIf="submitted && getFieldValidity(i)">
                                <mat-icon class="font-size-12 w-4 d-flex align-items-center">info</mat-icon>
                                <span class="text-capitalize">{{field.value.label.replace("_", "
                                    ")}}</span>&nbsp;{{errorJson.required}}
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'PHONE_NUMBER'">
                            <div class="d-flex">
                                <mat-form-field appearance="outline" class="me-1">
                                    <mat-select placeholder="Country code" formControlName="start">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                placeholderLabel="Search"
                                                noEntriesFoundLabel="No matching found"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let country of filteredBanks | async"
                                            [ngClass]="{'d-none': country.IAC === ''}"
                                            value={{country.IAC}}>{{country.IAC}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input style="outline: none" (keydown)="keyDownEvent($event,12)" matInput
                                        placeholder="{{field.value.label}}" formControlName="end" />
                                    <mat-error class="error text-danger d-flex"
                                        *ngIf="submitted && getFieldValidity(i)">
                                        <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                                        <span class="text-capitalize">{{field.value.label.replace("_", " ") }}
                                            {{errorJson.required}}</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.value.data_type === 'EMAIL'">
                            <mat-form-field appearance="outline" class="w-100">
                                <input style="outline: none" matInput placeholder="{{field.value.label}}"
                                    formControlName="data" />
                                <mat-error class="error text-danger d-flex" *ngIf="submitted && getFieldValidity(i)">
                                    <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon> <span
                                        class="text-capitalize">{{field.value.label.replace("_", " ")}}</span>&nbsp;
                                    {{errorJson.required}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="mt-3 d-flex justify-content-between align-items-center modal-footer">
    <div class="d-flex cursor" routerLink="settings/custom-fields" [queryParams]="{ activeLink: 'Clients'}"
        (click)="ngbActiveModal.dismiss()">
        <span class="material-icons text-primary">settings_applications</span>
        <span class=""></span> {{addclient.customize_text}}
    </div>
    <div class="d-flex">
        <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
            {{addclient.cancel_btn}}
        </button>
        <button class="btn btn-primary" [disabled]="loading" type="submit"
            (click)="onSubmit()">{{addclient.save_btn}}</button>
    </div>
</div>