<div class="z-index-header"
  [ngStyle]="{'box-shadow':!isHomeScreen ? '0px 0px 24px -8px rgba(0, 0, 0, 0.13)' : 'none' }">
  <div class="justify-content-between align-items-center py-1 mt-1 d-md-flex d-none">
    <div class="col-md-6 d-flex align-items-center">
      <div class="ps-4 d-flex align-items-center" style="width:180px">
        <img *ngIf="selectedMenu?.color_img" src="{{selectedMenu?.color_img}}" width="18" height="18">
        <h3 class=" fw-600 ms-2 text-capitalize"
          [innerHTML]="headerText ? (headerText | safeHtml) : selectedMenu?.label"></h3>
      </div>
      <!-- <div class="d-flex justify-content-center">
            <mat-form-field appearance="outline" class="search-select mat-hint-none bg-search-left">
                <mat-select [(ngModel)]="selectopt">
                    <mat-option value="all">All</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-divider [vertical]="true"></mat-divider>
            <mat-form-field appearance="outline" class="search mat-hint-none bg-search-right">
                <input style="outline: none" matInput placeholder={{headerJson.input_search}} />
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div> -->
    </div>
    <!-- <div class="col-md-2 text-end"> -->
    <!-- <p class="mb-0">{{headerJson.text}} <label class="text-primary fw-600">{{headerJson.text2}}</label></p> -->
    <!-- </div> -->
    <!-- <div class=""> -->
    <div class="d-flex justify-content-end align-items-center header-icons" style="padding-right: 28px;">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button mat-raised-button matTooltip="Add" (click)="getFeatues()"
          aria-label="Button that displays a tooltip when focused or hovered over" mat-button
          class="btn header-item border-0 p-0" [matMenuTriggerFor]="menuadd" id="page-header-create-dropdown">
          <span class="rounded d-flex align-items-center justify-content-center">
            <!-- <mat-icon class="font-size-26 position-relative" style="top: 5px">add</mat-icon> -->
            <img src="assets/images/newUI/MENU/HEADER/add_icon.svg" class="cursor" width="20">
          </span>
        </button>
        <mat-menu #menuadd="matMenu" class="rounded header-mat-menu">
          <div class="" *ngFor="let item of addmenuList">
            <button mat-menu-item (click)="openModalDialog(item.menu_title)" *ngIf="featuresData && item.viewFeature"
              class="py-2">
              <label class="border-radius-50 p-1 px-2 menu-icon me-2">
                <img src="../../../assets/images/{{item.menu_icon}}.svg" width="16">
              </label>
              <label class="d-flex align-items-center">
                <!-- <mat-icon
                                class="me-0 text-primary">add</mat-icon> -->
                <span class="position-relative menu-title fw-500"> {{item.menu_title}} </span></label>
            </button>
          </div>
        </mat-menu>
      </div>
      <button mat-raised-button matTooltip="Boards" *ngIf="featuresData && featuresData?.global_taskboard"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0  d-flex align-items-center" routerLink="/taskboard">
        <!-- <mat-icon class="font-size-20 cursor" routerLink="header-calendar">calendar_today</mat-icon> -->
        <span class="rounded d-flex align-items-center justify-content-center">
          <img src="assets/images/newUI/MENU/HEADER/board_icon.svg" class="cursor" width="16">
        </span>
      </button>
      <button mat-raised-button matTooltip="Personal calendar"
        *ngIf="featuresData && (featuresData?.header_calendar && featuresData?.meetings)"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-flex align-items-center" routerLink="header-calendar">
        <!-- <mat-icon class="font-size-20 cursor" routerLink="header-calendar">calendar_today</mat-icon> -->
        <span class="rounded d-flex align-items-center justify-content-center">
          <img src="assets/images/newUI/MENU/HEADER/calendar_icon.svg" class="cursor" width="16">
        </span>
      </button>
      <button mat-raised-button matTooltip="Inbox" *ngIf="featuresData && (featuresData?.email_inbox)"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 mx-2 d-flex align-items-center" routerLink="bot_chat" [queryParams]="{folder:'inbox'}">
        <span class="rounded d-flex align-items-center justify-content-center">
          <img src="../../../assets/images/Mail Inbox.svg" class="cursor" width="16">
        </span>
      </button>
      <button mat-raised-button matTooltip="Call" [matMenuTriggerFor]="menucall" #menuContacts="matMenuTrigger"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-flex align-items-center" (click)="getContacts()" *ngIf="callFeature">
        <span class="rounded d-flex align-items-center justify-content-center">
          <img src="assets/images/add-contact.svg" class="cursor" width="16">
        </span>
      </button>
      <mat-menu #menucall="matMenu" class="rounded header-mat-menu p-3">
        <label>From:</label>
        <mat-form-field appearance="outline" class="mat-hint-none w-100 mb-3">
          <mat-select (click)="$event.stopPropagation()" [(ngModel)]="fromNumber">
            <mat-option [value]=number *ngFor="let number of twilioFromNumbers">{{number}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="">
          <label>To:</label>
          <ng-select class="form-control" [items]="contactsList" bindLabel="first_name" bindValue="number"
            [multiple]="false" placeholder='Select' (click)="$event.stopPropagation()"
            (search)="searchSubject.next($event.term)" [(ngModel)]="toNumber" (change)="onSelectContact($event)">
            <ng-template ng-option-tmp let-item="item">
              {{item.first_name}} {{item.last_name}} <br />
              <small>{{item.ccode}} {{item.phone_number}}</small>
            </ng-template>
          </ng-select>
        </div>
        <div class="w-100 d-flex justify-content-end mt-3">
          <button class="btn btn-primary" (click)="makeaCall()" [disabled]="disableCall">Call</button>
        </div>
      </mat-menu>
      <button mat-raised-button matTooltip="Support"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-flex align-items-center" (click)="openSupportModel()">
        <span class="rounded d-flex align-items-center justify-content-center">
          <!-- <mat-icon class="font-size-20 cursor d-flex align-items-center justify-content-center"
            style="color: #000001;">contact_support</mat-icon> -->
          <img src="../../../assets/images/questionare.svg" class="cursor" width="16">
        </span>
      </button>
      <button mat-raised-button matTooltip="Settings"
        aria-label="Button that displays a tooltip when focused or hovered over" type="button"
        class="btn border-0 p-0 d-flex align-items-center" routerLink="settings">
        <!-- <mat-icon class="font-size-20 cursor" routerLink="settings">settings</mat-icon> -->
        <span class="rounded d-flex align-items-center justify-content-center">
          <img src="assets/images/newUI/MENU/HEADER/setting_icon.svg" class="cursor" width="16">
        </span>
      </button>
      <div class="notification-dropdown d-inline-block border-0" ngbDropdown #notifydrop="ngbDropdown"
        *ngIf="featuresData && featuresData?.notifications">
        <button mat-raised-button matTooltip="Notifications" (click)="getNotification('clear')"
          aria-label="Button that displays a tooltip when focused or hovered over" type="button"
          class="btn border-0 p-0 d-flex align-items-center" id="page-header-notifications-dropdown" ngbDropdownToggle>
          <span class="rounded d-flex align-items-center justify-content-center">
            <img src="assets/images/newUI/MENU/HEADER/notification_icon.svg" class="cursor" width="16">
          </span>
        </button>
        <div class="position-absolute font-size-10 border-radius-4 text-center notifications-count"
          *ngIf="notificationsCount > 0">
          <span>{{notificationsCount}}</span>
        </div>
        <div
          class="notifications-sections-header cursor p-0 dropdown-menu dropdown-menu-lg dropdown-menu-end dropdown-menu-right-notifications p-0 notification-dropdown-menu-end"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div
            class="notifications-header bg-white d-flex align-items-center justify-content-between position-sticky pt-3 pb-2">
            <div class="d-flex align-items-center">
              <p class="line-height-1 font-size-16 fw-600  m-0">Notifications</p>
            </div>
            <div class="d-flex align-item-center">
              <div (click)="markAllasRead($event)" id="mark_as_read_notification"
                class="mark_completed me-2 px-1 line-height-1 d-flex align-items-center mark-as-button">
                <input type="checkbox" class="cursor" id="mark_as_read">&nbsp;<label
                  class="m-auto my-0 cursor ms-1 label-text-clr font-size-12 fw-500 limit-line-length-1">Mark
                  all as
                  read</label>
              </div>
            </div>
          </div>
          <div class="h-notifications-body" #notificationList>
            <div class="overflow-auto side-noti-sec">
              <div class="row m-0" infinite-scroll [scrollWindow]="false" (scrolled)="onScroll('notifications')"
                [infiniteScrollContainer]="notificationList" [infiniteScrollUpDistance]="2">
                <div *ngFor="let item of notification_list;let i = index" class="notification-msg py-2 pb-0">
                  <div class="d-flex align-items-start">
                    <div class="home-avatar-xss border-radius-50 d-flex justify-content-between position-relative mb-1">
                      <span
                        class="avatar-title fw-500 rounded-circle text-uppercase font-size-13 text-secondary">{{item?.updated_by_name?.charAt(0)
                        || item?.created_by_name?.charAt(0) || 'U'}}</span>
                      <div class="mention-svg position-absolute " *ngIf="item?.mentioned">
                        <img class="rounded-circle bg-white" style="padding: 2px;" src="assets/images/mention.svg"
                          alt="">
                      </div>
                    </div>
                    <div class="ms-2 flex-grow-1 mb-2" style="width: calc(100% - 40px);">
                      <div class="d-flex align-items-center justify-content-between">
                        <p class="m-0 font-size-14 fw-400 text-dark">{{item?.updated_by_name || item?.created_by_name}}
                        </p>
                        <p class="m-0 font-size-12 fw-400" style="color: #8B8B8B;">{{item.customTime}}</p>
                      </div>
                      <div class="d-flex align-items-center position-relative">
                        <p class="font-size-14 m-0 pb-0 h-notification-text w-100 limit-line-length-1" #pRef
                          (click)="item?.redirection && onEventDeligation(item)"
                          [ngClass]="{'text-decoration-line-through': item.is_read,'cursor':item?.redirection}"
                          [innerHTML]="item?.message">
                        </p>
                        <div class="h-see-more position-absolute" (click)="onClickSeemore(pRef,i)">
                          <span *ngIf="pRef.scrollHeight > 38" class="font-size-13 cursor text-underline ps-1"
                            style="color: black;"><span class="" style="color:#000000b2">...
                            </span>see more</span>
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between mt-2">
                        <p class="border-radius-12 source-type font-size-12 fw-600 position-relative text-capitalize width-fit-content py-1 m-0"
                          [ngStyle]="{'background':sourceColorCode[item?.source_type]?.bgColor,'color':sourceColorCode[item?.source_type]?.color}">
                          {{item?.source_type === 'lead' ? 'Lead' : item?.source_type }}</p>
                        <div (click)="markasRead(item,'lbl')"
                          class="mark_completed px-2  line-height-1 d-flex align-items-center mark-as-button">
                          <input [(ngModel)]="item.is_read" type="checkbox" class="cursor"
                            id="mark_as_read">&nbsp;<label
                            class="m-auto my-0 cursor ms-1 label-text-clr font-size-12 fw-500 limit-line-length-1">Mark
                            as
                            read</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end dropdown-menu-right-notifications p-0 notification-dropdown-menu-end"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu #notificationList>
          <div class="p-3 pb-0 fixed-header w-100 position-sticky bg-white">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notifications</h6>
              </div>
            </div>
          </div>
          <div class="px-3">
            <div class="side-noti-sec">
              <div class="row m-0 overflow-auto" infinite-scroll [scrollWindow]="false"
                (scrolled)="onScroll('notifications')" [infiniteScrollContainer]="notificationList"
                [infiniteScrollUpDistance]="2">
                <div class="border-bottom least-border-radius p-0 pt-3 pb-2"
                  *ngFor="let item of notification_list;let i = index">
                  <div class="d-flex align-item-start">
                    <div class="col-2 ps-0">
                      <div class="avatar-xss d-flex justify-content-between position-relative">
                        <span class="avatar-title fw-500 rounded-circle text-uppercase font-size-13"
                          [ngStyle]="{'background-color': '#0d543d'}">{{item.info
                          ? item.info?.first_name?.charAt(0) : ''}}</span>
                        <div class="mention-svg position-absolute " *ngIf="item?.mentioned">
                          <img class="rounded-circle bg-white" style="padding: 2px;" src="assets/images/mention.svg"
                            alt="">
                        </div>
                      </div>
                    </div>
                    <div class="col-10">
                      <div class="d-flex align-items-center mb-3 position-relative">
                        <p class="font-size-14 m-0 pb-0 h-notification-text w-100 limit-line-length-1" #hpRef
                          (click)="item?.redirection && onEventDeligation(item)"
                          [ngClass]="{'text-decoration-line-through': item.is_read,'cursor':item?.redirection}"
                          [innerHTML]="item?.message">
                        </p>
                        <div class="h-see-more position-absolute" (click)="onClickSeemore(hpRef,i)">
                          <span *ngIf="hpRef.scrollHeight > 38" class="font-size-13 cursor text-underline ps-1"
                            style="color: black;"><span class="" style="color:#000000b2">... </span>see more</span>
                        </div>
                      </div>
                      <p class="border-radius-12 source-type font-size-12 fw-500 position-relative  text-capitalize width-fit-content px-2 py-1 m-0"
                        [ngStyle]="{'background':sourceColorCode[item?.source_type]?.bgColor,'color':sourceColorCode[item?.source_type]?.color}">{{item?.source_type === 'lead' ?
                        'Enquiry' :
                        item?.source_type }}</p>
                      <div
                        class="d-flex justify-content-between marked-as-read position-relative align-items-center font-size-12">
                        <label class="text-light font-size-12 line-height-1">{{item.updated_at | dateConversion}}
                        </label>
                        <div class="d-flex line-height-1 border-radius-4">
                          <input type="checkbox" class="cursor" (change)="markasRead(item,'inp')"
                            [(ngModel)]="item.is_read">&nbsp;<label (click)="markasRead(item,'lbl')"
                            class="m-auto cursor text-light font-size-10">Mark as
                            read</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div> -->
      </div>
      <div class="notification-dropdown d-inline-block border-0" ngbDropdown #notifydrop="ngbDropdown"
        *ngIf="featuresData && featuresData?.notifications">
        <button mat-raised-button matTooltip="Activity log" (click)="getActivityLogs('clear')"
          aria-label="Button that displays a tooltip when focused or hovered over" type="button"
          class="btn border-0 p-0 d-flex align-items-center" id="page-header-notifications-dropdown" ngbDropdownToggle>
          <!-- <mat-icon class="font-size-20 cursor">local_activity</mat-icon> -->
          <span class="rounded d-flex align-items-center justify-content-center">
            <img src="assets/images/newUI/MENU/HEADER/activity_icon.svg" class="cursor" width="16">
          </span>
          <!-- <span class="badge bg-danger rounded-pill text-white font-size-10"
                        style="top: -17px;">{{activity_logs.length}}</span> -->
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end dropdown-menu-right-activity notification-dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu #activity_log>
          <div class="p-3 pb-0 fixed-header w-100 position-sticky bg-white">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Activity Log</h6>
              </div>
            </div>
          </div>
          <div class="notification" infinite-scroll [scrollWindow]="false" (scrolled)="onScroll('activity')"
            [infiniteScrollContainer]="activity_log" [infiniteScrollUpDistance]="2">
            <!-- <div class="border-bottom border-2 p-3" *ngFor="let item of notification_list">
                            <div class="row">
                                <div class="col-2">
                                    <div class="avatar-xs d-flex justify-content-between">
                                        <span
                                            class="avatar-title rounded-circle text-capitalize">{{item.first_name[0]}}{{item.last_name[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-10 ps-0">
                                    <h6 class="font-size-16">{{item.name}}</h6>
                                    <label class="notification-message text-light font-size-13">{{item.dsp}}</label>
                                    <div class="d-flex justify-content-between font-size-13">
                                        <label class="text-light">{{item.due_date}} hours ago</label>
                                        <div class="">
                                            <input type="checkbox">&nbsp;<label class="m-auto text-light">Mark as
                                                read</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

            <div class="border-bottom border-2 p-3" *ngFor="let log of activity_logs">
              <div class="row">
                <!-- <div class="col-2">
                                    <div class="avatar-xs me-2">
                                        <span class="avatar-title rounded-circle">
                                            {{log.name[0]}}
                                        </span>
                                    </div>
                                </div> -->
                <div class="col-12">
                  <p class="mb-0 notification-message" [innerHTML]="log.activity_text"></p>
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center text-green">
                      <!-- <mat-icon class="font-size-20">assignment_turned_in</mat-icon> -->
                      <label class="font-size-14">{{log.source_type }}</label>
                    </div>
                    <label class="text-light font-size-13">{{log.on_date | dateConversion}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="notification-dropdown d-inline-block border-0" *ngIf="featuresData && featuresData?.announcements"
        ngbDropdown #notifydrop="ngbDropdown">
        <button mat-raised-button matTooltip="Announcements" (click)="getAnnouncments()"
          aria-label="Button that displays a tooltip when focused or hovered over" type="button"
          class="btn border-0 p-0 d-flex align-items-center" id="page-header-announcement-dropdown" ngbDropdownToggle>
          <span class="rounded d-flex align-items-center justify-content-center">
            <img src="assets/images/newUI/MENU/HEADER/announcement_icon.svg" class="cursor" width="16">
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right dropdown-menu-end p-0"
          style="position: relative; height: 500px; overflow-y: auto;width: 400px;"
          aria-labelledby="page-header-announcement-dropdown" ngbDropdownMenu>
          <div class="p-3 pb-0 fixed-header w-100 position-sticky bg-white">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Announcements</h6>
              </div>
            </div>
          </div>
          <div class="notification">
            <div class="border-bottom border-2 p-3" *ngFor="let item of announcements_list">
              <div class="row">
                <div class="col-12 line-height-1">
                  <p class="font-size-14 notification-message m-0 line-height-1"
                    [ngClass]="{'text-decoration-line-through': item?.is_read}">{{item?.title}}</p>
                  <label class="notification-message text-light font-size-12 w-100 mt-2 text-capitalize "
                    [innerHTML]="item?.text | safeHtml"></label>
                  <div class="d-flex justify-content-between font-size-12">
                    <label class="text-light font-size-12">{{item?.updated_at | dateConversion}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button mat-raised-button matTooltip="Your profile and logout"
          aria-label="Button that displays a tooltip when focused or hovered over" type="button"
          class="btn header-item d-flex align-items-center border-0 btn-outline-light ps-0" ngbDropdownToggle
          id="page-header-user-dropdown">
          <div class="">
            <span class="rounded d-flex align-items-center justify-content-center">
              <!-- <mat-icon>person</mat-icon> -->
              <img src="assets/images/newUI/MENU/HEADER/profile_icon.svg" width="16">
            </span>
            <!-- <img *ngIf="currentUser.image" class="rounded-circle avatar-xs" src={{currentUser.image}} alt=""> -->
          </div>
          <!-- <i class="mdi mdi-chevron-down ms-2 d-none d-xl-inline-block"></i> -->
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="user-profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{profile_name.data.fname}} {{profile_name.data.lname}}</a>

          <div class="dropdown-divider my-0"></div>
          <a class="dropdown-item" routerLink="/calendar/design" *ngIf="featuresData && featuresData?.meetings"><i
              class="bx bx-user font-size-16 align-middle me-1"></i>
            Calendar Meetings</a>

          <div class="dropdown-divider my-0"></div>
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1"></i>
            {{headerJson.logout_btn}}</a>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <div class="ps-4 pb-3 d-flex align-items-center" *ngIf="currentlyLandedInnerScreen">
    <mat-icon class="cursor" (click)="onBack()">keyboard_backspace</mat-icon>
    <p class="m-0 ms-2 font-size-20  fw-600 text-capitalize">{{currentlyLandedInnerScreen}}</p>
  </div>
</div>