<div class="container-fluid">
    <div class="row" *ngIf="featuresData?.tax_add">
        <div class="col-12 my-2 py-1">
            <button type="button" class="btn btn-primary float-end" (click)="openAddTaxModal(addTax)">Add Tax</button>
        </div>
    </div>
    <div class="flex-column justify-content-between d-flex custom-table-div-crm-height">
        <div class="table-responsive table-height">
            <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="table_cols.length"
                [fromLocation]="'TAX'"></app-table-skeleton>
            <table *ngIf="!tableSkeletonLoader" class="table align-middle table-nowrap table-hover mb-0 file-table">
                <thead>
                    <tr class="table-header-color">
                        <!-- <th scope="col">
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
                        </th> -->
                        <th scope="col" *ngFor="let col of table_cols">{{col}}</th>
                    </tr>
                </thead>
                <tbody *ngIf="!display_no_data">
                    <tr *ngFor="let item of taxes_list; let i = index">
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            {{ item.percentage }}
                        </td>
                        <td class="cursor">
                            <label class="switch ms-3" *ngIf="loggedInUser.data.role === 'ADMIN'">
                                <input type="checkbox" [id]="item.id" [checked]="item.status"
                                    (change)="onToggleChange($event,item)">
                                <span class="slider round"></span>
                            </label>
                        </td>
                        <td class="cursor">
                            <img class="me-2 cursor" src="../../../assets/images/edit-board-icon.svg"
                                (click)="openAddTaxModal(addTax,item)">
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-nodata-found *ngIf="display_no_data"></app-nodata-found>

        </div>
        <!-- <div>
            <mat-paginator [length]="total_count" [pageSize]="20" [pageIndex]="pageIndex" (page)="page($event)"
                aria-label="Select page" hidePageSize="true" showFirstLastButtons>
            </mat-paginator>
        </div> -->
    </div>
</div>
<!-- <div class="table-responsive table-height">
    <app-table-skeleton *ngIf="tableSkeletonLoader" [tableColumns]="table_cols.length"
        [fromLocation]="'TAX'"></app-table-skeleton>
    <table *ngIf="!tableSkeletonLoader" class="table align-middle table-nowrap table-hover mb-0 file-table">
        <thead>
            <tr class="table-header-color">
                <th scope="col" *ngFor="let col of table_cols">{{col}}</th>
            </tr>
        </thead>
        <tbody *ngIf="!display_no_data">
            <tr *ngFor="let item of taxes_list; let i = index">
                <td>
                    {{ item.name }}
                </td>
                <td>
                    {{ item.percentage }}
                </td>
                <td class="cursor">
                    <label class="switch ms-3" *ngIf="loggedInUser.data.role === 'ADMIN'">
                        <input type="checkbox" [id]="item.id" [checked]="item.status"
                            (change)="onToggleChange($event,item)">
                        <span class="slider round"></span>
                    </label>
                </td>
                <td class="cursor">
                    <img class="me-2 cursor" src="../../../assets/images/edit-board-icon.svg"
                        (click)="openAddTaxModal(addTax,item)">
                </td>
            </tr>
        </tbody>
    </table>
    <div class="h-30rem" *ngIf="display_no_data">
        <app-nodata-found></app-nodata-found>
    </div>
    <div>
<mat-paginator [length]="total_count" [pageSize]="10" (page)="page($event)" aria-label="Select page"
                hidePageSize="true" showFirstLastButtons>
            </mat-paginator>
    </div>
</div> -->


<ng-template #addTax let-modal>
    <div class="modal-header pb-0 border-0">
        <h6 class="modal-title text-primary fw-600" id="label">{{addTaxForm.value.id ? 'Update' : 'Add'}} Tax Rate</h6>
        <mat-icon class="cursor" (click)="modalRef.close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <form [formGroup]="addTaxForm" (ngSubmit)="onTaxSubmit()">
            <label class="mb-2 fw-500 text-primary">Tax Rate Name <small class="text-danger">*</small></label>
            <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput placeholder="Enter tax name" formControlName="name" />
                <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['name'].errors">
                    <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                    <span *ngIf="f['name'].errors['required']">Tax Rate Name is required</span>
                    <span *ngIf="f['name'].errors['pattern']">Invalid task rate name</span>
                </mat-error>
            </mat-form-field>
            <label class="mb-2 fw-500 text-primary">Tax Rate <small class="text-danger">*</small></label>
            <mat-form-field appearance="outline" class="w-100">
                <input style="outline: none" matInput type="number" (keydown)="keyDownEvent($event)"
                    (wheel)="$event.preventDefault()" placeholder="Enter tax rate" formControlName="percentage" />
                <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['percentage'].errors">
                    <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                    <span *ngIf="f['percentage'].errors['required']">Tax Rate is required</span>
                </mat-error>
            </mat-form-field>
            <label class="mb-2 fw-500 text-primary d-none">Type <small class="text-danger">*</small></label>
            <mat-form-field appearance="outline" class="w-100 d-none">
                <mat-select placeholder="Type" class="" formControlName="type">
                    <mat-option [value]=1>Inclusive</mat-option>
                    <mat-option [value]=2>Exclusive</mat-option>
                </mat-select>
                <mat-error class="error d-flex align-item-center" *ngIf="submitted && f['type'].errors">
                    <mat-icon class="font-size-12 mt-1 me-2">info</mat-icon>
                    <span *ngIf="f['type'].errors['required']">Tax Rate is required</span>
                </mat-error>
            </mat-form-field>
            <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary">Save</button>
            </div>
        </form>
    </div>
</ng-template>