export const CLIENTPROJECTJSON = [
  {
    heading_label: 'Client Projects',
    Filter_label: 'Filters',
    search_placeholder: 'Search Project',
    // back_label: 'Back',
    label_add_project: 'Add Project',
    modal_heading: 'Add New Project',
    btn_cancel: 'Close',
    btn_save: 'Save'
  }
];
export const ADDCLIENTPROJECTJSON = [
  {
    heading: 'Add Project',
    label_name: 'Client Name',
    label_product:'Product',
    label_project: 'Project Name',
    label_phone: 'Phone Number',
    btn_add_phone: 'Add Phone',
    label_email: 'Email',
    btn_add_email: 'Add Email',
    label_tag: 'Tag',
    btn_add_tag: 'Add Tag',
    owner: 'Owner',
    label_staff:'Select staff',
    label_price: 'Budget',
    label_currency: 'Currency',
    label_date: 'Due Date',
    label_dsp: 'Description',
    customize_text: 'Customize your client project form',
    save_btn: 'Save',
    cancel_btn: 'Close',
    btn_cancel: 'Cancel'
  }
];