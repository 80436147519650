import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  user_details = JSON.parse(sessionStorage.getItem('currentUser')!);

  pipelineNameSubs$ = new Subject();
  constructor(private http: HttpClient) { }

  //timeline
  getUserTimeline(id: string, page: any) {
    return this.http.get(`${environment.urlPrefix}${constant.auditlog}?source_id=${id}&page=${page}&offset=20`);
  }
  //files
  getAllFiles(id: string, page: any) {
    return this.http.get(`${environment.urlPrefix}${constant.files}?page=${page}&limit=20&source_id=${id}&show_related=true`);
  }
  getFilesById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.files}${id}/`);
  }
  fileUploadWorke(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.files}`, body);
  }
  fileUpdateWorke(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.files}${body.id}/`, body);
  }
  deleteFile(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.files}${id}/`);
  }
  //notes
  getAllNotes(id: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.notes}?source_id=${id}&page=${page}&offset=20`);
  }
  getNotesById(id: string) {
    return this.http.get(`${environment.urlPrefix}${constant.notes}${id}/`);
  }
  createNotes(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.notes}`, body);
  }
  updateNotes(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.notes}${body.id}/`, body);
  }
  deleteNotes(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.notes}${id}/`);
  }
  //activity
  getActivitysById(id: string, page: number) {
    return this.http.get(`${environment.urlPrefix}${constant.activity}?source_id=${id}&page=${page}&limit=20`);
  }
  getSelectedActivitesById(id:string){
    return this.http.get(`${environment.urlPrefix}${constant.activity}${id}/`);
  }
  createActivity(body: any) {
    return this.http.post(`${environment.urlPrefix}${constant.activity}`, body);
  }
  updateActivity(id: any, body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.activity}${id}/`, body);
  }
  deleteActivty(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.activity}${id}/`);
  }

  getActivity(pageSize: number) {
    return this.http.get(`${environment.urlPrefix}${constant.audit_activitylog}?user_id=${this.user_details.data.id}&page=${pageSize}`);
  }

  //Reports

  getContantReports(startDate: string, endDate?: string) {
    if (endDate) {
      return this.http.get(`${environment.urlPrefix}${constant.reports_crm_contact}?start_date=${startDate}&end_date=${endDate}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.reports_crm_contact}?start_date=${startDate}`);
    }
  }
  getCompanyReports(startDate: string, endDate?: string) {
    if (endDate) {
      return this.http.get(`${environment.urlPrefix}${constant.reports_crm_company}?start_date=${startDate}&end_date=${endDate}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.reports_crm_company}?start_date=${startDate}`);
    }
  }
  getEnquiryReports(startDate: string, endDate?: string) {
    if (endDate) {
      return this.http.get(`${environment.urlPrefix}${constant.reports_crm_enquiry}?start_date=${startDate}&end_date=${endDate}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.reports_crm_enquiry}?start_date=${startDate}`);
    }
  }
  getClientReports(startDate: string, endDate?: string) {
    if (endDate) {
      return this.http.get(`${environment.urlPrefix}${constant.reports_client}?start_date=${startDate}&end_date=${endDate}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.reports_client}?start_date=${startDate}`);
    }
  }
  getProjectReports(startDate: string, endDate?: string) {
    if (endDate) {
      return this.http.get(`${environment.urlPrefix}${constant.reports_project}?start_date=${startDate}&end_date=${endDate}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.reports_project}?start_date=${startDate}`);
    }
  }
  getTaskReports(startDate: string, endDate?: string) {
    if(endDate){
      return this.http.get(`${environment.urlPrefix}${constant.reports_task}?start_date=${startDate}&end_date=${endDate}`);
    }else{
      return this.http.get(`${environment.urlPrefix}${constant.reports_task}?start_date=${startDate}`);
    }
  }
  getActivityReports(startDate: string, endDate?: string) {
    if (endDate) {
      return this.http.get(`${environment.urlPrefix}${constant.reports_activities}?start_date=${startDate}&end_date=${endDate}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.reports_activities}?start_date=${startDate}`);
    }
  }
  getOrdersReports(startDate: string, endDate?: string) {
    if (endDate) {
      return this.http.get(`${environment.urlPrefix}${constant.reports_orders}?start_date=${startDate}&end_date=${endDate}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.reports_orders}?start_date=${startDate}`);
    }
  }
  getAppointmentsReports(startDate: string, endDate?: string) {
    if (endDate) {
      return this.http.get(`${environment.urlPrefix}${constant.reports_appointments}?start_date=${startDate}&end_date=${endDate}`);
    } else {
      return this.http.get(`${environment.urlPrefix}${constant.reports_appointments}?start_date=${startDate}`);
    }
  }
  
  getPipelineReports() {
    return this.http.get(`${environment.urlPrefix}${constant.reports_crm_pipeline}`);
  }
  getTaskByStageId(stageId: any) {
    return this.http.get(`${environment.urlPrefix}${constant.task}?stage=${stageId}`);
  }
}
