import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationMszService } from 'src/app/core/service/confirmation-msz.service';
import { CrmService } from 'src/app/core/service/crm.service';
import { EmailModalComponent } from 'src/app/pages/shared/email-modal/email-modal.component';

@Component({
  selector: 'app-email-draft',
  templateUrl: './email-draft.component.html',
  styleUrls: ['./email-draft.component.scss']
})
export class EmailDraftComponent implements OnInit {
  constructor(private confirmationMsz: ConfirmationMszService, private route: ActivatedRoute, private crmService: CrmService, private modalService: NgbModal) { }
  source_id = '';
  source_type = '';
  contact_id = '';
  draft_converstion: any[] = [];
  source_email = '';
  mail_template!: FormGroup;
  public Editor: any = ClassicEditor;
  modalRef: any;
  // ALL are Temp only will change in future
  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      this.source_id = params?.id;
      this.source_type = params?.showtype;
      this.contact_id = params?.contact_id;
    });
    this.crmService.registeredEmailId.subscribe((data: any) => {
      this.source_email = data?.email;
    });
    this.getMailTemplate();
  }

  editDraft(draft: any, i: number) {
    const emailData = {
      source_id: this.source_id,
      source_type: this.source_type,
      contact_id: this.contact_id,
      mailType: false,
      exsistSubject: draft?.subject ? true : false,
      source_email: this.source_email,
      subject: draft?.payload?.subject,
      content: draft?.payload?.body,
      draftId: draft?.payload?.id,
      type: 'draft'
    };
    this.modalRef = this.modalService.open(EmailModalComponent,{ size: 'lg', centered: true });
    this.modalRef.componentInstance.emailData = emailData;
    this.modalRef?.result?.then((receivedEntry: any) => {
      // console.log(receivedEntry);
      if (receivedEntry === 'success') this.getMailTemplate();
    });
  }
  // "subject": "this is schedule mail 023",
  // "content": "23 message but in reply to field is changed, so lets see what happens",
  // "source_type": "draft1234",
  // "source_id": "5edbf6f0-5970-40be-a684-09895887ba514",
  // "thread_id": "",
  // "type": "draft",
  // "method": "saved"
  confirmDelete(draft: any) {
    this.confirmationMsz.delete().then((result: any) => {
      if (result.value) {
        const payload = {
          method: 'delete',
          type: 'draft',
          draft_id: draft?.payload?.id,
          to:this.source_email,
          content:draft?.payload?.body,
          subject:draft?.payload?.subject
        };
        this.crmService.deleteDraftMail(payload).subscribe(() => {
          this.getMailTemplate();
        });
      }
    });
  }

  getMailTemplate() {
    const body = {
      source_id: this.source_id,
      source_type: this.source_type
    };
    this.crmService.getMails(body, 'draft').subscribe((resp: any) => {
      const email_converstion = resp.data;
      this.draft_converstion = email_converstion.filter((element: any) => {
        if (element?.type === 'draft') {
          element.date_created = this.customDateTimeConversion(element?.date_created);
          return element;
        }
      });
    });
  }
  customDateTimeConversion(inputDateTime: string) {
    const date = new Date(inputDateTime);
    const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
    const month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    let hours = date.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const formattedTime = `${hours}:${minutes} ${amOrPm}`;
    const formattedDateTime = `${formattedDate}, ${formattedTime}`;
    return formattedDateTime;

  }
}
