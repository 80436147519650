import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-skeleton',
  templateUrl: './table-skeleton.component.html',
  styleUrls: ['./table-skeleton.component.scss']
})
export class TableSkeletonComponent implements OnInit {
  @Input() tableColumns!:any;
  @Input() fromLocation!:string;
  tableLength = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
  
  ngOnInit() {
    const length = this.tableColumns;
    this.tableColumns = Array.from({ length }, (_, index) => index);
    if (this.fromLocation === 'CUSTOM'){
      this.tableLength = [1,2,3,4,5];
    }
  }
}
