import { NgModule } from '@angular/core';

import { CrmRoutingModule } from './crm-routing.module';
import { MaterialModule } from 'src/app/core/common/material/material.module';
import { ContactsComponent } from './contacts/contacts.component';
// import { ManagementModalModule } from './management-modal/management-modal.module';
import { CommonModule } from '@angular/common';
import { CompaniesComponent } from './companies/companies.component';
import { LeadsComponent } from './leads/leads.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbNavModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/core/layouts/pipes/pipes.module';
import { PagesModule } from '../pages.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { CampaignModule } from '../campaign/campaign.module';

@NgModule({
  declarations: [
    ContactsComponent,
    CompaniesComponent,
    LeadsComponent
  ],
  imports: [
    CommonModule,
    CrmRoutingModule,
    MaterialModule,
    // ManagementModalModule,
    NgSelectModule,
    NgbNavModule,
    MatTooltipModule,
    MatMenuModule,
    NgbDropdownModule,
    PipesModule,
    PagesModule,
    CampaignModule
  ],
  exports: [ContactsComponent]
})
export class CrmModule { }
