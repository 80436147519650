import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { constant } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {

  constructor(private http: HttpClient) { }

  getContactModelFields() {
    return this.http.get(`${environment.urlPrefix}${constant.custom_contact_models_crm}`);
  }

  putContactModelFields(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.custom_contact_models_crm}`, body);
  }
  getContactTemplateFields() {
    return this.http.get(`${environment.urlPrefix}${constant.custom_contact_template_crm}`);
  }

  postContactTemplateFields(data: any) {
    return this.http.post(`${environment.urlPrefix}${constant.custom_contact_template_crm}`, data);
  }

  putContactTemplateFields(data: any) {
    return this.http.put(`${environment.urlPrefix}${constant.custom_contact_template_crm}`, data);
  }

  deleteContactTemplateFields(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.custom_contact_template_crm}?id=${id}`);
  }

  getCompanyModelFields() {
    return this.http.get(`${environment.urlPrefix}${constant.customCompanyModel}`);
  }

  putCompanyModelFields(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.customCompanyModel}`, body);
  }
  getCompanyTemplateFields() {
    return this.http.get(`${environment.urlPrefix}${constant.CustomCompanyTemplate}`);
  }

  postCompanyTemplateFields(data: any) {
    return this.http.post(`${environment.urlPrefix}${constant.CustomCompanyTemplate}`, data);
  }

  putCompanyTemplateFields(data: any) {
    return this.http.put(`${environment.urlPrefix}${constant.CustomCompanyTemplate}`, data);
  }

  deleteCompanyTemplateFields(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.CustomCompanyTemplate}?id=${id}`);
  }

  getEnquiryModelFields() {
    return this.http.get(`${environment.urlPrefix}${constant.customEnquiryModel}`);
  }

  putEnquiryModelFields(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.customEnquiryModel}`, body);
  }

  getEnquiryTemplateFields() {
    return this.http.get(`${environment.urlPrefix}${constant.CustomEnquiryTemplate}`);
  }

  postEnquiryTemplateFields(data: any) {
    return this.http.post(`${environment.urlPrefix}${constant.CustomEnquiryTemplate}`, data);
  }

  putEnquiryTemplateFields(data: any) {
    return this.http.put(`${environment.urlPrefix}${constant.CustomEnquiryTemplate}`, data);
  }

  deleteEnquiryTemplateFields(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.CustomEnquiryTemplate}?id=${id}`);
  }

  getClientModelFields() {
    return this.http.get(`${environment.urlPrefix}${constant.customClientModel}`);
  }
  putClientModelFields(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.customClientModel}`,body);
  }
  getClientTemplateFields() {
    return this.http.get(`${environment.urlPrefix}${constant.CustomClientTemplate}`);
  }
  postClientTemplateFields(data: any) {
    return this.http.post(`${environment.urlPrefix}${constant.CustomClientTemplate}`, data);
  }
  putClientTemplateFields(data: any) {
    return this.http.put(`${environment.urlPrefix}${constant.CustomClientTemplate}`, data);
  }
  deleteClientTemplateFields(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.CustomClientTemplate}?id=${id}`);
  }

  getClientProjectModelFields() {
    return this.http.get(`${environment.urlPrefix}${constant.customClientProjectModel}`);
  }
  putClientProjectModelFields(body: any) {
    return this.http.put(`${environment.urlPrefix}${constant.customClientProjectModel}`,body);
  }
  getClientProjectTemplateFields() {
    return this.http.get(`${environment.urlPrefix}${constant.CustomClientProjectTemplate}`);
  }
  postClientProjectTemplateFields(data: any) {
    return this.http.post(`${environment.urlPrefix}${constant.CustomClientProjectTemplate}`, data);
  }
  putClientProjectTemplateFields(data: any) {
    return this.http.put(`${environment.urlPrefix}${constant.CustomClientProjectTemplate}`, data);
  }
  deleteClientProjectTemplateFields(id: string) {
    return this.http.delete(`${environment.urlPrefix}${constant.CustomClientProjectTemplate}?id=${id}`);
  }
}
