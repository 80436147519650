import { Component } from '@angular/core';
import { TwilioVoiceService } from '../../service/twilio-voice.service';

@Component({
  selector: 'app-dial-pad',
  templateUrl: './dial-pad.component.html',
  styleUrls: ['./dial-pad.component.scss']
})
export class DialPadComponent {
  dialedNumber = '';

  constructor(private twilioVoiceService: TwilioVoiceService) { }
  // Define number pad layout
  numberPad = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['*', '0', '#']
  ];

  press(value: string) {
    this.dialedNumber += value;
    this.twilioVoiceService.sendDigits(value);
  }

  clear() {
    this.dialedNumber = '';
  }
}
