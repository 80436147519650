export const mobile_json = [
  {
    "country": "Afghanistan",
    "country_code": "+93"
  },
  {
    "country": "Albania",
    "country_code": "+355"
  },
  {
    "country": "Algeria",
    "country_code": "+213"
  },
  {
    "country": "Andorra",
    "country_code": "+376"
  },
  {
    "country": "Angola",
    "country_code": "+244"
  },
  {
    "country": "Argentina",
    "country_code": "+54"
  },
  {
    "country": "Armenia",
    "country_code": "+374"
  },
  {
    "country": "Aruba",
    "country_code": "+297"
  },
  {
    "country": "Australia",
    "country_code": "+61"
  },
  {
    "country": "Australian External Territories",
    "country_code": "+672"
  },
  {
    "country": "Austria",
    "country_code": "+43"
  },
  {
    "country": "Azerbaijan",
    "country_code": "+994"
  },
  {
    "country": "Bahrain",
    "country_code": "+973"
  },
  {
    "country": "Bangladesh",
    "country_code": "+880"
  },
  {
    "country": "Belarus",
    "country_code": "+375"
  },
  {
    "country": "Belgium",
    "country_code": "+32"
  },
  {
    "country": "Belize",
    "country_code": "+501"
  },
  {
    "country": "Benin",
    "country_code": "+229"
  },
  {
    "country": "Bhutan",
    "country_code": "+975"
  },
  {
    "country": "Bolivia (Plurinational State of)",
    "country_code": "+591"
  },
  {
    "country": "Bonaire, Sint Eustatius and Saba",
    "country_code": "+599"
  },
  {
    "country": "Bosnia and Herzegovina",
    "country_code": "+387"
  },
  {
    "country": "Botswana",
    "country_code": "+267"
  },
  {
    "country": "Brazil",
    "country_code": "+55"
  },
  {
    "country": "Brunei Darussalam",
    "country_code": "+673"
  },
  {
    "country": "Bulgaria",
    "country_code": "+359"
  },
  {
    "country": "Burkina Faso",
    "country_code": "+226"
  },
  {
    "country": "Burundi",
    "country_code": "+257"
  },
  {
    "country": "Cambodia",
    "country_code": "+855"
  },
  {
    "country": "Cameroon",
    "country_code": "+237"
  },
  {
    "country": "Cape Verde",
    "country_code": "+238"
  },
  {
    "country": "Central African Rep.",
    "country_code": "+236"
  },
  {
    "country": "Chad",
    "country_code": "+235"
  },
  {
    "country": "Chile",
    "country_code": "+56"
  },
  {
    "country": "China",
    "country_code": "+86"
  },
  {
    "country": "Colombia",
    "country_code": "+57"
  },
  {
    "country": "Comoros",
    "country_code": "+269"
  },
  {
    "country": "Congo",
    "country_code": "+242"
  },
  {
    "country": "Cook Islands",
    "country_code": "+682"
  },
  {
    "country": "Costa Rica",
    "country_code": "+506"
  },
  {
    "country": "Côte d'Ivoire",
    "country_code": "+225"
  },
  {
    "country": "Croatia",
    "country_code": "+385"
  },
  {
    "country": "Cuba",
    "country_code": "+53"
  },
  {
    "country": "Cyprus",
    "country_code": "+357"
  },
  {
    "country": "Czech Rep.",
    "country_code": "+420"
  },
  {
    "country": "Dem. People's Rep. of Korea",
    "country_code": "+850"
  },
  {
    "country": "Dem. Rep. of the Congo",
    "country_code": "+243"
  },
  {
    "country": "Denmark",
    "country_code": "+45"
  },
  {
    "country": "Diego Garcia",
    "country_code": "+246"
  },
  {
    "country": "Djibouti",
    "country_code": "+253"
  },
  {
    "country": "Ecuador",
    "country_code": "+593"
  },
  {
    "country": "Egypt",
    "country_code": "+20"
  },
  {
    "country": "El Salvador",
    "country_code": "+503"
  },
  {
    "country": "Equatorial Guinea",
    "country_code": "+240"
  },
  {
    "country": "Eritrea",
    "country_code": "+291"
  },
  {
    "country": "Estonia",
    "country_code": "+372"
  },
  {
    "country": "Ethiopia",
    "country_code": "+251"
  },
  {
    "country": "Falkland Islands (Malvinas)",
    "country_code": "+500"
  },
  {
    "country": "Faroe Islands",
    "country_code": "+298"
  },
  {
    "country": "Fiji",
    "country_code": "+679"
  },
  {
    "country": "Finland",
    "country_code": "+358"
  },
  {
    "country": "France",
    "country_code": "+33"
  },
  {
    "country": "French Dep. and Territories in the Indian Ocean",
    "country_code": "+262"
  },
  {
    "country": "French Guiana",
    "country_code": "+594"
  },
  {
    "country": "French Polynesia",
    "country_code": "+689"
  },
  {
    "country": "Gabon",
    "country_code": "+241"
  },
  {
    "country": "Gambia",
    "country_code": "+220"
  },
  {
    "country": "Georgia",
    "country_code": "+995"
  },
  {
    "country": "Germany",
    "country_code": "+49"
  },
  {
    "country": "Ghana",
    "country_code": "+233"
  },
  {
    "country": "Gibraltar",
    "country_code": "+350"
  },
  {
    "country": "Global Mobile Satellite System (GMSS), shared",
    "country_code": "+881"
  },
  {
    "country": "Greece",
    "country_code": "+30"
  },
  {
    "country": "Greenland",
    "country_code": "+299"
  },
  {
    "country": "Group of countries, shared code",
    "country_code": "+388"
  },
  {
    "country": "Guadeloupe",
    "country_code": "+590"
  },
  {
    "country": "Guatemala",
    "country_code": "+502"
  },
  {
    "country": "Guinea",
    "country_code": "+224"
  },
  {
    "country": "Guinea-Bissau",
    "country_code": "+245"
  },
  {
    "country": "Guyana",
    "country_code": "+592"
  },
  {
    "country": "Haiti",
    "country_code": "+509"
  },
  {
    "country": "Honduras",
    "country_code": "+504"
  },
  {
    "country": "Hong Kong, China",
    "country_code": "+852"
  },
  {
    "country": "Hungary",
    "country_code": "+36"
  },
  {
    "country": "Iceland",
    "country_code": "+354"
  },
  {
    "country": "India",
    "country_code": "+91"
  },
  {
    "country": "Indonesia",
    "country_code": "+62"
  },
  {
    "country": "Inmarsat SNAC",
    "country_code": "+870"
  },
  {
    "country": "International Freephone Service",
    "country_code": "+800"
  },
  {
    "country": "International Networks, shared code",
    "country_code": "+882"
  },
  {
    "country": "International Networks, shared code",
    "country_code": "+883"
  },
  {
    "country": "International Premium Rate Service (IPRS)",
    "country_code": "+979"
  },
  {
    "country": "International Shared Cost Service (ISCS)",
    "country_code": "+808"
  },
  {
    "country": "Iran (Islamic Republic of)",
    "country_code": "+98"
  },
  {
    "country": "Iraq",
    "country_code": "+964"
  },
  {
    "country": "Ireland",
    "country_code": "+353"
  },
  {
    "country": "Israel",
    "country_code": "+972"
  },
  {
    "country": "Italy",
    "country_code": "+39"
  },
  {
    "country": "Japan",
    "country_code": "+81"
  },
  {
    "country": "Jordan",
    "country_code": "+962"
  },
  {
    "country": "Kenya",
    "country_code": "+254"
  },
  {
    "country": "Kiribati",
    "country_code": "+686"
  },
  {
    "country": "Korea (Rep. of)",
    "country_code": "+82"
  },
  {
    "country": "Kuwait",
    "country_code": "+965"
  },
  {
    "country": "Kyrgyzstan",
    "country_code": "+996"
  },
  {
    "country": "Lao P.D.R.",
    "country_code": "+856"
  },
  {
    "country": "Latvia",
    "country_code": "+371"
  },
  {
    "country": "Lebanon",
    "country_code": "+961"
  },
  {
    "country": "Lesotho",
    "country_code": "+266"
  },
  {
    "country": "Liberia",
    "country_code": "+231"
  },
  {
    "country": "Libya",
    "country_code": "+218"
  },
  {
    "country": "Liechtenstein",
    "country_code": "+423"
  },
  {
    "country": "Lithuania",
    "country_code": "+370"
  },
  {
    "country": "Luxembourg",
    "country_code": "+352"
  },
  {
    "country": "Macao, China",
    "country_code": "+853"
  },
  {
    "country": "Madagascar",
    "country_code": "+261"
  },
  {
    "country": "Malawi",
    "country_code": "+265"
  },
  {
    "country": "Malaysia",
    "country_code": "+60"
  },
  {
    "country": "Maldives",
    "country_code": "+960"
  },
  {
    "country": "Mali",
    "country_code": "+223"
  },
  {
    "country": "Malta",
    "country_code": "+356"
  },
  {
    "country": "Marshall Islands",
    "country_code": "+692"
  },
  {
    "country": "Martinique",
    "country_code": "+596"
  },
  {
    "country": "Mauritania",
    "country_code": "+222"
  },
  {
    "country": "Mauritius",
    "country_code": "+230"
  },
  {
    "country": "Mexico",
    "country_code": "+52"
  },
  {
    "country": "Micronesia",
    "country_code": "+691"
  },
  {
    "country": "Moldova (Republic of)",
    "country_code": "+373"
  },
  {
    "country": "Monaco",
    "country_code": "+377"
  },
  {
    "country": "Mongolia",
    "country_code": "+976"
  },
  {
    "country": "Montenegro",
    "country_code": "+382"
  },
  {
    "country": "Morocco",
    "country_code": "+212"
  },
  {
    "country": "Mozambique",
    "country_code": "+258"
  },
  {
    "country": "Myanmar",
    "country_code": "+95"
  },
  {
    "country": "Namibia",
    "country_code": "+264"
  },
  {
    "country": "Nauru",
    "country_code": "+674"
  },
  {
    "country": "Nepal",
    "country_code": "+977"
  },
  {
    "country": "Netherlands",
    "country_code": "+31"
  },
  {
    "country": "New Caledonia",
    "country_code": "+687"
  },
  {
    "country": "New Zealand",
    "country_code": "+64"
  },
  {
    "country": "Nicaragua",
    "country_code": "+505"
  },
  {
    "country": "Niger",
    "country_code": "+227"
  },
  {
    "country": "Nigeria",
    "country_code": "+234"
  },
  {
    "country": "Niue",
    "country_code": "+683"
  },
  {
    "country": "Norway",
    "country_code": "+47"
  },
  {
    "country": "Oman",
    "country_code": "+968"
  },
  {
    "country": "Pakistan",
    "country_code": "+92"
  },
  {
    "country": "Palau",
    "country_code": "+680"
  },
  {
    "country": "Panama",
    "country_code": "+507"
  },
  {
    "country": "Papua New Guinea",
    "country_code": "+675"
  },
  {
    "country": "Paraguay",
    "country_code": "+595"
  },
  {
    "country": "Peru",
    "country_code": "+51"
  },
  {
    "country": "Philippines",
    "country_code": "+63"
  },
  {
    "country": "Poland",
    "country_code": "+48"
  },
  {
    "country": "Portugal",
    "country_code": "+351"
  },
  {
    "country": "Qatar",
    "country_code": "+974"
  },
  {
    "country": "Romania",
    "country_code": "+40"
  },
  {
    "country": "Russian Federation",
    "country_code": "+7"
  },
  {
    "country": "Rwanda",
    "country_code": "+250"
  },
  {
    "country": "Saint Helena, Ascension and Tristan da Cunha",
    "country_code": "+247"
  },
  {
    "country": "Saint Helena, Ascension and Tristan da Cunha",
    "country_code": "+290"
  },
  {
    "country": "Saint Pierre and Miquelon",
    "country_code": "+508"
  },
  {
    "country": "Samoa",
    "country_code": "+685"
  },
  {
    "country": "San Marino",
    "country_code": "+378"
  },
  {
    "country": "Sao Tome and Principe",
    "country_code": "+239"
  },
  {
    "country": "Saudi Arabia",
    "country_code": "+966"
  },
  {
    "country": "Senegal",
    "country_code": "+221"
  },
  {
    "country": "Serbia",
    "country_code": "+381"
  },
  {
    "country": "Seychelles",
    "country_code": "+248"
  },
  {
    "country": "Sierra Leone",
    "country_code": "+232"
  },
  {
    "country": "Singapore",
    "country_code": "+65"
  },
  {
    "country": "Slovakia",
    "country_code": "+421"
  },
  {
    "country": "Slovenia",
    "country_code": "+386"
  },
  {
    "country": "Solomon Islands",
    "country_code": "+677"
  },
  {
    "country": "Somalia",
    "country_code": "+252"
  },
  {
    "country": "South Africa",
    "country_code": "+27"
  },
  {
    "country": "South Sudan",
    "country_code": "+211"
  },
  {
    "country": "Spain",
    "country_code": "+34"
  },
  {
    "country": "Sri Lanka",
    "country_code": "+94"
  },
  {
    "country": "Sudan",
    "country_code": "+249"
  },
  {
    "country": "Suriname",
    "country_code": "+597"
  },
  {
    "country": "Swaziland",
    "country_code": "+268"
  },
  {
    "country": "Sweden",
    "country_code": "+46"
  },
  {
    "country": "Switzerland",
    "country_code": "+41"
  },
  {
    "country": "Syrian Arab Republic",
    "country_code": "+963"
  },
  {
    "country": "Taiwan, China",
    "country_code": "+886"
  },
  {
    "country": "Tajikistan",
    "country_code": "+992"
  },
  {
    "country": "Tanzania",
    "country_code": "+255"
  },
  {
    "country": "Telecommunications for Disaster Relief (TDR)",
    "country_code": "+888"
  },
  {
    "country": "Thailand",
    "country_code": "+66"
  },
  {
    "country": "The Former Yugoslav Republic of Macedonia",
    "country_code": "+389"
  },
  {
    "country": "Timor-Leste",
    "country_code": "+670"
  },
  {
    "country": "Togo",
    "country_code": "+228"
  },
  {
    "country": "Tokelau",
    "country_code": "+690"
  },
  {
    "country": "Tonga",
    "country_code": "+676"
  },
  {
    "country": "Trial of a proposed new international service",
    "country_code": "+991"
  },
  {
    "country": "Tunisia",
    "country_code": "+216"
  },
  {
    "country": "Turkey",
    "country_code": "+90"
  },
  {
    "country": "Turkmenistan",
    "country_code": "+993"
  },
  {
    "country": "Tuvalu",
    "country_code": "+688"
  },
  {
    "country": "Uganda",
    "country_code": "+256"
  },
  {
    "country": "Ukraine",
    "country_code": "+380"
  },
  {
    "country": "United Arab Emirates",
    "country_code": "+971"
  },
  {
    "country": "United Kingdom",
    "country_code": "+44"
  },
  {
    "country": "United States",
    "country_code": "+1"
  },
  {
    "country": "Universal Personal Telecommunication (UPT)",
    "country_code": "+878"
  },
  {
    "country": "Uruguay",
    "country_code": "+598"
  },
  {
    "country": "Uzbekistan",
    "country_code": "+998"
  },
  {
    "country": "Vanuatu",
    "country_code": "+678"
  },
  {
    "country": "Vatican",
    "country_code": "+379"
  },
  {
    "country": "Venezuela (Bolivarian Republic of)",
    "country_code": "+58"
  },
  {
    "country": "Viet Nam",
    "country_code": "+84"
  },
  {
    "country": "Wallis and Futuna",
    "country_code": "+681"
  },
  {
    "country": "Yemen",
    "country_code": "+967"
  },
  {
    "country": "Zambia",
    "country_code": "+260"
  },
  {
    "country": "Zimbabwe",
    "country_code": "+263"
  },
  {
    "country": "Reserved",
    "country_code": "+970"
  }
];


export const time_zone_json = [
  {
    "name": "Canada",
    "countries": [
      {
        "name": "Pacific Time - US & Canada",
        "timezone": "America/Vancouver"
      },
      {
        "name": "Mountain Time - US & Canada",
        "timezone": "America/Denver"
      },
      {
        "name": "Central Time - US & Canada",
        "timezone": "America/Chicago"
      },
      {
        "name": "Eastern Time - US & Canada",
        "timezone": "America/New_York"
      },
      {
        "name": "Alaska Time",
        "timezone": "America/Anchorage"
      },
      {
        "name": "Arizona, Yukon Time",
        "timezone": "America/Phoenix"
      },
      {
        "name": "Newfoundland Time",
        "timezone": "America/St_Johns"
      },
      {
        "name": "Hawaii Time",
        "timezone": "Pacific/Honolulu"
      }
    ]
  },
  {
    "name": "America",
    "countries": [
      {
        "name": "America/Adak",
        "timezone": "America/Adak"
      },
      {
        "name": "Buenos Aires Time",
        "timezone": "America/Argentina/Buenos_Aires"
      },
      {
        "name": "Asuncion Time",
        "timezone": "America/Asuncion"
      },
      {
        "name": "Bogota, Jamaica, Lima Time",
        "timezone": "America/Bogota"
      },
      {
        "name": "America/Campo Grande",
        "timezone": "America/Campo_Grande"
      },
      {
        "name": "Caracas Time",
        "timezone": "America/Caracas"
      },
      {
        "name": "America/Godthab",
        "timezone": "America/Godthab"
      },
      {
        "name": "Atlantic Time",
        "timezone": "America/Halifax"
      },
      {
        "name": "Saskatchewan, Guatemala, Costa Rica Time",
        "timezone": "America/Guatemala"
      },
      {
        "name": "America/Havana",
        "timezone": "America/Havana"
      },
      {
        "name": "America/Mazatlan",
        "timezone": "America/Mazatlan"
      },
      {
        "name": "Mexico City Time",
        "timezone": "America/Mexico_City"
      },
      {
        "name": "Montevideo Time",
        "timezone": "America/Montevideo"
      },
      {
        "name": "America/Miquelon",
        "timezone": "America/Miquelon"
      },
      {
        "name": "America/Noronha",
        "timezone": "America/Noronha"
      },
      {
        "name": "Santiago Time",
        "timezone": "America/Santiago"
      },
      {
        "name": "America/Santa Isabel",
        "timezone": "America/Santa_Isabel"
      },
      {
        "name": "Atlantic Standard Time",
        "timezone": "America/Barbados"
      },
      {
        "name": "Brasilia Time",
        "timezone": "America/Sao_Paulo"
      }
    ]
  },
  {
    "name": "Africa",
    "countries": [
      {
        "name": "Africa/Cairo",
        "timezone": "Africa/Cairo"
      },
      {
        "name": "Central Africa Time",
        "timezone": "Africa/Khartoum"
      },
      {
        "name": "West Africa Time",
        "timezone": "Africa/Lagos"
      },
      {
        "name": "Africa/Windhoek",
        "timezone": "Africa/Windhoek"
      }
    ]
  },
  {
    "name": "Asia",
    "countries": [
      {
        "name": "Jordan Time",
        "timezone": "Asia/Amman"
      },
      {
        "name": "Baghdad, East Africa Time",
        "timezone": "Asia/Baghdad"
      },
      {
        "name": "Asia/Baku",
        "timezone": "Asia/Baku"
      },
      {
        "name": "Lebanon Time",
        "timezone": "Asia/Beirut"
      },
      {
        "name": "Syria Time",
        "timezone": "Asia/Damascus"
      },
      {
        "name": "Asia/Dhaka",
        "timezone": "Asia/Dhaka"
      },
      {
        "name": "Dubai Time",
        "timezone": "Asia/Dubai"
      },
      {
        "name": "Asia/Gaza",
        "timezone": "Asia/Gaza"
      },
      {
        "name": "Asia/Irkutsk",
        "timezone": "Asia/Irkutsk"
      },
      {
        "name": "Indochina Time",
        "timezone": "Asia/Bangkok"
      },
      {
        "name": "Israel Time",
        "timezone": "Asia/Jerusalem"
      },
      {
        "name": "Kabul Time",
        "timezone": "Asia/Kabul"
      },
      {
        "name": "Pakistan, Maldives Time",
        "timezone": "Asia/Karachi"
      },
      {
        "name": "Kathmandu Time",
        "timezone": "Asia/Kathmandu"
      },
      {
        "name": "Krasnoyarsk Time",
        "timezone": "Asia/Krasnoyarsk"
      },
      {
        "name": "Asia/Omsk",
        "timezone": "Asia/Omsk"
      },
      {
        "name": "Asia/Rangoon",
        "timezone": "Asia/Yangon"
      },
      {
        "name": "China, Singapore, Perth",
        "timezone": "Asia/Shanghai"
      },
      {
        "name": "Tehran Time",
        "timezone": "Asia/Tehran"
      },
      {
        "name": "Japan, Korea Time",
        "timezone": "Asia/Tokyo"
      },
      {
        "name": "Asia/Vladivostok",
        "timezone": "Asia/Vladivostok"
      },
      {
        "name": "Asia/Yakutsk",
        "timezone": "Asia/Yakutsk"
      },
      {
        "name": "Yekaterinburg Time",
        "timezone": "Asia/Yekaterinburg"
      },
      {
        "name": "Asia/Yerevan",
        "timezone": "Asia/Yerevan"
      },
      {
        "name": "India Standard Time",
        "timezone": "Asia/Kolkata"
      }
    ]
  },
  {
    "name": "Atlantic",
    "countries": [
      {
        "name": "Azores Time",
        "timezone": "Atlantic/Azores"
      },
      {
        "name": "Cape Verde Time",
        "timezone": "Atlantic/Cape_Verde"
      }
    ]
  },
  {
    "name": "Australia",
    "countries": [
      {
        "name": "Adelaide Time",
        "timezone": "Australia/Adelaide"
      },
      {
        "name": "Brisbane Time",
        "timezone": "Australia/Brisbane"
      },
      {
        "name": "Australia/Darwin",
        "timezone": "Australia/Darwin"
      },
      {
        "name": "Australia/Eucla",
        "timezone": "Australia/Eucla"
      },
      {
        "name": "Australia/Lord Howe",
        "timezone": "Australia/Lord_Howe"
      },
      {
        "name": "Australia/Perth",
        "timezone": "Australia/Perth"
      },
      {
        "name": "Sydney, Melbourne Time",
        "timezone": "Australia/Sydney"
      }
    ]
  },
  {
    "name": "UTC",
    "countries": [
      {
        "name": "UTC Time",
        "timezone": "UTC"
      }
    ]
  },
  {
    "name": "Europe",
    "countries": [
      {
        "name": "Central European Time",
        "timezone": "Europe/Berlin"
      },
      {
        "name": "Eastern European Time",
        "timezone": "Europe/Bucharest"
      },
      {
        "name": "UK, Ireland, Lisbon Time",
        "timezone": "Europe/London"
      },
      {
        "name": "Minsk Time",
        "timezone": "Europe/Minsk"
      },
      {
        "name": "Moscow Time",
        "timezone": "Europe/Moscow"
      },
      {
        "name": "Turkey Time",
        "timezone": "Europe/Istanbul"
      }
    ]
  },
  {
    "name": "Pacific",
    "countries": [
      {
        "name": "Pacific/Apia",
        "timezone": "Pacific/Apia"
      },
      {
        "name": "Auckland Time",
        "timezone": "Pacific/Auckland"
      },
      {
        "name": "Pacific/Chatham",
        "timezone": "Pacific/Chatham"
      },
      {
        "name": "Pacific/Easter",
        "timezone": "Pacific/Easter"
      },
      {
        "name": "Pacific/Fiji",
        "timezone": "Pacific/Fiji"
      },
      {
        "name": "Pacific/Gambier",
        "timezone": "Pacific/Gambier"
      },
      {
        "name": "Pacific/Kiritimati",
        "timezone": "Pacific/Kiritimati"
      },
      {
        "name": "Pacific/Majuro",
        "timezone": "Pacific/Majuro"
      },
      {
        "name": "Pacific/Marquesas",
        "timezone": "Pacific/Marquesas"
      },
      {
        "name": "Pacific/Norfolk",
        "timezone": "Pacific/Norfolk"
      },
      {
        "name": "Pacific/Noumea",
        "timezone": "Pacific/Noumea"
      },
      {
        "name": "Pacific/Pago Pago",
        "timezone": "Pacific/Pago_Pago"
      },
      {
        "name": "Pacific/Pitcairn",
        "timezone": "Pacific/Pitcairn"
      },
      {
        "name": "Pacific/Tarawa",
        "timezone": "Pacific/Tarawa"
      },
      {
        "name": "Pacific/Tongatapu",
        "timezone": "Pacific/Tongatapu"
      }
    ]
  }
];