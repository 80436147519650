<div class="modal-header">
    <h5 class="modal-title fw-600"><span>{{dayoff ? "Update" : "Add New"}}</span> {{dayoffjson.heading}}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="ngbActiveModal.dismiss()"></button>
</div>
<div class="modal-body">
    <div class="row mb-2">
        <form [formGroup]="dayoffForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12">
                    <label class="fw-600 mb-2 ">{{dayoffjson.heading}} {{dayoffjson.title}} <span
                            class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input style="outline: none" matInput placeholder={{dayoffjson.title}} formControlName="title"
                            #myFirstInput />
                        <mat-error class="error text-danger d-flex" *ngIf="submitted && f['title'].errors">
                            <mat-icon class="font-size-12 me-1 my-auto">info</mat-icon>
                            <span *ngIf="f['title'].errors['required']">{{dayoffjson.title}}
                                {{errorjson.required}}</span>
                            <span *ngIf="f['title'].errors['pattern']">Invalid Dayoff title</span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <label class="fw-600 mb-2 ">{{dayoffjson.date_range}} <span
                            class="text-danger">*</span></label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-date-range-input [rangePicker]="picker" [min]="currentDate">
                            <input matStartDate placeholder="Start date" formControlName="start_date">
                            <input matEndDate placeholder="End date" formControlName="end_date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                        <mat-error class="error d-flex align-items-center" *ngIf="submitted && (f['start_date'].errors || f['end_date'].errors)">
                            <mat-icon class="font-size-12 my-auto w-3 d-flex align-items-center">info</mat-icon>Invalid
                            Date</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 mb-3">
                    <label class="fw-600 mb-2 ">{{dayoffjson.start_time}} <span
                            class="text-danger">*</span></label>
                    <input matInput type="time" class="w-100 h-3rem border-radius-5 ps-3 pe-2"
                        style="border: 1px solid #9a9797" formControlName="start_time">
                    <mat-error class="error text-danger d-flex align-items-center"
                        *ngIf="submitted && f['start_time'].errors">
                        <mat-icon class="font-size-12 my-auto w-3 d-flex align-items-center">info</mat-icon>
                        <span *ngIf="f['start_time'].errors['required']">{{dayoffjson.start_time}}
                            {{errorjson.required}}</span>
                    </mat-error>
                </div>

                <div class="col-12">
                    <label class="fw-600 mb-2 ">{{dayoffjson.end_time}} <span
                            class="text-danger">*</span></label>
                    <input matInput type="time" class="w-100 h-3rem border-radius-5 ps-3 pe-2"
                        style="border: 1px solid #9a9797" formControlName="end_time">
                    <mat-error class="error text-danger d-flex align-items-center"
                        *ngIf="submitted && f['end_time'].errors">
                        <mat-icon class="font-size-12 my-auto w-3 d-flex align-items-center">info</mat-icon>
                        <span *ngIf="f['end_time'].errors['required']">{{dayoffjson.end_time}}
                            {{errorjson.required}}</span>
                    </mat-error>
                </div>

            </div>

            <div class="d-flex align-items-center justify-content-end modal-footer mt-2">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="btn btn-light me-2" type="button" (click)="ngbActiveModal.dismiss()">
                        {{dayoffjson.close_btn}}
                    </button>
                    <button class="btn btn-primary" type="submit">{{dayoffjson.save_btn}}</button>
                </div>
            </div>
        </form>
    </div>
</div>